import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Loader } from '@mantine/core';
import FreePlanBanner from '../FreePlanBanner';
import States from './States';
import styles from '../../billing.module.scss';

const Banner = (props) => {
  const { buyLicense, unassignedLicenses, checkout } = props;
  const { planInfo, loading } = useSelector((state) => state.plan);

  return (
    <div className={styles.billingPage}>
      {loading && <Loader />}
      {!loading && planInfo.hasSubscription && (
        <States buyLicense={buyLicense} unassignedLicenses={unassignedLicenses} />
      )}
      {!loading && !planInfo.hasSubscription && <FreePlanBanner checkout={checkout} />}
    </div>
  );
};

Banner.propTypes = {
  buyLicense: PropTypes.func.isRequired,
  checkout: PropTypes.func.isRequired,
  unassignedLicenses: PropTypes.number.isRequired
};

export default Banner;
