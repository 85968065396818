import { Textarea } from '@mantine/core';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const View = ({ value, onChange }) => {
  return (
    <div className={styles.wrapper}>
      <header>
        <h3>Persona</h3>
        <p>
          Personas are the feature that changes the way Albus responds. You can alter the bot&apos;s
          personality, tone, and communication style or give it more context to answer from.
        </p>
      </header>
      <Textarea
        name='persona'
        classNames={{
          input: styles.personaInput
        }}
        placeholder='Be professional and concise, emphasizing a formal tone suitable for business interactions'
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

View.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default View;
