import { toast } from 'react-toastify';
import api from './api';

const trutoApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTrutoLinkToken: build.query({
      query: (params) => ({
        url: '/truto/link-token',
        method: 'GET',
        params
      }),
      transformResponse: (response) => {
        if (response) {
          return response;
        } else {
          toast.error('Failed to fetch truto link token.');
        }
      }
    }),
    getTrutoIntegratedAccountToken: build.query({
      query: (payload) => ({
        url: `/truto/integrated-account/token/${payload}`,
        method: 'GET'
      }),
      transformResponse: (response) => {
        if (response) {
          return response;
        } else {
          toast.error('Failed to fetch truto integated account link token.');
        }
      },
      transformErrorResponse: (response) => {
        toast.error(response.data.message);
      }
    })
  })
});

export const { useLazyGetTrutoLinkTokenQuery, useLazyGetTrutoIntegratedAccountTokenQuery } =
  trutoApi;

export default trutoApi;
