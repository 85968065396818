import { ActionIcon, Avatar, Button, FileButton, TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import UploadDp from 'assets/icons/upload-dp.svg';
import FallbackImage from 'assets/company-fallback.svg';
import styles from './styles.module.scss';

const View = (props) => {
  const {
    isLoading,
    handleDeleteCtaClick,
    teamDp,
    teamNameValue,
    handleDpSave,
    showEdit,
    setShowEdit,
    handleSaveEdit,
    handleCancel,
    isDisabled,
    handleOnChange
  } = props;

  return (
    <div className={styles.wrapper}>
      <h1>Company name</h1>
      <div className={styles.company}>
        <div className={styles.avatar}>
          <Avatar
            size={42}
            radius='md'
            src={teamDp || FallbackImage}
            alt='dp'
            classNames={{ image: styles.avatarImage }}
          />

          <FileButton
            className={styles.fileButton}
            onChange={handleDpSave}
            accept='image/png,image/jpeg'
          >
            {(props) => (
              <Button {...props}>
                <img src={UploadDp} alt='upload dp' />
              </Button>
            )}
          </FileButton>
        </div>

        {showEdit ? (
          <>
            <TextInput
              classNames={{ input: styles.input, error: styles.error }}
              value={teamNameValue}
              onChange={(e) => handleOnChange(e.target.value)}
              error={
                !teamNameValue?.length || teamNameValue.length > 30
                  ? 'Name must be between 1 and 30 characters'
                  : null
              }
            />
            <Button
              className={classNames('primaryBtn', { [styles.disabled]: isDisabled })}
              size='sm'
              onClick={handleSaveEdit}
              disabled={isDisabled}
              loading={isLoading}
            >
              Save
            </Button>
            <ActionIcon variant='transparent' onClick={handleCancel}>
              <SVGIcon name={ICONS_LIST.CROSS_RED} height={37} width={36} />
            </ActionIcon>
          </>
        ) : (
          <>
            <p className={styles.teamName}>{teamNameValue || 'User'}</p>
            <ActionIcon variant='transparent' onClick={() => setShowEdit(true)}>
              <SVGIcon name={ICONS_LIST.EDIT} height={16} width={16} />
            </ActionIcon>
          </>
        )}
      </div>
      <div className={styles.divider} />
      <div className={styles.deleteWrapper}>
        <div className={styles.delete}>
          <h3>Delete company</h3>
          <p>Delete your company, people, wiki and all other data.</p>
        </div>
        <Button
          className={classNames('primaryBtnOutline', styles.button)}
          onClick={handleDeleteCtaClick}
        >
          Delete company
        </Button>
      </div>
    </div>
  );
};

View.propTypes = {
  teamDp: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  handleDeleteCtaClick: PropTypes.func.isRequired,
  teamNameValue: PropTypes.string.isRequired,
  handleDpSave: PropTypes.func.isRequired,
  showEdit: PropTypes.bool.isRequired,
  setShowEdit: PropTypes.func.isRequired,
  handleSaveEdit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired
};

export default View;
