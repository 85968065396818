import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';

const initialState = {
  licenseStatus: 'assigned',
  isLoading: false,
  totalRequests: 0,
  total: 10,
  users: [],
  searchQuery: ''
};

export const licenseSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveLicenseUsersInfo: (state, action) => {
      return produce(state, (draftState) => {
        draftState.users = action.payload.data;
        draftState.total = action.payload.total;
      });
    },
    saveTotalRequests: (state, action) => {
      return produce(state, (draftState) => {
        draftState.totalRequests = action.payload;
      });
    },
    isUsersLoading: (state, action) => {
      return produce(state, (draftState) => {
        draftState.isLoading = action.payload;
      });
    },
    updateSearchQuery: (state, action) => {
      return produce(state, (draftState) => {
        draftState.searchQuery = action.payload;
      });
    }
  }
});

export const { saveLicenseUsersInfo, isUsersLoading, saveTotalRequests, updateSearchQuery } =
  licenseSlice.actions;

export default licenseSlice.reducer;
