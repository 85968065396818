import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useDebouncedValue, usePagination } from '@mantine/hooks';
import { Loader, Pagination } from '@mantine/core';
import View from './View';
import integrationsApi, {
  useFetchSubDocumentsQuery,
  useModifySourceMutation,
  useTrainSourcesMutation,
  useDisconnectSourcesMutation
} from 'redux/services/integrations';
import SearchEmptyState from '../../SearchEmptyState';
import { SOURCE_ACTION } from '../../../constants';
import { NAVBAR_ROUTES, PROVIDERS } from 'utils/constants';

const Tab = (props) => {
  const {
    tab,
    searchValue,
    setCount,
    teamId,
    count,
    selectedTab,
    selectedSources,
    setSelectedSources,
    isAllSelected,
    setIsAllSelected,
    page,
    setPage,
    totalDocumentsCount
  } = props;
  const dispatch = useDispatch();
  const url = new URL(window.location.href);
  const docId = url.searchParams.get('docId');
  const navigate = useNavigate();
  const [subDocumentsList, setSubDocumentsList] = useState([]);
  const [excludedSubDocs, setExcludedSubDocs] = useState([]);
  const [showConfirmationModalData, setShowConfirmationModalData] = useState({
    open: false,
    action: null,
    data: null
  });
  const [debouncedSearchTerm] = useDebouncedValue(encodeURIComponent(searchValue.trim()), 500);

  const pagination = usePagination({
    total: Math.ceil(count[selectedTab] / 10),
    page,
    onChange: setPage,
    siblings: 2
  });

  const {
    data: {
      data: {
        documents: webpages = [],
        activeDocumentsCount,
        processingDocumentsCount,
        errorDocumentsCount,
        excludedDocumentsCount
      } = {}
    } = {},
    isFetching
  } = useFetchSubDocumentsQuery(
    {
      docId,
      params: { status: tab, q: debouncedSearchTerm || undefined, page, teamId }
    },
    { skip: tab !== selectedTab, refetchOnMountOrArgChange: true }
  );

  const [trainSources, { isLoading: retrainInitiating }] = useTrainSourcesMutation();
  const [modifySource, { isLoading: modificationInitiating }] = useModifySourceMutation();
  const [disconnectSources, status] = useDisconnectSourcesMutation();

  useEffect(() => {
    if (activeDocumentsCount !== undefined && count.active === null) {
      setCount({
        active: activeDocumentsCount,
        processing: processingDocumentsCount,
        error: errorDocumentsCount,
        excluded: excludedDocumentsCount
      });
    }
  }, [activeDocumentsCount, webpages]);

  useEffect(() => {
    if (webpages.length && !isFetching) {
      setSubDocumentsList(() => [
        ...subDocumentsList,
        ...webpages.map((page) => ({
          _id: page._id,
          name: page.name,
          status: page.status,
          documentId: page.documentId,
          error: page.error,
          errorMessage: page.errorMessage
        }))
      ]);
    }
  }, [webpages]);

  useEffect(() => {
    if (isAllSelected && webpages.length) {
      setSelectedSources([
        ...webpages.map((source) => !excludedSubDocs.includes(source._id) && source._id)
      ]);
    }
  }, [webpages]);

  useEffect(() => {
    if (searchValue) {
      setPage(1);
    }
  }, [searchValue]);

  function handleModalForExclude(source) {
    const noDocInErrorOrProcessing = processingDocumentsCount === 0 && errorDocumentsCount === 0;
    const areAllDocsSelected =
      (isAllSelected && !excludedSubDocs.length) || selectedSources.length === activeDocumentsCount;
    const isLastDocExclude = 1 + excludedDocumentsCount === totalDocumentsCount;

    if (noDocInErrorOrProcessing && areAllDocsSelected) {
      setShowConfirmationModalData({
        open: true,
        action: SOURCE_ACTION.EXCLUDE_WILL_REMOVE_SOURCE
      });
      return;
    }

    if (source) {
      if (noDocInErrorOrProcessing && isLastDocExclude) {
        setShowConfirmationModalData({
          open: true,
          action: SOURCE_ACTION.EXCLUDE_WILL_REMOVE_SOURCE
        });
        return;
      }

      setShowConfirmationModalData({
        open: true,
        action: SOURCE_ACTION.EXCLUDE,
        data: source._id
      });
      return;
    }

    setShowConfirmationModalData({
      open: true,
      action: SOURCE_ACTION.EXCLUDE
    });
  }

  function handleGlobalCheckClick() {
    setExcludedSubDocs([]);

    if (isAllSelected) {
      setSelectedSources([]);
      setIsAllSelected(false);
    } else {
      setSelectedSources([...webpages.map((source) => source._id)]);
      setIsAllSelected(true);
    }
  }

  function handleSingleCheckClick(source) {
    if (isAllSelected) {
      const indexInExcludedSubDocs = excludedSubDocs.findIndex((id) => id === source._id);
      if (indexInExcludedSubDocs !== -1) {
        const localExcludedSubDocs = [...excludedSubDocs];
        localExcludedSubDocs.splice(indexInExcludedSubDocs, 1);
        setExcludedSubDocs([...localExcludedSubDocs]);
      } else {
        setExcludedSubDocs((prev) => [...prev, source._id]);
      }
    }

    const index = selectedSources.findIndex((id) => id === source._id);
    if (index !== -1) {
      const localSelectedSources = [...selectedSources];
      localSelectedSources.splice(index, 1);
      setSelectedSources([...localSelectedSources]);
    } else {
      setSelectedSources((prev) => [...prev, source._id]);
    }
  }

  async function handleConfirm() {
    let response;
    let payload = {};
    if (showConfirmationModalData.action === SOURCE_ACTION.EXCLUDE_WILL_REMOVE_SOURCE) {
      payload = {
        provider: PROVIDERS.WEB,
        teamId,
        documentMongoIds: [docId]
      };

      response = await disconnectSources(payload);
    } else if (showConfirmationModalData.action === SOURCE_ACTION.RETRAIN) {
      payload.teamId = teamId;
      payload.retrain = 'now';
      payload.trainType = 'Force refresh by user';
      payload.parentMongoId = docId;
      payload.provider = 'web';
      payload.includeAllSubDocs = false;
      payload.status = selectedTab;

      if (isAllSelected) {
        // When all sources are selected
        payload.includeAllSubDocs = true;
        payload.documentMongoIds = [];

        if (excludedSubDocs.length) {
          payload.excludedSubDocs = excludedSubDocs;
        }
      } else if (showConfirmationModalData.data) {
        payload.documentMongoIds = [showConfirmationModalData.data];
      } else {
        payload.documentMongoIds = [...selectedSources];
        payload.includeAllSubDocs = false;
      }

      response = await trainSources({ ...payload });
    } else {
      payload.parentMongoId = docId;
      payload.status =
        showConfirmationModalData.action === SOURCE_ACTION.EXCLUDE ? 'excluded' : 'active';

      if (isAllSelected) {
        payload.includeAllSubDocs = true;
        payload.documentMongoIds = [];

        if (excludedSubDocs.length) {
          payload.excludedSubDocs = excludedSubDocs;
        }
      } else if (showConfirmationModalData.data) {
        payload.documentMongoIds = [showConfirmationModalData.data];
      } else {
        payload.documentMongoIds = [...selectedSources];
      }
      response = await modifySource(payload);
    }

    if (response?.data?.ok) {
      if (showConfirmationModalData.action === SOURCE_ACTION.EXCLUDE_WILL_REMOVE_SOURCE) {
        navigate(`${NAVBAR_ROUTES.INTEGRATIONS_PROVIDERS.WEB}/${teamId}`);
        dispatch(integrationsApi.util.invalidateTags(['SourcesByProvider']));
      } else {
        dispatch(integrationsApi.util.invalidateTags(['SubDocuments']));
      }
      toast.success(response.data.message);
      setShowConfirmationModalData({ open: false, action: null, data: null });
      setIsAllSelected(false);
      setPage(1);
      setSelectedSources([]);
      setCount({ active: null });
    }
  }

  if (isFetching) {
    return <Loader mt='5%' ml='50%' />;
  }

  if (searchValue && webpages !== undefined && !webpages.length) {
    return <SearchEmptyState searchValue={searchValue} fromSearch={true} />;
  }

  if (count[selectedTab] === 0 || count[selectedTab] === null) {
    return <SearchEmptyState fromSearch={false} tab={selectedTab} />;
  }

  return (
    <>
      <View
        handleConfirm={handleConfirm}
        handleGlobalCheckClick={handleGlobalCheckClick}
        handleSingleCheckClick={handleSingleCheckClick}
        selectedSources={selectedSources}
        setShowConfirmationModalData={setShowConfirmationModalData}
        showConfirmationModalData={showConfirmationModalData}
        tab={tab}
        sources={webpages}
        count={isAllSelected ? count[selectedTab] - excludedSubDocs.length : selectedSources.length}
        loading={retrainInitiating || modificationInitiating || status.isLoading}
        isAllSelected={isAllSelected}
        excludedSubDocs={excludedSubDocs}
        handleExclude={handleModalForExclude}
      />
      <Pagination
        total={Math.ceil(count[selectedTab] / 10)}
        size='sm'
        value={pagination.active}
        onChange={pagination.setPage}
        color='dark'
        mt={20}
      />
    </>
  );
};

Tab.propTypes = {
  tab: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  setCount: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
  count: PropTypes.object.isRequired,
  selectedTab: PropTypes.string.isRequired,
  selectedSources: PropTypes.array.isRequired,
  setSelectedSources: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  setIsAllSelected: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalDocumentsCount: PropTypes.number.isRequired
};

export default Tab;
