import { useState } from 'react';
import { Select } from '@mantine/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PromptCard from '../../PromptCard';
import { promptIdeas, categories } from 'data/onlinePromptIdeas.json';
import styles from '../../styles.module.scss';

const OnlineData = (props) => {
  const { tab, setShowPromptIdeas, isFreePlan } = props;
  const [selectedSource, setSelectedSource] = useState('all');
  const promptList =
    selectedSource === 'all'
      ? promptIdeas
      : promptIdeas.filter((idea) => idea.id === selectedSource);

  return (
    <>
      {/* Source Selection */}
      <Select
        radius='sm'
        label={'Categories'}
        value={selectedSource}
        data={[{ label: 'All', value: 'all' }, ...categories]}
        onChange={(value) => setSelectedSource(value)}
        classNames={{
          input: styles.selectInput,
          label: styles.selectLabel,
          root: classNames('select-style-v1', {
            [styles.freePlanselectRoot]: isFreePlan,
            [styles.selectRoot]: !isFreePlan
          })
        }}
        allowDeselect={false}
        maxDropdownHeight={250}
        searchable
      />

      {/* Prompt Ideas Cards */}
      <div className={styles.promptIdeasCardsWrapper}>
        {promptList.map((idea) => (
          <PromptCard
            key={`${idea.id}-${idea.prompt}`}
            prompt={idea.prompt}
            documentName={idea.documentName}
            provider={idea.provider}
            type={tab}
            category={idea.category}
            setShowPromptIdeas={setShowPromptIdeas}
            questionId={idea.id}
          />
        ))}
      </div>
    </>
  );
};

OnlineData.propTypes = {
  tab: PropTypes.string.isRequired,
  setShowPromptIdeas: PropTypes.func.isRequired,
  isFreePlan: PropTypes.bool.isRequired
};
export default OnlineData;
