import { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Title, Text, Button as MantineButton } from '@mantine/core';
import VideoPlayer from 'components/VideoPlayer';
import WikiModal from '../WikiModal';
import Button from 'components/Button';
import { ReactComponent as GlitterIcon } from 'assets/icons/glitter-yellow.svg';
import styles from './styles.module.scss';

const StepGuide = (props) => {
  const { title, steps, video, showCustomApiCta, createCustomApi } = props;
  const [showWikiModal, setShowWikiModal] = useState(false);
  const [isPlaying, seIsPlaying] = useState(false);

  return (
    <div className={styles.stepsContainer}>
      <Flex direction='column' gap={16}>
        <Flex align='center' justify='space-between' wrap='wrap' gap={10}>
          <Title className={styles.title}>{title}</Title>
          {showCustomApiCta && (
            <Flex direction='row' gap={3} align='center'>
              <Button withIcon onClick={() => createCustomApi()}>
                Create Custom API
              </Button>
            </Flex>
          )}
        </Flex>
        {steps.map((step) => {
          return (
            <Flex gap={10} key={step.step}>
              <Text className={styles.stepCount}>{step.step}</Text>
              <Flex direction='column' gap={4}>
                <Text className={styles.stepTitle}>{step.title}</Text>
                <Text className={styles.stepDescription}>{step.description}</Text>
                {step.showWikiIdCta && (
                  <Flex direction='row' gap={3} align='center'>
                    <GlitterIcon />
                    <MantineButton
                      onClick={() => setShowWikiModal(true)}
                      className={styles.wikiIdText}
                    >
                      Get Wiki ID
                    </MantineButton>
                  </Flex>
                )}
              </Flex>
            </Flex>
          );
        })}

        {video && (
          <VideoPlayer
            playing={isPlaying}
            video={video}
            clickHandler={() => seIsPlaying(!isPlaying)}
          />
        )}
      </Flex>

      {showWikiModal && <WikiModal handleClose={() => setShowWikiModal(false)} />}
    </div>
  );
};

StepGuide.defaultProps = {
  showCustomApiCta: false,
  createCustomApi: () => {}
};

StepGuide.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.array.isRequired,
  video: PropTypes.object,
  showCustomApiCta: PropTypes.bool,
  createCustomApi: PropTypes.func
};

export default StepGuide;
