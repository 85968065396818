import dayjs from 'dayjs';

export function dateDifference(fromDate = new Date(), toDate) {
  const date1 = dayjs(fromDate);
  const date2 = dayjs(toDate);
  return date2.startOf('day').diff(date1.startOf('day'), 'd');
}

export function dateFormat(userDate = new Date()) {
  return dayjs(userDate).format('D MMM, YYYY');
}
