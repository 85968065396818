import React from 'react';
import PropTypes from 'prop-types';
import AuthForm from 'pages/Auth/Components/AuthForm/index';
import InvitedByFormHeader from '../InvitedByFormHeader';

const ViaEmail = (props) => {
  const { invitedBy, formData, isSubmitting, handleSubmitCTA } = props;

  return (
    <AuthForm
      initialValues={formData}
      header={<InvitedByFormHeader invitedBy={invitedBy} allowedDomains={[]} />}
      email
      disableEmail
      name
      newPassword
      confirmPassword
      ctaLabel='Set password'
      isSubmitting={isSubmitting}
      handleSubmitCTA={handleSubmitCTA}
    />
  );
};

ViaEmail.propTypes = {
  invitedBy: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmitCTA: PropTypes.func.isRequired
};

export default ViaEmail;
