import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import styles from './styles.module.scss';
import 'react-phone-input-2/lib/style.css';

const PhoneNumber = ({ setPhoneNumber, selectedCountry, setSelectedCountry }) => {
  return (
    <PhoneInput
      dropdownClass={styles.dropDown}
      buttonClass={styles.phoneNumber}
      country={selectedCountry}
      onChange={(phone, country) => {
        setPhoneNumber(phone);
        setSelectedCountry(country.countryCode.toUpperCase?.());
      }}
    />
  );
};

PhoneNumber.propTypes = {
  setPhoneNumber: PropTypes.func,
  selectedCountry: PropTypes.string,
  setSelectedCountry: PropTypes.func
};

export default PhoneNumber;
