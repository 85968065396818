import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Anchor,
  Badge,
  Avatar,
  Button,
  Center,
  Flex,
  Group,
  Image,
  Popover,
  Text
} from '@mantine/core';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { useProSidebar } from 'react-pro-sidebar';
import { usePostSlackConnectMutation } from 'redux/services/slack-connect.js';
import { useUpdateUserMutation } from 'redux/services/user';
import { POPUP, usePopupContext } from 'contexts/PopupContext';
import { ReactComponent as HelpIcon } from 'assets/icons/help-faq.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as KBIcon } from 'assets/icons/kb-faq.svg';
import SlackIcon from 'assets/slack.png';
import TutorialIcon from 'assets/tut-icon.svg';
import CrossPopover from 'assets/icons/cross-popover.svg';
import { TUTORIAL_STATUS } from 'utils/constants';
import styles from './Help.module.scss';

const AccountManager = () => {
  return (
    <Group wrap='nowrap' className={styles.accountManager}>
      <Avatar
        src={'https://albus-backend-assets.s3.amazonaws.com/Kartik.png'}
        radius='md'
        size='md'
      />
      <div>
        Get a direct line with <strong>CEO Kartik Mandaville</strong>.
      </div>
    </Group>
  );
};

const Help = ({ isSlackPlatform }) => {
  const { user } = useSelector((state) => state.user);
  const { collapsed } = useProSidebar();
  const [postSlackConnect, { isLoading }] = usePostSlackConnectMutation();
  const url = new URL(window.location.href);
  const showExploreMorePopup = !!url.searchParams.get('showExploreMorePopup');
  const navigate = useNavigate();
  const [updateUser] = useUpdateUserMutation();
  const { openedPopup, setOpenedPopup } = usePopupContext();
  const hideTutorial = true;

  const handleSlackConnect = async () => {
    const response = await postSlackConnect({
      emails: [user?.email]
    });
    if (response?.data?.channelId) {
      toast.warning("You're already connected to Kartik on Slack");
    } else if (response?.data?.ok) {
      toast.success('You have been connected to Kartik on Slack');
    } else if (response?.error) {
      toast.error(response.error?.data?.message || 'Something went wrong!');
    }
  };

  function closeExploreMorePopup() {
    navigate(window.location.pathname);
  }

  function tutorialPointerClickHandler() {
    setOpenedPopup(null);
    if (user?.tutorialStatus !== TUTORIAL_STATUS.COMPLETED) {
      //on clicking tutorial point in help popover, should mark the tutorial complete.
      updateUser({
        tutorialStatus: TUTORIAL_STATUS.COMPLETED
      });
    }
    navigate(`${window.location.pathname}?showTutorialModal=true`);
  }

  if (showExploreMorePopup) {
    return (
      <Popover
        opened
        position='right-end'
        offset={collapsed ? -10 : -50}
        arrowSize={12}
        arrowPosition='center'
        shadow='md'
        onClose={closeExploreMorePopup}
        withArrow
        classNames={{ arrow: styles.arrow }}
      >
        <Popover.Target>
          <Center
            className={classnames(styles.collapsedHelpIcon, { [styles.helpIcon]: !collapsed })}
          >
            <HelpIcon />
            <div className={styles.blueDot} />
            {!collapsed && <span>Help</span>}
          </Center>
        </Popover.Target>
        <Popover.Dropdown className={styles.exploreMorePopup}>
          <Flex direction='column' gap={7}>
            <Flex justify='space-between'>
              <Text className={styles.text}>Explore Albus later</Text>
              <Image
                src={CrossPopover}
                alt='cross_popover'
                className={styles.img}
                onClick={closeExploreMorePopup}
                h='20'
                w='20'
              />
            </Flex>
            <Text className={styles.subText}>
              You can revisit the tour again in the future from here
            </Text>
          </Flex>
        </Popover.Dropdown>
      </Popover>
    );
  }

  return (
    <Popover
      opened={openedPopup === POPUP.HELP}
      position='right-end'
      offset={collapsed ? -10 : -50}
      arrowSize={12}
      arrowPosition='center'
      shadow='md'
      onChange={() => setOpenedPopup(POPUP.HELP)}
    >
      <Popover.Target onClick={() => setOpenedPopup(POPUP.HELP)}>
        <Center className={classnames(styles.collapsedHelpIcon, { [styles.helpIcon]: !collapsed })}>
          <HelpIcon />
          {user?.tutorialStatus === TUTORIAL_STATUS.MINIMISED && <div className={styles.blueDot} />}
          {!collapsed && <span>Help</span>}
        </Center>
      </Popover.Target>
      {openedPopup === POPUP.HELP && (
        <Popover.Dropdown className={styles.helpPopover}>
          <div className={styles.title}>Help</div>
          <div className={styles.helpItems}>
            <div className={styles.helpItem}>
              <div className={styles.accountManager}>
                <AccountManager />
              </div>
              <div className={styles.actionPanel}>
                <Flex gap={12} wrap='wrap'>
                  <Button
                    leftSection={<PhoneIcon />}
                    className={styles.button}
                    classNames={{ leftIcon: styles.buttonIcon }}
                    onClick={() =>
                      window.open('https://calendly.com/albus-sw/1v1-with-albus-team', '_blank')
                    }
                  >
                    Schedule a call
                  </Button>
                  {isSlackPlatform && (
                    <Button
                      leftSection={<img src={SlackIcon} alt='connect on slack' />}
                      className={styles.button}
                      classNames={{ leftIcon: styles.buttonIcon }}
                      onClick={handleSlackConnect}
                      loading={isLoading}
                    >
                      DM on Slack
                    </Button>
                  )}
                </Flex>
              </div>
            </div>
            <div className={styles.kbNote}>
              <KBIcon />
              <span>
                Have questions? Visit our&nbsp;
                <Anchor href='https://support.springworks.in/portal/en/kb/albus' target='_blank'>
                  <Text display='inline' size='14px'>
                    knowledge base
                  </Text>
                </Anchor>
                .
              </span>
            </div>

            {/*Hiding tutorial for time being*/}
            {!hideTutorial && (
              <div
                className={classnames(styles.kbNote, 'cursor-pointer')}
                onClick={tutorialPointerClickHandler}
              >
                <img src={TutorialIcon} alt='tutorial-icon' />
                <span>
                  Explore Albus with a tour&nbsp;
                  <Badge
                    variant='gradient'
                    gradient={{ from: '#462980 0%', to: '#6129CF 114.13%', deg: 90 }}
                    size='sm'
                    className='font-poppins'
                  >
                    New
                  </Badge>
                </span>
              </div>
            )}
          </div>
        </Popover.Dropdown>
      )}
    </Popover>
  );
};

Help.defaultProps = {
  isSlackPlatform: false
};

Help.propTypes = {
  isSlackPlatform: PropTypes.bool
};

export default Help;
