import mixpanel from 'mixpanel-browser';
import { ENV } from 'utils/constants';

export const mixpanelTracking = (eventName, user, page) => {
  const properties = {
    distinct_id: user._id,
    Page: page,
    'Organisation ID': user.organisationId
  };

  mixpanel.track(eventName, properties);
};

export const CIOTracking = (eventName, user, page) => {
  const properties = {
    Page: page,
    'Organisation ID': user.organisationId
  };

  window?._cio?.track(eventName, properties);
};

export const CIOIdentify = (user) => {
  window?._cio?.identify({
    id: user._id,
    name: user.name
  });
};

export const eventTracking = (eventName, user, page) => {
  mixpanelTracking(eventName, user, page);
  if (import.meta.env.VITE_NODE_ENV !== ENV.PRODUCTION) {
    return;
  }
  CIOIdentify(user);
  CIOTracking(eventName, user, page);
};
