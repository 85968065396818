import { Select } from '@mantine/core';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-down.svg';
import styles from './styles.module.scss';

const SelectTheme = Select.extend({
  defaultProps: {
    withCheckIcon: false,
    rightSection: <ArrowIcon />
  },
  classNames: {
    input: styles.input,
    label: styles.label,
    option: styles.option,
    section: styles.section
  }
});

export default SelectTheme;
