import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import store from 'redux/store';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { MantineProvider } from '@mantine/core';
import { ToastContainer } from 'react-toastify';
// import { worker } from './mocks/browser';
import '@mantine/core/styles.css';
import '@mantine/code-highlight/styles.css';
import '@mantine/dates/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './utils/styles/global.scss';
import EventAnalyticsProvider from 'components/EventAnalyticsProvider/index.jsx';
import { CurrentChatProvider } from 'contexts/CurrentChatContext/index.jsx';
import albusTheme from 'themes';
import App from 'App.jsx';

// Start the mocking conditionally.
// if (import.meta.env.MODE === 'localhost') {
//   worker.start();
// }

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <ProSidebarProvider>
      <MantineProvider withGlobalStyles withNormalizeCSS theme={albusTheme}>
        <EventAnalyticsProvider>
          <CurrentChatProvider>
            <App />
          </CurrentChatProvider>
        </EventAnalyticsProvider>
      </MantineProvider>
    </ProSidebarProvider>
    <ToastContainer
      position='bottom-right'
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='colored'
      className={'toast-container'}
    />
  </Provider>
);
