import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Center, Select, TextInput } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-o.svg';
import styles from './FilterSection.module.scss';
import { NAVBAR_ROUTES } from 'utils/constants';

const channelFilterData = [
  { value: 'all', label: 'All' },
  { value: 'private', label: 'Private channels' },
  {
    value: 'public',
    label: 'Public channels'
  }
];

const FilterSection = (props) => {
  const {
    channelFilter,
    setChannelFilter,
    search,
    setSearch,
    setShowAddChannelsModal,
    paginationOnChange
  } = props;
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 480px)');

  return (
    <>
      <div className={styles.filterWrapper}>
        <Select
          classNames={{ root: styles.channelFilter, input: styles.channelFilterInput }}
          label='Channels'
          radius='sm'
          size='xs'
          value={channelFilter}
          onChange={(value) => {
            setChannelFilter(value);
            paginationOnChange(1);
            navigate(NAVBAR_ROUTES.SETTINGS_TABS.AUTO_ANSWER + '#' + value);
          }}
          data={channelFilterData}
          allowDeselect={false}
        />

        <div className={styles.channelSearchWrapper}>
          <TextInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className={styles.searchInput}
            size='sm'
            placeholder='Search for channels'
            leftSection={<SearchIcon />}
          />
          <Button
            radius='sm'
            size={isMobile ? 'xs' : 'sm'}
            leftSection={<PlusIcon />}
            className='primaryBtn'
            onClick={() => setShowAddChannelsModal(true)}
          >
            Add channel
          </Button>
        </div>
      </div>
      <Center className={styles.mobileSearchWrapper}>
        <TextInput
          size='xs'
          placeholder='Search for channels'
          leftSection={<SearchIcon />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Center>
    </>
  );
};

FilterSection.defaultProps = {
  channelFilter: 'all',
  search: ''
};

FilterSection.propTypes = {
  channelFilter: PropTypes.string,
  setChannelFilter: PropTypes.func.isRequired,
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
  setShowAddChannelsModal: PropTypes.func.isRequired,
  paginationOnChange: PropTypes.func.isRequired
};

export default FilterSection;
