import PropTypes from 'prop-types';
import { Tabs } from '@mantine/core';
import Tab from './Tab';
import { SOURCES_STATUS } from '../../constants';

const DataView = (props) => {
  const {
    searchValue,
    teamId,
    count,
    setCount,
    selectedSources,
    setSelectedSources,
    isAllSelected,
    setIsAllSelected,
    selectedTab,
    handleTabChange,
    page,
    setPage,
    totalDocumentsCount
  } = props;

  return (
    <Tabs defaultValue={SOURCES_STATUS.ACTIVE} onChange={handleTabChange} className='tabs-style-1'>
      <Tabs.List>
        <Tabs.Tab value={SOURCES_STATUS.ACTIVE}>Active ({count.active || 0})</Tabs.Tab>
        <Tabs.Tab value={SOURCES_STATUS.PROCESSING}>Processing ({count.processing || 0})</Tabs.Tab>
        <Tabs.Tab value={SOURCES_STATUS.ERROR}>Error ({count.error || 0})</Tabs.Tab>
        <Tabs.Tab value={SOURCES_STATUS.EXCLUDED}>Excluded ({count.excluded || 0})</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value={SOURCES_STATUS.ACTIVE}>
        <Tab
          teamId={teamId}
          tab={SOURCES_STATUS.ACTIVE}
          searchValue={searchValue}
          setCount={setCount}
          count={count}
          selectedTab={selectedTab}
          selectedSources={selectedSources}
          setSelectedSources={setSelectedSources}
          isAllSelected={isAllSelected}
          setIsAllSelected={setIsAllSelected}
          page={page}
          setPage={setPage}
          totalDocumentsCount={totalDocumentsCount}
        />
      </Tabs.Panel>
      <Tabs.Panel value={SOURCES_STATUS.PROCESSING}>
        <Tab
          teamId={teamId}
          tab={SOURCES_STATUS.PROCESSING}
          searchValue={searchValue}
          setCount={setCount}
          count={count}
          selectedTab={selectedTab}
          selectedSources={selectedSources}
          setSelectedSources={setSelectedSources}
          isAllSelected={isAllSelected}
          setIsAllSelected={setIsAllSelected}
          page={page}
          setPage={setPage}
          totalDocumentsCount={totalDocumentsCount}
        />
      </Tabs.Panel>
      <Tabs.Panel value={SOURCES_STATUS.ERROR}>
        <Tab
          teamId={teamId}
          tab={SOURCES_STATUS.ERROR}
          searchValue={searchValue}
          setCount={setCount}
          count={count}
          selectedTab={selectedTab}
          selectedSources={selectedSources}
          setSelectedSources={setSelectedSources}
          isAllSelected={isAllSelected}
          setIsAllSelected={setIsAllSelected}
          page={page}
          setPage={setPage}
          totalDocumentsCount={totalDocumentsCount}
        />
      </Tabs.Panel>
      <Tabs.Panel value={SOURCES_STATUS.EXCLUDED}>
        <Tab
          teamId={teamId}
          tab={SOURCES_STATUS.EXCLUDED}
          searchValue={searchValue}
          setCount={setCount}
          count={count}
          selectedTab={selectedTab}
          selectedSources={selectedSources}
          setSelectedSources={setSelectedSources}
          isAllSelected={isAllSelected}
          setIsAllSelected={setIsAllSelected}
          page={page}
          setPage={setPage}
          totalDocumentsCount={totalDocumentsCount}
        />
      </Tabs.Panel>
    </Tabs>
  );
};

DataView.propTypes = {
  searchValue: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  count: PropTypes.object.isRequired,
  setCount: PropTypes.func.isRequired,
  selectedSources: PropTypes.array.isRequired,
  setSelectedSources: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  setIsAllSelected: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalDocumentsCount: PropTypes.number.isRequired
};

export default DataView;
