import React, { useState } from 'react';
import Table from 'components/Table';
import PropTypes from 'prop-types';
import TableHeader from '../TableHeader';
import { Flex } from '@mantine/core';
import AddTeammates from 'pages/Teams/Forms/AddTeammates';

const MembersTable = ({ formattedRows, actionMenuData, teamId }) => {
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const filteredRows = formattedRows.filter((row) =>
    row.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Flex direction={'column'}>
      <TableHeader
        teamId={teamId}
        value={searchValue}
        setValue={setSearchValue}
        isTeamTable={false}
        ctaHandler={() => setOpenAddMemberModal(true)}
      />
      <Table
        headers={['Name', 'Permissions', '']}
        rows={filteredRows}
        actionMenuData={actionMenuData}
        disableRowClick={true}
      />
      {openAddMemberModal && (
        <AddTeammates
          open={openAddMemberModal}
          handleClose={() => setOpenAddMemberModal(false)}
          teamId={teamId}
        />
      )}
    </Flex>
  );
};

MembersTable.propTypes = {
  formattedRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      permission: PropTypes.string.isRequired
    }).isRequired
  ),
  actionMenuData: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired
};

export default MembersTable;
