import DefaultPersonaIcon from 'assets/icons/personas/default.svg';
import StraightForwardPersonaIcon from 'assets/icons/personas/straight-forward.svg';
import PowerfulPersonIcon from 'assets/icons/personas/powerful.svg';
import CasualPersonaIcon from 'assets/icons/personas/casual.svg';
import JaneAustinPersonaIcon from 'assets/icons/personas/jane-austin.svg';

export const PERSONA_ICON = {
  DefaultPersonaIcon,
  StraightForwardPersonaIcon,
  PowerfulPersonIcon,
  CasualPersonaIcon,
  JaneAustinPersonaIcon
};
