import { useState } from 'react';
import PropTypes from 'prop-types';
import Form from './Form';
import { useCreateTeamMutation, useGetUsersQuery } from 'redux/services/settings';

const CreateTeam = (props) => {
  const { open, handleClose, setSelectedTeamId } = props;
  const { data: users = [], isFetching: fetchingUsers } = useGetUsersQuery();
  const [createTeam, { isLoading: creatingTeam }] = useCreateTeamMutation();
  const [formValues, setFormValues] = useState({
    name: '',
    admins: []
  });
  const usersOptions = fetchingUsers
    ? ['Fetching users...']
    : users?.map((user) => ({ value: user.userId, label: user.name }));

  const onSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      ...formValues,
      name: formValues.name.trim()
    };

    const response = await createTeam(payload).unwrap();
    if (response?.ok) {
      handleClose();
      setSelectedTeamId(response.data?.teamId);
    }
  };

  const handleChange = (field, value) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Form
      open={open}
      formValues={formValues}
      users={fetchingUsers ? [] : usersOptions}
      handleChange={handleChange}
      handleConfirm={onSubmit}
      handleClose={handleClose}
      loading={fetchingUsers || creatingTeam}
    />
  );
};

CreateTeam.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setSelectedTeamId: PropTypes.func.isRequired
};

export default CreateTeam;
