import { Title, Text, Button, Container, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import CustomMessageErrorPage from './CustomMessageErrorPage';
import localStorage from 'utils/localStorage';
import { NAVBAR_ROUTES } from 'utils/constants';
import styles from './styles.module.scss';

const ErrorPage = (props) => {
  const { errorStatus, errorPageTitle, errorDescription } = props;
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const message = searchParams.get('message');

  const handleCtaClick = () => {
    const isValidToken = localStorage.getToken();
    if (isValidToken) {
      navigate(NAVBAR_ROUTES.DASHBOARD);
    } else {
      navigate(NAVBAR_ROUTES.AUTH.SIGNIN);
    }
  };

  if (message) {
    return <CustomMessageErrorPage message={message} handleCtaClick={handleCtaClick} />;
  }

  return (
    <Container className={styles.root}>
      <div className={styles.label}>{errorStatus ? errorStatus : 404}</div>
      <Title className={styles.title}>{errorPageTitle}</Title>
      <Text c='dimmed' size='lg' ta='center' className={styles.description}>
        {errorDescription}
      </Text>
      <Group justify='center'>
        <Button variant='subtle' size='md' onClick={() => navigate('/')}>
          Take me back home
        </Button>
      </Group>
    </Container>
  );
};

ErrorPage.propTypes = {
  errorStatus: PropTypes.number,
  errorPageTitle: PropTypes.string,
  errorDescription: PropTypes.string
};

export default ErrorPage;
