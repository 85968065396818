import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  slack_connect: {
    state: 'inactive'
  },
  chrome_extension: {
    state: 'inactive'
  },
  personas: {
    state: 'inactive'
  }
};

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    initialisePopupState: (state, action) => {
      let tempState = { ...state };

      action.payload?.forEach((popup) => {
        tempState[popup.featureName] = {
          ...tempState[popup.featureName],
          ...popup
        };
      });

      return tempState;
    },

    updateChromeExtention: (state, action) => ({
      ...state,
      chrome_extension: {
        ...state.chrome_extension,
        state: action.payload
      }
    }),

    updatePersonas: (state, action) => ({
      ...state,
      personas: {
        ...state.personas,
        state: action.payload
      }
    })
  }
});

export const { initialisePopupState, updateChromeExtention, updatePersonas } = popupSlice.actions;

export default popupSlice.reducer;
