import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import View from './View';

const FreePlanBanner = (props) => {
  const { checkout } = props;
  const { planInfo } = useSelector((state) => state.plan);

  return (
    <View
      questionsLeft={planInfo.totalQuestionsRemaining}
      trialEndsAt={planInfo.trialEndsAt}
      checkout={checkout}
    />
  );
};

FreePlanBanner.propTypes = {
  checkout: PropTypes.func.isRequired
};

export default FreePlanBanner;
