import { useState } from 'react';
import { array, bool, func, string } from 'prop-types';
import { CopyButton, Button, Tooltip, TagsInput } from '@mantine/core';
import { CodeHighlight } from '@mantine/code-highlight';
import { ReactComponent as InfoIcon } from 'assets/icons/info-gray.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as IncompleteSetupIllustration } from 'assets/widgets/incomplete-setup.svg';
import { embedCode } from '../../data.js';
import styles from './styles.module.scss';

const InstallationTab = ({
  setupComplete,
  allowedOrigins,
  onOriginsChange,
  widgetId,
  originError
}) => {
  const [showOriginError, setShowOriginError] = useState(originError);
  const [openTooltip, setOpenTooltip] = useState(originError);
  const code = embedCode(widgetId);

  const isOriginValid = (origin) => {
    // match regex pattern for test.example.com *.example.com and example.com
    const regex = new RegExp('^(\\*\\.)?([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$');
    return regex.test(origin);
  };

  return (
    <div className={styles.container}>
      {setupComplete ? (
        <>
          <div className={styles.title}>Installation</div>
          <div className={styles.inputLabel}>
            <>
              <span>Widget destination</span>
              <Tooltip
                position={'top-start'}
                label={
                  <>
                    This widget will be activated on all web pages on this domain.
                    <br />
                    <br />
                    Supported formats: example.com, *.example.com
                  </>
                }
                opened={openTooltip}
                classNames={{
                  tooltip: styles.tooltip
                }}
              >
                <span onClick={() => setOpenTooltip(!openTooltip)}>
                  <InfoIcon className={styles.infoIcon} />
                </span>
              </Tooltip>
            </>
          </div>
          <TagsInput
            error={showOriginError ? 'The destination format is not supported' : null}
            mb={24}
            w={'50%'}
            data={allowedOrigins}
            value={allowedOrigins}
            onChange={onOriginsChange}
            onOptionSubmit={(query) => {
              if (isOriginValid(query)) {
                onOriginsChange(query);
                setOpenTooltip(false);
                setShowOriginError(false);
              } else {
                setOpenTooltip(true);
                setShowOriginError(true);
              }
            }}
            placeholder='Click enter to add new destinations'
            classNames={{
              label: styles.inputLabel,
              error: styles.erroredInput,
              input: styles.searchInput
            }}
          />
          <div className={styles.codeWrapper}>
            <div className={styles.inputLabel}>Installation</div>
            <div className={styles.description}>
              Paste this installation in the &lt;head&gt; tag on the web pages you want to set up
              this chat widget
            </div>
            <div className={styles.codeContainer}>
              <CodeHighlight
                withCopyButton={false}
                language='jsx'
                className={styles.code}
                code={code}
              />
              <CopyButton value={code}>
                {({ copied, copy }) => (
                  <Button leftSection={<CopyIcon />} onClick={copy} className={styles.copyBtn}>
                    {copied ? 'Copied' : 'Copy'}
                  </Button>
                )}
              </CopyButton>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.incompleteSetupContainer}>
          <IncompleteSetupIllustration />
          <div className={styles.incompleteSetupText}>
            To retrieve the embed code for this widget, complete setting up Search and Wiki.
          </div>
        </div>
      )}
    </div>
  );
};

InstallationTab.propTypes = {
  setupComplete: bool.isRequired,
  allowedOrigins: array.isRequired,
  onOriginsChange: func.isRequired,
  widgetId: string,
  originError: bool
};

InstallationTab.defaultProps = {
  originError: false
};

export default InstallationTab;
