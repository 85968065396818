import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useDeleteTeamMutation, useUpdateTeamMutation } from 'redux/services/settings';
import userApi from 'redux/services/user';
import MembersTable from './MembersTable';
import TeamsTable from './TeamsTable';
import WarningModal from '../Modal/Warning';
import { ACTIONS, warningDataConstructor } from '../utils';
import { NAVBAR_ROUTES } from 'utils/constants';

const Table = (props) => {
  const { user } = useSelector((state) => state.user);
  const { teams, handleTeamCreateClick, selectedTeamId, setSelectedTeamId } = props;
  const [updateTeam, { isLoading: updatingTeam }] = useUpdateTeamMutation();
  const [deleteTeam, { isLoading: deletingTeam }] = useDeleteTeamMutation();
  const [warningData, setWarningData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = async (action, data) => {
    let editTeam = true;
    let formData = { teamId: data.id };
    let toastMessage = '';

    switch (action) {
      case ACTIONS.LEAVE_TEAM:
        formData.body = { membersRemoved: [user.userId] };
        toastMessage = `You left team ${data.title} successfully`;
        break;
      case ACTIONS.REMOVE_TEAMMATE:
        formData.body = { membersRemoved: [data.userId] };
        toastMessage = `Removed ${data.name} from team successfully`;
        break;
      case ACTIONS.UNMARK_ADMIN:
        formData.body = { adminsRemoved: [data.userId] };
        formData.loggedInUser = user.userId;
        toastMessage = `Removed ${data.name}'s team admin privileges successfully`;
        break;
      case ACTIONS.MARK_ADMIN:
        formData.body = { adminsAdded: [data.userId] };
        toastMessage = `Marked ${data.name} as team admin successfully`;
        break;
      default:
        editTeam = false;
        break;
    }

    let response = null;
    try {
      if (editTeam) {
        response = await updateTeam(formData).unwrap();
      } else {
        response = await deleteTeam(formData).unwrap();
      }

      if (user.userId === data.userId) {
        dispatch(userApi.util.invalidateTags(['User']));
        if (!user.isAdmin && user.isTeamAdmin) {
          setSelectedTeamId(null);
          navigate(NAVBAR_ROUTES.TEAMS);
        }
      }

      if (response.ok) {
        setWarningData(null);
        if (toastMessage) {
          toast.success(toastMessage);
        }
      }
    } catch (error) {
      toast.error('Oops, something went wrong. Try again!');
    }
  };

  const handleActions = (action, data) => {
    if (action === ACTIONS.EDIT_TEAM) {
      setSelectedTeamId(data.id);
      window.history.replaceState(null, '', `/teams/${data.id}`);
      return;
    }

    if (action === ACTIONS.VIEW_SOURCES) {
      navigate(`${NAVBAR_ROUTES.INTEGRATIONS}/${data.id}`);
      return;
    }

    if (action === ACTIONS.MARK_ADMIN) {
      handleClick(action, data);
      return;
    }

    setWarningData(warningDataConstructor(action, data));
  };

  return (
    <>
      {warningData?.open ? (
        <WarningModal
          warningData={warningData}
          handleCancel={() => setWarningData(null)}
          handleDelete={handleClick}
          disabled={updatingTeam || deletingTeam}
        />
      ) : null}

      {selectedTeamId ? (
        <MembersTable handleActions={handleActions} teamId={selectedTeamId} />
      ) : (
        <TeamsTable
          teams={teams}
          handleActions={handleActions}
          handleTeamCreateClick={handleTeamCreateClick}
        />
      )}
    </>
  );
};

Table.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      teamId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      sourcesCount: PropTypes.number.isRequired,
      membersCount: PropTypes.number.isRequired,
      isUserMember: PropTypes.bool.isRequired
    })
  ),
  members: PropTypes.arrayOf(
    PropTypes.shape({
      teamId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      sourcesCount: PropTypes.number.isRequired,
      membersCount: PropTypes.number.isRequired,
      isMember: PropTypes.bool.isRequired
    })
  ),
  handleTeamCreateClick: PropTypes.func.isRequired,
  selectedTeamId: PropTypes.string,
  setSelectedTeamId: PropTypes.func.isRequired
};

Table.defaultProps = {
  teams: [],
  members: [],
  selectedTeamId: null
};

export default Table;
