import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, Button, Flex, Select, Text, TextInput, Tooltip } from '@mantine/core';
import classNames from 'classnames';
import PlusIcon from 'assets/icons/plus-o.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { NAVBAR_ROUTES } from 'utils/constants';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from './styles.module.scss';

const TeamFilter = (props) => {
  const {
    selectedTeam,
    options,
    disabled,
    handleChange,
    searchTerm,
    setSearchTerm,
    showAddConnectionsCTA
  } = props;

  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const isSlackPlatform = user?.platform === 'slack';
  const teamName = options.find(({ value }) => value === selectedTeam)?.label || '';

  return (
    <Flex w='100%' justify='space-between' align='end' mr='sm' ml='sm' className={styles.wrapper}>
      <TextInput
        placeholder='Search for connections'
        leftSection={<SearchIcon />}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={styles.search}
        rightSection={
          searchTerm ? (
            <ActionIcon
              variant='transparent'
              onClick={() => {
                setSearchTerm('');
              }}
            >
              <SVGIcon name={ICONS_LIST.CROSS} height={18} width={18} />
            </ActionIcon>
          ) : null
        }
      />
      <Flex justify='end' align='end' className={styles.CTAs}>
        {isSlackPlatform && (
          <Tooltip
            className={styles.tooltip}
            w='fit-content'
            disabled={teamName.length < 19}
            label={teamName}
          >
            <Select
              searchable
              label={<Text className={styles.label}>Team</Text>}
              className={styles.filter}
              withScrollArea={false}
              classNames={{
                input: styles.selectInput,
                option: styles.option,
                dropdown: styles.dropdown
              }}
              data={options}
              value={selectedTeam}
              onChange={handleChange}
              disabled={disabled}
              allowDeselect={false}
              id='team-select'
            />
          </Tooltip>
        )}
        {showAddConnectionsCTA && (
          <Button
            onClick={() => navigate(NAVBAR_ROUTES.SETTINGS_TABS.CONNECTIONS)}
            className={classNames('primaryBtnOutline', styles.button)}
            id='add-connection'
          >
            <img src={PlusIcon} alt='add connections' /> Add connections
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

TeamFilter.propTypes = {
  selectedTeam: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  showAddConnectionsCTA: PropTypes.bool.isRequired
};

TeamFilter.defaultProps = {
  selectedTeam: ''
};

export default TeamFilter;
