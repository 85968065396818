import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { LoadingOverlay } from '@mantine/core';
import View from './View';
import UnableToAssignLicensesModal from '../UnableToAssignLicensesModal';
import ConfirmationModal from '../ConfirmationModal';
import { useFetchLicenseRequestsQuery, useUpdateLicensesMutation } from 'redux/services/licenses';

const AssignLicensesModal = (props) => {
  const { opened, setOpened } = props;

  const { planInfo } = useSelector((state) => state.plan);

  // Filtered Users
  const [filteredUsers, setFilteredUsers] = useState([]);

  // Modals ON / OFF
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDenyConfirmOpen, setIsDenyConfirmOpen] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);

  // Selected Users for update
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Get only license requested users
  const { data: requestedUsers = [], isLoading: isRequestLoading } = useFetchLicenseRequestsQuery();
  const [updateLicenses, { isSuccess: reqIsSuccess, isLoading: reqLoading }] =
    useUpdateLicensesMutation();

  // Update selected users after fetch
  useEffect(() => {
    setSelectedUsers(requestedUsers.map((u) => u.userId));
    setFilteredUsers(requestedUsers);
  }, [requestedUsers]);

  // On Approve or Deny Success close modal
  useEffect(() => {
    if (reqIsSuccess) setOpened(false);
  }, [reqIsSuccess]);

  // Count of unassigned licenses
  const unassignedLicenses = planInfo?.plan
    ? Math.max(planInfo.plan.licensesBought - planInfo.plan.licensesAssigned, 0)
    : 0;

  // On request confirmation
  const handleRequestConfirmation = (type, size = 0, autoBuySubscription = false) => {
    const body = {
      ids:
        size > 0
          ? selectedUsers.sort(() => Math.random() - Math.random()).slice(0, size)
          : selectedUsers,
      status: type,
      autoBuySubscription
    };
    updateLicenses(body);
  };

  return (
    <>
      <LoadingOverlay visible={reqLoading} />
      <View
        filteredUsers={filteredUsers}
        setFilteredUsers={setFilteredUsers}
        setIsModalOpen={setIsModalOpen}
        setIsApproveModalOpen={setIsApproveModalOpen}
        setIsDenyConfirmOpen={setIsDenyConfirmOpen}
        unassignedLicenses={unassignedLicenses}
        requestedUsers={requestedUsers}
        isRequestLoading={isRequestLoading}
        opened={opened}
        setOpened={setOpened}
        setSelectedUsers={setSelectedUsers}
        selectedUsers={selectedUsers}
      />

      {isModalOpen && (
        <UnableToAssignLicensesModal
          opened={isModalOpen}
          selectedUsersCount={selectedUsers.length}
          unusedLicenses={unassignedLicenses}
          handleConfirmation={handleRequestConfirmation}
          setOpened={setIsModalOpen}
        />
      )}

      <ConfirmationModal
        opened={isDenyConfirmOpen}
        setOpened={setIsDenyConfirmOpen}
        message={`Are you sure you want to deny License requests for ${selectedUsers.length} teammates?`}
        title='Deny License requests'
        confirmBtnLabel='Deny'
        actionType='Denied'
        handleConfirmation={handleRequestConfirmation}
      />

      <ConfirmationModal
        opened={isApproveModalOpen}
        setOpened={setIsApproveModalOpen}
        message={`Are you sure you want to approve License requests for ${selectedUsers.length} teammates?`}
        title='Approve License requests'
        confirmBtnLabel='Approve'
        actionType='Approved'
        handleConfirmation={handleRequestConfirmation}
      />
    </>
  );
};

AssignLicensesModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  setOpened: PropTypes.func.isRequired
};

export default AssignLicensesModal;
