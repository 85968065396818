import { node } from 'prop-types';
import styles from './MultiSelectConfirmationBar.module.scss';

const MultiSelectConfirmationBar = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

MultiSelectConfirmationBar.propTypes = {
  children: node.isRequired
};

export default MultiSelectConfirmationBar;
