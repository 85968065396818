import { Button, Menu, Title } from '@mantine/core';
import PropTypes from 'prop-types';
import { ReactComponent as InfoIcon } from 'assets/icons/info-blue.svg';
import styles from './styles.module.scss';

const InfoPopup = (props) => {
  const { title, body } = props;

  return (
    <Menu width={350} position='bottom-end' withArrow arrowPosition='center'>
      <Menu.Target>
        <Button className={styles.disclosureBtn}>
          <InfoIcon />
        </Button>
      </Menu.Target>
      <Menu.Dropdown className={styles.menuDropdown}>
        <Menu.Item className={styles.menuItem}>
          <Title>{title}</Title>
          {body}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

InfoPopup.defaultProps = {
  title: '',
  body: ''
};

InfoPopup.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default InfoPopup;
