import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  CopyButton,
  Flex,
  Menu,
  Pagination,
  Table,
  TextInput,
  Tooltip,
  UnstyledButton
} from '@mantine/core';
import { usePagination } from '@mantine/hooks';
import dayjs from 'dayjs';
import omit from 'lodash.omit';
import { toast } from 'react-toastify';
import pluralize from 'pluralize';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as AddIcon } from 'assets/icons/plus-o.svg';
import { ReactComponent as ActionIcon } from 'assets/icons/3-v-dots.svg';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal/index.jsx';
import {
  useDeleteWidgetMutation,
  useCreateWidgetMutation,
  useLazyGetWidgetQuery
} from 'redux/services/widgets.js';
import { embedCode } from '../data.js';
import { NAVBAR_ROUTES } from 'utils/constants';
import styles from './styles.module.scss';

const ROWS_PER_PAGE = 10;

const WidgetsTable = ({ widgets, onManageWidget, onNewWidget }) => {
  const [search, setSearch] = useState('');
  const [page, onChange] = useState(1);
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [deleteWidget, { isSuccess: deleteSuccessful, isLoading: deleteInProgress }] =
    useDeleteWidgetMutation();
  const [duplicateWidget] = useCreateWidgetMutation();
  const [getWidgetDetails] = useLazyGetWidgetQuery();
  const pagination = usePagination({
    total: Math.ceil(widgets.length / ROWS_PER_PAGE),
    page,
    onChange,
    siblings: 2
  });
  const navigate = useNavigate();

  let widgetsToDisplay = [...widgets];
  if (search) {
    widgetsToDisplay = widgetsToDisplay.filter((widget) =>
      widget.name.toLowerCase().includes(search.toLowerCase())
    );
  }
  const hasPagination = widgetsToDisplay.length > ROWS_PER_PAGE;
  const totalPages = Math.ceil(widgetsToDisplay.length / ROWS_PER_PAGE);
  const pageWidgets = widgetsToDisplay.slice(
    (pagination.active - 1) * ROWS_PER_PAGE,
    pagination.active * ROWS_PER_PAGE
  );

  const clearSelection = () => {
    setSelectedWidget(null);
    setShowWarningModal(false);
  };

  const handleDeleteWidget = async () => {
    await deleteWidget(selectedWidget.id);
    clearSelection();
    if (deleteSuccessful) {
      toast.success('Chat widget deleted successfully');
    }
  };

  const handleDuplicateWidget = async (widget) => {
    const { data } = await getWidgetDetails(widget.id);
    const duplicate = omit(data.data, ['_id', 'id', 'createdBy', 'updatedBy', 'interactions']);
    await duplicateWidget({ ...duplicate, name: `${widget.name} copy`, allowedOrigins: [] });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.searchSection}>
          <TextInput
            className={styles.searchBar}
            leftSection={<SearchIcon />}
            placeholder='Search for widgets'
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
          <Button
            className={styles.createButton}
            radius='sm'
            leftSection={<AddIcon />}
            onClick={onNewWidget}
          >
            Create chat widget
          </Button>
        </div>
        <div className={styles.tableWrapper}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Widget name</Table.Th>
                <Table.Th>Widget destination</Table.Th>
                <Table.Th>Added on</Table.Th>
                <Table.Th>Added by</Table.Th>
                <Table.Th>Questions asked</Table.Th>
                <Table.Th />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {pageWidgets.map((widget) => (
                <Table.Tr
                  key={widget.id}
                  onClick={() => {
                    onManageWidget(widget.id);
                    navigate(NAVBAR_ROUTES.CHAT_WIDGETS + '/' + widget.id);
                  }}
                >
                  <Table.Td>{widget.name}</Table.Td>
                  <Table.Td>
                    {widget.allowedOrigins.length ? widget.allowedOrigins[0] : '-'}
                    {widget.allowedOrigins.length > 1 && (
                      <Tooltip
                        position='top-start'
                        label={widget.allowedOrigins.slice(1).map((d) => (
                          <React.Fragment key={d}>
                            {d}
                            <br />
                          </React.Fragment>
                        ))}
                        closeDelay={500}
                        transitionProps={{ transition: 'fade', duration: 200 }}
                        classNames={{
                          tooltip: styles.tooltip
                        }}
                      >
                        <span className={styles.strongText}>
                          &nbsp;+{widget.allowedOrigins.length - 1}
                        </span>
                      </Tooltip>
                    )}
                  </Table.Td>
                  <Table.Td className={styles.strongText}>
                    {dayjs(widget.createdAt).format('DD MMM YYYY')}
                  </Table.Td>
                  <Table.Td>{widget.createdBy}</Table.Td>
                  <Table.Td>{widget.questionsAsked ?? 0}</Table.Td>
                  <Table.Td onClick={(e) => e.stopPropagation()}>
                    <Menu>
                      <Menu.Target>
                        <UnstyledButton className={styles.menuIcon}>
                          <ActionIcon />
                        </UnstyledButton>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item
                          onClick={() => {
                            onManageWidget(widget.id);
                            navigate(NAVBAR_ROUTES.CHAT_WIDGETS + '/' + widget.id);
                          }}
                        >
                          Manage widget
                        </Menu.Item>
                        <CopyButton value={embedCode(widget.id)}>
                          {({ copy }) => (
                            <Menu.Item
                              onClick={() => {
                                copy();
                                toast.success('Embed code copied');
                              }}
                            >
                              Embed code
                            </Menu.Item>
                          )}
                        </CopyButton>
                        <Menu.Item
                          onClick={() => {
                            setSelectedWidget(widget);
                            setShowWarningModal(true);
                          }}
                        >
                          Delete widget
                        </Menu.Item>
                        <Menu.Item onClick={() => handleDuplicateWidget(widget)}>
                          Duplicate
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </div>
        <Flex justify={hasPagination ? 'space-between' : 'flex-end'} align='center' mt={20} pb={16}>
          {hasPagination && (
            <Pagination
              size='xs'
              color='dark'
              siblings={2}
              total={totalPages}
              value={pagination.active}
              onChange={pagination.setPage}
            />
          )}
          <div className={styles.pageCounter}>
            <strong>
              {(pageWidgets.length > 0 ? (pagination.active - 1) * ROWS_PER_PAGE + 1 : 0) +
                '-' +
                Math.min(ROWS_PER_PAGE * pagination.active, widgetsToDisplay.length)}
            </strong>
            &nbsp;of&nbsp;
            <strong>{widgetsToDisplay.length}</strong>&nbsp;
            {pluralize('widget', widgetsToDisplay.length, false)}
          </div>
        </Flex>
      </div>
      <DeleteWarningModal
        onClose={clearSelection}
        handleCancel={clearSelection}
        open={showWarningModal}
        handleDelete={handleDeleteWidget}
        header='Delete widget'
        removeText='Delete'
        loading={deleteInProgress}
        title={
          <>
            Are you sure you want to delete <strong>{selectedWidget?.name}</strong>?
          </>
        }
      />
    </>
  );
};

WidgetsTable.propTypes = {
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      allowedOrigins: PropTypes.arrayOf(PropTypes.string).isRequired,
      createdAt: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
      questionsAsked: PropTypes.number
    })
  ).isRequired,
  onManageWidget: PropTypes.func.isRequired,
  onNewWidget: PropTypes.func.isRequired
};

export default WidgetsTable;
