import { useEffect, useState } from 'react';
import { ActionIcon, Button, Flex, TextInput } from '@mantine/core';
import { func, string } from 'prop-types';
import classNames from 'classnames';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from './styles.module.scss';

const Heading = ({ teamName, handleTeamNameChange, backBtnClickHandeler }) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(teamName);

  useEffect(() => {
    setValue(teamName);
  }, [teamName]);

  return (
    <Flex align='center'>
      {teamName && (
        <Button
          className={styles.navigateBackButton}
          onClick={backBtnClickHandeler}
          variant='default'
        >
          <SVGIcon name={ICONS_LIST.LEFT_ARROW} height={18} width={18} />
        </Button>
      )}

      {edit ? (
        <Flex className={classNames(styles.teamTitle, styles.editContainer)}>
          Teams&nbsp;&nbsp;&gt;
          <TextInput
            classNames={{
              input: styles.input,
              rightSection: styles.rightSection
            }}
            value={value}
            onChange={(event) => setValue(event.currentTarget.value)}
          />
          <Flex ml={20}>
            <ActionIcon
              variant='transparent'
              onClick={() => {
                setEdit(false);
                setValue(teamName);
              }}
            >
              <SVGIcon name={ICONS_LIST.CROSS_RED} height={14} width={14} />
            </ActionIcon>
            <ActionIcon
              variant='transparent'
              onClick={() => {
                handleTeamNameChange(value);
                setEdit(false);
              }}
            >
              <SVGIcon name={ICONS_LIST.CHECK_GREEN} height={14} width={14} />
            </ActionIcon>
          </Flex>
        </Flex>
      ) : (
        <Flex className={styles.teamTitle} align='center'>
          Teams
          {teamName && (
            <>
              &nbsp;&nbsp;&gt; <b>{teamName}</b>
              <ActionIcon variant='transparent' ml={4} onClick={() => setEdit(true)}>
                <SVGIcon name={ICONS_LIST.EDIT} height={14} width={14} />
              </ActionIcon>
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};

Heading.propTypes = {
  teamName: string.isRequired,
  handleTeamNameChange: func.isRequired,
  backBtnClickHandeler: func.isRequired
};

export default Heading;
