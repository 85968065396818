import { Checkbox, Menu, Table, UnstyledButton } from '@mantine/core';
import classNames from 'classnames';
import { ReactComponent as ActionIcon } from 'assets/icons/3-v-dots.svg';
import { ReactComponent as PrivateChannelIcon } from 'assets/icons/private-channel-lock.svg';
import { array, bool, func } from 'prop-types';
import styles from './styles.module.scss';

const ChannelsTable = ({
  allSelected,
  someSelected,
  setSelectedForDisable,
  selectedForDisable,
  pageChannels,
  channelsToDisplay,
  handleAutoAnswerForChannels
}) => {
  const handleSelectAllChange = (e) => {
    if (e.currentTarget.checked) {
      setSelectedForDisable(
        channelsToDisplay.filter((channel) => !channel.masked).map((channel) => channel.id)
      );
    } else {
      setSelectedForDisable([]);
    }
  };

  const handleRowSelectChange = (e, channel) => {
    const checked = e.currentTarget.checked;
    setSelectedForDisable((prev) => {
      if (checked) {
        return [...prev, channel.id];
      }
      return prev.filter((id) => id !== channel.id);
    });
  };

  return (
    <Table className={styles.autoAnswerChannelsTable}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>
            <Checkbox
              checked={allSelected || someSelected}
              indeterminate={someSelected}
              onChange={handleSelectAllChange}
              radius={4}
              label={'Auto-answering in'}
              classNames={{
                label: styles.tableHeaderCheckboxLabel,
                input: classNames(styles.defaultCheckboxInput, {
                  [styles.checkboxInput]: allSelected || someSelected
                }),
                icon: styles.checkboxIcon
              }}
            />
          </Table.Th>
          <Table.Th></Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {pageChannels.map((channel) => (
          <Table.Tr key={channel.id}>
            <Table.Td>
              <Checkbox
                disabled={channel.masked}
                size={'xs'}
                checked={selectedForDisable.includes(channel.id)}
                onChange={(e) => handleRowSelectChange(e, channel)}
                radius={4}
                label={
                  channel.type === 'private' ? (
                    <div
                      className={classNames(styles.privateChannelLabel, {
                        [styles.maskedChannel]: channel.masked
                      })}
                    >
                      <PrivateChannelIcon style={{ marginRight: '2px' }} />
                      {channel.name}
                    </div>
                  ) : (
                    `#${channel.name}`
                  )
                }
                classNames={{
                  label: styles.tableDataCheckboxLabel,
                  input: classNames(styles.defaultCheckboxInput, {
                    [styles.checkboxInput]: selectedForDisable.includes(channel.id)
                  })
                }}
              />
            </Table.Td>
            {!channel.masked ? (
              <Table.Td>
                <Menu position='left'>
                  <Menu.Target>
                    <UnstyledButton className={styles.button}>
                      <ActionIcon />
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      onClick={async () => {
                        await handleAutoAnswerForChannels([channel], false);
                      }}
                    >
                      Disable auto-answer
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Table.Td>
            ) : (
              <td />
            )}
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};

ChannelsTable.propTypes = {
  allSelected: bool,
  someSelected: bool,
  setSelectedForDisable: func.isRequired,
  selectedForDisable: array.isRequired,
  pageChannels: array.isRequired,
  channelsToDisplay: array.isRequired,
  handleAutoAnswerForChannels: func.isRequired
};

ChannelsTable.defaultProps = {
  allSelected: false,
  someSelected: false
};

export default ChannelsTable;
