// import { toast } from 'react-toastify';
import { END_POINT } from 'utils/apiEndpoints';
import api from './api';

const onboarding = api.injectEndpoints({
  endpoints: (build) => ({
    updateOnboarding: build.mutation({
      query: (body) => ({
        url: END_POINT.ON_BOARDING.INSTALLER,
        method: 'POST',
        body
      }),
      invalidatesTags: ['onboarding']
    })
  })
});

export const { useUpdateOnboardingMutation } = onboarding;

export default onboarding;
