import { useState } from 'react';
import { Button, Divider, Flex, Modal, Image, Text, Textarea, Tooltip } from '@mantine/core';
import PropTypes from 'prop-types';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import styles from './styles.module.scss';

const EditDescriptionModal = (props) => {
  const {
    onClose,
    defalutValue,
    placeholder,
    icon,
    title,
    subText,
    resetValue,
    showDefaultTag,
    onSave,
    isLoading
  } = props;

  const [description, setDescription] = useState(defalutValue);
  const [showWarningModal, setShowWarningModal] = useState(false);

  function cancelCtaClickHandler() {
    if (description?.trim() === defalutValue?.trim()) {
      onClose();
      return;
    }
    setShowWarningModal(true);
  }

  return (
    <>
      {showWarningModal && (
        <DeleteWarningModal
          open={true}
          title='Discard description'
          body='You have unsaved changes. Are you sure you want to discard them?'
          removeText='Discard'
          onClose={() => setShowWarningModal(false)}
          handleDelete={() => {
            setShowWarningModal(false);
            onClose();
          }}
          handleCancel={() => setShowWarningModal(false)}
        />
      )}
      <Modal
        opened
        onClose={onClose}
        title={
          <Text size='18px' fw={600} lh='27px'>
            Edit description
          </Text>
        }
        className='mobile-modal'
        classNames={{ root: styles.wrapper }}
        size={668}
        centered
        onClick={(e) => e.stopPropagation()}
      >
        <Flex gap={10} align='center' justify='start' mb={15}>
          {icon ? <Image h={30} w={30} src={icon} alt='icon' /> : null}
          <Flex direction='column' justify='start' align='start'>
            <Text size='16px' fw={500} lh='22px'>
              {title}
            </Text>
            {!!subText && (
              <Flex gap={4}>
                <Text c='#333' size='12px' fw={400} lh='22px'>
                  {subText}
                </Text>
                {showDefaultTag && (
                  <Text c='grayscale.1' size='10px' fw={400} lh='22px'>
                    (default)
                  </Text>
                )}
                {showDefaultTag && (
                  <Tooltip
                    w={297}
                    label="This description will be set as this connection's default description across all your wikis. You can modify the description of each wiki on the Wikis page later."
                  >
                    <span>
                      <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
                    </span>
                  </Tooltip>
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
        <Textarea
          size={12}
          mt={7}
          mb={7}
          minRows={9}
          classNames={{
            input: styles.textArea
          }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={placeholder}
        />
        {!!resetValue && (
          <>
            <Text
              align='end'
              className='cursor-pointer'
              c='primaryGreen.3'
              size={10}
              fw={500}
              lh='18px'
              onClick={() => setDescription(resetValue)}
            >
              Reset description
            </Text>
            <Divider mb={11} mt={11} />
          </>
        )}
        <Text>
          <Text display='contents' c='#000' size={12} fw={400} lh='18px'>
            Why descriptions?
          </Text>
          &nbsp;
          <Text span c='grayscale.1' size={12} fw={400} lh='18px'>
            Descriptions help Albus answer questions more accurately.
            <a
              href=' https://support.springworks.in/portal/en/kb/articles/how-do-descriptions-help-albus-answer-questions-correctly'
              target='_blank'
              rel='noreferrer'
            >
              <Text span c='primaryGreen.3' fw={500} td='underline' size={12} lh='18px' ml={6}>
                Here&apos;s
              </Text>
            </a>
            &nbsp; a guide on writing descriptions and its benefits.
          </Text>
        </Text>
        <Flex justify='end' gap={13} mt={resetValue ? 16 : 25}>
          <Button
            className='primaryBtnOutlineV2'
            onClick={cancelCtaClickHandler}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            className='primaryBtn'
            disabled={
              isLoading ||
              !description?.trim?.()?.length ||
              description?.trim() === defalutValue?.trim()
            }
            onClick={() => onSave(description?.trim())}
          >
            Save
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

EditDescriptionModal.defaultProps = {
  defalutValue: '',
  placeholder: '',
  subText: '',
  resetValue: '',
  showDefaultTag: false,
  isLoading: false
};

EditDescriptionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  defalutValue: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  subText: PropTypes.string,
  resetValue: PropTypes.bool,
  showDefaultTag: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default EditDescriptionModal;
