import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Image, Modal, Tooltip } from '@mantine/core';
import { useAddSourcesMutation } from 'redux/services/integrations';
import GoogleDriveIcon from 'assets/icons/google-drive.svg';
import styles from './styles.module.scss';

const Picker = (props) => {
  const { close, open, data } = props;
  const { teamId } = useParams();
  const [addSources, { isLoading }] = useAddSourcesMutation();

  const handleDriveClick = (drive) => {
    const document = { documentId: drive.driveId, mimeType: drive.mimeType };

    addSources({
      body: { documents: [document] },
      params: { teamId },
      provider: 'google'
    });

    toast.success(`Adding files from your ${drive.name} drive... This may take a few minutes.`);
    close();
  };

  return (
    <Modal
      centered
      opened={open}
      classNames={{ body: styles.modalBody }}
      title={<p className={styles.modalTitle}>Choose a Drive to Connect</p>}
      onClose={isLoading ? () => null : close}
      size={800}
    >
      {data.map((item) => (
        <Tooltip key={item.driveId} label={item.name} position='top'>
          <div
            key={item.title}
            className={styles.driveCard}
            onClick={isLoading ? () => null : () => handleDriveClick(item)}
          >
            <Image src={GoogleDriveIcon} alt='Google Drive' className={styles.image} />
            <span>{item.name}</span>
          </div>
        </Tooltip>
      ))}
    </Modal>
  );
};

Picker.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
};

export default Picker;
