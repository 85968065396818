import ZapierIcon from 'assets/automations/zapier.svg';
import N8NIcon from 'assets/automations/n8n.svg';
import IFTTT from 'assets/automations/ifttt.svg';
import PipeDreamIcon from 'assets/automations/pipedream.svg';
import WorkatoIcon from 'assets/automations/workato.svg';
import MakeIcon from 'assets/automations/make.svg';
import { AUTOMATION_APPS } from 'pages/ControlPanel/constants';

export const data = [
  {
    id: 1,
    icon: ZapierIcon,
    title: 'Zapier',
    description: 'Create a Zap to automatically train Albus on a file when added to Google Drive.',
    isActive: true,
    name: AUTOMATION_APPS.ZAPIER
  },
  {
    id: 2,
    icon: N8NIcon,
    title: 'n8n',
    description:
      '☕ Something is brewing...our team is currently working on automations for this app.',
    isActive: false,
    name: AUTOMATION_APPS.N8N
  },
  {
    id: 3,
    icon: IFTTT,
    title: 'IFTTT',
    description:
      '☕ Something is brewing...our team is currently working on automations for this app.',
    isActive: false,
    name: AUTOMATION_APPS.IFTTT
  },
  {
    id: 4,
    icon: PipeDreamIcon,
    title: 'Pipedream',
    description:
      '☕ Something is brewing...our team is currently working on automations for this app.',
    isActive: false,
    name: AUTOMATION_APPS.PIPEDREAM
  },
  {
    id: 5,
    icon: WorkatoIcon,
    title: 'Workato',
    description:
      '☕ Something is brewing...our team is currently working on automations for this app.',
    isActive: false,
    name: AUTOMATION_APPS.WORKATO
  },
  {
    id: 6,
    icon: MakeIcon,
    title: 'Make',
    description:
      '☕ Something is brewing...our team is currently working on automations for this app.',
    isActive: false,
    name: AUTOMATION_APPS.MAKE
  }
];
