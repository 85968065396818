import { forwardRef } from 'react';
import { Flex, Button as MantineButton } from '@mantine/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-o.svg';
import styles from './styles.module.scss';

/**
 * Button Component - Custom button component with additional features.
 *
 * @component
 * @param {object} props - React props for Button component.
 * @param {ReactNode} props.children - The content of the button.
 * @param {'primary'|'secondary'|'danger'} [props.variance='primary'] - The color variance of the button. Possible values: 'primary', 'secondary', 'danger'.
 * @param {boolean} [props.outlined=false] - Whether the button should have an outlined style.
 * @param {...object} props... - Additional props from the underlying Button component.
 * @returns {ReactElement} - Rendered Button component.
 *
 * @example
 * // Basic usage
 * <Button>Click me</Button>
 *
 * @example
 * // Outlined button with secondary variance
 * <Button outlined variance="secondary">Click me</Button>
 */
const Button = forwardRef((props, ref) => {
  const {
    children,
    variance,
    outlined,
    disabled,
    withIcon,
    onClick,
    rightSection,
    leftSection,
    className,
    ...others
  } = props;
  return (
    <MantineButton
      ref={ref}
      disabled={disabled}
      className={classNames(
        outlined ? styles.outlined : styles.filled,
        styles[variance],
        disabled && styles.disabled,
        className
      )}
      {...others}
      onClick={onClick}
    >
      <Flex align='center' gap={6}>
        {withIcon && <PlusIcon />} {leftSection && leftSection} {children}
        {rightSection && rightSection}
      </Flex>
    </MantineButton>
  );
});

Button.displayName = 'Button';

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variance: PropTypes.oneOf(['primary', 'secondary', 'danger']),
  outlined: PropTypes.bool,
  withIcon: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  rightSection: PropTypes.node,
  ...MantineButton.propTypes
};

Button.defaultProps = {
  outlined: false,
  variance: 'primary',
  withIcon: false,
  rightSection: null
};

export default Button;
