import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import { useDeleteAccountMutation, useUpdateUserDetailsMutation } from 'redux/services/settings';
import resetCache from 'redux/actions/resetStore';
import localStorage from 'utils/localStorage';
import View from './View';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import ChangePassword from './Component/ChangePassword';
import { NAVBAR_ROUTES } from 'utils/constants';
import { validateFileUpload } from 'utils/helpers';

const MyAccount = () => {
  const { user } = useSelector((state) => state.user);
  const [nameValue, setNameValue] = useState(user?.name || '');
  const [showEdit, setShowEdit] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showLeaveCompanyModal, setShowLeaveCompanyModal] = useState(false);
  const [updateUserDetails, { isLoading }] = useUpdateUserDetailsMutation();
  const [deleteAccount] = useDeleteAccountMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      password: '',
      newPassword: '',
      confirmPassword: ''
    },
    validate: yupResolver(
      Yup.object().shape({
        password: Yup.string().required('required*'),
        newPassword: Yup.string()
          .required('required*')
          .notOneOf([Yup.ref('password')], 'New password must be different from the old password'),
        confirmPassword: Yup.string()
          .required('required*')
          .oneOf([Yup.ref('newPassword')], 'Passwords must match')
      })
    )
  });

  const handleEditSave = async () => {
    const response = await updateUserDetails({ name: nameValue });
    if (response?.data?.ok) {
      toast.success(response.data.message);
      setShowEdit(false);
    }
  };

  const handleChangePassword = async (values) => {
    const response = await updateUserDetails({
      oldPassword: values.password,
      newPassword: values.newPassword
    });
    if (response?.data?.ok) {
      setShowPasswordModal(false);
      toast.success(response.data.message);
    }
    if (response?.error) {
      form.setFieldError('password', 'The current password is incorrect. ');
    }
  };

  const redirectToSignup = () => {
    localStorage.clearStorage();
    dispatch(resetCache);
    navigate(NAVBAR_ROUTES.AUTH.SIGNUP);
  };

  const handleDeleteAccount = async () => {
    const { data } = await deleteAccount({ deleteAccount: true });
    if (data?.ok) {
      redirectToSignup();
    }
  };

  const handleLeaveCompany = async () => {
    const { data } = await deleteAccount({ deleteAccount: false });
    if (data?.ok) {
      redirectToSignup();
    }
  };

  const handleDpSave = async (photo) => {
    const validateImage = validateFileUpload(photo);
    if (!validateImage) {
      return;
    }
    const formData = new FormData();
    formData.append('photo', photo);
    const response = await updateUserDetails(formData);
    if (response?.data?.ok) {
      toast.success('Photo updated successfully');
    }
  };

  return (
    <>
      {showPasswordModal && (
        <ChangePassword
          handleSubmitCTA={handleChangePassword}
          onClose={() => setShowPasswordModal(false)}
          form={form}
        />
      )}
      {showDeleteAccountModal && (
        <DeleteWarningModal
          open={true}
          onClose={() => {
            setShowDeleteAccountModal(false);
          }}
          header='Delete account'
          title='Are you sure you want to delete your account?'
          handleCancel={() => {
            setShowDeleteAccountModal(false);
          }}
          handleDelete={() => {
            handleDeleteAccount();
            setShowDeleteAccountModal(false);
          }}
          body={
            'Note: when you delete your account, you will not be able to access Albus and lose data across all your companies.'
          }
          removeText='Delete'
        />
      )}
      {showLeaveCompanyModal && (
        <DeleteWarningModal
          open={true}
          onClose={() => {
            setShowLeaveCompanyModal(false);
          }}
          header='Leave company'
          title='Are you sure you want to leave this company?'
          handleCancel={() => {
            setShowLeaveCompanyModal(false);
          }}
          handleDelete={() => {
            handleLeaveCompany();
            setShowLeaveCompanyModal(false);
          }}
          body={
            "Note: when you leave this company, you will no longer be able to ask Albus to answer questions from this company's wikis, get help from your admins and access this dashboard."
          }
          removeText='Leave'
        />
      )}

      <View
        isLoading={isLoading}
        showAllSections={user?.isAdmin === false} // for non admins
        showChangePassword={user?.platform === 'email'}
        nameValue={nameValue}
        setNameValue={setNameValue}
        teamName={user?.team.name}
        dp={user?.photo}
        handleDpSave={handleDpSave}
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        email={user.email}
        handleSaveEdit={handleEditSave}
        lastPasswordChangeDate={user?.lastPasswordUpdatedOn}
        handleChangePasswordCtaClick={() => setShowPasswordModal(true)}
        handleDeleteAccountCtaClick={() => setShowDeleteAccountModal(true)}
        handleLeaveCompanyCtaClick={() => setShowLeaveCompanyModal(true)}
        handleCancel={() => {
          setShowEdit(false);
          setNameValue(user?.name);
        }}
      />
    </>
  );
};

export default MyAccount;
