import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Button, Flex, Text, Tooltip } from '@mantine/core';
import { ReactComponent as GradientGlitterIcon } from 'assets/gradient-glitter.svg';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from './styles.module.scss';

const View = (props) => {
  const { questionsLeft, trialEndsAt, checkout } = props;

  return (
    <Flex justify='space-around' align='center' className={styles.billingContainer}>
      <Text className={styles.showInMobile}>Billing</Text>
      <Flex justify='flex-start' align='flex-start' className={styles.billingStatsContainer}>
        <div className={styles.billingContent}>
          <div className={styles.billingContentTop}>
            <Text>Current&nbsp;plan:</Text>
          </div>
          <div className={styles.billingContentBottom}>
            <GradientGlitterIcon />
            <Text className={styles.gradientText}>Trial</Text>
            <Tooltip
              w={274}
              label='We want to help early stage companies work more efficiently with the help of AI.'
            >
              <span>Why?</span>
            </Tooltip>
          </div>
        </div>
        <div className={styles.billingContent}>
          <div className={styles.billingContentTop}>
            <Text>Questions left&nbsp;</Text>
            <Tooltip w={274} label='Your free trial includes 50 questions and 30 days.'>
              <span>
                <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
              </span>
            </Tooltip>
          </div>
          <div className={styles.billingContentBottom}>
            <Text className={styles.questionsLeftText}>{questionsLeft}</Text>
            <Text>Expires on {dayjs(trialEndsAt).format('MMMM DD')}</Text>
          </div>
        </div>
      </Flex>
      <div>
        <Button className={styles.primaryBtn} onClick={checkout}>
          Upgrade now
        </Button>
      </div>
    </Flex>
  );
};

View.propTypes = {
  questionsLeft: PropTypes.number,
  trialEndsAt: PropTypes.string,
  checkout: PropTypes.func.isRequired
};

export default View;
