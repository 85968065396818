import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { Flex, TextInput, Anchor, Loader, Tooltip, Checkbox } from '@mantine/core';
import integrationsApi, { useDisconnectSourcesMutation } from 'redux/services/integrations';
import Table from '../../../../../components/Table';
import ViewChanger from '../../Components/ViewChanger';
import SourceGrid from '../../Components/SourceGrid';
import InfoPopup from 'components/InfoPopup';
import { trainedAtTableHeader } from '../../Notion/DataView';
import MultiSelectActions from '../../Components/MultiSelectActions';
import EmptyState from '../../Components/EmptyState';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import GoogleDriveIcon from 'assets/icons/google-drive.svg';
import { formatDate } from '../../../../../utils/formatters';
import { retrainFailureTooltip, selectSourceIcon, truncateName } from 'pages/Integrations/utils';
import { ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS, VIEW_TYPE, PROVIDERS } from 'utils/constants';
import { SOURCES_STATUS } from '../../Web/constants';
import styles from './styles.module.scss';
import Drives from '../Drives';

const DocumentsDataView = (props) => {
  const {
    viewType,
    setViewType,
    documents,
    user,
    pagination,
    pageCount,
    searchValue,
    debouncedValue,
    setSearchValue,
    isLoading,
    setOpenDisclosure,
    metaPayload,
    totalCount
  } = props;

  const dispatch = useDispatch();
  const { provider, teamId } = useParams();
  const [source, setSource] = useState({
    selectAll: false,
    showRemoveModal: false,
    selected: []
  });
  const [totalItems, setTotalItems] = useState([]);
  const selectedSources = totalItems.filter((item) => source.selected.includes(item?.id));
  const [disconnectSources, { isLoading: isDisconnectSourcesLoading }] =
    useDisconnectSourcesMutation();
  const allInProcessing = documents.every((source) => source.status === SOURCES_STATUS.PROCESSING);
  const isProcessing = documents.some((source) => source.status === SOURCES_STATUS.PROCESSING);
  const tooltipLabels = [
    'Unchecking individual sources when you ‘select all’ is not supported currently. Coming soon.',
    'You cannot select the sources which are under the process. Please wait until they turn active.'
  ];

  const formattedRows = documents.map((list) => ({
    id: list.id,
    name: list.name,
    title: truncateName(list.name, 40),
    sourceIcon: selectSourceIcon(list.type) || GoogleDriveIcon,
    url: list.url,
    type: list.type,
    date: list.createdAt,
    uploadBy: <div className={styles.uploaderName}>{list?.uploadedBy || 'User'}</div>,
    status: list.status,
    admin: list.admin,
    lastTrainingStartedAt: list.lastTrainingStartedAt || list.createdAt,
    lastTrainingAttemptedAt: list.lastTrainingAttemptedAt || list.lastTrainingStartedAt,
    lastTrainedOn: formatDate(list.lastTrainingStartedAt),
    trainingFrequency: list.trainingFrequency,
    error: list.error,
    description: list.description,
    retrainError: retrainFailureTooltip(
      list.status,
      list.lastTrainType,
      list.lastTrainingAttemptedAt || list.lastTrainingStartedAt
    ),
    handleOpenSource: () => list.url && window.open(list.url, '_blank'),
    tooltip: list.name.length > 40 && list.name
  }));

  async function handleDeleteSources() {
    const payload = {
      provider: PROVIDERS.GOOGLE,
      teamId,
      ...(source.selected.length ? { documentMongoIds: source.selected } : {})
    };

    const response = await disconnectSources(payload);
    if (response?.data?.ok) {
      toast.success(response.data.message);
      setTotalItems((prevItems) => prevItems.filter((item) => !source.selected.includes(item.id)));
      dispatch(integrationsApi.util.invalidateTags(['SourcesByProvider']));
      setSource((prevState) => ({
        ...prevState,
        showRemoveModal: false,
        selected: [],
        selectAll: false
      }));
    }
  }

  function handleSelectAll() {
    setSource((prevState) => ({
      ...prevState,
      selectAll: !prevState.selectAll,
      selected: []
    }));
  }

  function handleCheckboxCta(source) {
    setSource((prevState) => {
      const uncheckSelectedSource = prevState.selected.includes(source.id);

      const updatedSelectedSources = uncheckSelectedSource
        ? prevState.selected.filter((sourceId) => sourceId !== source.id)
        : [...prevState.selected, source.id];

      return {
        ...prevState,
        selected: updatedSelectedSources
      };
    });
  }

  return (
    <>
      <Flex mt={27} justify='space-between'>
        <TextInput
          className={styles.input}
          placeholder='Search for sources'
          leftSection={<SearchIcon />}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
        <Flex align='flex-end'>
          <Tooltip
            disabled={!allInProcessing}
            w={325}
            label='To select sources and take action, wait for them to be processed.'
          >
            <Checkbox
              label='Select all'
              onChange={handleSelectAll}
              color='primaryGreen.3'
              radius={4}
              checked={source.selectAll}
              disabled={allInProcessing}
              classNames={{
                root: classNames(styles.checkbox, {
                  [styles.hideSelectAll]: !documents.length
                }),
                input: 'cursor-pointer',
                label: classNames(styles.selectAll, { [styles.disabled]: allInProcessing })
              }}
            />
          </Tooltip>
          <ViewChanger
            viewType={viewType}
            setViewType={setViewType}
            user={user}
            provider={provider}
            setOpenDisclosure={setOpenDisclosure}
            pagination={pagination}
          />
          {/* Upload entire drive or shared drive */}
          <Drives />
          {provider === PROVIDERS.GOOGLE && (
            <InfoPopup
              title='Disclosure'
              body={
                <>
                  Your data is uniquely stored in our database in a non-human readable format.&nbsp;
                  <Anchor underline c='primaryGreen.3' onClick={() => setOpenDisclosure(true)}>
                    Learn more.
                  </Anchor>
                </>
              }
            />
          )}
        </Flex>
      </Flex>
      {documents.length ? (
        viewType === VIEW_TYPE.GRID ? (
          <SourceGrid
            items={formattedRows}
            totalItems={totalItems}
            setTotalItems={setTotalItems}
            user={user}
            hideCreatedAt={true}
            sourceIcon={GoogleDriveIcon}
            pagination={pagination}
            pageCount={pageCount}
            isLoading={isLoading}
            metaPayload={metaPayload}
            selectedSources={source.selected}
            isAllSourcesSelected={source.selectAll}
            handleCheckboxCta={handleCheckboxCta}
            showCheckbox
            checkboxTooltipLabels={tooltipLabels}
          />
        ) : (
          <Table
            headers={['Name', trainedAtTableHeader, 'Uploaded by', 'Status', '']}
            rows={formattedRows}
            pagination={pagination}
            pageCountFromProp={pageCount}
            pageLimitFromProp={ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS}
            sourceIcon={GoogleDriveIcon}
            totalCount={totalCount}
            metaPayload={metaPayload}
            disableRowClick
            selectedSources={source.selected}
            isAllSourcesSelected={source.selectAll}
            handleCheckboxCta={handleCheckboxCta}
            showCheckbox
            checkboxTooltipLabels={tooltipLabels}
          />
        )
      ) : isLoading ? (
        <Flex align='center' justify='center' h='55vh'>
          <Loader />
        </Flex>
      ) : debouncedValue ? (
        <EmptyState hideButton text={`No sources matching "${searchValue}" found.`} />
      ) : (
        <EmptyState hideButton={searchValue} isDisabled={!user.isAdmin} />
      )}
      {(source.selectAll || source.selected.length > 0) && (
        <MultiSelectActions
          selectedSources={selectedSources}
          isDisconnectSourcesLoading={isDisconnectSourcesLoading}
          selectAll={source.selectAll}
          isProcessing={isProcessing}
          handleDeleteSources={handleDeleteSources}
          showRemoveSourceModal={source.showRemoveModal}
          setShowRemoveSourceModal={(value) =>
            setSource((prevState) => ({ ...prevState, showRemoveModal: value }))
          }
        />
      )}
    </>
  );
};

DocumentsDataView.defaultProps = {
  viewType: 'grid',
  documents: []
};

DocumentsDataView.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func.isRequired,
  documents: PropTypes.array,
  user: PropTypes.object.isRequired,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  }).isRequired,
  pageCount: PropTypes.PropTypes.number.isRequired,
  searchValue: PropTypes.PropTypes.string.isRequired,
  setSearchValue: PropTypes.PropTypes.func.isRequired,
  isLoading: PropTypes.PropTypes.bool.isRequired,
  debouncedValue: PropTypes.PropTypes.string.isRequired,
  setOpenDisclosure: PropTypes.PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }).isRequired
};
export default DocumentsDataView;
