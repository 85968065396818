import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Anchor, HoverCard, Image, Tooltip } from '@mantine/core';
import { useGetSettingsQuery } from 'redux/services/settings';
import { useNavigate } from 'react-router-dom';
import WikiIcon from 'assets/ask-albus/wiki-icon.svg';
import WikiIconDisabled from 'assets/ask-albus/wiki-icon-disabled.svg';
import AiIcon from 'assets/ask-albus/ai-icon.svg';
import AiIconDisabled from 'assets/ask-albus/ai-icon-disabled.svg';
import { NAVBAR_ROUTES } from 'utils/constants';
import styles from '../styles.module.scss';

const SearchAnswerIcon = (props) => {
  const { showAiAnswer, isFreePlan, isAdmin, handleSearchAnswerClick } = props;
  const { data: settings } = useGetSettingsQuery();
  const navigate = useNavigate();

  // Check the conditions for disabling the CTA button
  // 1. Free Plan
  // 2. AI is disabled + wiki is enabled + current answer is generated using wiki
  // 3. Wiki is disabled + AI is enabled + current answer is generated using ai
  const isDisabled =
    isFreePlan ||
    (!showAiAnswer && !settings?.ai && settings?.training) ||
    (showAiAnswer && !settings?.training && settings?.ai);

  let tooltipLabel = showAiAnswer ? 'Search wiki' : 'Search Online';

  // If the CTA is disabled and user is either Admin or on Free Plan, return HoverCard with CTA to upgrade plan/ enable the search capability
  if ((isDisabled && isFreePlan) || (isDisabled && isAdmin)) {
    tooltipLabel = isFreePlan ? (
      <span>
        You cannot search for an answer online on the free trial.&nbsp;
        <Anchor onClick={() => navigate(NAVBAR_ROUTES.BILLING)}>Upgrade</Anchor> your plan to unlock
        this feature.
      </span>
    ) : (
      <span>
        Searching for {showAiAnswer ? `an answer in wiki` : `an answer online`} is disabled.&nbsp;
        <Anchor onClick={() => navigate(NAVBAR_ROUTES.SETTINGS_TABS.SEARCH_CAPABILITIES)}>
          Enable
        </Anchor>
        &nbsp; it for your company.
      </span>
    );

    return (
      <HoverCard
        position='top-start'
        arrowOffset={15}
        withArrow
        arrowSize={8}
        width={280}
        classNames={{ dropdown: styles.hovercardDropDown, arrow: styles.hovercardArrow }}
        className={styles.disabledActionIcon}
      >
        <HoverCard.Target>
          <Image src={showAiAnswer ? WikiIconDisabled : AiIconDisabled} />
        </HoverCard.Target>
        <HoverCard.Dropdown>{tooltipLabel}</HoverCard.Dropdown>
      </HoverCard>
    );
  } else if (isDisabled && !isAdmin) {
    // If user is on Paid Plan and not an admin, set Tooltip Label as per method of completion of answer, i.e., wiki or ai
    tooltipLabel = showAiAnswer
      ? `Your company admins have disabled searching for an answer in the wiki. Ask them to enable it for your company.`
      : `Your company admins have disabled searching for an answer online. Ask them to enable it for your company.`;
  }

  return (
    <Tooltip label={tooltipLabel}>
      <div
        className={classNames(styles.actionIcon, { [styles.disabledActionIcon]: isDisabled })}
        onClick={() => handleSearchAnswerClick(isDisabled)}
      >
        {showAiAnswer ? (
          <Image src={isDisabled ? WikiIconDisabled : WikiIcon} w={16} />
        ) : (
          <Image src={isDisabled ? AiIconDisabled : AiIcon} w={16} />
        )}
      </div>
    </Tooltip>
  );
};

SearchAnswerIcon.propTypes = {
  showAiAnswer: PropTypes.bool.isRequired,
  isFreePlan: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  handleSearchAnswerClick: PropTypes.func.isRequired
};

export default SearchAnswerIcon;
