import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Combobox, Input, InputBase, useCombobox, ScrollArea } from '@mantine/core';

const CustomOptionSingleSelect = (props) => {
  const {
    CustomOption,
    optionsData,
    label,
    placeholder,
    onChange,
    optionValue,
    inputStyles,
    inputBaseStyles,
    inputLabelStyles,
    inputDropdownStyles,
    searchType,
    onSearchChange,
    searchValue,
    leftSection,
    rightSection,
    error,
    inputOptionStyles,
    ...others
  } = props;

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  });
  const [value, setValue] = useState(optionValue || null);
  const [search, setSearch] = useState(searchValue || '');
  const shouldFilterOptions = optionsData.every((item) => item.value !== search);
  const filteredOptions = shouldFilterOptions
    ? optionsData.filter((item) => {
        const value = (item.label || item.title || '').toLowerCase();
        const searchTerms = searchValue.toLowerCase().trim().split(' ');
        return searchTerms.every((searchTerm) => value.includes(searchTerm));
      })
    : optionsData;
  const selectedOption = filteredOptions.find((item) => item.value === value);
  const options = filteredOptions.map((item) => {
    return (
      <Combobox.Option
        value={item.value || item.url}
        key={item.value || item.url}
        className={inputOptionStyles}
      >
        <CustomOption {...item} />
      </Combobox.Option>
    );
  });

  useEffect(() => {
    if (!search && optionValue) {
      const selectedOption = filteredOptions.find((item) => item.value === optionValue);
      setSearch(selectedOption.label || '');
    }
  }, []);

  useEffect(() => {
    setValue(optionValue || []);
  }, [optionValue]);

  const handleOptionSubmit = (val) => {
    setValue(val);
    onChange(val);
    if (onSearchChange) {
      const selectedOption = filteredOptions.find((item) => item.value === val || item.url === val);
      setSearch(selectedOption.label || selectedOption.title || '');
      onSearchChange(selectedOption.label || selectedOption.title || '');
    }
    combobox.closeDropdown();
  };

  const handleInputChange = (event) => {
    combobox.openDropdown();
    combobox.updateSelectedOptionIndex();
    setSearch(event.currentTarget.value);
    if (onSearchChange) {
      onSearchChange(event.currentTarget.value);
    }
  };

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => handleOptionSubmit(val)}
    >
      <Combobox.Target>
        {searchType ? (
          <InputBase
            label={label}
            placeholder={placeholder}
            leftSection={leftSection}
            rightSection={rightSection ? rightSection : <Combobox.Chevron />}
            rightSectionPointerEvents='none'
            classNames={{
              label: inputLabelStyles,
              wrapper: inputStyles,
              input: inputBaseStyles
            }}
            value={search}
            onChange={(event) => handleInputChange(event)}
            onClick={() => combobox.openDropdown()}
            onFocus={() => combobox.openDropdown()}
            onBlur={() => {
              combobox.closeDropdown();
            }}
            error={error}
            {...others}
          />
        ) : (
          <InputBase
            component='button'
            pointer
            type='button'
            label={label}
            rightSection={<Combobox.Chevron />}
            rightSectionPointerEvents='none'
            classNames={{
              label: inputLabelStyles,
              wrapper: inputStyles,
              input: inputBaseStyles
            }}
            onClick={() => combobox.openDropdown()}
            error={error}
            {...others}
          >
            {selectedOption ? (
              selectedOption.label
            ) : (
              <Input.Placeholder>{placeholder}</Input.Placeholder>
            )}
          </InputBase>
        )}
      </Combobox.Target>

      <Combobox.Dropdown
        hidden={options.length === 0}
        classNames={{ dropdown: inputDropdownStyles }}
      >
        <Combobox.Options>
          <ScrollArea.Autosize type='scroll' mah={220}>
            {options}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

CustomOptionSingleSelect.defaultProps = {
  label: '',
  placeholder: 'Pick any',
  onChange: () => null,
  inputLabelStyles: '',
  inputBaseStyles: '',
  inputDropdownStyles: '',
  inputStyles: '',
  searchValue: '',
  searchType: false,
  error: '',
  leftSection: null,
  rightSection: null,
  inputOptionStyles: ''
};

CustomOptionSingleSelect.propTypes = {
  CustomOption: PropTypes.elementType.isRequired,
  optionsData: PropTypes.array.isRequired,
  optionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  inputLabelStyles: PropTypes.string,
  inputBaseStyles: PropTypes.string,
  inputDropdownStyles: PropTypes.string,
  inputStyles: PropTypes.string,
  searchType: PropTypes.bool,
  onSearchChange: PropTypes.func,
  searchValue: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  leftSection: PropTypes.element,
  rightSection: PropTypes.element,
  inputOptionStyles: PropTypes.string
};

export default CustomOptionSingleSelect;
