import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { LoadingOverlay } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import ApisTable from './ApisTable';
import EmptyState from './EmptyState';
import OnlyDesktop from 'pages/ChatWidgets/OnlyDesktop';
import {
  useGetCustomApisQuery,
  useLazyGetTeamsQuery,
  useLazyGetCustomApiQuery
} from 'redux/services/settings';
import styles from './styles.module.scss';
import CustomApiDetails from './CustomApiDetails';

const CustomApis = ({ setSubTab, subTab }) => {
  const [newCustomApi, setNewCustomApi] = useState(false);
  const [selectedCustomApi, setSelectedCustomApi] = useState(null);
  const [optionsData, setOptionsData] = useState([]);
  const [existingWikis, setExistingWikis] = useState([]);

  const { data: { data = {} } = { data: {} }, isLoading: fetchingCustomApis } =
    useGetCustomApisQuery();
  const [getTeams, { isFetching }] = useLazyGetTeamsQuery();
  const [getCustomApiDetails, { isFetching: fetchingDetails }] = useLazyGetCustomApiQuery();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const customApis = data;
  const id = window.location.hash.replace('#', '');

  useEffect(() => {
    if (id && !selectedCustomApi) {
      onManageCustomApi(id);
    }
  }, [id]);

  useEffect(() => {
    if (!subTab) {
      setNewCustomApi(false);
      setSelectedCustomApi(null);
    }
  }, [subTab]);

  const formatTeams = (customApi, availableTeams) => {
    const selectedWikis = customApi?.teams || [];
    const availableWikis = availableTeams
      .filter((team) => !selectedWikis.includes(team.teamId))
      .sort((a, b) => a.name.localeCompare(b.name));

    const data = availableWikis.map((wiki) => ({
      value: wiki.teamId,
      label: wiki.name,
      sourcesCount: wiki.sourcesCount
    }));

    setOptionsData(data);
  };

  const formatCustomApiData = (customApi, availableTeams) => {
    if (!customApi?.id) return null;
    const mappedWikis = customApi.teams
      ?.map((wikiId) => {
        const wikiDetails = availableTeams.find((wiki) => wiki.teamId === wikiId);
        return (
          wikiDetails && {
            value: wikiDetails.teamId,
            label: wikiDetails.name,
            sourcesCount: wikiDetails.sourcesCount
          }
        );
      })
      .filter(Boolean);
    setExistingWikis(mappedWikis);
    const updatedCustomApi = { ...customApi, wikis: mappedWikis };
    delete updatedCustomApi.teams;
    return updatedCustomApi;
  };

  const onManageCustomApi = async (customApiId) => {
    try {
      const {
        data: { data }
      } = await getCustomApiDetails(customApiId);
      const {
        data: { teams: availableTeams }
      } = await getTeams();
      formatTeams(data, availableTeams);
      const formattedCustomApiData = formatCustomApiData(data, availableTeams);
      setSelectedCustomApi(formattedCustomApiData);
      setNewCustomApi(false);
      setSubTab('manage');
      return formattedCustomApiData;
    } catch (error) {
      toast.error('Failed to fetch custom API details. Try again!');
    }
  };

  const onNewCustomApi = async () => {
    try {
      const {
        data: { teams: availableTeams }
      } = await getTeams();
      formatTeams({}, availableTeams);
      setSelectedCustomApi(null);
      setNewCustomApi(true);
      setExistingWikis([]);
      setSubTab('create');
    } catch (error) {
      toast.error('Oops, something went wrong. Try again!');
    }
  };

  if (fetchingCustomApis) {
    return <LoadingOverlay visible />;
  }

  if (isMobile) {
    return (
      <>
        {customApis.length > 0 || newCustomApi || selectedCustomApi ? (
          <OnlyDesktop className={styles.onlyDesktopView} onlyDesktopText='Custom API' />
        ) : (
          <EmptyState onNewCustomApi={onNewCustomApi} />
        )}
        <LoadingOverlay visible={fetchingDetails || isFetching} />
      </>
    );
  }

  if (!customApis.length && !subTab) {
    return <EmptyState onNewCustomApi={onNewCustomApi} />;
  }

  return (
    <>
      {subTab ? (
        <CustomApiDetails
          className={styles.customApiDetailsView}
          customApi={selectedCustomApi}
          selectedWikis={existingWikis}
          isFetchingTeams={isFetching}
          optionsData={optionsData}
          onManageCustomApi={onManageCustomApi}
        />
      ) : (
        <ApisTable
          customApis={customApis}
          onManageCustomApi={onManageCustomApi}
          onNewCustomApi={onNewCustomApi}
        />
      )}
      {/* Show loading overlay when click on manage wiki */}
      <LoadingOverlay visible={fetchingDetails || isFetching} />
    </>
  );
};

CustomApis.defaultProps = {
  subTab: null
};

CustomApis.propTypes = {
  setSubTab: PropTypes.func.isRequired,
  subTab: PropTypes.string
};

export default CustomApis;
