import { useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from '@mantine/core';
import InternetIcon from 'assets/icons/internet.svg';

const SourceIcon = (props) => {
  const { url, sourceIconStyles } = props;
  const [showInternetIcon, setShowInternetIcon] = useState(false);

  const handleImageLoad = (event) => {
    // Check if the natural height of the image is less than 16 and render custom Web Icon
    if (event.target.naturalHeight <= 16) {
      setShowInternetIcon(true);
    }
  };

  return (
    <>
      {!showInternetIcon ? (
        <img
          src={url}
          alt='Website Icon'
          onLoad={handleImageLoad}
          className={sourceIconStyles}
          loading='lazy'
        />
      ) : (
        <Image src={InternetIcon} alt='Website Icon' className={sourceIconStyles} />
      )}
    </>
  );
};

SourceIcon.defaultProps = {
  sourceIconStyles: ''
};

SourceIcon.propTypes = {
  url: PropTypes.string.isRequired,
  sourceIconStyles: PropTypes.string
};

export default SourceIcon;
