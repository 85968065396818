import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Center, Text, Button } from '@mantine/core';
import userApi from 'redux/services/user';
import { ReactComponent as TrailExpiryImage } from 'assets/trail-expiry.svg';
import { CONNECTION_SYNC_STATUS } from 'utils/constants';
import { capitalizeFirstLetter } from 'utils/formatters';
import styles from './styles.module.scss';

const ProviderConnectionStatusModal = (props) => {
  const { provider, status } = props;
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      {showModal ? (
        <Modal
          centered
          opened={showModal}
          withCloseButton={false}
          size='xl'
          onClose={() => setShowModal(false)}
          classNames={{ root: styles.root }}
        >
          <Center>
            <TrailExpiryImage />
          </Center>
          {status === CONNECTION_SYNC_STATUS.FAILED ? (
            <Text className={styles.content}>
              Albus could not connect with your {capitalizeFirstLetter(provider)} account.
              Disconnect and connect with your
              {capitalizeFirstLetter(provider)} account again in&nbsp;
              <span className={styles.greenText}>Settings &gt; My connections.</span>
            </Text>
          ) : (
            <Text className={styles.content}>
              Albus is connecting with your {capitalizeFirstLetter(provider)} account. You can add
              sources once the connection is complete.
            </Text>
          )}
          <Center>
            <Button
              className={styles.primaryBtn}
              onClick={() => {
                dispatch(userApi.util.invalidateTags(['User']));
                setShowModal(false);
              }}
            >
              Close
            </Button>
          </Center>
        </Modal>
      ) : null}

      <Button
        className='primaryBtn'
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
      >
        Add source
      </Button>
    </>
  );
};

ProviderConnectionStatusModal.propTypes = {
  provider: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

export default ProviderConnectionStatusModal;
