import PropTypes from 'prop-types';
import { Modal, Title, Text, Flex, Button, Tooltip } from '@mantine/core';
import { useSelector } from 'react-redux';
import { stringDecoder } from 'utils/helpers';
import { truncateName } from 'pages/Integrations/utils';
import styles from './styles.module.scss';

const RemoveSource = (props) => {
  const {
    opened,
    onClose,
    handleDelete,
    item,
    disableButton,
    selectAll,
    isDisconnectSourcesLoading,
    count,
    selectedSources
  } = props;
  const { user } = useSelector((state) => state.user);
  const sourceName = (item && item?.originalName) || item?.name || item?.name?.props?.children;

  // Extract admin names of the sources other than the current user
  const adminNames = [
    ...new Set(
      selectedSources
        .filter((item) => item?.admin?._id !== user._id)
        .map((item) => item?.admin?.name)
    )
  ];

  return (
    <Modal
      centered
      opened={opened}
      size={550}
      onClose={onClose}
      title={
        <Title className={styles.title}>{`Remove ${
          count > 1 || selectAll ? 'sources' : 'source'
        }`}</Title>
      }
      onClick={(e) => e.stopPropagation()}
    >
      <Title className={styles.question} order={6}>
        {selectAll || count > 1 ? (
          `Are you sure you want to remove ${
            selectAll ? 'all sources' : count > 1 ? `${count} sources` : 'the source'
          }?`
        ) : (
          <>
            Are you sure you want to remove the source&nbsp;
            <Tooltip
              label={sourceName || selectedSources[0]?.name}
              disabled={sourceName?.length < 50 || selectedSources[0]?.name.length < 50}
              w={500}
              classNames={{ tooltip: styles.tooltipText }}
            >
              <span>
                <b>{truncateName(stringDecoder(sourceName || selectedSources[0]?.name), 50)}</b>?
              </span>
            </Tooltip>
          </>
        )}
      </Title>

      {!selectAll && count > 1 && adminNames.length > 0 && (
        <Text className={styles.noteContent}>
          <span className={styles.noteTitle}>Note:</span>&nbsp; These sources were added by&nbsp;
          {adminNames.length === 2
            ? adminNames.join(', ')
            : `${adminNames.slice(0, 2).join(', ')}${adminNames.length > 2 ? ' and more' : ''}`}
          . I will inform {adminNames.length > 1 ? 'them' : 'him/her'} once you remove the sources.
        </Text>
      )}

      {!selectAll &&
        count === 1 &&
        ((Object.keys(item).length > 0 && user._id !== item?.admin?._id) ||
          (selectedSources.length > 0 && user._id !== selectedSources[0]?.admin?._id)) && (
          <Text className={styles.noteContent}>
            <span className={styles.noteTitle}>Note:</span>&nbsp; This source was added by&nbsp;
            {item?.admin?.name ? item.admin.name : adminNames ? adminNames[0] : 'another admin'}. I
            will inform him/her once you remove the source.
          </Text>
        )}

      <Flex align='center' justify='flex-end' style={{ width: '100%', marginTop: '30px' }}>
        <Button
          variant='outline'
          color='red'
          className={styles.button}
          onClick={onClose}
          disabled={disableButton}
        >
          Cancel
        </Button>
        <Button
          color='red'
          className={styles.button}
          onClick={handleDelete}
          disabled={disableButton}
          loading={isDisconnectSourcesLoading}
        >
          Remove
        </Button>
      </Flex>
    </Modal>
  );
};

RemoveSource.defaultProps = {
  opened: false,
  onClose: () => null,
  handleDelete: () => null,
  item: {},
  disableButton: false,
  selectAll: false,
  isDisconnectSourcesLoading: false,
  selectedSources: [],
  count: 1
};

RemoveSource.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  handleDelete: PropTypes.func,
  item: PropTypes.object,
  disableButton: PropTypes.bool,
  selectAll: PropTypes.bool,
  count: PropTypes.number,
  isDisconnectSourcesLoading: PropTypes.bool,
  selectedSources: PropTypes.array
};

export default RemoveSource;
