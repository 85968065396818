import PropTypes from 'prop-types';
import { Image, Textarea, Group, Text } from '@mantine/core';
import CustomOptionSingleSelect from 'components/CustomOptionSingleSelect';
import InputIcon from 'assets/ask-albus/input-icon.svg';
import styles from './styles.module.scss';

const SelectItem = ({ label, description, ...others }) => {
  return (
    <div {...others}>
      <Group wrap='nowrap'>
        <div>
          <Text fz={13}>{label}</Text>
          <Text fz={11} opacity={0.65}>
            {description}
          </Text>
        </div>
      </Group>
    </div>
  );
};

const View = (props) => {
  const {
    isFreePlan,
    formValues,
    queryRoutingOptions,
    handleChange,
    handleSubmit,
    chatSelected,
    handleOpenPromptIdeasPage,
    isOnBoarding,
    wikiPromptIdeas
  } = props;

  function handleKeyDown(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  }

  return (
    <div className={styles.inputContainer}>
      <div className={styles.inputController}>
        {!isFreePlan && (
          <div id='select-routing'>
            <CustomOptionSingleSelect
              label='Answering from'
              optionValue={formValues.selectedQueryRouting}
              optionsData={queryRoutingOptions}
              onChange={(value) => handleChange('selectedQueryRouting', value)}
              inputLabelStyles={styles.selectLabel}
              inputStyles={styles.selectWrapper}
              inputOptionStyles={styles.selectOption}
              CustomOption={SelectItem}
            />
          </div>
        )}
        {!isOnBoarding && (
          <div className={styles.getPromptIdeasText} onClick={handleOpenPromptIdeasPage}>
            {chatSelected
              ? 'View more questions'
              : !wikiPromptIdeas.length && 'View suggested questions'}
          </div>
        )}
      </div>
      <Textarea
        id='ask-a-question-input-field'
        placeholder='Ask me anything!'
        rightSection={
          <Image src={InputIcon} className={styles.submitButton} onClick={() => handleSubmit()} />
        }
        rightSectionWidth={24}
        value={formValues.textInput}
        onChange={(e) => handleChange('textInput', e.target.value)}
        onKeyDown={(event) => {
          handleKeyDown(event);
        }}
        disabled={isOnBoarding}
        mb={14}
        classNames={{
          input: styles.askAlbusInput,
          rightSection: styles.askAlbusInputRightSection
        }}
      />
    </div>
  );
};

View.propTypes = {
  queryRoutingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  isFreePlan: PropTypes.bool.isRequired,
  formValues: PropTypes.shape({
    textInput: PropTypes.string,
    selectedQueryRouting: PropTypes.string
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  chatSelected: PropTypes.bool.isRequired,
  handleOpenPromptIdeasPage: PropTypes.func.isRequired,
  isOnBoarding: PropTypes.bool,
  wikiPromptIdeas: PropTypes.array
};

View.defaultProps = {
  selectisOnBoardingedChat: false,
  wikiPromptIdeas: []
};

SelectItem.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default View;
