import { SimpleGrid } from '@mantine/core';
import styles from './styles.module.scss';

const SimpleGridTheme = SimpleGrid.extend({
  defaultProps: {
    cols: { base: 1, xs: 2, sm: 2, lg: 3, xl: 4 },
    spacing: { base: 'lg', sm: 'sm', lg: 'md', xl: 'md' }
  },
  classNames: {
    root: styles.simpleGrid
  }
});

export default SimpleGridTheme;
