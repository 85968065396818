import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ChatContext = createContext();

const CurrentChatProvider = ({ children }) => {
  const [currentChat, setCurrentChat] = useState({});

  const saveCurrentChat = (chat) => {
    setCurrentChat(chat);
  };

  return (
    <ChatContext.Provider value={{ currentChat, saveCurrentChat }}>{children}</ChatContext.Provider>
  );
};

CurrentChatProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const useChat = () => {
  return useContext(ChatContext);
};

export { CurrentChatProvider, useChat };
