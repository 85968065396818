import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import View from './View';
import ConfirmationModal from '../ConfirmationModal';
import { useUpdateLicensesMutation } from 'redux/services/licenses';
import { useRevokeInvitationsMutation } from 'redux/services/invite';
import { maybePluralize } from 'utils/utilities';

const MultiSelectActions = (props) => {
  const { status, resetData, selectedUsers = [], setIsModalOpen, unassignedLicenses } = props;

  // Modals ON or OFF
  const [isDenyConfirmModalOpen, setIsDenyConfirmModalOpen] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isAssignConfirmModalOpen, setIsAssignConfirmModalOpen] = useState(false);
  const [isUnassignConfirmModalOpen, setIsUnassignConfirmModalOpen] = useState(false);
  const [isRevokeInvitationConfirmModalOpen, setIsRevokeInvitationConfirmModalOpen] =
    useState(false);
  // Patch Licenses
  const [updateLicenses, { isSuccess: isUpdateLicenseSuccess }] = useUpdateLicensesMutation();
  const [revokeInvitations, { isLoading }] = useRevokeInvitationsMutation();

  // Reset data on success updating
  useEffect(() => {
    if (isUpdateLicenseSuccess) resetData([]);
  }, [isUpdateLicenseSuccess]);

  // On Confirmation Actions
  const handleConfirmation = (type) => {
    const body = {
      ids: selectedUsers,
      status: type,
      autoBuySubscription: false
    };
    updateLicenses(body);
  };

  const handleRevokeInvitationConfirmation = () => {
    const body = {
      ids: selectedUsers
    };

    try {
      revokeInvitations(body);
    } catch (error) {
      toast.error('Failed to send request');
    } finally {
      resetData([]);
    }
  };

  return (
    <>
      <View
        setIsUnassignConfirmModalOpen={setIsUnassignConfirmModalOpen}
        setIsRevokeInvitationConfirmModalOpen={setIsRevokeInvitationConfirmModalOpen}
        setIsAssignConfirmModalOpen={setIsAssignConfirmModalOpen}
        setIsDenyConfirmModalOpen={setIsDenyConfirmModalOpen}
        setIsApproveModalOpen={setIsApproveModalOpen}
        resetData={resetData}
        selectedUsers={selectedUsers}
        status={status}
        setIsModalOpen={setIsModalOpen}
        unassignedLicenses={unassignedLicenses}
      />

      <ConfirmationModal
        opened={isDenyConfirmModalOpen}
        setOpened={setIsDenyConfirmModalOpen}
        message={`Are you sure you want to deny License ${maybePluralize(
          selectedUsers.length,
          'request'
        )} for ${selectedUsers.length} ${maybePluralize(selectedUsers.length, 'teammate')}?`}
        title={`Deny License ${maybePluralize(selectedUsers.length, 'request')}`}
        confirmBtnLabel='Deny'
        handleConfirmation={handleConfirmation}
        actionType='Denied'
      />
      <ConfirmationModal
        opened={isApproveModalOpen}
        setOpened={setIsApproveModalOpen}
        message={`Are you sure you want to approve License ${maybePluralize(
          selectedUsers.length,
          'request'
        )} for ${selectedUsers.length} ${maybePluralize(selectedUsers.length, 'teammate')}?`}
        title={`Approve License ${maybePluralize(selectedUsers.length, 'request')}`}
        confirmBtnLabel='Approve'
        handleConfirmation={handleConfirmation}
        actionType='Approved'
      />
      <ConfirmationModal
        opened={isAssignConfirmModalOpen}
        setOpened={setIsAssignConfirmModalOpen}
        message={`Are you sure you want to assign ${maybePluralize(
          selectedUsers.length,
          'License'
        )} to ${selectedUsers.length} ${maybePluralize(selectedUsers.length, 'teammate')}?`}
        title={`Assign ${maybePluralize(selectedUsers.length, 'License')}`}
        confirmBtnLabel='Assign'
        handleConfirmation={handleConfirmation}
        actionType='Assign'
      />
      <ConfirmationModal
        opened={isUnassignConfirmModalOpen}
        setOpened={setIsUnassignConfirmModalOpen}
        message={`Are you sure you want to unassign ${maybePluralize(
          selectedUsers.length,
          'License'
        )} to ${selectedUsers.length} ${maybePluralize(selectedUsers.length, 'teammate')}?`}
        title={`Unassign ${maybePluralize(selectedUsers.length, 'License')}`}
        confirmBtnLabel='Unassign'
        handleConfirmation={handleConfirmation}
        actionType='Unassign'
      />
      <ConfirmationModal
        isLoading={isLoading}
        opened={isRevokeInvitationConfirmModalOpen}
        setOpened={setIsRevokeInvitationConfirmModalOpen}
        message={`Are you sure you want to revoke ${maybePluralize(
          selectedUsers.length,
          'invitation'
        )} to ${selectedUsers.length} ${maybePluralize(selectedUsers.length, 'teammate')}?`}
        title={`Revoke ${maybePluralize(selectedUsers.length, 'Invitation')}`}
        confirmBtnLabel='Revoke'
        handleConfirmation={handleRevokeInvitationConfirmation}
        actionType='revoke'
      />
    </>
  );
};

MultiSelectActions.defaultProps = {
  selectedUsers: []
};

MultiSelectActions.propTypes = {
  status: PropTypes.string.isRequired,
  resetData: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array,
  setIsModalOpen: PropTypes.func.isRequired,
  unassignedLicenses: PropTypes.number.isRequired
};

export default MultiSelectActions;
