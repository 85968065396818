import { Image, HoverCard, List, Anchor, Avatar } from '@mantine/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { usePostSlackConnectMutation } from 'redux/services/slack-connect.js';
import trutoIcon from 'assets/connectors/truto.svg';
import Icon from 'assets/connectors/i-icon.svg';
import AICPASOC from 'assets/connectors/aicpa-soc.svg';
import ALbusLogo from 'assets/albus-logo.svg';
import ISO27001 from 'assets/connectors/iso-27001.svg';
import ISO27701 from 'assets/connectors/iso-27701.svg';
import GDPR from 'assets/connectors/gdpr.svg';
import { ReactComponent as KBIcon } from 'assets/icons/kb-faq.svg';
import { ReactComponent as Email } from 'assets/icons/email-circle.svg';
import styles from '../styles.module.scss';

const RightSection = (props) => {
  const { conn } = props;

  const { user } = useSelector((state) => state.user);
  const [postSlackConnect] = usePostSlackConnectMutation();
  const handleSlackConnect = async () => {
    const response = await postSlackConnect({
      emails: [user?.email]
    });
    if (response?.data?.channelId) {
      toast.warning("You're already connected to Kartik on Slack");
    } else if (response?.data?.ok) {
      toast.success('You have been connected to Kartik on Slack');
    } else if (response?.error) {
      toast.error(response.error?.data?.message || 'Something went wrong!');
    }
  };

  return (
    <div className={styles.rightSection}>
      <div className={styles.title}>
        {!conn.isNonTrutoConnector ? (
          <>
            Connect with <Image className={styles.logo} src={trutoIcon} />
          </>
        ) : (
          <>
            Connection by <Image className={styles.albusLogo} src={ALbusLogo} />
          </>
        )}
        {!conn.isNonTrutoConnector && (
          <HoverCard width={310} shadow='md'>
            <HoverCard.Target>
              <Image className={styles.logo} src={Icon} />
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <List size='sm' pr='15'>
                <List.Item mb={20}>
                  Truto connects your Albus account with your account on workplace apps and does not
                  store your data.
                </List.Item>
                <List.Item>
                  Truto is SOC 2 Type II certified, ISO 27001 certified, ISO 27701 certified and
                  GDPR compliant.
                  <a
                    href='https://support.springworks.in/portal/en/kb/albus/albus-for-slack/data-privacy-and-security'
                    target='_blank'
                    className={styles.learnMore}
                    rel='noreferrer'
                  >
                    Learn more.
                  </a>
                </List.Item>
              </List>
              <div className={styles.certifications}>
                <Image className={styles.companyLogo} src={AICPASOC} />
                <Image className={styles.companyLogo} src={ISO27001} />
                <Image className={styles.companyLogo} src={ISO27701} />
                <Image className={styles.companyLogo} src={GDPR} />
              </div>
            </HoverCard.Dropdown>
          </HoverCard>
        )}
      </div>

      <div className={styles.text}>
        Category: <span>{conn.category}</span>
      </div>
      <div className={styles.text}>Get more help:</div>

      <div className={styles.group}>
        <span className={styles.heading}>
          <KBIcon className={styles.icon} />
          <div className={styles.question}>
            Have questions? <br />
            <span className={styles.link}>Visit our&nbsp;</span>
            <Anchor
              href='https://support.springworks.in/portal/en/kb/albus'
              target='_blank'
              className={styles.contactCta}
            >
              knowledge base
            </Anchor>
            .
          </div>
        </span>
      </div>

      <div className={styles.group}>
        <span className={styles.heading}>
          <Email className={styles.icon} />
          <div className={styles.question}>
            Drop an email at <br />
            <Anchor
              href='mailto:albus@springworks.in'
              target='_blank'
              className={styles.contactCta}
            >
              albus@springworks.in
            </Anchor>
          </div>
        </span>
      </div>

      <div className={styles.wrap}>
        <Avatar
          src='https://albus-backend-assets.s3.amazonaws.com/Kartik.png'
          radius='xl'
          size={34}
          mr={8}
        />
        <span className={styles.text1}>
          Get a direct line with
          <strong>
            &nbsp;CEO <br />
            Kartik Mandaville.
          </strong>
          &nbsp;DM on&nbsp; <br />
          <span className={styles.button} onClick={handleSlackConnect}>
            Slack
          </span>
          &nbsp;or&nbsp;
          <span
            className={styles.button}
            onClick={() =>
              window.open('https://calendly.com/albus-sw/1v1-with-albus-team', '_blank')
            }
          >
            schedule a call
          </span>
          .
        </span>
      </div>
    </div>
  );
};

RightSection.defaultProps = {
  conn: { isNonTrutoConnector: true }
};

RightSection.propTypes = {
  conn: PropTypes.shape({
    category: PropTypes.string.isRequired,
    isNonTrutoConnector: PropTypes.bool
  })
};

export default RightSection;
