import { createSlice } from '@reduxjs/toolkit';
import { CONNECTOR_LIST } from 'pages/ControlPanel/constants';

const initialState = {
  connections: [...CONNECTOR_LIST]
};

export const connectionSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    updateConnectStatus: (state, action) => {
      const formatConnections = state.connections.map((connection) => {
        const filteredConnection = action.payload.connections.find(
          (p) => p.provider === connection.name
        );
        return {
          ...connection,
          isConnected: !!filteredConnection,
          token: filteredConnection?.token || filteredConnection?.trutoIntegratedAccountId
        };
      });

      if (action.payload.platform === 'teams') {
        //hiding slack connector for teams platform
        return { connections: formatConnections.filter((conn) => conn.name !== 'slack') };
      }

      return { connections: formatConnections };
    },
    updateWikiData: (state, action) => {
      const formatConnections = state.connections.map((connection) => {
        return {
          ...connection,
          description:
            action.payload.find((p) => p.provider === connection.name)?.description ?? {},
          docCount: action.payload.find((p) => p.provider === connection.name)?.sources ?? 0
        };
      });
      return { connections: formatConnections };
    }
  }
});

export const { updateConnectStatus, updateWikiData } = connectionSlice.actions;

export default connectionSlice.reducer;
