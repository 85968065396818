import React from 'react';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Combobox, Pill, PillsInput, useCombobox, ScrollArea, Tooltip } from '@mantine/core';
import styles from './styles.module.scss';

const CustomOptionMultiSelect = forwardRef((props, ref) => {
  const {
    label,
    optionsData,
    placeholder,
    optionValue,
    onChange,
    nothingFoundMsg,
    CustomOption,
    CustomPill,
    withinPortal,
    optionsLoading,
    pillsInputStyles,
    pillsInputLabelStyles,
    resetOptionsData,
    setResetOptionsData,
    ...others
  } = props;

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex()
  });
  const [localValue, setLocalValue] = useState(optionValue || []);
  const [search, setSearch] = useState('');
  const [visibleOptions, setVisibleOptions] = useState(optionsData || []);

  useEffect(() => {
    setVisibleOptions(optionsData || []);
  }, [optionsLoading]);

  useEffect(() => {
    setLocalValue(optionValue || []);
  }, [optionValue]);

  useEffect(() => {
    if (resetOptionsData) {
      // Reset visible options on re-render of optionsData
      setVisibleOptions(optionsData || []);
      setResetOptionsData(false);
    }
  }, [resetOptionsData]);

  const options = visibleOptions
    .filter((item) => {
      const itemLabel = item.label || '';
      const trimmedSearch = (search || '').trim().toLowerCase();
      return itemLabel.toLowerCase().includes(trimmedSearch);
    })
    .map((item) => (
      <CustomOption
        key={item.value}
        label={item.label || ''}
        value={item}
        url={item.value}
        {...(item.type && { type: item.type })}
        {...(item.isAdded !== undefined && { isAdded: item.isAdded })}
        {...(item.status !== undefined && { status: item.status })}
        {...(item.sourcesCount !== undefined && { sourcesCount: item.sourcesCount })}
      />
    ));

  const values = localValue.map((item) => {
    const isProcessing = item?.status === 'processing';

    return (
      <React.Fragment key={item}>
        {CustomPill ? (
          <CustomPill
            label={item.label}
            type={item.type}
            onRemove={() => handleValueRemove(item)}
          />
        ) : (
          <Tooltip
            w='fit-content'
            disabled={!isProcessing}
            label='You can remove this sitemap after it is processed'
          >
            <Pill
              className={classNames({ [styles.removeIcon]: isProcessing })}
              withRemoveButton={!isProcessing}
              onRemove={() => handleRemove(item)}
            >
              {item.label}
            </Pill>
          </Tooltip>
        )}
      </React.Fragment>
    );
  });

  function handleValueSelect(selectedOption) {
    if (selectedOption) {
      const updatedValue = [...localValue, selectedOption];
      setLocalValue(updatedValue);
      setSearch('');

      setVisibleOptions((options) => {
        return options.filter((option) => {
          return option.value !== selectedOption.value;
        });
      });

      if (onChange) {
        onChange(updatedValue);
      }
    }
  }

  function handleValueRemove(removedOption) {
    if (removedOption) {
      const updatedValue = localValue.filter((option) => option !== removedOption);

      // Determine the index to insert the removedOption based on isAdded
      const insertIndex = removedOption.isAdded ? visibleOptions.length : 0;

      setLocalValue(updatedValue);
      setVisibleOptions((options) => [
        ...options.slice(0, insertIndex),
        removedOption,
        ...options.slice(insertIndex)
      ]);

      if (onChange) {
        onChange(updatedValue);
      }
    }
  }

  function handleRemove(item) {
    if (item.status === 'processing') return null;
    handleValueRemove(item);
  }

  function handleSelectAll() {
    const updatedValue = [
      ...localValue,
      ...visibleOptions.filter((item) => item.status !== 'processing' && item.status !== 'active')
    ];
    setLocalValue(updatedValue);
    setVisibleOptions([]);
    if (onChange) {
      onChange(updatedValue);
    }
  }

  function handleDeselectAll() {
    const updatedValue = localValue.filter(
      (item) => item.status === 'active' || item.status === 'processing'
    );
    setLocalValue(updatedValue);
    setVisibleOptions(optionsData || []);
    if (onChange) {
      onChange(updatedValue);
    }
  }

  useImperativeHandle(ref, () => ({
    handleSelectAll,
    handleDeselectAll
  }));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      withinPortal={withinPortal}
      classNames={{ option: styles.comboboxOption }}
      {...others}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          size='sm'
          radius='md'
          pointer
          label={label}
          disabled={optionsLoading}
          classNames={{ input: pillsInputStyles, label: pillsInputLabelStyles }}
          onClick={() => combobox.toggleDropdown()}
        >
          <Pill.Group>
            {values}
            <Combobox.EventsTarget>
              <PillsInput.Field
                value={search}
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                placeholder={placeholder}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault();
                    handleValueRemove(localValue[localValue.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown hidden={options.length === 0}>
        <Combobox.Options>
          <ScrollArea.Autosize type='scroll' mah={160}>
            {options.length > 0 ? options : <Combobox.Empty>{nothingFoundMsg}</Combobox.Empty>}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
});

CustomOptionMultiSelect.displayName = 'CustomOptionMultiSelect';

CustomOptionMultiSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  optionsData: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  optionValue: PropTypes.array,
  onChange: PropTypes.func,
  nothingFoundMsg: PropTypes.string,
  CustomOption: PropTypes.elementType.isRequired,
  CustomPill: PropTypes.elementType,
  withinPortal: PropTypes.bool,
  optionsLoading: PropTypes.bool,
  pillsInputStyles: PropTypes.string,
  pillsInputLabelStyles: PropTypes.string,
  resetOptionsData: PropTypes.bool,
  setResetOptionsData: PropTypes.func
};

CustomOptionMultiSelect.defaultProps = {
  CustomPill: null,
  label: '',
  placeholder: '',
  nothingFoundMsg: '',
  withinPortal: true,
  optionsLoading: false,
  pillsInputStyles: '',
  pillsInputLabelStyles: '',
  resetOptionsData: false,
  setResetOptionsData: () => {}
};

export default CustomOptionMultiSelect;
