import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip, Button } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { maybePluralize } from 'utils/utilities';
import styles from '../layout.module.scss';

const RemainingQuestionsBanner = ({ user, ErrorIcon }) => {
  const { planInfo, loading } = useSelector((state) => state.plan);
  const navigate = useNavigate();

  if (!loading && !planInfo.hasSubscription) {
    return (
      <Tooltip
        disabled={false}
        w={249}
        label='Your company is on the free trial. It includes 50 questions and 30 days.'
      >
        <Button
          onClick={user.isAdmin && (() => navigate('/billing'))}
          disabled={!user.isAdmin}
          className={classNames(styles.questionButton, { [styles.disabledBtn]: !user.isAdmin })}
        >
          <span className={planInfo?.trialExpired && styles.exhaustedQuestions}>
            {planInfo?.trialExpired && ErrorIcon}&nbsp;
            {Math.max(planInfo.totalQuestionsRemaining ?? 0, 0)}&nbsp;
            {maybePluralize(planInfo.totalQuestionsRemaining, 'question').slice(0, -1)},&nbsp;
            {Math.max(planInfo.trialDaysLeft ?? 0, 0)}&nbsp;
            {maybePluralize(planInfo.trialDaysLeft, 'day')} left
          </span>
        </Button>
      </Tooltip>
    );
  }
};

RemainingQuestionsBanner.propTypes = {
  user: PropTypes.object.isRequired,
  ErrorIcon: PropTypes.node.isRequired
};

export default RemainingQuestionsBanner;
