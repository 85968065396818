import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { NAVBAR_ROUTES } from 'utils/constants';
import View from './View';

const ChangeEmail = ({ handleCreateCompany, showCreateCompany }) => {
  const navigate = useNavigate();

  return (
    <View
      handleTryDifferentEmail={() => navigate(NAVBAR_ROUTES.AUTH.SIGNIN)}
      handleCreateCompany={handleCreateCompany}
      showCreateCompany={showCreateCompany}
    />
  );
};

ChangeEmail.propTypes = {
  handleCreateCompany: PropTypes.func.isRequired,
  showCreateCompany: PropTypes.bool.isRequired
};

export default ChangeEmail;
