import PropTypes from 'prop-types';
import { Flex, Text } from '@mantine/core';
import Button from 'components/Button';
import { maybePluralize } from 'utils/utilities';
import { SOURCES_STATUS } from '../../../constants';
import styles from './styles.module.scss';

const Footer = (props) => {
  const { count, tab, handleRetrain, handleExclude, handleInclude } = props;
  return (
    <Flex className={styles.wrapper}>
      <div>
        {tab !== SOURCES_STATUS.EXCLUDED && (
          <Flex direction='row' gap={14} rowGap={5} wrap='wrap'>
            <Button onClick={handleRetrain}>Retrain</Button>
            <Button onClick={handleExclude} variance='danger' outlined>
              Exclude
            </Button>
          </Flex>
        )}
        {tab === SOURCES_STATUS.EXCLUDED && <Button onClick={handleInclude}>Include</Button>}
      </div>
      <Text>
        {count} web {maybePluralize(count, 'page')} selected
      </Text>
    </Flex>
  );
};

Footer.propTypes = {
  count: PropTypes.number.isRequired,
  tab: PropTypes.string.isRequired,
  handleExclude: PropTypes.func.isRequired,
  handleInclude: PropTypes.func.isRequired,
  handleRetrain: PropTypes.func.isRequired
};
export default Footer;
