import PropTypes from 'prop-types';
import View from './View';

const AnswerTab = ({ form }) => {
  const { value, onChange } = { ...form.getInputProps('persona') };
  return <View value={value} onChange={onChange} />;
};

AnswerTab.propTypes = {
  form: PropTypes.object.isRequired
};

export default AnswerTab;
