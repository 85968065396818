import PropTypes from 'prop-types';
import { Modal, Text, Flex, Button, UnstyledButton } from '@mantine/core';
import styles from './styles.module.scss';

const ConfirmationModal = (props) => {
  const {
    isLoading,
    opened,
    setOpened,
    message,
    title,
    confirmBtnLabel,
    cancelBtnLabel,
    handleConfirmation,
    actionType
  } = props;

  return (
    <>
      <Modal
        centered
        opened={opened}
        size={550}
        radius={4}
        className='mobile-modal'
        onClose={() => setOpened(false)}
        title={<Text className={styles.title}>{title}</Text>}
      >
        <Text className={styles.content}>
          {message ? message : 'Are you sure you want to assign Licenses to 14 teammates?'}
        </Text>

        <Flex className={styles.buttonContainer}>
          <UnstyledButton className={styles.button} mr={20} onClick={() => setOpened(false)}>
            {cancelBtnLabel}
          </UnstyledButton>
          <Button
            className={styles.primaryBtn}
            onClick={() => {
              handleConfirmation(actionType);
              setOpened(false);
            }}
            loading={isLoading}
          >
            {confirmBtnLabel}
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

ConfirmationModal.defaultProps = {
  message: '',
  confirmBtnLabel: 'Confirm',
  cancelBtnLabel: 'Cancel',
  handleConfirmation: () => null,
  actionType: '',
  isLoading: false
};

ConfirmationModal.propTypes = {
  isLoading: PropTypes.bool,
  opened: PropTypes.bool.isRequired,
  setOpened: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  confirmBtnLabel: PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  handleConfirmation: PropTypes.func,
  actionType: PropTypes.string
};

export default ConfirmationModal;
