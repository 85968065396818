import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TextInput, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import authenticate from '@truto/truto-link-sdk';
import ConnectionsCatergoryList from 'pages/ControlPanel/Components/ConnectionsCategoryList';
import NoSearchResult from 'pages/ControlPanel/Components/EmptyState/NoSearchResult';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import ConnectorDetailsModal from 'pages/ControlPanel/Components/ConnectorDetailsModal';
import { CATEGROY_TAB } from 'pages/ControlPanel/constants';
import ConnectionsList from 'pages/ControlPanel/Components/ConnectionsList';
import { constructOnlyLiveConnectionsByCategories } from 'pages/ControlPanel/utils';
import { useDisconnectProviderMutation } from 'redux/services/integrations';
import { useLazyGetTrutoLinkTokenQuery } from 'redux/services/truto';
import userApi from 'redux/services/user';
import { useEnableConnectionMutation } from 'redux/services/connectors';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { STEPS } from 'pages/OnBoarding/constants';
import { NAVBAR_ROUTES } from 'utils/constants';
import styles from './styles.module.scss';

const Connectors = ({ handleStepChange }) => {
  const searchParams = new URLSearchParams(location.search);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [selectedConnector, setSelectedConnector] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disconnectProvider] = useDisconnectProviderMutation();
  const isMobileScreen = useMediaQuery('(max-width:480px)');
  const [activeCategory, setActiveCategory] = useState('All');
  const isInstalled = searchParams.get('ok');
  const provider = searchParams.get('provider');
  const connectorFromParams = searchParams.get('connector') ?? provider;
  const { connections } = useSelector((state) => state.connections);
  const [getTrutoLinkTokenTrigger] = useLazyGetTrutoLinkTokenQuery();
  const [enableConnection] = useEnableConnectionMutation();
  const { updateConnectorCategories, liveConnections } =
    constructOnlyLiveConnectionsByCategories(connections);
  let connectorsByCategories = [...updateConnectorCategories];
  const [showDisconnectModal, setDisconnectModal] = useState({
    state: false,
    connector: null,
    disabled: false
  });
  const [showDisableModal, setDisableModal] = useState({
    state: false,
    connector: null,
    disabled: false
  });

  useEffect(() => {
    if (connectorFromParams === 'slack') {
      const conn = connections?.find((conn) => conn.name === connectorFromParams);
      setSelectedConnection(conn);
    }

    if (isInstalled === 'true') {
      toast.success('Installed on Slack successfully.');
    } else if (isInstalled === 'false') {
      toast.error('Failed to install on Slack.');
    }
  }, [isInstalled]);

  const handleConnect = async (conn) => {
    setSelectedConnector(conn.name);
    try {
      const options = {
        integration: conn.name,
        noBack: true,
        authFormat: 'oauth2',
        iframe: true,
        skipRapidForm: true
      };
      const params = { provider: conn.name, isConnect: true };
      const { data } = await getTrutoLinkTokenTrigger(params);
      setSelectedConnection(null);
      const response = await authenticate(data.linkToken, options);

      if (response?.result === 'success') {
        setSelectedConnector('');
        dispatch(userApi.util.invalidateTags(['User']));
        localStorage.setItem('syncStarted', conn.name);
        setSelectedConnection(conn);
        toast.success(`Started connecting to ${conn.title}`);
        handleStepChange(STEPS.ADD_A_SOURCE, conn.name);
      }
    } catch (error) {
      setSelectedConnector('');
      if (
        error.message === 'Not Found: Error while fetching resource - LIST ticketing/workspaces'
      ) {
        return toast.error('Unable to find the workspace by this name. Please try again.');
      }
      return toast.error(
        'Authentication error: Unable to proceed. Please check your credentials or try again.'
      );
    }
  };

  const handleDisconnect = async () => {
    setDisconnectModal({ ...showDisconnectModal, disabled: true });
    try {
      const response = await disconnectProvider(showDisconnectModal.connector.name);
      if (response?.data?.ok) {
        toast.success(`${showDisconnectModal.connector.title} disconnected successfully`);
        setDisconnectModal({
          state: false,
          connector: null,
          disabled: false
        });
      }
    } catch (error) {
      toast.error('Unable to disconnect, Try again!');
    }
  };

  const handleDisable = async () => {
    setDisableModal({ ...showDisableModal, disabled: true });
    try {
      const response = await disconnectProvider(showDisableModal.connector.name);
      if (response?.data?.ok) {
        toast.success(`${showDisableModal.connector.title} disabled successfully`);
        setDisableModal({
          state: false,
          connector: null,
          disabled: false
        });
      }
    } catch (error) {
      toast.error('Unable to disable, Try again!');
    }
  };

  const handleEnable = async (conn) => {
    setSelectedConnector(conn.name);
    try {
      const response = await enableConnection(conn.name);
      if (response?.data?.ok) {
        setSelectedConnector('');
        dispatch(userApi.util.invalidateTags(['User']));
        toast.success(`${conn.title} enabled successfully`);
        setSelectedConnection(conn);
        handleStepChange(STEPS.ADD_A_SOURCE, conn.name);
      }
    } catch (error) {
      setSelectedConnector('');
      toast.error(error);
    }
  };

  if (isMobileScreen) {
    connectorsByCategories = [
      ...updateConnectorCategories.filter((cat) => cat.name === activeCategory)
    ];
  }

  if (searchTerm) {
    connectorsByCategories = [
      {
        name: 'Search result',
        connectors: liveConnections.filter((conn) =>
          conn.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      }
    ];
  }

  const handleCloseDetailsModal = () => {
    setSelectedConnection(null);
    if (connectorFromParams) {
      navigate(NAVBAR_ROUTES.SETTINGS_TABS.CONNECTIONS);
    }
  };

  return (
    <div className={styles.wrapper}>
      {showDisconnectModal.state && (
        <DeleteWarningModal
          open={true}
          header={`Disconnect ${showDisconnectModal?.connector?.title}`}
          title={
            <>
              Are you sure you want to disconnect from&nbsp;
              <strong>{showDisconnectModal?.connector?.title}</strong> ?
            </>
          }
          handleDelete={handleDisconnect}
          handleCancel={() => {
            setDisconnectModal({
              state: false,
              connector: null,
              disabled: false
            });
          }}
          onClose={() => {
            setDisconnectModal({
              state: false,
              connector: null,
              disabled: false
            });
          }}
          removeText='Disconnect'
          disabled={showDisconnectModal.disabled}
        >
          <Text className={styles.text}>
            <span className={styles.fadedText}>Note:&nbsp;</span>When you disconnect, sources you
            added through this connection will be removed.
          </Text>
        </DeleteWarningModal>
      )}

      {selectedConnection && (
        <ConnectorDetailsModal
          conn={connections?.find((conn) => conn.name === selectedConnection.name) ?? {}}
          onClose={handleCloseDetailsModal}
          handleConnect={handleConnect}
          handleDisable={(conn) =>
            setDisableModal({
              state: true,
              connector: conn,
              disabled: false
            })
          }
          handleEnable={handleEnable}
          handleDisconnect={(conn) =>
            setDisconnectModal({
              state: true,
              connector: conn,
              disabled: false
            })
          }
          handleRequest={() => null}
          selectedConnector={selectedConnector}
          handleAddSource={(conn) => handleStepChange(STEPS.ADD_A_SOURCE, conn.name)}
        />
      )}

      {showDisableModal.state && (
        <DeleteWarningModal
          open={true}
          header={`Disable ${showDisableModal?.connector?.title}`}
          title={
            <>
              Are you sure you want to disable <strong>{showDisableModal?.connector?.title}</strong>
              &nbsp;connection?
            </>
          }
          handleDelete={handleDisable}
          handleCancel={() => {
            setDisableModal({
              state: false,
              connector: null,
              disabled: false
            });
          }}
          onClose={() => {
            setDisableModal({
              state: false,
              connector: null,
              disabled: false
            });
          }}
          removeText='Disable'
          disabled={showDisableModal.disabled}
        >
          <Text className={styles.text}>
            <span className={styles.fadedText}>Note:&nbsp;</span>
            When you disable, sources you added through this connection will be removed.
          </Text>
        </DeleteWarningModal>
      )}

      <header>
        <Text size='24px' fw={600} mb={20}>
          To get started, connect an app
        </Text>
        <Text c='#565656' size='14px' fw={400}>
          Start with one. Connect more later.
        </Text>
      </header>

      <TextInput
        className={styles.search}
        classNames={{ section: styles.searchIcon }}
        placeholder='Search from 50+ connections'
        leftSection={<SearchIcon />}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <div className={styles.body}>
        <ConnectionsCatergoryList
          activeCategory={activeCategory}
          handleCategoryChange={(cat) => {
            setActiveCategory(cat);
          }}
          connectedCount={connections.filter((conn) => conn.isConnected || conn.autoConnect).length}
          categories={connectorsByCategories}
          hideMyConnections
          connectorCatrgoryForMobileSelect={[
            ...updateConnectorCategories.map((cat) => ({ label: cat.name, value: cat.name }))
          ]}
          searchTerm={searchTerm}
        />
        {connectorsByCategories[0]?.connectors.length ? (
          <ConnectionsList
            connectorsByCategories={connectorsByCategories}
            setActiveCategory={setActiveCategory}
            setSelectedConnection={setSelectedConnection}
            hideChevron={
              !!searchTerm || !!activeCategory === CATEGROY_TAB.MY_CONNECTIONS || !!isMobileScreen
            }
            searchTerm={searchTerm}
          />
        ) : (
          <>{searchTerm && <NoSearchResult />}</>
        )}
      </div>
    </div>
  );
};

Connectors.propTypes = {
  handleStepChange: PropTypes.func.isRequired
};

export default Connectors;
