import { useEffect, useState } from 'react';
import { useLazyDashboardSignupQuery, useResendVerificationMutation } from 'redux/services/auth';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import AuthWrapper from '../Components/AuthWrapper';
import EmailVerification from '../Components/EmailVerification';
import JoinViaLink from '../Components/JoinViaLink';
import ViaEmail from '../Components/JoinViaEmail';
import localStorage from 'utils/localStorage';
import { INITIAL_VALUES, STEPS } from '../constants';

const Invite = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const [step, setStep] = useState(null);
  const [formData, setFormData] = useState(INITIAL_VALUES);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [decodedToken, setDecodedToken] = useState(null);
  const [signupTrigger] = useLazyDashboardSignupQuery();
  const [resendVerification] = useResendVerificationMutation();

  useEffect(() => {
    const handleSignUp = async () => {
      try {
        const token = searchParams.get('token');
        if (token) {
          try {
            const decoded = jwtDecode(token);
            setDecodedToken(decoded);
            if (decoded.inviteType === 'user') {
              setFormData({ ...formData, email: decoded.email });
              setStep(STEPS.JOIN_VIA_EMAIL);
            } else if (decoded.inviteType === 'group') {
              setStep(STEPS.JOIN_VIA_LINK);
            }
          } catch (error) {
            toast.error('Error handling invite token');
          }
        } else {
          toast.error('Invite token not found in query parameters');
        }
      } catch (error) {
        toast.error('Error decoding invite token');
      }
    };

    handleSignUp();
  }, []);

  async function handleJoinViaEmailCTA(values) {
    setFormData(values);
    setIsSubmitting(true);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const apiUrl = `${import.meta.env.VITE_API_URL}/setup/user`;
    const { token } = localStorage.decryptToken(decodedToken.setupToken);
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: `${values.firstName} ${values.lastName}`,
          password: values.newPassword,
          timezone: userTimezone,
          code: decodedToken.code
        })
      });

      if (!response.ok) {
        throw new Error(response.message);
      }

      const responseData = await response.json();

      if (responseData?.ok) {
        window.open(responseData.data.redirectTo, '_self');
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      toast.error(error.message);
    }

    setIsSubmitting(false);
  }

  return (
    <AuthWrapper>
      {step === STEPS.EMAIL_VERIFICATION ? (
        <EmailVerification
          email={formData.email}
          handleBack={() => setStep(STEPS.JOIN_VIA_LINK)}
          handleResendEmail={() => resendVerification({ email: formData.email })}
        />
      ) : null}

      {step === STEPS.JOIN_VIA_EMAIL ? (
        <ViaEmail
          formData={formData}
          isSubmitting={isSubmitting}
          invitedBy={decodedToken.invitedBy}
          handleSubmitCTA={(values) => handleJoinViaEmailCTA(values)}
        />
      ) : null}

      {step === STEPS.JOIN_VIA_LINK ? (
        <JoinViaLink
          formData={formData}
          isSubmitting={isSubmitting}
          setFormData={setFormData}
          setIsSubmitting={setIsSubmitting}
          signupTrigger={signupTrigger}
          setStep={setStep}
          allowedDomains={decodedToken.allowedDomains}
          invitedBy={decodedToken.invitedBy}
          code={decodedToken.code}
          id={localStorage.getToken()}
        />
      ) : null}
    </AuthWrapper>
  );
};

export default Invite;
