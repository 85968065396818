import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { current } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Menu, TextInput, ActionIcon, Flex } from '@mantine/core';
import ActionMenu from 'components/ActionMenu';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import api from 'redux/services/api';
import { useUpdateChatMutation } from 'redux/services/questions';
import { useDeleteChatMutation } from 'redux/services/ask-albus';
import { deleteValidationChat } from 'redux/features/userSlice';
import { emojiFy } from 'utils/helpers';
import { sanitizePrompt } from 'utils/formatters';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from './styles.module.scss';

const ChatTitle = (props) => {
  const { chat, selectedChat, handleChatSelect, dateRange } = props;
  const dispatch = useDispatch();
  const { validationChats } = useSelector((state) => state.user);
  const [updateChat] = useUpdateChatMutation();
  const [deleteChat, { isFetching: isDeletingChat }] = useDeleteChatMutation();
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showTextInput, setShowTextInput] = useState(false);
  const [value, setValue] = useState(sanitizePrompt(chat.title));
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [openOverflowMenu, setOpenOverflowMenu] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    // Set the cursor position to the rightmost side for rename chat textInput
    if (showTextInput && inputRef.current) {
      inputRef.current.setSelectionRange(value.length, value.length);
      inputRef.current.focus();
    }
  }, [showTextInput, value]);

  function handleChatNameUpdateForAll() {
    if (validationChats[chat.promptId]) {
      //updating chat name for validation chats
      if (validationChats[chat.promptId].continuePromptId) {
        //if continue prompt chat exists update its chat title as well
        const payload = {
          questionId: validationChats[chat.promptId].continuePromptId,
          body: { title: value },
          operation: 'chatNameUpdate',
          dateRange
        };

        updateChat(payload);
      }
      dispatch(
        api.util.updateQueryData('getChatsHistory', undefined, (draft) => {
          const chatsHistory = current(draft);
          const updatedChatHistory = [...chatsHistory[dateRange]];

          const chatIndex = updatedChatHistory.findIndex((item) => item.promptId === chat.promptId);

          if (chatIndex !== -1) {
            const updatedChat = { ...updatedChatHistory[chatIndex] };
            updatedChat.title = value;
            updatedChatHistory[chatIndex] = updatedChat;
            draft[dateRange] = updatedChatHistory;
          }

          return draft;
        })
      );
    } else {
      handleChatNameUpdate();
    }
  }

  function handleChatNameUpdate() {
    const payload = {
      questionId: chat.promptId,
      body: { title: value },
      operation: 'chatNameUpdate',
      dateRange
    };

    updateChat(payload);
  }

  async function handleDeleteChat() {
    if (validationChats[chat.promptId]) {
      //deleting validation chats
      const showToast = !validationChats[chat.promptId].continuePromptId;
      if (validationChats[chat.promptId].continuePromptId) {
        //delete the continue prompt chat within the validation chat
        const payload = {
          questionId: validationChats[chat.promptId].continuePromptId,
          dateRange
        };
        await deleteChat(payload).unwrap();
      }
      dispatch(deleteValidationChat(chat.promptId));
      dispatch(
        api.util.updateQueryData('getChatsHistory', undefined, (draft) => {
          const chatsHistory = current(draft);
          const updatedChatHistory = [...chatsHistory[dateRange]];
          const updatedChatHistoryWithoutDeleted = updatedChatHistory.filter(
            (item) => item.promptId !== chat.promptId
          );

          draft[dateRange] = updatedChatHistoryWithoutDeleted;

          return draft;
        })
      );

      if (showToast) {
        toast.success('Chat deleted successfully');
      }
      if (selectedChat?.promptId === chat.promptId) {
        handleChatSelect(null);
      }
    } else {
      handleDeleteNonValidationChat();
    }
  }

  async function handleDeleteNonValidationChat() {
    //deleting non-validation type chats
    const payload = {
      questionId: chat.promptId,
      dateRange
    };

    try {
      const response = await deleteChat(payload).unwrap();
      if (response.ok && selectedChat?.promptId === chat.promptId) {
        handleChatSelect(null);
      }
    } catch (error) {
      return Promise.resolve();
    }
  }

  const actionMenuData = (data) => {
    const actions = [
      <Menu.Item key={`${data.title}-rename-chat`} onClick={() => setShowTextInput(true)}>
        Rename
      </Menu.Item>,
      <Menu.Item key={`${data.title}-delete-chat`} onClick={() => setShowDeleteModal(true)}>
        Delete
      </Menu.Item>
    ];

    return actions;
  };

  return (
    <div
      className={styles.chatTitleContainer}
      key={chat.promptId}
      onClick={() => {
        if (selectedChat !== chat) {
          handleChatSelect(chat);
        }
      }}
      onMouseEnter={() => setShowActionMenu(true)}
      onMouseLeave={() => {
        setShowActionMenu(false);
        setOpenOverflowMenu(false);
      }}
    >
      {selectedChat?.promptId === chat.promptId && <div className={styles.highlight} />}
      {showTextInput ? (
        <TextInput
          ref={inputRef}
          classNames={{
            input: styles.input,
            section: styles.section
          }}
          value={value}
          onChange={(event) => setValue(event.currentTarget.value)}
          rightSection={
            <Flex>
              <ActionIcon
                variant='transparent'
                onClick={() => {
                  setShowTextInput(false);
                  setValue(chat.title);
                }}
              >
                <SVGIcon name={ICONS_LIST.CROSS_RED} height={14} width={14} />
              </ActionIcon>
              <ActionIcon
                variant='transparent'
                onClick={() => {
                  handleChatNameUpdateForAll();
                  setShowTextInput(false);
                }}
              >
                <SVGIcon name={ICONS_LIST.CHECK_GREEN} height={14} width={14} />
              </ActionIcon>
            </Flex>
          }
        />
      ) : (
        <div className={styles.chatTitle}>{emojiFy(value)}</div>
      )}
      {!showTextInput && showActionMenu && (
        <div className={styles.actionMenuWrapper}>
          <ActionMenu
            offset={-2}
            data={{
              delete: 'Delete',
              rename: 'Rename'
            }}
            actionMenuData={actionMenuData}
            opened={openOverflowMenu}
            setOpened={setOpenOverflowMenu}
          />
        </div>
      )}
      {showDeleteModal && (
        <DeleteWarningModal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          handleCancel={() => setShowDeleteModal(false)}
          header='Delete chat'
          title={
            <>
              Are you sure you want to delete the chat <b>{chat.title.replace(/\?+$/, '')}</b>?
            </>
          }
          body='Note: once you delete a chat, you cannot recover it.'
          removeText='Delete'
          handleDelete={handleDeleteChat}
          disabled={isDeletingChat}
        />
      )}
    </div>
  );
};

ChatTitle.propTypes = {
  chat: PropTypes.shape({
    promptId: PropTypes.string,
    title: PropTypes.string.isRequired
  }).isRequired,
  selectedChat: PropTypes.shape({
    promptId: PropTypes.string,
    title: PropTypes.string
  }),
  handleChatSelect: PropTypes.func.isRequired,
  dateRange: PropTypes.string.isRequired
};

export default ChatTitle;
