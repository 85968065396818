import PropTypes from 'prop-types';
import { Anchor } from '@mantine/core';
import StepGuide from 'pages/ControlPanel/Automations/AutomationsRouter/Components/StepGuide';
import styles from './styles.module.scss';

const steps = [
  {
    step: 1,
    title: 'Configure search capabilities for your custom API',
    description: "Set Albus's search capabilities and persona.",
    showWikiIdCta: false
  },
  {
    step: 2,
    title: 'Select wikis',
    description: (
      <>
        Albus will answer questions using the wiki you select. If you want a new wiki for this API,
        first&nbsp;
        <Anchor href='/teams' className={styles.linkText}>
          create a team
        </Anchor>
        &nbsp;and add sources to its wiki.
      </>
    ),
    showWikiIdCta: false
  },
  {
    step: 3,
    title: 'Install on your tool or workflow',
    description: 'Set up your custom API with API key and cURL.',
    showWikiIdCta: false
  }
];

const EmptyState = ({ onNewCustomApi }) => {
  return (
    <div className={styles.wrapper}>
      <StepGuide
        title='Integrate Albus with internal tools and workflows.'
        steps={steps}
        showCustomApiCta={!!onNewCustomApi}
        createCustomApi={onNewCustomApi}
      />
    </div>
  );
};

EmptyState.defaultProps = {
  onNewCustomApi: null
};

EmptyState.propTypes = {
  onNewCustomApi: PropTypes.func
};

export default EmptyState;
