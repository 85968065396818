import { data } from './data';
import styles from './styles.module.scss';

const Information = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>Get more done with automations</div>
      <div className={styles.body}>
        {data.map((item) => (
          <div className={styles.bodyItem} key={item.title}>
            <div className={styles.bodyIcon}>
              <img src={item.icon} alt={item.title} />
            </div>
            <div className={styles.bodyContent}>
              <div className={styles.contentTitle}>{item.title}</div>
              <div className={styles.contentDescription}>{item.desc}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Information;
