import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const POPUP = {
  CHROME_EXTENSION: 'chrome_extension',
  WHATS_NEW: 'whats_new',
  HELP: 'help',
  INVITE_TEAMS: 'invite_teams',
  LOGOUT: 'logout',
  INSTALL_ALBUS: 'install_albus'
};

const PopupContext = createContext();

const PopupContextProvider = ({ children }) => {
  const [value, setValue] = useState('');

  const updateOpenedPopup = (popup) => {
    if (value !== popup) {
      setValue(popup);
    } else {
      setValue('');
    }
  };

  return (
    <PopupContext.Provider value={{ openedPopup: value, setOpenedPopup: updateOpenedPopup }}>
      {children}
    </PopupContext.Provider>
  );
};

PopupContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const usePopupContext = () => {
  return useContext(PopupContext);
};

export { PopupContextProvider, usePopupContext };
