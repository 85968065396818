import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { LoadingOverlay, Title } from '@mantine/core';
import { usePagination, useDebouncedState } from '@mantine/hooks';
import Layout from 'components/Layout';
import Banner from './components/Banner';
import AssignLicensesBanner from './components/AssignLicensesBanner';
import UnsavedChangesModal from 'components/UnsavedChangesModal/UnsavedChangesModal';
import ManageLicensesFilter from './components/ManageLicensesFilter';
import ManageLicensesTable from './components/ManageLicensesTable';
import AssignLicensesModal from './components/AssignLicensesModal';
import { useGetPlanQuery } from 'redux/services/plan';
import { useFetchLicenseUsersQuery } from 'redux/services/licenses';
import { eventTracking } from 'components/EventAnalyticsProvider/utils';
import localStorage from 'utils/localStorage';
import styles from './billing.module.scss';

const Billing = () => {
  const { user, loading } = useSelector((state) => state.user);

  // Get Plan Info
  useGetPlanQuery();
  const { planInfo, loading: isPlanLoading } = useSelector((state) => state.plan);
  useEffect(() => {
    if (!isPlanLoading && !loading && user._id) {
      eventTracking('User opened dashboard', user, 'Billing');
    }
  }, [user]);

  // Channel or Team Filter
  const [chanelFilters, setChanelFilters] = useState({ channel: 'all' });

  // Licenses Count
  const { totalRequests: licensesRequestCount, total } = useSelector((state) => state.license);

  // Modals control
  const [isAssignLicensesModalOpen, setIsAssignLicensesModalOpen] = useState(false);
  const [isWarnModalOpen, setIsWarnModalOpen] = useState(false);

  // Pagination
  const [page, onChange] = useState(1);
  const pagination = usePagination({
    total: Math.ceil(total / 10),
    page,
    onChange
  });

  // Search Term
  const [searchTerm, setSearchTerm] = useDebouncedState('', 500);

  // Status Filter
  const [licenseStatus, setLicenseStatus] = useState('requested');
  const [manualStatusFilter, setManualStatusFilter] = useState(false);

  // Users Fetch Query
  const { data, isFetching } = useFetchLicenseUsersQuery(
    {
      status: licenseStatus,
      page: page,
      ...(searchTerm ? { search: searchTerm } : {}),
      ...chanelFilters
    },
    { skip: isPlanLoading || !planInfo.hasSubscription }
  );

  // Reset Pagination On Status Filter Change
  useEffect(() => {
    onChange(1);
  }, [licenseStatus]);

  useEffect(() => {
    if (!isFetching && data && !manualStatusFilter) {
      if (licenseStatus === 'requested' && !data?.total) {
        setLicenseStatus('unassigned');
      } else if (licenseStatus === 'unassigned' && !data?.total) {
        setLicenseStatus('assigned');
      }
    }
  }, [isFetching]);

  // To Get Unused Licenses
  const unassignedLicenses = planInfo?.plan
    ? Math.max((planInfo.plan.licensesBought ?? 0) - planInfo.plan.licensesAssigned, 0)
    : 0;

  // To Subscribe
  const checkout = () => {
    const token = localStorage.getToken();
    const referral = window.Rewardful?.referral ?? '';
    window.open(
      `${import.meta.env.VITE_API_URL}/billing/checkout-session?referral=${referral}&key=${token}`,
      '_self'
    );
  };

  // To redirect customer billing portal
  const buyLicense = () => {
    const token = localStorage.getToken();
    window.open(`${import.meta.env.VITE_API_URL}/billing/customer-portal?key=${token}`, '_self');
  };

  return (
    <>
      <Layout pageTitle='Billing'>
        <LoadingOverlay />
        <Banner
          buyLicense={buyLicense}
          checkout={checkout}
          unassignedLicenses={unassignedLicenses}
        />

        {/* Billing Manage Licenses Data Section */}
        {planInfo.hasSubscription && (
          <div>
            <div className={classNames(styles.billingPage, styles.manageLicenseContainer)}>
              <Title className={styles.pageTitle} order={4}>
                Manage Licenses
              </Title>

              {licensesRequestCount > 0 && (
                <AssignLicensesBanner
                  unassignedLicenses={unassignedLicenses}
                  licensesBought={planInfo.plan.licensesBought}
                  buyLicense={buyLicense}
                  isPlanLoading={isPlanLoading}
                  setIsAssignLicensesModalOpen={setIsAssignLicensesModalOpen}
                  setLicenseStatus={setLicenseStatus}
                />
              )}

              <ManageLicensesFilter
                licenseStatus={licenseStatus}
                onSearch={setSearchTerm}
                setChanelFilters={setChanelFilters}
                channelFilters={chanelFilters}
                setLicenseStatus={setLicenseStatus}
                setManualStatusFilter={setManualStatusFilter}
              />
            </div>
            <ManageLicensesTable
              searchTerm={searchTerm}
              licenseStatus={licenseStatus}
              pagination={pagination}
            />
          </div>
        )}

        {isAssignLicensesModalOpen && planInfo.hasSubscription && (
          <AssignLicensesModal
            opened={isAssignLicensesModalOpen}
            setOpened={setIsAssignLicensesModalOpen}
          />
        )}

        <UnsavedChangesModal opened={isWarnModalOpen} setOpened={setIsWarnModalOpen} />
      </Layout>
    </>
  );
};

export default Billing;
