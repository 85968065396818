import { ActionIcon, Badge, Button, Flex, Group, Image, Text } from '@mantine/core';
import { bool, func, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { PERSONA_ICON } from '../../constants';

import HighlightIcon from 'assets/icons/personas/highlight.svg';
import styles from './styles.module.scss';

const PersonListItem = ({
  persona,
  selected,
  focused,
  handleTileClick,
  handleSelectBtnClick,
  handleEditPersonaClick,
  handleDeletePersonaClick
}) => {
  return (
    <Flex
      className={classNames(styles.wrapper, { [styles.focused]: focused })}
      onClick={(e) => {
        e.stopPropagation();
        handleTileClick(persona);
      }}
    >
      <Flex className={styles.highlightWrapper}>
        {focused && (
          <Image classNames={{ root: styles.highlight }} src={HighlightIcon} alt={persona.name} />
        )}
        <Flex className={classNames(styles.titleWraper, { [styles['ml-9']]: !focused })}>
          <Image
            className={styles.icon}
            src={PERSONA_ICON[persona.icon || 'DefaultPersonaIcon']}
            alt={persona.name}
          />
          <Text className={styles.title}>{persona.name}</Text>
          {selected && (
            <Badge className={styles.badge} variant='outline'>
              Selected
            </Badge>
          )}
        </Flex>
      </Flex>

      <Group>
        {persona.scope !== 'global' && (
          <Flex className={styles.actions}>
            <ActionIcon
              variant='transparent'
              className={styles.edit}
              onClick={(e) => {
                e.stopPropagation();
                handleEditPersonaClick(persona);
              }}
            >
              <SVGIcon name={ICONS_LIST.EDIT} height={16} width={16} />
            </ActionIcon>
            <ActionIcon
              variant='transparent'
              className={styles.delete}
              onClick={(e) => {
                e.stopPropagation();
                handleDeletePersonaClick(persona);
              }}
            >
              <SVGIcon name={ICONS_LIST.TRASH_ICON} height={16} width={16} />
            </ActionIcon>
          </Flex>
        )}

        <ActionIcon variant='transparent' className={styles.accordion}>
          <SVGIcon name={ICONS_LIST.RIGHT_ACCORDION} height={22} width={22} />
        </ActionIcon>

        {!selected && (
          <Button
            variant={'outline'}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectBtnClick(persona._id);
            }}
            className={styles.button}
          >
            Select
          </Button>
        )}
      </Group>
    </Flex>
  );
};

PersonListItem.defaultProps = {
  selected: false,
  focused: false
};

PersonListItem.propTypes = {
  persona: shape({
    name: string,
    value: string,
    icon: node
  }).isRequired,
  selected: bool,
  focused: bool,
  handleTileClick: func.isRequired,
  handleSelectBtnClick: func.isRequired,
  handleEditPersonaClick: func.isRequired,
  handleDeletePersonaClick: func.isRequired
};

export default PersonListItem;
