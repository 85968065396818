import { toast } from 'react-toastify';
import { savePlanInfo } from 'redux/features/planSlice';
import { PLAN } from 'utils/apiEndpoints';
import api from './api';

const planApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPlan: build.query({
      query: () => ({
        url: PLAN,
        method: 'GET'
      }),
      providesTags: ['Plan'],
      keepUnusedDataFor: 0,
      async onQueryStarted(Data, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response?.ok) {
            dispatch(savePlanInfo(response.data));
          } else {
            toast.error(response?.message);
          }
        } catch ({ error }) {
          if (![401, 403].includes(error?.status)) {
            toast.error(error.data?.message ?? error.message ?? 'Failed to fetch plan info');
          }
        }
      }
    })
  })
});

export const { useGetPlanQuery } = planApi;

export default planApi;
