import PropTypes from 'prop-types';
import { Modal, Title, Text, Flex, Button, UnstyledButton } from '@mantine/core';
import styles from './styles.module.scss';

const UnsavedChangesModal = ({ opened, setOpened }) => {
  return (
    <>
      <Modal
        centered
        opened={opened}
        size={550}
        onClose={() => setOpened(false)}
        title={<Title className={styles.title}>Unsaved selection</Title>}
      >
        <Text className={styles.content}>
          Please note that you have selected items but they have not been saved. If you leave this
          page, all your selections will be lost.
        </Text>

        <Flex align={'center'} justify={'flex-end'} style={{ width: '100%' }}>
          <UnstyledButton className={styles.button} mr={20} onClick={() => setOpened(false)}>
            Cancel
          </UnstyledButton>
          <Button color='green' className={styles.button} onClick={() => setOpened(false)}>
            Continue
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

UnsavedChangesModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  setOpened: PropTypes.func.isRequired
};

export default UnsavedChangesModal;
