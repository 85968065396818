import { toast } from 'react-toastify';
import { END_POINT, GOOGLE_AUTH } from 'utils/apiEndpoints';
import { handleApiError, showToastMessage } from 'utils/helpers';
import api from './api';
import { EMAIL_NOT_VERIFIED, USER_ALREADY_SETUP } from 'pages/Auth/constants';

const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    googleAuth: build.mutation({
      query: (userId) => ({
        url: GOOGLE_AUTH,
        method: 'POST',
        body: { userId }
      }),
      transformResponse: (response) => {
        if (response.ok) {
          window.open(response.redirectTo, '_self', `noopener,noreferrer`);
        }
        toast.error(response.message);
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),
    dashboardSignup: build.query({
      query: (params) => ({
        url: END_POINT.AUTH.DASHBOARD_SIGNUP,
        method: 'GET',
        params
      }),
      transformErrorResponse: (error) => {
        if (error?.data?.redirectTo) {
          showToastMessage(error?.data?.message, true);
        } else if (error?.data?.message !== EMAIL_NOT_VERIFIED) {
          handleApiError(error);
        }

        return error.data;
      }
    }),
    resendVerification: build.mutation({
      query: (body) => ({
        url: END_POINT.AUTH.RESEND_VERIFICATION,
        method: 'POST',
        body
      }),
      transformResponse: () => {
        toast.success('Verification mail sent successfully');
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),
    createPassword: build.mutation({
      query: (body) => ({
        url: END_POINT.AUTH.SETUP_USER,
        method: 'POST',
        body
      }),
      transformErrorResponse: (error) => {
        handleApiError(error);
        if ([401, 403].includes(error?.status)) {
          return error;
        }
      }
    }),
    dashboardLogin: build.mutation({
      query: (body) => ({
        url: END_POINT.AUTH.DASHBOARD_LOGIN,
        method: 'POST',
        body
      }),
      transformErrorResponse: (error) => handleApiError(error)
    }),
    forgotPassword: build.mutation({
      query: (body) => ({
        url: END_POINT.AUTH.USER_FORGOT_PASSWORD,
        method: 'POST',
        body
      }),
      transformResponse: (response) => toast.success(response.message),
      transformErrorResponse: (error) => handleApiError(error)
    }),
    companyList: build.query({
      query: (params) => ({
        url: END_POINT.AUTH.SETUP_COMPANY_LIST,
        method: 'GET',
        params
      }),
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),
    joinTeam: build.mutation({
      query: (body) => ({
        url: END_POINT.AUTH.SETUP_COMPANY_JOIN,
        method: 'POST',
        body
      }),
      transformErrorResponse: (error) => handleApiError(error)
    }),
    createCompany: build.mutation({
      query: (body) => ({
        url: END_POINT.AUTH.SETUP_COMPANY,
        method: 'POST',
        body
      }),
      transformErrorResponse: (error) => {
        if (error?.status === 403 && error?.data?.message === USER_ALREADY_SETUP) {
          return error;
        }

        handleApiError(error);
      }
    }),
    resetPassword: build.mutation({
      query: (body) => ({
        url: END_POINT.AUTH.USER_PASSWORD_RESET,
        method: 'POST',
        body
      })
    }),
    loginCompany: build.mutation({
      query: (body) => ({
        url: END_POINT.AUTH.SETUP_COMPANY_LOGIN,
        method: 'POST',
        body
      }),
      transformErrorResponse: (error) => handleApiError(error)
    })
  })
});

export const {
  useGoogleAuthMutation,
  useLazyDashboardSignupQuery,
  useResendVerificationMutation,
  useDashboardLoginMutation,
  useForgotPasswordMutation,
  useCreatePasswordMutation,
  useCompanyListQuery,
  useJoinTeamMutation,
  useCreateCompanyMutation,
  useResetPasswordMutation,
  useLoginCompanyMutation
} = authApi;

export default authApi;
