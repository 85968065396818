import PropTypes from 'prop-types';
import { Modal, Input, MultiSelect, Flex, Button } from '@mantine/core';
import styles from '../Styles.module.scss';

const Form = (props) => {
  const { open, formValues, users, handleChange, handleConfirm, loading, handleClose } = props;

  return (
    <Modal
      centered
      opened={open}
      closeOnClickOutside={false}
      size={633}
      classNames={{ inner: styles.modalBody }}
      title={<div className={styles.title}>Create team</div>}
      onClose={handleClose}
    >
      <form className={styles.formWrapper} onSubmit={handleConfirm}>
        <Input.Wrapper label='Team name' classNames={{ label: styles.label }} required>
          <Input
            value={formValues.name}
            classNames={{ input: styles.input }}
            onChange={(event) => handleChange('name', event.target.value)}
          />
        </Input.Wrapper>
        <MultiSelect
          searchable
          label='Select team admins'
          data={users}
          required
          value={formValues.admins}
          classNames={{
            label: styles.label,
            input: styles.input,
            defaultValue: styles.defaultValue,
            option: styles.item
          }}
          onChange={(value) => handleChange('admins', value)}
        />

        <Flex justify='flex-end' className={styles.buttonsWrapper}>
          <Button
            variant='outline'
            color='dark'
            className={styles.cancelButton}
            disabled={loading}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={styles.confirmButton}
            type='submit'
            disabled={!formValues.admins.length || !formValues.name.trim().length || loading}
          >
            Confirm
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  formValues: PropTypes.shape({
    name: PropTypes.string,
    admins: PropTypes.arrayOf(PropTypes.string.isRequired)
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default Form;
