import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import askALbusApi, {
  useGetChatsHistoryQuery,
  useGetPromptIdeasQuery
} from 'redux/services/ask-albus';
import { useGetSettingsQuery } from 'redux/services/settings';
import userApi from 'redux/services/user';
import useShowTutorial from 'custom_hooks/useShowTutorial';
import View from './View';
import PromptIdeas from './PromptIdeas';
import { startAskAlbusTutorial } from './Tutorial';
import { NAVBAR_ROUTES, PROMPT_IDEA_TYPES } from 'utils/constants';

const AskAlbus = () => {
  const { data: chatHistory, isFetching: isFetchingChats } = useGetChatsHistoryQuery();
  const { data: settings } = useGetSettingsQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const validationChats = useSelector((state) => state.user.validationChats);
  const [selectedChat, setSelectedChat] = useState(null);
  const [conversation, setConversation] = useState([]);
  const [isConversationLoading, setIsConversationLoading] = useState(false);
  const [showPromptIdeas, setShowPromptIdeas] = useState(
    location.pathname.includes('/prompt-ideas') ? PROMPT_IDEA_TYPES.COMPANY_WIKI : null
  );
  const [hideChats, setHideChats] = useState(false);
  const [showChatHistoryModal, setShowChatHistoryModal] = useState(false);
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const isOnBoarding = !!params.get('on-boarding');
  const showWelcomeModal = !!params.get('showWelcomeModal');
  const provider = params.get('provider');
  const promptId = params.get('promptId');
  const [isPolling, setIsPolling] = useState(false);
  const [wikiPromptIdeas, setWikiPromptIdeas] = useState([]);

  const {
    data: { questions: generatedQuestions = [], canGenerateQuestion = isOnBoarding } = {},
    isFetching
  } = useGetPromptIdeasQuery(isOnBoarding ? { origin: 'onboarding' } : undefined, {
    skip: showWelcomeModal || !!wikiPromptIdeas.length,
    pollingInterval: isPolling ? 2000 : undefined
  });

  useEffect(() => {
    if (generatedQuestions.length) {
      setWikiPromptIdeas(generatedQuestions);
    }
  }, [generatedQuestions]);

  useShowTutorial(startAskAlbusTutorial);

  useEffect(() => {
    if (promptId && chatHistory && !selectedChat?.promptId) {
      const chatList = Object.values(chatHistory).flat();
      const filteredChat = chatList.filter((chat) => chat.promptId === promptId);
      filteredChat?.length && handleSelectChat(filteredChat[0]);
    }
  }, [chatHistory]);

  function handleCloseWelcomeModal() {
    navigate(`${NAVBAR_ROUTES.ASK_ALBUS}?on-boarding=true&provider=${provider}`);
  }

  const handleSelectChat = async (chat, type = 'callConversationApi') => {
    if (!chat?.promptId && !chat?.parentTs) {
      setSelectedChat(null);
      setConversation([]);
      !isOnBoarding && window.history.replaceState(null, null, NAVBAR_ROUTES.ASK_ALBUS);
    } else if (validationChats[chat.promptId]) {
      setIsConversationLoading(true);
      let response;
      let conversations = validationChats[chat.promptId].conversations;
      if (validationChats[chat.promptId]?.continuePromptId) {
        response = await dispatch(
          askALbusApi.endpoints.getConversation.initiate(
            { questionId: validationChats[chat.promptId].continuePromptId },
            { forceRefetch: true }
          )
        );
        conversations = conversations.concat(response.data);
      }
      setConversation(conversations);
      setSelectedChat(chat);
      setIsConversationLoading(false);
      !isOnBoarding &&
        window.history.replaceState(
          null,
          null,
          `${NAVBAR_ROUTES.ASK_ALBUS}?promptId=${chat.promptId}`
        );
    } else {
      setIsConversationLoading(true);
      if (type !== 'doNotCallConversationApi') {
        const params = chat?.promptId ? { questionId: chat.promptId } : { parentTs: chat.parentTs };
        const response = await dispatch(
          askALbusApi.endpoints.getConversation.initiate(params, { forceRefetch: true })
        );
        setConversation(response.data);
      }
      setSelectedChat(chat);
      setIsConversationLoading(false);
      !isOnBoarding &&
        window.history.replaceState(
          null,
          null,
          `${NAVBAR_ROUTES.ASK_ALBUS}?promptId=${chat.promptId}`
        );
    }
  };

  function handleSuggestedQuestionClick(prompt) {
    navigate(
      `${NAVBAR_ROUTES.ASK_ALBUS}?${isOnBoarding ? `on-boarding=true&` : ''}textInput=${
        prompt.question
      }&questionId=${prompt._id}`
    );
  }

  function answerCompletedCallback(promptId) {
    if (!isOnBoarding) {
      return;
    }
    dispatch(userApi.util.invalidateTags(['User']));
    navigate(`${NAVBAR_ROUTES.ASK_ALBUS}?promptId=${promptId}`);
  }

  function handleOpenPromptIdeasPage() {
    const promptIdeasTab = settings?.training
      ? PROMPT_IDEA_TYPES.COMPANY_WIKI
      : PROMPT_IDEA_TYPES.OPEN_AI;
    setShowPromptIdeas(promptIdeasTab);
    window.history.replaceState(null, '', `/ask-albus/prompt-ideas`);
  }

  return (
    <>
      {showPromptIdeas ? (
        <PromptIdeas showPromptIdeas={showPromptIdeas} setShowPromptIdeas={setShowPromptIdeas} />
      ) : (
        <View
          chatHistory={chatHistory}
          isLoading={isFetchingChats}
          selectedChat={selectedChat}
          handleSelectChat={handleSelectChat}
          conversation={conversation}
          conversationLoading={isConversationLoading}
          setShowPromptIdeas={setShowPromptIdeas}
          setConversation={setConversation}
          hideChats={hideChats}
          setHideChats={setHideChats}
          showChatHistoryModal={showChatHistoryModal}
          setShowChatHistoryModal={setShowChatHistoryModal}
          answerCompletedCallback={answerCompletedCallback}
          handleSuggestedQuestionClick={handleSuggestedQuestionClick}
          handleOpenPromptIdeasPage={handleOpenPromptIdeasPage}
          isOnBoarding={isOnBoarding}
          showWelcomeModalFromParams={showWelcomeModal}
          wikiPromptIdeas={wikiPromptIdeas}
          canGenerateQuestion={canGenerateQuestion}
          isFetching={isFetching}
          setIsPolling={setIsPolling}
          isPolling={isPolling}
          provider={provider}
          handleCloseWelcomeModal={handleCloseWelcomeModal}
        />
      )}
    </>
  );
};

export default AskAlbus;
