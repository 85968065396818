import { toast } from 'react-toastify';
import { current } from '@reduxjs/toolkit';
import { CHAT, CHAT_HISTORY, CHAT_ESCALATION_SUMMARY } from 'utils/apiEndpoints';
import { handleApiError } from 'utils/helpers';
import api from './api';

const questionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchQuestions: build.query({
      providesTags: ['Questions'],
      query: (params) => ({
        url: CHAT_HISTORY,
        method: 'GET',
        params
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        } else {
          toast.error('Failed to fetch channels or teams');
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),

    fetchQuestionsDetails: build.query({
      providesTags: ['Questions_Details'],
      query: (payload) => ({
        url: CHAT,
        method: 'GET',
        params: { page: payload.page, origin: 'escalations', questionId: payload.id }
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        } else {
          toast.error('Failed to fetch questions details');
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),

    fetchEscalationSummary: build.query({
      providesTags: ['Escalation_Summary'],
      query: () => ({
        url: CHAT_ESCALATION_SUMMARY,
        method: 'GET'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        } else {
          toast.error('Failed to fetch escalation summary');
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),

    updateChat: build.mutation({
      query: (payload) => ({
        url: `${CHAT}/${payload.questionId}`,
        method: 'PATCH',
        body: payload.body
      }),
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;

          if (response.ok) {
            if (formData.operation === 'chatNameUpdate') {
              dispatch(
                api.util.updateQueryData('getChatsHistory', undefined, (draft) => {
                  const chatsHistory = current(draft);
                  const { dateRange } = formData;
                  const updatedChatHistory = [...chatsHistory[dateRange]];

                  const chatIndex = updatedChatHistory.findIndex(
                    (item) => item.promptId === formData.questionId
                  );

                  if (chatIndex !== -1) {
                    const updatedChat = { ...updatedChatHistory[chatIndex] };
                    updatedChat.title = formData.body.title;
                    updatedChatHistory[chatIndex] = updatedChat;
                    draft[dateRange] = updatedChatHistory;
                  }

                  return draft;
                })
              );
            }
          } else {
            toast.error('Failed to update chat');
          }

          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      }
    }),

    resolveEscalation: build.mutation({
      query: ({ body, promptId }) => ({
        url: `${CHAT}/${promptId}`,
        method: 'PATCH',
        body: body
      }),
      invalidatesTags: ['Escalation_Summary', 'Questions'],
      transformErrorResponse: (error) => handleApiError(error)
    })
  })
});

export const {
  useFetchQuestionsQuery,
  useFetchQuestionsDetailsQuery,
  useLazyFetchQuestionsDetailsQuery,
  useFetchEscalationSummaryQuery,
  useUpdateChatMutation,
  useResolveEscalationMutation
} = questionsApi;

export default questionsApi;
