import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useState } from 'react';
import classNames from 'classnames';
import { Anchor, Button, Image, Text, Tooltip } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { useRequestLicenseMutation } from 'redux/services/ask-albus';
import { useFetchUsersQuery } from 'redux/services/users';
import localStorage from 'utils/localStorage';
import BuyAddOnQuestionsModal from 'components/Modals/BuyAddOnQuestionsModal';
import { NAVBAR_ROUTES } from 'utils/constants';
import PeopleIcon from 'assets/components/people-image.svg';
import ChatboxIcon from 'assets/components/chatbox-image.svg';
import { EMPTY_STATE_TYPE } from 'utils/constants';
import styles from './styles.module.scss';

const EmptyStates = (props) => {
  const {
    isAdmin,
    type,
    thirtyOneDaysOfCancellation,
    userAlreadyRequestedLicense,
    isTrialDaysCompleted
  } = props;
  const screen767 = useMediaQuery('(max-width: 767px)');
  const navigate = useNavigate();
  const [requestLicense] = useRequestLicenseMutation();

  const { data: { admins = [] } = {} } = useFetchUsersQuery(undefined, {
    skip:
      isAdmin ||
      (type !== EMPTY_STATE_TYPE.EXHAUSTED_QUESTIONS &&
        type !== EMPTY_STATE_TYPE.FREE_PLAN_QUESTIONS_EXHAUSTED)
  });
  const [showAddOnQuestionsModal, setShowAddOnQuestionsModal] = useState(false);

  const {
    planInfo: { billingDate = '', billingCycle = '' }
  } = useSelector((state) => state.plan);

  function checkout() {
    const token = localStorage.getToken();
    window.open(`${import.meta.env.VITE_API_URL}/billing/checkout-session?key=${token}`, '_self');
  }

  function billingCycleText() {
    return (
      <>
        {billingCycle === 'monthly'
          ? `Your monthly limit resets on the ${formatDayWithSuffix(billingDate)}.`
          : `Your yearly limit resets on ${billingDate}.`}
      </>
    );
  }

  function getEmptyStateIcon() {
    switch (type) {
      case EMPTY_STATE_TYPE.UNLICENSED_USER:
      case EMPTY_STATE_TYPE.EXHAUSTED_QUESTIONS:
        return PeopleIcon;
      case EMPTY_STATE_TYPE.FREE_PLAN_QUESTIONS_EXHAUSTED:
        return PeopleIcon;
      case EMPTY_STATE_TYPE.SUBSCRIPTION_INACTIVE:
        return ChatboxIcon;
      default:
        return PeopleIcon;
    }
  }

  function getEmptyStateTitleText() {
    switch (type) {
      case EMPTY_STATE_TYPE.UNLICENSED_USER:
        return isAdmin ? `You don't have a License` : 'You need a License to ask questions';
      case EMPTY_STATE_TYPE.SUBSCRIPTION_INACTIVE:
        return 'Your subscription is inactive';
      case EMPTY_STATE_TYPE.EXHAUSTED_QUESTIONS:
        return null;
      case EMPTY_STATE_TYPE.FREE_PLAN_QUESTIONS_EXHAUSTED:
        return null;
      default:
        return isAdmin ? `You don't have a License` : 'You need a License to ask questions';
    }
  }

  function formatDayWithSuffix(dateString) {
    const date = dayjs(dateString, { format: 'MMMM D, YYYY' });
    const dayOfMonth = date.date();
    const suffix =
      dayOfMonth >= 11 && dayOfMonth <= 13
        ? 'th'
        : ['st', 'nd', 'rd', 'th'][Math.min((dayOfMonth - 1) % 10, 3)];
    return `${dayOfMonth}${suffix}`;
  }

  function getEmptyStateDescriptionText() {
    switch (type) {
      case EMPTY_STATE_TYPE.UNLICENSED_USER:
        return isAdmin
          ? 'Go to the Billing page and assign yourself a License to ask me questions.'
          : '👩‍💻 Request admins to assign you a License.';
      case EMPTY_STATE_TYPE.SUBSCRIPTION_INACTIVE:
        return thirtyOneDaysOfCancellation
          ? 'If you want to continue to ask me questions across all your workplace apps, subscribe now!'
          : 'Subscribe within 30 days to keep sources, training and settings intact. After that you will lose all your data.';
      case EMPTY_STATE_TYPE.EXHAUSTED_QUESTIONS:
        return isAdmin ? (
          <>
            Your company exhausted its question quota.&nbsp;
            {billingCycleText()}
          </>
        ) : (
          <>
            You can not ask any questions because your company exhausted its question quota.&nbsp;
            {billingCycleText()}
            &nbsp;To ask questions now, request an admin to buy an add-on:&nbsp;
            {admins.map((admin, index) => (
              <>
                <span key={admin._id}>
                  <Tooltip label={admin.email}>
                    <Anchor className={styles.adminNameText}>{admin.name}</Anchor>
                  </Tooltip>
                </span>
                {index < admins.length - 1 ? ', ' : ''}
              </>
            ))}
          </>
        );
      case EMPTY_STATE_TYPE.FREE_PLAN_QUESTIONS_EXHAUSTED:
        return (
          <>
            {isTrialDaysCompleted ? (
              <Text className={styles.exhaustedQuestionHeading}>⚠️ Your free trial is over.</Text>
            ) : (
              <Text className={styles.exhaustedQuestionHeading}>
                ⚠️ Your company exhausted the 50 free questions in your free trial.
              </Text>
            )}
            <Text>To ask more questions now, upgrade to a paid plan!</Text>
          </>
        );
      default:
        return 'Go to the Billing page and assign yourself a License to ask me questions.';
    }
  }

  function getEmptyStateButtonText() {
    switch (type) {
      case EMPTY_STATE_TYPE.UNLICENSED_USER:
        return isAdmin ? 'Go to Billing' : 'Request License';
      case EMPTY_STATE_TYPE.SUBSCRIPTION_INACTIVE:
        return 'Subscribe Now';
      case EMPTY_STATE_TYPE.EXHAUSTED_QUESTIONS:
        return isAdmin ? 'Buy add-on questions' : null;
      case EMPTY_STATE_TYPE.FREE_PLAN_QUESTIONS_EXHAUSTED:
        return 'Upgrade now';
      default:
        return isAdmin ? 'Go to Billing' : 'Request License';
    }
  }

  function handleButtonClick(buttonText) {
    switch (buttonText) {
      case 'Go to Billing':
        navigate(NAVBAR_ROUTES.BILLING);
        return;
      case 'Request License':
        requestLicense();
        return;
      case 'Buy add-on questions':
        setShowAddOnQuestionsModal(true);
        return;
      case 'Upgrade now':
        checkout();
        return;
      default:
        return;
    }
  }

  return (
    <div className={styles.emptyStateWrapper}>
      <Image src={getEmptyStateIcon()} alt='People Icon' w={screen767 ? 258 : 536} />
      <div className={styles.titleText}>{getEmptyStateTitleText()}</div>
      <div
        className={classNames(styles.descriptionText, {
          [styles.exhaustedQuesForAdmin]: type === EMPTY_STATE_TYPE.EXHAUSTED_QUESTIONS && isAdmin,
          [styles.freePlanQuestionsExhausted]:
            type === EMPTY_STATE_TYPE.FREE_PLAN_QUESTIONS_EXHAUSTED
        })}
      >
        {getEmptyStateDescriptionText()}
      </div>
      {!(type === EMPTY_STATE_TYPE.EXHAUSTED_QUESTIONS && !isAdmin) &&
        !(
          (type === EMPTY_STATE_TYPE.FREE_PLAN_QUESTIONS_EXHAUSTED ||
            type === EMPTY_STATE_TYPE.UNLICENSED_USER) &&
          userAlreadyRequestedLicense &&
          !isAdmin
        ) && (
          <Button
            classNames={{
              root: styles.GoToBillingButtonRoot,
              label: styles.GoToBillingButtonLabel
            }}
            onClick={() => handleButtonClick(getEmptyStateButtonText())}
          >
            {getEmptyStateButtonText()}
          </Button>
        )}
      {type === EMPTY_STATE_TYPE.UNLICENSED_USER && userAlreadyRequestedLicense && !isAdmin && (
        <div className={styles.sentYourRequestText}>
          I sent your request for a License to your admins.
        </div>
      )}
      {showAddOnQuestionsModal && (
        <BuyAddOnQuestionsModal
          showAddOnQuestionsModal={showAddOnQuestionsModal}
          setShowAddOnQuestionsModal={setShowAddOnQuestionsModal}
        />
      )}
    </div>
  );
};

EmptyStates.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  thirtyOneDaysOfCancellation: PropTypes.bool,
  userAlreadyRequestedLicense: PropTypes.bool,
  isTrialDaysCompleted: PropTypes.bool
};

EmptyStates.defaultProps = {
  userAlreadyRequestedLicense: false,
  thirtyOneDaysOfCancellation: false,
  isTrialDaysCompleted: false
};

export default EmptyStates;
