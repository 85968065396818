import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Button, Flex, Skeleton, Text, Title } from '@mantine/core';
import { maybePluralize } from 'utils/utilities';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import styles from '../../billing.module.scss';

const AssignLicensesBanner = (props) => {
  const {
    unassignedLicenses,
    licensesBought,
    buyLicense,
    isPlanLoading,
    setIsAssignLicensesModalOpen,
    setLicenseStatus
  } = props;

  const { totalRequests: licensesRequestCount, isLoading: isUsersLoading } = useSelector(
    (state) => state.license
  );

  return (
    <Flex
      align='center'
      justify='space-between'
      className={classNames(styles.billingContainer, styles.requestLicenseContainer)}
    >
      <div className={styles.billingContent}>
        <Title order={6}>
          {isUsersLoading ? (
            <Skeleton height={10} radius={2} />
          ) : (
            licensesRequestCount +
            ` ${maybePluralize(licensesRequestCount, 'teammate')} requested ${maybePluralize(
              licensesRequestCount,
              'License'
            )}`
          )}
        </Title>
        {licensesBought > 0 && (
          <Text className={styles.licensesInfo}>
            <InfoIcon className={styles.infoIcon} />
            {!isPlanLoading && unassignedLicenses >= 0 && (
              <> Unassigned Licenses on your current plan: {unassignedLicenses} </>
            )}
            {isPlanLoading && <Skeleton height={20} radius={2} />}
          </Text>
        )}
      </div>
      <div>
        {licensesBought > 0 && (
          <Button
            className={styles.primaryBtn}
            onClick={() => {
              setIsAssignLicensesModalOpen(true);
              setLicenseStatus('requested');
            }}
          >
            Assign Licenses
          </Button>
        )}

        {licensesBought <= 0 && (
          <Button onClick={buyLicense} className={styles.primaryBtn}>
            Buy Licenses
          </Button>
        )}
      </div>
    </Flex>
  );
};

AssignLicensesBanner.defaultProps = {
  isPlanLoading: true
};

AssignLicensesBanner.propTypes = {
  buyLicense: PropTypes.func.isRequired,
  unassignedLicenses: PropTypes.number.isRequired,
  licensesBought: PropTypes.number.isRequired,
  isPlanLoading: PropTypes.bool,
  setIsAssignLicensesModalOpen: PropTypes.func.isRequired,
  setLicenseStatus: PropTypes.func.isRequired
};

export default AssignLicensesBanner;
