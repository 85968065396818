import { Flex, TextInput, Button, Loader } from '@mantine/core';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-o.svg';
import { useFetchUsersQuery } from 'redux/services/users';

import { useAssignPrivilegeMutation, useUnAssignPrivilegeMutation } from 'redux/services/admin';
import { toast } from 'react-toastify';
import AddAdminsModal from '../Components/AddAdminsModal';
import AdminsList from '../Components/AdminsList';
import useInput from 'custom_hooks/useInput';
import { bool } from 'prop-types';
import { constructAdminAddedSuccessMessage, formatUsersDataForDropdown } from '../utils';
import styles from './styles.module.scss';

const CompanyAdmins = ({ skipApiCall }) => {
  const { data: { users = [], admins = [] } = {}, isFetching: isAdminsLoading } =
    useFetchUsersQuery(undefined, {
      skip: skipApiCall
    });
  const [assignPrivilege, { isSuccess: assignPrivilegeSuccess, isLoading }] =
    useAssignPrivilegeMutation();
  const [unAssignPrivilege, { isSuccess: unAssignPrivilegeSuccess }] =
    useUnAssignPrivilegeMutation();
  const [isAddAdminModalOpen, setIsAddAdminModalOpen] = useState(false);
  const [adminsToBeAdded, setAdminsToBeAdded] = useState([]);
  const [adminToBeRemoved, setAdminToBeRemoved] = useState({});
  const { value, onChange } = useInput('');
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (assignPrivilegeSuccess) {
      toast.success(constructAdminAddedSuccessMessage(adminsToBeAdded));
      setAdminsToBeAdded([]);
    }
  }, [assignPrivilegeSuccess]);

  useEffect(() => {
    if (unAssignPrivilegeSuccess) {
      toast.success(`${adminToBeRemoved.name} lost admin privileges.`);
      setAdminToBeRemoved({});
    }
  }, [unAssignPrivilegeSuccess]);

  function filterAdmins() {
    const updatedAdmins = admins.length ? admins?.filter((admin) => admin.name !== 'Albus') : [];

    if (user?.isAdmin && !isAdminsLoading) {
      updatedAdmins.unshift({ name: user?.name, photo: user?.photo, owner: true });
    }

    return value?.length
      ? updatedAdmins.filter((a) => a.name?.toLowerCase().includes(value.toLowerCase()))
      : updatedAdmins;
  }

  const filteredAdmins = filterAdmins();

  return (
    <>
      <Flex className={styles.adminHeader}>
        <TextInput
          className={styles.searchInput}
          placeholder='Search for people'
          leftSection={<SearchIcon />}
          value={value}
          onChange={onChange}
        />

        <Button
          className={classNames('primaryBtn', styles.addAdminBtn)}
          onClick={() => setIsAddAdminModalOpen(true)}
          disabled={isAdminsLoading}
        >
          <PlusIcon /> Add company admin
        </Button>
      </Flex>

      {isAdminsLoading ? (
        <Flex align='center' justify='center' w='100%' h='40vh'>
          <Loader />
        </Flex>
      ) : (
        <AdminsList
          admins={filteredAdmins}
          unAssignPrivilege={unAssignPrivilege}
          setAdminToBeRemoved={setAdminToBeRemoved}
          adminToBeRemoved={adminToBeRemoved}
        />
      )}

      <AddAdminsModal
        isLoading={isLoading}
        opened={isAddAdminModalOpen}
        setOpened={setIsAddAdminModalOpen}
        users={formatUsersDataForDropdown(users?.filter((user) => user.name !== 'Albus'))}
        adminsToBeAdded={adminsToBeAdded}
        setAdminsToBeAdded={setAdminsToBeAdded}
        handleConfirm={() => {
          assignPrivilege({ users: adminsToBeAdded.map((user) => user.value) });
        }}
      />
    </>
  );
};
CompanyAdmins.propTypes = {
  skipApiCall: bool
};
export default CompanyAdmins;
