import { arrayOf, element, func, shape, string } from 'prop-types';
import styles from './styles.module.scss';

const Sign = ({ headerText, providers, footerText }) => {
  return (
    <div className={styles.sign}>
      <header>{headerText}</header>
      <ul>
        {providers.map((provider, i) => (
          <li key={i} onClick={provider.handler}>
            <img src={provider.icon} alt='' />
            <p>{provider.label}</p>
          </li>
        ))}
      </ul>
      <footer>{footerText}</footer>
    </div>
  );
};

Sign.propTypes = {
  headerText: string.isRequired,
  providers: arrayOf(
    shape({
      icon: string,
      label: string,
      handler: func
    })
  ).isRequired,
  footerText: element.isRequired
};

export default Sign;
