import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, LoadingOverlay } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  useFetchWidgetsQuery,
  useLazyFetchWidgetWikisQuery,
  useLazyGetWidgetQuery
} from 'redux/services/widgets.js';
import Layout from 'components/Layout';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import WidgetsTable from './WidgetsTable';
import WidgetDetails from './WidgetDetails';
import EmptyState from './EmptyState';
import OnlyDesktop from './OnlyDesktop';
import { NAVBAR_ROUTES } from 'utils/constants';
import styles from './ChatWidgets.module.scss';

const ChatWidgets = () => {
  const { isLoading: fetchingWidgets } = useFetchWidgetsQuery();
  const { widgets = [] } = useSelector((state) => state.widgets);
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [newWidget, setNewWidget] = useState(false);
  const [getWidgetDetails, { isFetching: fetchingDetails }] = useLazyGetWidgetQuery();
  const [getWidgetWikis, { isFetching: fetchingWikis }] = useLazyFetchWidgetWikisQuery();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();
  const { widgetId } = useParams();

  useEffect(() => {
    if (widgetId) {
      onMangeWidget(widgetId);
    }
  }, [widgetId]);

  const onMangeWidget = async (widgetId) => {
    const [
      {
        data: { data }
      },
      {
        data: { data: wikis }
      }
    ] = await Promise.all([getWidgetDetails(widgetId), getWidgetWikis(widgetId)]);
    const widget = {
      ...data,
      wikis,
      height: parseInt(data.size.height.replace('px', '')),
      width: parseInt(data.size.width.replace('px', '')),
      persona: data.persona || ''
    };
    delete widget.teams;
    delete widget.size;
    setSelectedWidget(widget);
    setNewWidget(false);
  };

  function handleClose() {
    setNewWidget(false);
    setSelectedWidget(null);
    navigate(NAVBAR_ROUTES.CHAT_WIDGETS);
  }

  let pageTitle = 'Chat widgets';
  let pageSubtitle = newWidget ? 'Create chat widget' : selectedWidget ? 'Manage widget' : '';

  if (newWidget || selectedWidget) {
    pageTitle = (
      <div className={styles.titleWrapper}>
        <Button className={styles.navigateBackButton} onClick={handleClose} variant='default'>
          <SVGIcon name={ICONS_LIST.LEFT_ARROW} height={18} width={18} />
        </Button>
        <div>
          <span className={styles.pageTitle}>{pageTitle} &nbsp;&gt;&nbsp;</span>
          <span className={styles.pageSubtitle}>{pageSubtitle}</span>
        </div>
      </div>
    );
  }

  if (isMobile) {
    return (
      <Layout pageTitle={pageTitle}>
        {widgets.length > 0 || newWidget || selectedWidget ? (
          <OnlyDesktop />
        ) : (
          <EmptyState onNewWidget={() => setNewWidget(true)} />
        )}
      </Layout>
    );
  }

  // Show loading overlay, when page is opening for the first time
  if (fetchingWidgets) {
    return (
      <Layout pageTitle={pageTitle}>
        <LoadingOverlay visible />;
      </Layout>
    );
  }

  if (!fetchingWidgets && !widgets.length && !newWidget && !selectedWidget) {
    return (
      <Layout pageTitle={pageTitle}>
        <EmptyState onNewWidget={() => setNewWidget(true)} />;
      </Layout>
    );
  }

  return (
    <Layout pageTitle={pageTitle}>
      {newWidget || selectedWidget ? (
        //Display WidgetDetails page to  create or manage widget
        <WidgetDetails
          widget={selectedWidget}
          postAction={onMangeWidget}
          handleClose={handleClose}
        />
      ) : (
        <>
          <WidgetsTable
            widgets={widgets}
            onManageWidget={onMangeWidget}
            onNewWidget={() => setNewWidget(true)}
          />
          {/* Show loading overlay over the table when click on manage wiki */}
          <LoadingOverlay visible={fetchingWidgets || fetchingDetails || fetchingWikis} />
        </>
      )}
    </Layout>
  );
};
export default ChatWidgets;
