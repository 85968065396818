import PropTypes from 'prop-types';
import { Text, Tooltip } from '@mantine/core';
import Table from '../../../../../components/Table';
import SourceGrid from '../../Components/SourceGrid';
import SlackIcon from 'assets/icons/slack.svg';
import { trainedAtTableHeader } from '../../Notion/DataView';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { formatDate } from 'utils/formatters';
import { retrainFailureTooltip, truncateName } from 'pages/Integrations/utils';
import { ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS, DOCUMENT_TYPE, VIEW_TYPE } from 'utils/constants';
import styles from './styles.module.scss';

const ChannelsDataView = (props) => {
  const {
    viewType,
    channels,
    user,
    isLoading,
    pagination,
    pageCount,
    totalCount,
    metaPayload,
    totalItems,
    setTotalItems,
    selectedSources,
    isAllSourcesSelected,
    handleCheckboxCta,
    checkboxTooltipLabels
  } = props;

  const getChannelIcon = (channelType) =>
    channelType === DOCUMENT_TYPE.SLACK_PRIVATE ? (
      <>
        <LockIcon />
        &nbsp;
      </>
    ) : (
      '#'
    );

  const formattedRows = channels.map((list) => ({
    id: list.id,
    title: (
      <span>
        {getChannelIcon(list.type)}
        {truncateName(list.name, 40)}
      </span>
    ),
    name: (
      <Tooltip
        label={<Text>{list.name}</Text>}
        w={300}
        p={12}
        position='top'
        disabled={list.name.length < 40}
      >
        <span>
          {getChannelIcon(list.type)}
          {truncateName(list.name, 40)}
        </span>
      </Tooltip>
    ),
    url: list.url,
    date: formatDate(list.lastTrainingStartedAt),
    uploadBy: <div className={styles.uploaderName}>{list?.uploadedBy || 'User'}</div>,
    status: list.status,
    admin: list.admin,
    trainingFrequency: list.trainingFrequency,
    lastTrainingStartedAt: list.lastTrainingStartedAt || list.createdAt,
    lastTrainingAttemptedAt: list.lastTrainingAttemptedAt || list.createdAt,
    error: list.error,
    description: list.description,
    retrainError: retrainFailureTooltip(
      list.status,
      list.lastTrainType,
      list.lastTrainingAttemptedAt || list.lastTrainingStartedAt
    ),
    handleOpenSource: () => list.url && window.open(list.url, '_blank'),
    tooltip: list.name.length > 40 && list.name
  }));

  return viewType === VIEW_TYPE.GRID ? (
    <SourceGrid
      items={formattedRows}
      totalItems={totalItems}
      setTotalItems={setTotalItems}
      user={user}
      hideCreatedAt={true}
      sourceIcon={SlackIcon}
      pagination={pagination}
      pageCount={pageCount}
      isLoading={isLoading}
      metaPayload={metaPayload}
      selectedSources={selectedSources}
      isAllSourcesSelected={isAllSourcesSelected}
      handleCheckboxCta={handleCheckboxCta}
      showCheckbox
      checkboxTooltipLabels={checkboxTooltipLabels}
    />
  ) : (
    <Table
      headers={['Source', trainedAtTableHeader, 'Added by', 'Status', '']}
      rows={formattedRows}
      hideCreatedAt={true}
      pagination={pagination}
      pageCountFromProp={pageCount}
      pageLimitFromProp={ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS}
      isLoading={isLoading}
      sourceIcon={SlackIcon}
      totalCount={totalCount}
      metaPayload={metaPayload}
      disableRowClick
      selectedSources={selectedSources}
      isAllSourcesSelected={isAllSourcesSelected}
      handleCheckboxCta={handleCheckboxCta}
      showCheckbox
      checkboxTooltipLabels={checkboxTooltipLabels}
    />
  );
};

ChannelsDataView.defaultProps = {
  viewType: 'grid',
  handleCheckboxCta: () => null,
  selectedSources: [],
  channels: [],
  isAllSourcesSelected: false,
  setTotalItems: () => null,
  totalItems: [],
  checkboxTooltipLabels: []
};

ChannelsDataView.propTypes = {
  selectedSources: PropTypes.array,
  checkboxTooltipLabels: PropTypes.array,
  handleCheckboxCta: PropTypes.func,
  totalItems: PropTypes.array,
  setTotalItems: PropTypes.func,
  viewType: PropTypes.string,
  channels: PropTypes.array,
  isAllSourcesSelected: PropTypes.bool,
  user: PropTypes.object.isRequired,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }).isRequired
};

export default ChannelsDataView;
