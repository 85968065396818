import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Menu, Loader, Tooltip, Flex } from '@mantine/core';
import MembersTable from './MembersTable';
import ProfilePicture from 'components/ProfilePicture';
import { useGetTeamQuery } from 'redux/services/settings';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { ACTIONS } from '../../utils';
import styles from '../../Teams.module.scss';

const Table = (props) => {
  const { handleActions, teamId } = props;

  const { user } = useSelector((state) => state.user);
  const {
    data: teamDetails = {
      members: []
    },
    isFetching
  } = useGetTeamQuery(teamId);

  const formattedRows = teamDetails.members.map((member) => {
    return {
      userId: member.userId,
      title: (
        <Flex align='center' gap={10}>
          <ProfilePicture photoUrl={member.photo} name={member.name} />
          {member.name}
        </Flex>
      ),
      name: member.name,
      permission: member.admin ? 'Admin' : 'Member',
      id: teamId,
      teamName: teamDetails.name,
      isMemberTable: true
    };
  });

  const actionMenuData = (data) => {
    const actions = [];
    const hideRemoveTeammate = user.userId === data.userId;
    const disableOption =
      data.permission === 'Admin' &&
      teamDetails.members.filter((member) => member.admin).length <= 1;

    const handleUnmarkAdmin = () => handleActions(ACTIONS.UNMARK_ADMIN, data);
    const handleRemoveTeammate = () => handleActions(ACTIONS.REMOVE_TEAMMATE, data);

    if (data.permission === 'Admin') {
      actions.push(
        <Menu.Item
          className={classNames({ [styles.disable]: disableOption })}
          key={`${data.title}-unmark-admin`}
          onClick={!disableOption ? handleUnmarkAdmin : undefined}
        >
          Unmark admin
          {disableOption && (
            <Tooltip label='Team should have atleast one admin'>
              <span>
                <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
              </span>
            </Tooltip>
          )}
        </Menu.Item>
      );
    } else {
      actions.push(
        <Menu.Item
          key={`${data.title}-mark-admin`}
          onClick={() => handleActions(ACTIONS.MARK_ADMIN, data)}
        >
          Mark admin
        </Menu.Item>
      );
    }

    if (!hideRemoveTeammate) {
      actions.push(
        <Menu.Item
          className={classNames({ [styles.disable]: disableOption })}
          key={`${data.title}-remove-teammate`}
          onClick={!disableOption ? handleRemoveTeammate : undefined}
        >
          Remove teammate
          {disableOption && (
            <Tooltip label='Team should have atleast one admin'>
              <span>
                <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
              </span>
            </Tooltip>
          )}
        </Menu.Item>
      );
    }

    return actions;
  };

  if (isFetching) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <MembersTable formattedRows={formattedRows} actionMenuData={actionMenuData} teamId={teamId} />
  );
};

Table.defaultProps = {
  members: [],
  value: '',
  setValue: () => null
};

Table.propTypes = {
  teamId: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      admin: PropTypes.bool
    })
  ),
  value: PropTypes.string,
  setValue: PropTypes.func,
  handleActions: PropTypes.func.isRequired
};

export default Table;
