import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import styles from './styles.module.scss';
import './styles.css';

export function checkForElement(elementId, onElementFound, showTutorialModal) {
  let intervalId;
  const checkAndExecute = () => {
    const targetElement = document.getElementById(elementId);
    if (targetElement) {
      !!intervalId && clearInterval(intervalId);
      onElementFound(showTutorialModal);
    }
  };

  // Run the check immediately
  checkAndExecute();

  // Set up interval for periodic checks
  intervalId = setInterval(checkAndExecute, 1000);

  return intervalId;
}

export default checkForElement;

export function stepConstructor(title = '', desc = '', img) {
  return `
  <div class="${styles.body}">
    <div class="${styles.wrapper}">
      ${img ? `<img src="${img}" alt="step one" />` : ''}
      <div class="${styles.contentWrapper}">
        <p class="${styles.text}">${title}</p>
        <p class="${styles.subText}">
          ${desc}
        </p>
      </div>
    </div>
  </div>
`;
}

export function tutorialIntiator(steps = [], showTutorialModal) {
  const driverObj = driver({
    showProgress: true,
    popoverClass: 'driverjs-theme',
    nextBtnText: 'Next',
    showButtons: ['previous', 'next'],
    prevBtnText: 'Back',
    allowClose: false,
    disableActiveInteraction: true,
    onNextClick: () => {
      if (!driverObj.hasNextStep()) {
        showTutorialModal();
      }
      driverObj.moveNext();
    },
    onPopoverRender: (popover) => {
      const skipButton = document.createElement('button');

      skipButton.innerHTML = `
        <span class="${styles.backToMenu}">
          <svg
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              opacity='0.5'
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M7.60149 11.6481C7.24351 11.2902 7.24351 10.7098 7.60149 10.3518L13.1015 4.85178C13.4595 4.4938 14.0399 4.4938 14.3979 4.85178C14.7558 5.20976 14.7558 5.79016 14.3979 6.14814L9.54604 11L14.3979 15.8518C14.7558 16.2098 14.7558 16.7902 14.3979 17.1481C14.0399 17.5061 13.4595 17.5061 13.1015 17.1481L7.60149 11.6481Z'
              fill='black'
            />
          </svg>
          Back to menu
        </span>
      `;

      // Style skipButton

      skipButton.style.border = '0';
      skipButton.style.backgroundColor = '#FFF';
      skipButton.style.cursor = 'pointer';

      const footerButtons = popover.footer;
      footerButtons.insertBefore(skipButton, footerButtons.firstChild);

      skipButton.addEventListener('click', () => {
        driverObj.destroy();
        showTutorialModal();
      });
    },
    steps
  });

  driverObj.drive();
}
