import { createBrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Analytics from 'pages/Analytics';
import AskAlbus from 'pages/AskAlbus';
import Actions from 'pages/Actions';
import Integrations from 'pages/Integrations';
import Billing from 'pages/Billing';
import SettingsRouter from 'pages/ControlPanel/SettingsRouter';
import ErrorPage from 'pages/ErrorPage';
import ErrorBoundary from 'components/ErrorBoundary';
import FirstUserLogin from 'pages/Integrations/FirstUserLogin';
import Connector from 'pages/Integrations/Connector';
import Navbar from 'components/Layout/Navbar';
import CustomNavigate from 'components/CustomNavigate';
import Questions from 'pages/Questions';
import Teams from 'pages/Teams';
import OnBoarding from 'pages/OnBoarding';
import Signup from 'pages/Auth/Signup';
import Signin from 'pages/Auth/Signin';
import Invite from 'pages/Auth/Invite';
import ControlPanel from 'pages/ControlPanel';
import ChatWidgets from './pages/ChatWidgets/index.jsx';
import SettingsSubRouter from 'pages/ControlPanel/SettingsSubRouter/index.jsx';
import AddSource from 'pages/Integrations/Connector/Web/AddSource/index.jsx';
import ManageSource from 'pages/Integrations/Connector/Web/ManageSources/index.jsx';
import { useGetUserQuery } from 'redux/services/user';
import { useGetPlanQuery } from 'redux/services/plan.js';
import localStorage from 'utils/localStorage';
import { NAVBAR_ROUTES } from 'utils/constants';
import { canAccessRoute } from 'utils/utilities.js';

const AuthenticateRoute = ({ Component, ...rest }) => {
  const params = new URLSearchParams(window.location.search);
  const encToken = params.get('token');
  const { pathname = '' } = useLocation();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  if (!encToken) {
    // Check if token is present in local storage
    const hasToken = localStorage.hasToken();
    const token = localStorage.getToken();
    const platform = localStorage.getPlatform();
    if (hasToken && token) {
      useGetUserQuery();
      useGetPlanQuery();
      if (!canAccessRoute(pathname, user.userType, user?.analyticsUrl)) {
        navigate(NAVBAR_ROUTES.ASK_ALBUS);
      }
      return <Component {...rest} />;
    }
    let queryParams = {};
    for (const k of params.keys()) {
      queryParams[k] = params.get(k);
    }
    queryParams.redirect = window.location.pathname.substring(1);
    if (platform) {
      queryParams.platform = platform;
    }
    queryParams = new URLSearchParams(queryParams);
    window.open(`${import.meta.env.VITE_BASE_URL}/login/?${queryParams.toString()}`, '_self');
    return null;
  }
  const { status, key } = localStorage.decryptToken(encToken);
  if (status === 'VALID' && key === import.meta.env.VITE_DEFAULT_KEY) {
    localStorage.setToken(encToken);
    useGetUserQuery();
    useGetPlanQuery();
    if (!canAccessRoute(pathname, user.userType, user?.analyticsUrl)) {
      navigate(NAVBAR_ROUTES.ASK_ALBUS);
    }
    return <Component {...rest} />;
  }
};

AuthenticateRoute.propTypes = {
  Component: PropTypes.elementType.isRequired
};

const AuthenticatePublicToken = ({ Component, ...rest }) => {
  const params = new URLSearchParams(window.location.search);
  const encPublicToken = params.get('token');
  const { status, key, inviteCode } = localStorage.decryptToken(encPublicToken);
  if (inviteCode) {
    localStorage.set('inviteCode', inviteCode);
  }
  if (status === 'VALID' && key === import.meta.env.VITE_DEFAULT_KEY) {
    localStorage.setToken(encPublicToken);
    return <Component {...rest} />;
  } else {
    const encPublicToken = params.get('token');
    const decoded = jwtDecode(encPublicToken);
    localStorage.setToken(decoded.setupToken);
    return <Component {...rest} />;
  }
};

AuthenticatePublicToken.propTypes = {
  Component: PropTypes.elementType.isRequired
};

const pages = [
  { path: NAVBAR_ROUTES.ON_BOARDING, element: <AuthenticateRoute Component={OnBoarding} /> },
  { path: NAVBAR_ROUTES.AUTH.SIGNUP, element: <Signup /> },
  { path: NAVBAR_ROUTES.AUTH.SIGNIN, element: <Signin /> },
  { path: NAVBAR_ROUTES.AUTH.SETUP, element: <AuthenticatePublicToken Component={Signup} /> },
  { path: NAVBAR_ROUTES.AUTH.INVITE, element: <AuthenticatePublicToken Component={Invite} /> },
  {
    path: NAVBAR_ROUTES.AUTH.RESET_PASSWORD,
    element: <AuthenticatePublicToken Component={Signin} />
  },

  {
    path: NAVBAR_ROUTES.DASHBOARD,
    element: <AuthenticateRoute Component={Navbar} />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: NAVBAR_ROUTES.INTEGRATIONS,
        element: <AuthenticateRoute Component={Integrations} />
      },
      {
        path: `${NAVBAR_ROUTES.INTEGRATIONS}/:teamId`,
        element: <AuthenticateRoute Component={Integrations} />
      },
      {
        path: `${NAVBAR_ROUTES.INTEGRATIONS}/:provider/:teamId`,
        element: <AuthenticateRoute Component={Connector} />
      },
      {
        path: NAVBAR_ROUTES.WEB_ADD_SOURCE,
        element: <AuthenticateRoute Component={AddSource} />
      },
      {
        path: NAVBAR_ROUTES.WEB_MANAGE_SOURCES,
        element: <AuthenticateRoute Component={ManageSource} />
      },
      {
        path: NAVBAR_ROUTES.FIRST_USER_LOGIN,
        element: <AuthenticateRoute Component={FirstUserLogin} />
      },
      {
        path: NAVBAR_ROUTES.SETTINGS,
        element: <AuthenticateRoute Component={ControlPanel} />
      },
      {
        path: NAVBAR_ROUTES.SETTINGS_TABS.ROUTE,
        element: <AuthenticateRoute Component={SettingsRouter} />
      },
      {
        path: NAVBAR_ROUTES.SETTINGS_SUB_TABS.ROUTE,
        element: <AuthenticateRoute Component={SettingsSubRouter} />
      },
      {
        path: NAVBAR_ROUTES.QUESTIONS,
        element: <AuthenticateRoute Component={Questions} />
      },
      {
        path: NAVBAR_ROUTES.BILLING,
        element: <AuthenticateRoute Component={Billing} />
      },
      {
        path: NAVBAR_ROUTES.TEAMS,
        element: <AuthenticateRoute Component={Teams} />
      },
      {
        path: NAVBAR_ROUTES.TEAM.ROUTE,
        element: <AuthenticateRoute Component={Teams} />
      },
      {
        //for root route '/' redirect user to '/integrations'
        path: NAVBAR_ROUTES.DASHBOARD,
        element: <CustomNavigate to={NAVBAR_ROUTES.INTEGRATIONS} replace={true} />
      },
      {
        path: NAVBAR_ROUTES.CHAT_WIDGETS,
        element: <AuthenticateRoute Component={ChatWidgets} />
      },
      {
        path: NAVBAR_ROUTES.CHAT_WIDGET.ROUTE,
        element: <AuthenticateRoute Component={ChatWidgets} />
      },
      {
        path: NAVBAR_ROUTES.ASK_ALBUS,
        element: <AuthenticateRoute Component={AskAlbus} />
      },
      {
        path: `${NAVBAR_ROUTES.ASK_ALBUS}/prompt-ideas`,
        element: <AuthenticateRoute Component={AskAlbus} />
      },
      {
        path: NAVBAR_ROUTES.ACTIVITY_LOGS,
        element: <AuthenticateRoute Component={Analytics} />
      },
      {
        path: NAVBAR_ROUTES.ACTIONS,
        element: <AuthenticateRoute Component={Actions} />
      }
    ]
  },
  {
    path: '*',
    element: (
      <ErrorPage
        errorStatus={404}
        errorPageTitle={'You have found a secret place'}
        errorDescription={
          'Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another URL.'
        }
      />
    )
  }
];

const router = createBrowserRouter(pages);

export default router;
