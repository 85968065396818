import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Flex, Image, Modal, Progress, Text, Title } from '@mantine/core';
import { ReactComponent as CloseIcon } from 'assets/icons/cross-popover.svg';
import Tick from 'assets/icons/tick.svg';
import BgStars from 'assets/onboarding/welcome-modal-stars.svg';
import { ReactComponent as HandWaveEmoji } from 'assets/icons/handwave-emoji.svg';
import Cross from 'assets/icons/cross.svg';
import { CAN_ANSWER, CANNOT_ANSWER } from './constants';
import styles from './styles.module.scss';

const WelcomeModal = (props) => {
  const { name, open, setOpen } = props;
  const [value, setValue] = useState(0);

  useEffect(() => {
    const incrementValue = () => {
      setValue((prev) => {
        if (prev < 100) {
          return prev + 0.1;
        }
        return prev;
      });
    };

    const interval = setInterval(() => {
      incrementValue();
    }, 10);

    return () => clearInterval(interval);
  }, []);

  return (
    <Modal
      opened={open}
      centered
      size='964px'
      padding={0}
      className='mobile-modal'
      withCloseButton={false}
      closeOnClickOutside={false}
      classNames={{
        content: styles.gradient
      }}
    >
      {value < 100 && <Progress h={5} value={value} color='#0e9ad7' />}
      <div className={styles.wrapper}>
        <Image src={BgStars} className={styles.bgStars} />
        {value > 100 && (
          <Button onClick={setOpen} className={styles.closeIcon}>
            <CloseIcon />
          </Button>
        )}
        <Title className={styles.name} size={24} c='#fff' fw={600}>
          <HandWaveEmoji /> Welcome {name}
        </Title>
        <Text size='sm' c='#d0cae6' fw={400} mt={16} mb={50}>
          Find information where it belongs - at your fingertips.
        </Text>
        <div className={styles.body}>
          <div className={classNames(styles.panel, styles.canAnswerPanel)}>
            <div className={styles.title}>
              <span className={styles.greenUnderline}>Albus</span>
              <span>&nbsp;can</span>&nbsp;answer
            </div>

            <Flex direction='column' gap={20} className={styles.pointsContainer}>
              {CAN_ANSWER.map((point, index) => (
                <Flex align='center' key={index}>
                  <Image mt={5} mr={8} h={18} src={Tick} />
                  <div className={styles.points}>
                    <span>@Albus,</span>&nbsp;{point}
                  </div>
                </Flex>
              ))}
            </Flex>
            <div className={styles.infoContainer}>
              Albus answers 7 out of 10 questions on average correctly and gets better over time as
              he trains on your company&apos;s context.
            </div>
          </div>
          <div className={classNames(styles.panel, styles.cannotAnswerPanel)}>
            <div className={styles.title}>
              <span className={styles.redUnderline}>Albus</span>
              <span>&nbsp;cannot</span>&nbsp;answer
            </div>
            <Flex direction='column' gap={20} className={styles.pointsContainer}>
              {CANNOT_ANSWER.map((point, index) => (
                <Flex key={index} align='center'>
                  <Image mt={5} mr={8} h={16} src={Cross} />
                  <div>
                    <span>@Albus,</span>&nbsp;{point}
                  </div>
                </Flex>
              ))}
            </Flex>
            <div className={styles.infoContainer}>
              If you encounter any issues, you can quickly reach out to&nbsp;
              <span>CEO Kartik Mandaville.</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

WelcomeModal.defaultProps = {
  open: false
};

WelcomeModal.propTypes = {
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default WelcomeModal;
