import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Button, Divider, LoadingOverlay, Modal, Select, TextInput } from '@mantine/core';
import { useGetTeamsQuery } from 'redux/services/settings';
import CopyIcon from 'assets/icons/copy.svg';
import styles from './styles.module.scss';

const WikiModal = (props) => {
  const { handleClose } = props;
  const [allTeams, setAllTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const {
    data: { teams = [] } = {
      teams: []
    },
    isFetching
  } = useGetTeamsQuery();

  useEffect(() => {
    if (teams?.length) {
      const formattedTeamsData = teams?.map((team) => ({
        value: team.teamId,
        label: team.name,
        companywiki: team.companyWiki
      }));
      setAllTeams([...formattedTeamsData]);
      setSelectedTeam(formattedTeamsData?.[0]?.value);
    }
  }, [teams]);

  async function handleCopyUrl() {
    try {
      await navigator.clipboard.writeText(selectedTeam);
      toast.success('Copied Wiki ID successfully');
    } catch (err) {
      toast.error("Couldn't copy Wiki ID. Try again");
    }
  }

  return (
    <Modal
      opened={true}
      onClose={handleClose}
      centered
      title='Wiki ID'
      size='lg'
      className='mobile-modal'
      classNames={{ title: styles.title }}
    >
      <div className={styles.wrapper}>
        <div className={styles.textContent}>
          <div className={styles.heading}>
            Select destination Wiki for new sources added using automations
          </div>
          <div className={styles.subHeading}>
            When you create an automation for Albus to train on a source, you need to specify where
            he adds the source. You can specify that using the Wiki ID.
          </div>
          <div className={styles.subHeading}>
            For example, if you want Albus to add the trained source to your Company Wiki, use the
            Wiki ID of your Company Wiki.
          </div>
        </div>

        <Divider opacity={0.3} size={1} w='100%' />
        <div className={styles.wikiIdText}>Wiki IDs</div>
        <LoadingOverlay visible={isFetching} />
        <Select
          label='Select Wiki'
          data={allTeams}
          value={selectedTeam}
          onChange={(value) => setSelectedTeam(value)}
          className='select-style-v1'
          nothingFoundMessage='no teams found..'
          selectFirstOptionOnChange
          searchable
          classNames={{
            label: styles.selectLabel,
            input: styles.selectInput
          }}
        />
        <div className={styles.copyContent}>
          <TextInput
            classNames={{ label: styles.label, input: styles.input }}
            value={selectedTeam}
            disabled
            label='Wiki ID'
          />
          <Button
            className={styles.button}
            leftIcon={<img src={CopyIcon} />}
            onClick={handleCopyUrl}
            disabled={!selectedTeam}
          >
            Copy
          </Button>
        </div>
        <div className={styles.closeButtonContainer} onClick={handleClose}>
          <Button
            variant='outline'
            color='dark'
            radius={4}
            size='md'
            classNames={{ root: styles.closeButton }}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

WikiModal.propTypes = {
  handleClose: PropTypes.func.isRequired
};
export default WikiModal;
