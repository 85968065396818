import React, { useState } from 'react';
import { Button, Center, Flex, Popover } from '@mantine/core';
import classnames from 'classnames';
import { useProSidebar } from 'react-pro-sidebar';
import InviteViaLinkModal from 'components/InviteModal/ViaLink';
import ViaEmail from 'components/InviteModal/ViaEmail/index';
import { POPUP, usePopupContext } from 'contexts/PopupContext';
import { ReactComponent as InviteTeammatesIcon } from 'assets/icons/invite-teammates.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail-icon-navbar.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link-icon.svg';
import styles from './styles.module.scss';

const InviteTeammates = () => {
  const { collapsed } = useProSidebar();
  const [viaEmailVisible, setViaEmailVisible] = useState(false);
  const [openInviteViaLinkModal, setOpenInviteViaLinkModal] = useState(false);
  const { openedPopup, setOpenedPopup } = usePopupContext();

  const openViaEmail = () => {
    setOpenedPopup(null);
    setViaEmailVisible(true);
  };

  const openViaLink = () => {
    setOpenInviteViaLinkModal(true);
    setOpenedPopup(null);
  };

  return (
    <>
      <Popover
        position='right-end'
        offset={collapsed ? -10 : -50}
        width={300}
        arrowSize={12}
        arrowPosition='center'
        shadow='md'
        opened={openedPopup === POPUP.INVITE_TEAMS}
        onChange={() => setOpenedPopup(POPUP.INVITE_TEAMS)}
      >
        <Popover.Target>
          <Center
            className={classnames(styles.collapsedInviteIcon, { [styles.inviteIcon]: !collapsed })}
            onClick={() => setOpenedPopup(POPUP.INVITE_TEAMS)}
          >
            <InviteTeammatesIcon />
            {!collapsed && <span>Invite teammates</span>}
          </Center>
        </Popover.Target>
        <Popover.Dropdown className={styles.helpPopover}>
          <div className={styles.title}>Invite teammates</div>
          <div className={styles.helpItems}>
            <div className={styles.actionPanel}>
              <Flex direction='column' gap={12}>
                <Button
                  leftSection={<MailIcon />}
                  className={styles.button}
                  classNames={{ leftIcon: styles.buttonIcon }}
                  onClick={openViaEmail}
                >
                  Invite via email
                </Button>
                <Button
                  leftSection={<LinkIcon />}
                  className={styles.button}
                  classNames={{ leftIcon: styles.buttonIcon }}
                  onClick={openViaLink}
                >
                  Invite via link
                </Button>
              </Flex>
            </div>
          </div>
        </Popover.Dropdown>
      </Popover>

      <InviteViaLinkModal
        open={openInviteViaLinkModal}
        onClose={() => setOpenInviteViaLinkModal(false)}
      />

      <ViaEmail open={viaEmailVisible} handleClose={() => setViaEmailVisible(false)} />
    </>
  );
};

export default InviteTeammates;
