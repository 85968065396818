export const STEPS = {
  ZERO: 'zero',
  ONE: 'one',
  EMAIL_VERIFICATION: 'email-verification',
  SETUP_USER: 'setup-user',
  CREATE_PASSWORD: 'create-password',
  CREATE_COMPANY: 'create-company',
  CHOOSE_COMPANY: 'choose-company',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  JOIN_VIA_LINK: 'join-via-link',
  JOIN_VIA_EMAIL: 'join-via-email'
};

export const INITIAL_VALUES = {
  company: '',
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  newPassword: '',
  confirmPassword: ''
};

export const EMAIL_NOT_VERIFIED = 'Your email address is not verified';

export const USER_ALREADY_SETUP = 'User already setup';
