import { toast } from 'react-toastify';
import api from './api';
import { handleApiError } from 'utils/helpers';

const activityApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEmbedHtml: build.query({
      query: (mixpanelReportURL) => ({
        url: `https://mixpanel.com/api/app/embed/oembed/?url=${encodeURIComponent(
          mixpanelReportURL
        )}`,
        method: 'GET'
      }),
      transformResponse: (response) => {
        if (response.html) {
          return response.html;
        } else {
          toast.error('Failed to fetch embed data.');
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    })
  })
});

export const { useLazyGetEmbedHtmlQuery } = activityApi;
export default activityApi;
