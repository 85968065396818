import { toast } from 'react-toastify';
import { SLACK_CONNECT } from 'utils/apiEndpoints';
import api from './api';

const slackConnectApi = api.injectEndpoints({
  endpoints: (build) => ({
    postSlackConnect: build.mutation({
      query: (body) => ({
        url: SLACK_CONNECT,
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response;
        } else {
          toast.error('Failed to fetch slack connect url.');
        }
      }
    })
  })
});

export const { usePostSlackConnectMutation } = slackConnectApi;

export default slackConnectApi;
