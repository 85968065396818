/* eslint-disable no-constant-condition */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { current } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import cloneDeep from 'lodash.clonedeep';
import View from './View';
import { useGetSettingsQuery } from 'redux/services/settings';
import api from 'redux/services/api';
import { useGenerateAnswerMutation } from 'redux/services/ask-albus';
import { useChat } from 'contexts/CurrentChatContext';
import { QUERY_ROUTING_OPTIONS, PROMPT_IDEA_TYPES, TUTORIAL_STATUS } from 'utils/constants';
import {
  checkAndUpdateUserSetupStatus,
  handleValidationChatUpdates,
  handleUpdatePromptId
} from '../utils';

const AskQueryInput = (props) => {
  const { selectedChat, handleSelectChat, setConversation, setShowPromptIdeas, wikiPromptIdeas } =
    props;
  const selectedChatParentTs = selectedChat?.parentTs ?? dayjs().unix().toString();
  const messageTs = dayjs().unix().toString();
  const dispatch = useDispatch();
  const { currentChat, saveCurrentChat } = useChat();
  const [formValues, setFormValues] = useState({
    textInput: '',
    selectedQueryRouting: 'auto'
  });
  const { data: settings } = useGetSettingsQuery();
  const { planInfo } = useSelector((state) => state.plan);
  const { user, validationChats } = useSelector((state) => state.user);
  const initialRender = useRef(user.tutorialStatus === TUTORIAL_STATUS.NOT_STARTED);
  const url = new URL(window.location.href);
  const questionIdParam = url.searchParams.get('questionId');
  const textInputParam = url.searchParams.get('textInput');
  const isOnBoarding = url.searchParams.get('on-boarding');
  const forcedQueryRoute = url.searchParams.get('forcedQueryRoute');
  const [generateAnswer] = useGenerateAnswerMutation();
  const [queryRoutingOptions, setQueryRoutingOptions] = useState(QUERY_ROUTING_OPTIONS);

  useEffect(() => {
    if (
      (user.tutorialStatus === TUTORIAL_STATUS.COMPLETED ||
        user.tutorialStatus === TUTORIAL_STATUS.MINIMISED) &&
      initialRender.current
    ) {
      initialRender.current = false;
      handleSelectChat(currentChat, 'callConversationApi');
    }
  }, [user.tutorialStatus]);

  useEffect(() => {
    if (!settings) {
      return;
    }
    let filteredOptions = cloneDeep(QUERY_ROUTING_OPTIONS);
    if (!settings?.ai) {
      filteredOptions = filteredOptions.filter(
        (option) => option.value !== QUERY_ROUTING_OPTIONS[0].value
      );
    }
    if (!settings?.training) {
      filteredOptions = filteredOptions.filter(
        (option) => option.value !== QUERY_ROUTING_OPTIONS[1].value
      );
    }
    if (!settings?.ai || !settings?.training) {
      filteredOptions = filteredOptions.filter(
        (option) => option.value !== QUERY_ROUTING_OPTIONS[2].value
      );
    }
    setQueryRoutingOptions(filteredOptions);
    setFormValues((prev) => ({
      ...prev,
      selectedQueryRouting: filteredOptions[filteredOptions.length - 1]?.value
    }));
  }, [settings]);

  useEffect(() => {
    if (textInputParam) {
      CreateAnswer(textInputParam, forcedQueryRoute);
      !isOnBoarding &&
        window.history.replaceState(null, '', `/ask-albus?promptId=${selectedChat?.promptId}`);
    }
  }, [textInputParam]);

  function handleChange(key, value) {
    setFormValues((prev) => ({ ...prev, [key]: value }));
  }

  function handleOpenPromptIdeasPage() {
    const promptIdeasTab = settings?.training
      ? PROMPT_IDEA_TYPES.COMPANY_WIKI
      : PROMPT_IDEA_TYPES.OPEN_AI;
    setShowPromptIdeas(promptIdeasTab);
    window.history.replaceState(null, '', `/ask-albus/prompt-ideas`);
  }

  // Method to post the user query in the coversation section
  function postUserQuery(text = null) {
    // Updating the cache with the user query
    const newChat = {
      promptId: null,
      title: text ?? formValues.textInput,
      parentTs: selectedChatParentTs
    };

    if (!selectedChat) {
      dispatch(
        api.util.updateQueryData('getChatsHistory', undefined, (draft) => {
          const chatsHistory = current(draft);
          const updatedChatHistory = [...chatsHistory['today']];
          updatedChatHistory.unshift(newChat);
          draft['today'] = updatedChatHistory;
          return draft;
        })
      );

      // Updating the conversation section by fetching updated data from cache
      handleSelectChat(newChat, 'doNotCallConversationApi');
    }
    setConversation((prev) => [...prev, { prompt: text ?? formValues.textInput, role: 'user' }]);
  }

  function updateChatHistoryWithPromptId(promptId) {
    dispatch(
      api.util.updateQueryData('getChatsHistory', undefined, (draft) => {
        const chatsHistory = current(draft);
        const updatedChatHistory = [...chatsHistory['today']];

        const updatedChat = { ...updatedChatHistory[0] };
        updatedChat.promptId = promptId;
        updatedChatHistory[0] = updatedChat;
        draft['today'] = updatedChatHistory;

        return draft;
      })
    );

    handleSelectChat(
      {
        promptId,
        title: formValues.textInput,
        parentTs: selectedChatParentTs
      },
      'doNotCallConversationApi'
    );

    if (isOnBoarding) {
      saveCurrentChat({
        promptId,
        title: formValues.textInput,
        parentTs: selectedChatParentTs
      });
    }
  }

  function addLoadingMessage() {
    // Add loading state
    setConversation((prev) => {
      const conversations = [...prev];

      conversations.push({
        content: '',
        role: 'assistant',
        messageTs,
        loading: true,
        canPoll: false,
        streaming: true
      });

      return conversations;
    });
  }

  function updateAlbusResponse(data) {
    setConversation((prev) => {
      const conversations = [...prev];
      const index = conversations.findIndex((item) => item.messageTs === messageTs);
      checkAndUpdateUserSetupStatus(data, dispatch, user);
      const mutableConversation = { ...conversations[index] };

      // If promptId is present, we need to poll to get the completion
      if (data.promptId) {
        mutableConversation.canPoll = true;
        mutableConversation.promptId = data.promptId;
        conversations[index] = mutableConversation;

        handleUpdatePromptId(
          data.promptId,
          selectedChat,
          validationChats,
          dispatch,
          updateChatHistoryWithPromptId
        );
      } else {
        mutableConversation.completion = { validation: data.message };
        mutableConversation.loading = false;
        mutableConversation.completedUsing = 'validation';
        mutableConversation.canPoll = false;
        conversations[index] = mutableConversation;

        let lastTwoConversations = cloneDeep(conversations);
        lastTwoConversations = lastTwoConversations.slice(-2);

        handleValidationChatUpdates(
          lastTwoConversations,
          selectedChat,
          validationChats,
          dispatch,
          updateChatHistoryWithPromptId
        );
      }

      return conversations;
    });
  }

  async function CreateAnswer(text = null, type = null) {
    if (!text && !formValues.textInput?.trim()?.length) {
      //restricting empty message
      return;
    }
    handleChange('textInput', '');
    postUserQuery(text, type);
    addLoadingMessage();

    const body = {
      prompt: text ?? formValues.textInput,
      messageTs,
      parentTs: selectedChatParentTs,
      entryPoint: 'Web',
      stream: false
    };

    if (questionIdParam) {
      body.questionId = questionIdParam;
    }

    if (formValues.selectedQueryRouting !== 'auto') {
      body.forcedQueryRoute = type ?? formValues.selectedQueryRouting;
    }

    try {
      const response = await generateAnswer(body).unwrap();
      updateAlbusResponse(response);
    } catch (error) {
      toast.error('Oops, something went wrong. Try again!');
    }
  }

  return (
    <View
      isFreePlan={!planInfo.hasSubscription}
      formValues={formValues}
      queryRoutingOptions={queryRoutingOptions}
      handleChange={handleChange}
      handleSubmit={CreateAnswer}
      chatSelected={!!selectedChat}
      handleOpenPromptIdeasPage={handleOpenPromptIdeasPage}
      isOnBoarding={isOnBoarding}
      wikiPromptIdeas={wikiPromptIdeas}
    />
  );
};

AskQueryInput.propTypes = {
  selectedChat: PropTypes.shape({
    promptId: PropTypes.string,
    title: PropTypes.string,
    parentTs: PropTypes.string
  }),
  handleSelectChat: PropTypes.func.isRequired,
  setConversation: PropTypes.func.isRequired,
  setShowPromptIdeas: PropTypes.func.isRequired,
  wikiPromptIdeas: PropTypes.array
};

export default AskQueryInput;
