import { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddSourceButtonUI } from '../../Components/AddSourceButton';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import ChannelPicker from '../ChannelPicker';
import { useFetchChannelFiltersQuery } from 'redux/services/licenses';
import { useAddSourcesMutation } from 'redux/services/integrations';
import { CHANNEL_TYPE, DOCUMENT_TYPE, PROVIDERS } from 'utils/constants';

const AddSlackSources = ({ showUpdateModal, teamIdFromProps, clickCallback }) => {
  const [channelsToBeAdded, setChannelsToBeAdded] = useState([]);
  const [showChannelPicker, setShowChannelPicker] = useState(false);
  const [showReinstallModal, setShowReinstallModal] = useState(false);
  const { teamId } = useParams();
  const { data: channels = [], isFetching: isChannelsFetching } = useFetchChannelFiltersQuery(
    {
      origin: 'integration',
      teamId: teamId || teamIdFromProps
    },
    { skip: !teamId && !teamIdFromProps }
  );

  const [addSources, { isLoading }] = useAddSourcesMutation();
  const formattedChannels = channels
    .filter((c) => c.id !== 'all')
    .map((c) => ({
      label: c.name,
      name: c.name,
      value: c.id,
      type: c.type,
      isAdded: c.isAdded
    }))
    .sort((a, b) => {
      const nameComparison = a.name.localeCompare(b.name);
      if (a.isAdded === b.isAdded) {
        return nameComparison;
      }
      return a.isAdded ? 1 : -1;
    });

  const handleConfirm = async () => {
    const documents = channelsToBeAdded.map((doc) => ({
      provider: PROVIDERS.SLACK,
      documentId: doc.value,
      documentName: doc.name,
      type:
        doc.type === CHANNEL_TYPE.PRIVATE ? DOCUMENT_TYPE.SLACK_PRIVATE : DOCUMENT_TYPE.SLACK_PUBLIC
    }));

    const payload = {
      body: { documents },
      provider: PROVIDERS.SLACK,
      params: { teamId: teamId || teamIdFromProps }
    };

    try {
      const response = await addSources(payload);
      if (response?.data?.ok) {
        setChannelsToBeAdded([]);
        setShowChannelPicker(false);
        clickCallback?.();
      }
    } catch (error) {
      toast.error('Oops, something went wrong. Try again!');
    }
  };

  return (
    <>
      <DeleteWarningModal
        open={showReinstallModal}
        onClose={() => setShowReinstallModal(false)}
        header={'Update Albus to add sources'}
        body='The Slack connection for Albus is due an important update. Once you update, you can add Slack channels to your company wiki.'
        title=' '
        handleCancel={() => setShowReinstallModal(false)}
        handleDelete={() =>
          window.open(
            `${import.meta.env.VITE_API_URL}/install?redirect=/integrations/slack/${teamId}`,
            '_self'
          )
        }
        removeText='Update'
        buttonColor='teal'
      />

      {showChannelPicker && (
        <ChannelPicker
          isLoading={isLoading}
          opened={showChannelPicker}
          setOpened={setShowChannelPicker}
          channels={formattedChannels}
          channelsToBeAdded={channelsToBeAdded}
          setChannelsToBeAdded={setChannelsToBeAdded}
          handleConfirm={handleConfirm}
          isChannelsFetching={isChannelsFetching}
        />
      )}
      <AddSourceButtonUI
        handleAddSource={() => {
          showUpdateModal ? setShowReinstallModal(true) : setShowChannelPicker(true);
        }}
      />
    </>
  );
};

AddSlackSources.defaultProps = {
  showUpdateModal: false,
  teamIdFromProps: '',
  clickCallback: () => null
};

AddSlackSources.propTypes = {
  showUpdateModal: PropTypes.bool,
  teamIdFromProps: PropTypes.string,
  clickCallback: PropTypes.func
};

export default AddSlackSources;
