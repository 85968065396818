import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Button,
  Flex,
  Loader,
  Popover,
  Radio,
  Stack,
  Tabs,
  TagsInput,
  Text,
  Title,
  Tooltip
} from '@mantine/core';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import { useAddSourcesMutation } from 'redux/services/integrations';
import { useLazyIsValidSitemapQuery } from 'redux/services/sitemap';
import TABS from '../../../constants';
import { truncateName } from 'pages/Integrations/utils';
import styles from './styles.module.scss';

const InputTab = (props) => {
  const { handler, setHandler, addSourceSuccessCallback, teamIdFromProps } = props;
  const addSourceUrl = new URL(window.location.href);
  const teamId = addSourceUrl.searchParams.get('teamId') || teamIdFromProps;
  const [error, setError] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [urlsData, setUrlsData] = useState([]);
  const [sitemapUrlsData, setSitemapUrlsData] = useState([]);
  const [crawlDepthValue, setCrawlDepthValue] = useState('0');
  const [validatingNewUrl, setValidatingNewUrl] = useState(false);
  const [erroredUrls, setErroredUrls] = useState([]);
  const navigate = useNavigate();
  const [addSources, { isLoading }] = useAddSourcesMutation();
  const [isValidSitemap, { isLoading: validityCheckLoading }] = useLazyIsValidSitemapQuery();

  const displayErroredUrls = erroredUrls
    .slice(0, 3)
    .map((url) => truncateName(url, 35))
    .join(', ');
  const remainingCount = erroredUrls.length - 3;
  const remainingErroredUrls = erroredUrls
    .slice(3)
    .map((url) => truncateName(url, 50))
    .join(', ');

  const placeholder =
    urlsData.length + sitemapUrlsData.length === 0
      ? handler == TABS.WEBSITE
        ? 'https://example.com, https://example.in, https://example.org...'
        : 'https://example.com/sitemap.xml, https://example.in/sitemap.xml...'
      : '';

  async function handleAddWebSource() {
    setErroredUrls([]);
    const webDocuments = urlsData.map((url) => ({
      documentId: url,
      url,
      type: 'website',
      depth: +crawlDepthValue
    }));
    const sitemapDocuments = sitemapUrlsData.map((url) => ({
      documentId: url,
      url,
      type: 'sitemap'
    }));
    if (
      sitemapUrlsData.length > 0 ||
      (urlsData.length > 0 && handler === TABS.SITEMAP) ||
      showAddModal
    ) {
      const payload = {
        body: {
          documents: [...webDocuments, ...sitemapDocuments]
        },
        params: { teamId },
        provider: 'web'
      };

      const { data, error } = await addSources(payload);
      if (!isLoading && data?.ok) {
        setShowAddModal(false);
        addSourceSuccessCallback
          ? addSourceSuccessCallback()
          : navigate(`/integrations/web/${teamId}`);
      } else if (error) {
        setError(error?.message);
      }
    } else {
      setShowAddModal(true);
    }
  }

  async function validateNewUrl(url) {
    const newUrl = url.trim();
    const formattedUrl =
      !newUrl.startsWith('https://') && !newUrl.startsWith('http://')
        ? 'https://' + newUrl
        : newUrl;

    const { data: validationData, error: validationError } = await isValidSitemap({
      url: formattedUrl
    });

    if (validationError) {
      setErroredUrls((prevUrls) => [...prevUrls, newUrl]);
      return null;
    } else if (validationData?.isSitemap) {
      return { url: formattedUrl, isSitemap: true };
    } else {
      return { url: formattedUrl, isSitemap: false };
    }
  }

  async function handleTagsInputChange(value) {
    setErroredUrls([]);
    setError(null);
    if (value.length > urlsData.length + sitemapUrlsData.length) {
      // Determine newly added URLs
      const newValues = value.filter(
        (val) => !urlsData.includes(val) && !sitemapUrlsData.includes(val)
      );
      setValidatingNewUrl(true);
      for (const newUrl of newValues) {
        const validUrl = await validateNewUrl(newUrl);
        if (validUrl) {
          if (validUrl.isSitemap) {
            setSitemapUrlsData((prevUrls) => [...prevUrls, validUrl?.url]);
          } else if (!validUrl.isSitemap) {
            setUrlsData((prevUrls) => [...prevUrls, validUrl?.url]);
          }
        }
      }
      setValidatingNewUrl(false);
    } else {
      // Removing an existing URL/handle any other change
      setUrlsData(value.filter((val) => !sitemapUrlsData.includes(val)));
      setSitemapUrlsData(value.filter((val) => !urlsData.includes(val)));
    }
  }

  function handleTabChange(value) {
    if (handler === value) {
      return;
    }
    setHandler(value);
    setError(null);
    setUrlsData([]);
    setSitemapUrlsData([]);
  }

  function modalBody() {
    return (
      <div>
        <TagsInput
          data={[]}
          splitChars={[',', ' ', '|']}
          placeholder={placeholder}
          radius='sm'
          value={urlsData}
          onChange={handleTagsInputChange}
          maxTags={10}
          error={error}
          allowDuplicates={false}
          rightSection={
            validatingNewUrl && <Loader size={18} className={styles.validateUrlLoader} />
          }
        />
        <Radio.Group
          value={crawlDepthValue}
          onChange={setCrawlDepthValue}
          defaultValue='webpage as source'
          mt={20}
        >
          <Stack>
            <Radio color='#1CA555' value={'0'} label='Add this web page as a source' />
            <Radio
              color='#1CA555'
              value={import.meta.env.VITE_WEBSITE_CRAWL_DEPTH}
              label='Add this web page and underlying web pages as sources'
            />
          </Stack>
        </Radio.Group>
      </div>
    );
  }

  return (
    <div>
      <Title className={styles.addSourceTitle}>Train Albus on the web</Title>
      <Tabs
        value={handler}
        onChange={(value) => handleTabChange(value)}
        radius='xs'
        variant='pills'
        color='#0A1D43'
        classNames={{ tab: styles.tab }}
      >
        <Tabs.List>
          <Tabs.Tab value={TABS.WEBSITE}>Web pages</Tabs.Tab>
          <Tabs.Tab value={TABS.SITEMAP}>Sitemaps</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={TABS.WEBSITE}>
          <div className={classNames(styles.tabPanelContainer, styles.webPageInput)}>
            <Popover
              position='bottom-start'
              withArrow
              shadow='md'
              arrowOffset={10}
              disabled={showAddModal}
              classNames={{
                dropdown: classNames({
                  [styles.popoverDropdown]: error || (!urlsData.length && !sitemapUrlsData.length),
                  [styles.amberPopoverDropdown]:
                    !error && (urlsData.length || sitemapUrlsData.length)
                }),
                arrow: classNames({
                  [styles.popoverArrow]: error || (!urlsData.length && !sitemapUrlsData.length),
                  [styles.amberPopoverArrow]: !error && (urlsData.length || sitemapUrlsData.length)
                })
              }}
              opened={
                handler === TABS.WEBSITE && ((erroredUrls.length > 0 && !validatingNewUrl) || error)
              }
              onClose={() => {
                setErroredUrls([]);
                setError(null);
              }}
            >
              <Popover.Target>
                <div className={styles.inputContainer}>
                  <TagsInput
                    data={[]}
                    splitChars={[',', ' ', '|']}
                    placeholder={placeholder}
                    radius='sm'
                    value={[...urlsData, ...sitemapUrlsData]}
                    onChange={handleTagsInputChange}
                    maxTags={10}
                    allowDuplicates={false}
                    classNames={{ input: styles.urlInput, pill: styles.urlInputPill }}
                  />
                  <Flex className={styles.buttonAndLoaderContainer}>
                    {validatingNewUrl && <Loader size={18} className={styles.validateUrlLoader} />}
                    <Button
                      disabled={urlsData.length === 0 && sitemapUrlsData.length === 0}
                      onClick={handleAddWebSource}
                      className={classNames(styles.addButton, {
                        [styles.disabled]: !urlsData.length && !sitemapUrlsData.length
                      })}
                    >
                      Add
                    </Button>
                  </Flex>
                </div>
              </Popover.Target>
              <Popover.Dropdown>
                <Text size='sm'>
                  {erroredUrls.length > 0 ? (
                    <div>
                      {displayErroredUrls}
                      {remainingCount > 0 && (
                        <Tooltip
                          classNames={{ tooltip: styles.tooltipError }}
                          label={remainingErroredUrls}
                          position='bottom'
                        >
                          <span
                            style={{ cursor: 'pointer', fontWeight: '800' }}
                          >{` + ${remainingCount}`}</span>
                        </Tooltip>
                      )}
                      {erroredUrls.length > 1 ? ' are' : ' is'} invalid or
                      {erroredUrls.length > 1 ? ' do' : ' does'} not exist.
                    </div>
                  ) : (
                    error
                  )}
                </Text>
              </Popover.Dropdown>
            </Popover>
          </div>
          <Text c='black' size='xs' mt={5}>
            Note: A maximum of 10 URLs can be included at once.
          </Text>
        </Tabs.Panel>

        <Tabs.Panel value={TABS.SITEMAP}>
          <div className={classNames(styles.tabPanelContainer, styles.sitemapInput)}>
            <Popover
              position='bottom-start'
              withArrow
              shadow='md'
              disabled={showAddModal}
              arrowOffset={10}
              classNames={{
                dropdown: classNames({
                  [styles.popoverDropdown]: error || (!urlsData.length && !sitemapUrlsData.length),
                  [styles.amberPopoverDropdown]:
                    !error && (urlsData.length || sitemapUrlsData.length)
                }),
                arrow: classNames({
                  [styles.popoverArrow]: error || (!urlsData.length && !sitemapUrlsData.length),
                  [styles.amberPopoverArrow]: !error && (urlsData.length || sitemapUrlsData.length)
                })
              }}
              opened={
                handler === TABS.SITEMAP && ((erroredUrls.length > 0 && !validatingNewUrl) || error)
              }
              onClose={() => {
                setErroredUrls([]);
                setError(null);
              }}
            >
              <Popover.Target>
                <div className={styles.inputContainer}>
                  <TagsInput
                    data={[]}
                    splitChars={[',', ' ', '|']}
                    placeholder={placeholder}
                    radius='sm'
                    value={[...urlsData, ...sitemapUrlsData]}
                    onChange={handleTagsInputChange}
                    maxTags={10}
                    allowDuplicates={false}
                    classNames={{ input: styles.urlInput, pill: styles.urlInputPill }}
                  />
                  <Flex className={styles.buttonAndLoaderContainer}>
                    {validatingNewUrl && <Loader size={18} className={styles.validateUrlLoader} />}
                    <Button
                      disabled={urlsData.length === 0 && sitemapUrlsData.length === 0}
                      onClick={handleAddWebSource}
                      className={classNames(styles.addButton, {
                        [styles.disabled]: !urlsData.length && !sitemapUrlsData.length
                      })}
                    >
                      Add
                    </Button>
                  </Flex>
                </div>
              </Popover.Target>
              <Popover.Dropdown>
                <Text size='sm'>
                  {erroredUrls.length > 0 ? (
                    <div>
                      {displayErroredUrls}
                      {remainingCount > 0 && (
                        <Tooltip
                          classNames={{ tooltip: styles.tooltipError }}
                          label={remainingErroredUrls}
                          position='bottom'
                        >
                          <span
                            style={{ cursor: 'pointer', fontWeight: '800' }}
                          >{` + ${remainingCount}`}</span>
                        </Tooltip>
                      )}
                      {erroredUrls.length > 1 ? ' are' : ' is'} invalid or
                      {erroredUrls.length > 1 ? ' do' : ' does'} not exist.
                    </div>
                  ) : (
                    error
                  )}
                </Text>
              </Popover.Dropdown>
            </Popover>
          </div>
          <Text c='black' size='xs' mt={5}>
            Note: A maximum of 10 URLs can be included at once.
          </Text>
        </Tabs.Panel>
      </Tabs>

      {showAddModal && (
        <DeleteWarningModal
          open={showAddModal}
          onClose={() => setShowAddModal(false)}
          header='Add source'
          title=''
          body={modalBody()}
          handleCancel={() => {
            setShowAddModal(false);
            setError(null);
            setErroredUrls([]);
          }}
          handleDelete={!error && handleAddWebSource}
          removeText='Confirm'
          customClass={styles}
          disableRemove={isLoading || validityCheckLoading || error}
        />
      )}
    </div>
  );
};

InputTab.propTypes = {
  handler: PropTypes.string.isRequired,
  setHandler: PropTypes.func.isRequired,
  addSourceSuccessCallback: PropTypes.func.isRequired,
  teamIdFromProps: PropTypes.string.isRequired
};

export default InputTab;
