import { useState } from 'react';
import { Modal, Radio, Group, Button, Title, Flex } from '@mantine/core';
import { bool, func, string } from 'prop-types';
import styles from './auto-train.module.scss';
import classNames from 'classnames';

const AutoTrainModal = ({ opened, onClose, onConfirm, sourceName, defaultValue, disabled }) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue || 'none');

  return (
    <Modal
      centered
      opened={opened}
      size={550}
      onClose={onClose}
      title={<Title className={styles.title}>Auto-retrain</Title>}
    >
      <Title className={styles.question} order={6}>
        How often do you want Albus to automatically retrain on {sourceName} ?
      </Title>

      <Flex>
        <Radio.Group value={selectedOption} onChange={setSelectedOption} size='sm' pl={8} mb={50}>
          <Group mt='xs'>
            <Radio
              classNames={{
                label: styles.label
              }}
              className={styles.radio}
              color='green'
              value='none'
              label='Never'
            />
            <Radio
              classNames={{
                label: styles.label
              }}
              className={styles.radio}
              color='green'
              value='daily'
              label='Daily'
            />
            <Radio
              classNames={{
                label: styles.label
              }}
              className={styles.radio}
              color='green'
              value='weekly'
              label='Weekly'
            />
            <Radio
              classNames={{
                label: styles.label
              }}
              className={styles.radio}
              color='green'
              value='monthly'
              label='Monthly'
            />
          </Group>
        </Radio.Group>
      </Flex>
      <Flex align={'center'} justify={'flex-end'} style={{ width: '100%', marginTop: '30px' }}>
        <Button
          variant='outline'
          styles={{
            root: {
              color: '#5E5873',
              border: '1px solid #5E5873',
              height: 42
            }
          }}
          onClick={onClose}
          className={styles.button}
          disabled={disabled}
        >
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm(selectedOption)}
          className={classNames(styles.button, 'primaryBtn')}
          disabled={disabled}
        >
          Confirm
        </Button>
      </Flex>
    </Modal>
  );
};

AutoTrainModal.defaultProps = {
  disabled: false
};

AutoTrainModal.propTypes = {
  opened: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  sourceName: string.isRequired,
  defaultValue: string,
  disabled: bool
};
export default AutoTrainModal;
