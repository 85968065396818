import PropTypes from 'prop-types';
import { Anchor, Button, Flex, Text } from '@mantine/core';
import { maybePluralize } from 'utils/utilities';
import styles from './styles.module.scss';

const View = (props) => {
  const {
    status,
    setIsModalOpen,
    setIsUnassignConfirmModalOpen,
    setIsApproveModalOpen,
    setIsRevokeInvitationConfirmModalOpen,
    setIsAssignConfirmModalOpen,
    unassignedLicenses,
    selectedUsers,
    resetData,
    setIsDenyConfirmModalOpen
  } = props;

  const labelText = status === 'invited' ? 'invitation' : 'user';
  return (
    <div className={styles.actionsContainer}>
      <Flex className={styles.resetContainer}>
        <Text mr={20} className={styles.content}>
          {selectedUsers.length} {maybePluralize(selectedUsers.length, labelText)} selected
        </Text>
        <Anchor
          component='a'
          className={styles.resetBtn}
          underline={true}
          onClick={() => {
            resetData([]);
          }}
        >
          Reset
        </Anchor>
      </Flex>
      <Flex className={styles.buttonsGroupContainer}>
        {status === 'requested' && (
          <>
            <Anchor
              className={styles.buttonRed}
              component='button'
              underline={false}
              onClick={() => {
                setIsDenyConfirmModalOpen(true);
              }}
            >
              Deny {maybePluralize(selectedUsers.length, 'request')}
            </Anchor>
            <Button
              ml={20}
              mr={20}
              className={styles.primaryBtn}
              onClick={() => {
                setIsApproveModalOpen(true);
              }}
            >
              Approve {maybePluralize(selectedUsers.length, 'License')}
            </Button>
          </>
        )}
        {status === 'unassigned' && (
          <Button
            ml={20}
            mr={20}
            className={styles.primaryBtn}
            onClick={() => {
              if (unassignedLicenses < selectedUsers.length) {
                setIsModalOpen(true);
              } else {
                setIsAssignConfirmModalOpen(true);
              }
            }}
          >
            Assign Licenses
          </Button>
        )}
        {status === 'assigned' && (
          <Button
            ml={20}
            mr={20}
            className={styles.primaryBtn}
            onClick={() => {
              setIsUnassignConfirmModalOpen(true);
            }}
          >
            Unassign Licenses
          </Button>
        )}
        {status === 'invited' && (
          <Button
            ml={20}
            mr={20}
            className={styles.buttonRed}
            onClick={() => setIsRevokeInvitationConfirmModalOpen(true)}
          >
            Revoke invitations
          </Button>
        )}
      </Flex>
    </div>
  );
};

View.propTypes = {
  status: PropTypes.string.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  unassignedLicenses: PropTypes.number.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  resetData: PropTypes.func.isRequired,
  setIsDenyConfirmModalOpen: PropTypes.func.isRequired,
  setIsApproveModalOpen: PropTypes.func.isRequired,
  setIsAssignConfirmModalOpen: PropTypes.func.isRequired,
  setIsUnassignConfirmModalOpen: PropTypes.func.isRequired,
  setIsRevokeInvitationConfirmModalOpen: PropTypes.func.isRequired
};

export default View;
