import PropTypes from 'prop-types';
import { Modal, MultiSelect, Flex, Button } from '@mantine/core';
import classNames from 'classnames';
import styles from '../Styles.module.scss';

const Form = (props) => {
  const { open, formValues, users, handleChange, handleConfirm, handleClose, loading } = props;

  return (
    <Modal
      centered
      opened={open}
      size={633}
      classNames={{ inner: styles.modalBody }}
      title={<div className={styles.title}>Add teammates</div>}
      onClose={handleClose}
    >
      <form className={styles.formWrapper} onSubmit={handleConfirm}>
        <MultiSelect
          searchable
          label='Select teammates'
          data={users}
          value={formValues.members}
          classNames={{
            label: styles.label,
            input: styles.input,
            defaultValue: styles.defaultValue,
            option: styles.item
          }}
          onChange={(value) => handleChange('members', value)}
        />

        <Flex justify='flex-end' className={styles.buttonsWrapper}>
          <Button
            variant='outline'
            color='dark'
            classNames={{ root: styles.cancelButton, label: styles.buttomLabel }}
            disabled={loading}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            classNames={{
              root: classNames(styles.cancelButton, styles.confirmButton),
              label: styles.buttomLabel,
              defaultValue: styles.defaultValue
            }}
            type='submit'
            disabled={!formValues.members.length || loading}
          >
            Confirm
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  formValues: PropTypes.shape({
    members: PropTypes.arrayOf(PropTypes.string.isRequired)
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default Form;
