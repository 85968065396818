import { Flex } from '@mantine/core';
import { arrayOf, func, shape, string } from 'prop-types';
import PersonListItem from './PersonaListItem';
import styles from './styles.module.scss';

const PersonaList = ({
  personasList,
  selectedPersona,
  focusedPersona,
  handleSelectBtnClick,
  handleTileClick,
  handleEditPersonaClick,
  handleDeletePersonaClick
}) => {
  return (
    <Flex className={styles.list}>
      {personasList.map((persona) => (
        <PersonListItem
          key={persona._id}
          persona={persona}
          selected={selectedPersona === persona._id}
          focused={focusedPersona?._id === persona._id}
          handleTileClick={handleTileClick}
          handleSelectBtnClick={handleSelectBtnClick}
          handleEditPersonaClick={handleEditPersonaClick}
          handleDeletePersonaClick={handleDeletePersonaClick}
        />
      ))}
    </Flex>
  );
};

PersonaList.propTypes = {
  personasList: arrayOf(
    shape({
      icon: string,
      name: string,
      _id: string
    })
  ).isRequired,
  selectedPersona: string.isRequired,
  focusedPersona: shape({ icon: string, name: string, _id: string }).isRequired,
  handleTileClick: func.isRequired,
  handleSelectBtnClick: func.isRequired,
  handleEditPersonaClick: func.isRequired,
  handleDeletePersonaClick: func.isRequired
};

export default PersonaList;
