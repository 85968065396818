import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Flex, Text } from '@mantine/core';
import { useParams, useNavigate } from 'react-router';
import Layout from 'components/Layout';
import SearchCapabilities from '../SearchCapabilities';
import CompanyAdmins from '../CompanyAdmins';
import Personas from 'pages/ControlPanel/Personas';
import Connections from '../Connections';
import General from '../General';
import MyAccount from '../MyAccount';
import AutoAnswer from '../AutoAnswer';
import Automations from '../Automations';
import CustomApis from '../CustomApis';
import { eventTracking } from 'components/EventAnalyticsProvider/utils';
import { settingsTitleProvider } from '../utils';
import { NAVBAR_ROUTES } from 'utils/constants';
import { SETTINGS_TABS } from '../constants';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from './styles.module.scss';

const SettingsRouter = () => {
  const { tab } = useParams();
  const [subTab, setSubTab] = useState();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user._id && Object.values(SETTINGS_TABS).includes(tab)) {
      eventTracking('User opened dashboard', user, `Settings > ${tab}`);
    }
  }, [user]);

  const tabComponents = {
    // add new tab here
    [SETTINGS_TABS.GENERAL]: <General />,
    [SETTINGS_TABS.MY_ACCOUNT]: <MyAccount />,
    [SETTINGS_TABS.SEARCH_CAPABILITIES]: <SearchCapabilities />,
    [SETTINGS_TABS.COMPANY_ADMIN]: <CompanyAdmins />,
    [SETTINGS_TABS.PERSONAS]: <Personas />,
    [SETTINGS_TABS.CONNECTIONS]: <Connections />,
    [SETTINGS_TABS.AUTO_ANSWER]: <AutoAnswer />,
    [SETTINGS_TABS.AUTOMATIONS]: <Automations />,
    [SETTINGS_TABS.CUSTOM_APIS]: <CustomApis subTab={subTab} setSubTab={setSubTab} />
  };

  function title() {
    if (subTab) {
      return (
        <>
          Settings &gt; {settingsTitleProvider(tab)} &gt;
          <strong className='capitalize-first-letter'> {subTab}</strong>
        </>
      );
    }
    return (
      <>
        Settings &gt; <strong>{settingsTitleProvider(tab)}</strong>
      </>
    );
  }

  function handleBack() {
    if (subTab) {
      setSubTab(null);
      navigate(SETTINGS_TABS.ROUTE);
      return;
    }
    navigate(NAVBAR_ROUTES.SETTINGS);
  }

  return (
    <Layout
      pageTitle={
        <Flex align='center'>
          <Button className={styles.navigateBackButton} onClick={handleBack} variant='default'>
            <SVGIcon name={ICONS_LIST.LEFT_ARROW} height={18} width={18} />
          </Button>

          <Text className={styles.providerTitle}>{title()}</Text>
        </Flex>
      }
    >
      <div
        className={
          tab === SETTINGS_TABS.AUTOMATIONS || tab === SETTINGS_TABS.CUSTOM_APIS
            ? styles.automationsTab
            : styles.tab
        }
      >
        {tabComponents[tab]}
      </div>
    </Layout>
  );
};

export default SettingsRouter;
