import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AuthForm from '../AuthForm';
import { STEPS } from 'pages/Auth/constants';
import InvitedByFormHeader from '../InvitedByFormHeader';

const JoinViaLink = (props) => {
  const {
    formData,
    isSubmitting,
    setFormData,
    setIsSubmitting,
    signupTrigger,
    setStep,
    allowedDomains,
    invitedBy,
    code,
    id
  } = props;

  const [error, setError] = useState('');

  useEffect(() => {
    if (formData.email) {
      setError('');
    }
  }, [formData.email, handleSubmitCTA]);

  function checkValid(values) {
    const userDomain = values.email.split('@')[1];
    if (allowedDomains.length === 0 || allowedDomains.includes(userDomain)) {
      return true;
    } else {
      const allowedDomainList = allowedDomains.map((domain) => `@${domain}`).join(', ');
      setError(`Please add ${allowedDomainList} domain email`);
      return false;
    }
  }

  async function handleSubmitCTA(values) {
    setFormData(values);
    if (checkValid(values)) {
      setIsSubmitting(true);
      const { data, error } = await signupTrigger({
        provider: 'email',
        email: values.email,
        code,
        id
      });
      if (data?.ok) {
        toast.success('Email sent successfully');
        setStep(STEPS.EMAIL_VERIFICATION);
      } else if (error) {
        setTimeout(() => {
          window.open(error.redirectTo, '_self');
        }, 3000);
      }
    } else {
      toast.error('Invalid email domain');
    }
    setIsSubmitting(false);
  }

  return (
    <AuthForm
      header={<InvitedByFormHeader invitedBy={invitedBy} allowedDomains={allowedDomains} />}
      initialValues={formData}
      email
      error={error}
      hideEmailLabel
      ctaLabel='Continue'
      isSubmitting={isSubmitting}
      handleSubmitCTA={(values) => handleSubmitCTA(values)}
    />
  );
};

JoinViaLink.propTypes = {
  formData: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFormData: PropTypes.func.isRequired,
  setIsSubmitting: PropTypes.func.isRequired,
  signupTrigger: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  allowedDomains: PropTypes.array.isRequired,
  invitedBy: PropTypes.string.isRequired,
  code: PropTypes.string,
  id: PropTypes.string
};

JoinViaLink.defaultProps = {
  code: '',
  id: ''
};

export default JoinViaLink;
