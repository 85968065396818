export const embedCode = () => {
  return `curl --location '${import.meta.env.VITE_API_URL}/chat/completions/custom' 
--header 'Authorization: [INSERT_YOUR_API_KEY_HERE]' 
--header 'Content-Type: application/json' 
--data '{
    "prompt": "[INSERT_YOUR_PROMPT_HERE]"
}'`;
};

export const pollingCurlCommand = () => {
  return `curl --location '[INSERT_THE_POLLING_URL_HERE]' 
--header 'Authorization: [INSERT_YOUR_API_KEY_HERE]' 
--header 'Content-Type: application/json'
}`;
};

export const feedbackCurlCommand = () => {
  return `curl --location --request PATCH '[INSERT_THE_VOTING_URL_HERE]' 
--header 'Authorization: [INSERT_YOUR_API_KEY_HERE]' 
--header 'Content-Type: application/json' 
--data '{
    "vote": [INSERT_FEEDBACK_HERE] // 1 for upvote, -1 for downvote
  }'`;
};
