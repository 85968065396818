import { ColorInput } from '@mantine/core';
import styles from './styles.module.scss';

const ColorInputTheme = ColorInput.extend({
  classNames: {
    input: styles.input,
    label: styles.label,
    section: styles.section,
    colorPreview: styles.colorPreview
  }
});

export default ColorInputTheme;
