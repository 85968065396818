import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, Image, Divider, Button, Tooltip, Avatar } from '@mantine/core';
import { truncateName } from 'pages/Integrations/utils';
import FallbackProfilePic from 'assets/profile.svg';
import { maybePluralize } from 'utils/utilities';
import { capitalizeInitials } from 'utils/formatters';
import styles from './styles.module.scss';

const View = (props) => {
  const { header, companiesList, companiesCount, handleJoin } = props;

  const THREE = 3;
  const [displayCount, setDisplayCount] = useState(THREE);
  const isMoreThanThree = companiesCount > THREE;

  const handleViewMore = () => {
    setDisplayCount(companiesList.length);
  };

  const handleViewLess = () => {
    setDisplayCount(THREE);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerLable}>
        <Text className={styles.text}>{header}</Text>
      </div>

      <div>
        <div className={styles.wrapper}>
          {companiesList?.slice(0, displayCount).map((company, index, array) => (
            <React.Fragment key={index}>
              <div className={styles.companyWrapper}>
                <div className={styles.companyContainer}>
                  {company?.organisation?.logo ? (
                    <Image className={styles.companyProfile} src={company.organisation.logo} />
                  ) : (
                    <Avatar color='blue' mr='10' size={42} alt='profile pic' radius='md'>
                      {capitalizeInitials(company.organisation.name)}
                    </Avatar>
                  )}
                  <div className={styles.companyInfo}>
                    <Tooltip
                      disabled={company.organisation.name.length <= 25}
                      label={company.organisation.name}
                    >
                      <Text className={styles.companyName}>
                        {truncateName(company.organisation.name, 25)}
                      </Text>
                    </Tooltip>
                    <div className={styles.profiles}>
                      {company.users?.map((user, index) => (
                        <Tooltip key={index} w='fit-content' label={user.name}>
                          <Image
                            key={index}
                            className={styles.profile}
                            src={user.photo || FallbackProfilePic}
                          />
                        </Tooltip>
                      ))}
                      <Text className={styles.profileCount}>
                        {company.usersCount} {maybePluralize(company.usersCount, 'member')}
                      </Text>
                    </div>
                  </div>
                </div>
                <Button
                  className={styles.button}
                  onClick={() => handleJoin(company.organisation.id, company.inviteMongoId)}
                >
                  Join
                </Button>
              </div>
              {(isMoreThanThree || index !== array.length - 1) && (
                <Divider className={styles.divider} mt={18} mb={16} size='sm' />
              )}
            </React.Fragment>
          ))}
        </div>
        {isMoreThanThree &&
          (displayCount < companiesList.length ? (
            <span onClick={handleViewMore} className={styles.viewButton}>
              View {companiesCount - THREE} more{' '}
              {companiesCount - THREE > 1 ? 'companies' : 'company'}
            </span>
          ) : (
            <span onClick={handleViewLess} className={styles.viewButton}>
              View less
            </span>
          ))}
      </div>
    </div>
  );
};

View.propTypes = {
  header: PropTypes.string.isRequired,
  companiesList: PropTypes.array.isRequired,
  companiesCount: PropTypes.number.isRequired,
  handleJoin: PropTypes.func.isRequired
};

export default View;
