import PropTypes from 'prop-types';
import { Modal, Button, Image } from '@mantine/core';
import AddOnIcon from 'assets/components/add-on-image.svg';
import styles from './styles.module.scss';

const View = (props) => {
  const { open, onClose, handleCancel, handleConfirm, disabled, loading } = props;
  const questions = import.meta.env.VITE_ADD_ON_QUESTIONS_COUNT;

  return (
    <Modal
      centered
      opened={open}
      size={550}
      closeOnClickOutside={false}
      onClose={disabled ? undefined : onClose}
      title='Buy add-on questions'
      classNames={{
        title: styles.title
      }}
    >
      <div className={styles.byAddOnModalBody}>
        <div>
          <Image src={AddOnIcon} alt='add on icon' w={134} />
        </div>
        <div className={styles.priceDetailsText}>
          {`When you buy ${questions} questions, you will be charged a one-time fee of $4.99. Are you sure you want to continue?`}
        </div>
        <ul className={styles.listWrapper}>
          <li>
            {`To unlock access for your organization right now, you can add on a pack of ${questions} questions
            at $4.99.`}
          </li>
          <li>
            {`These ${questions} questions will be utilized when your organization crosses the monthly limit.`}
          </li>
          <li>Questions purchased as add-ons carry over to the next month and never expire.</li>
        </ul>
      </div>
      <div className={styles.footerWrapper}>
        <Button
          variant='outline'
          onClick={handleCancel}
          disabled={disabled}
          classNames={{
            label: styles.cancelBtnLabel,
            root: styles.cancelBtnRoot
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={disabled}
          loading={loading}
          classNames={{
            label: styles.confirmBtnLabel,
            root: styles.confirmBtnRoot
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

View.defaultProps = {
  loading: false,
  disabled: false
};

View.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};

export default View;
