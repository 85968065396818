import { useSelector } from 'react-redux';
import { useFetchUsersQuery } from 'redux/services/users';
import View from './View';

const QuestionsExhaustedDialogBox = () => {
  const { user, loading: userLoading } = useSelector((state) => state.user);
  const { planInfo } = useSelector((state) => state.plan);
  const { data: { admins = [] } = {} } = useFetchUsersQuery(undefined, {
    skip: userLoading || user.isAdmin
  });

  return <View isAdmin={user.isAdmin} admins={admins} billingDate={planInfo.billingDate} />;
};

export default QuestionsExhaustedDialogBox;
