import { Title } from '@mantine/core';
import styles from './styles.module.scss';

const TitleTheme = Title.extend({
  classNames: {
    root: styles.title
  }
});

export default TitleTheme;
