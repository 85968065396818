import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Loader } from '@mantine/core';
import Message from '../Message';
import styles from './styles.module.scss';

const View = (props) => {
  const {
    messages = [],
    conversationLoading,
    handleSelectChat,
    setConversation,
    hideChats,
    answerCompletedCallback
  } = props;
  const scrollableDivRef = useRef(null);

  // This effect runs when the component mounts and whenever new content is added.
  // It scrolls the div to the bottom.
  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
    }
  });

  if (conversationLoading) {
    return (
      <div className={styles.loadingState}>
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.conversationWrapper, { [styles.hiddenChatView]: hideChats })}
      ref={scrollableDivRef}
    >
      {messages.map((message, index) => (
        <Message
          {...message}
          key={message.promptId || index}
          handleSelectChat={handleSelectChat}
          setConversation={setConversation}
          answerCompletedCallback={answerCompletedCallback}
        />
      ))}
    </div>
  );
};

View.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      promptId: PropTypes.string,
      avatar: PropTypes.string,
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      role: PropTypes.string.isRequired,
      citedSources: PropTypes.array,
      completedUsing: PropTypes.string
    })
  ).isRequired,
  conversationLoading: PropTypes.bool.isRequired,
  handleSelectChat: PropTypes.func.isRequired,
  setConversation: PropTypes.func.isRequired,
  hideChats: PropTypes.bool.isRequired,
  answerCompletedCallback: PropTypes.func.isRequired
};

export default View;
