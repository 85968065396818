import { array } from 'prop-types';
import { Table } from '@mantine/core';

const Header = ({ headers }) => {
  return (
    <Table.Thead>
      <Table.Tr>
        {headers.map((title) => (
          <Table.Th key={title}>{title}</Table.Th>
        ))}
      </Table.Tr>
    </Table.Thead>
  );
};

Header.propTypes = {
  headers: array.isRequired
};

export default Header;
