import {
  ColorInput,
  Flex,
  Grid,
  Group,
  NumberInput,
  Radio,
  ScrollArea,
  Select,
  Textarea,
  TextInput
} from '@mantine/core';
import { shape } from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { POSITIONS, COLOR_INPUTS, THEMES } from '../data.jsx';

const AppearanceTab = ({ form }) => {
  return (
    <ScrollArea className={styles.scrollArea} scrollbarSize={4}>
      <div className={styles.container}>
        <div className={styles.title}>Appearance</div>
        <div className={styles.subTitle}>Customize your widget to match your brand.</div>
        <div className={styles.form}>
          <TextInput
            radius='sm'
            label='Widget name'
            classNames={{
              label: styles.inputLabel,
              root: styles.inputRoot,
              input: styles.inputText
            }}
            {...form.getInputProps('name')}
          />
          <TextInput
            radius='sm'
            label='Welcome message'
            classNames={{
              label: styles.inputLabel,
              root: styles.inputRoot,
              input: styles.inputText
            }}
            {...form.getInputProps('welcomeMessage')}
          />
          <Textarea
            radius='sm'
            label='Bot message'
            classNames={{
              label: styles.inputLabel,
              root: styles.inputRoot,
              input: styles.botMessageInputText
            }}
            {...form.getInputProps('botMessage')}
          />
          <Select
            radius='sm'
            data={Object.values(POSITIONS)}
            label='Widget position'
            allowDeselect={false}
            classNames={{
              label: styles.inputLabel,
              root: styles.inputRoot,
              input: styles.inputText,
              option: styles.dropdownItem
            }}
            {...form.getInputProps('position')}
          />
          <Flex gap={16} w={380} justify='space-between'>
            <NumberInput
              rightSection={<span>px</span>}
              label='Widget width'
              defaultValue={form.values.width}
              min={300}
              {...form.getInputProps('width')}
            />
            <NumberInput
              rightSection={<span>px</span>}
              label='Widget height'
              defaultValue={form.values.height}
              min={450}
              {...form.getInputProps('height')}
            />
          </Flex>
          <Radio.Group
            radius='sm'
            classNames={{
              label: styles.inputLabel,
              input: styles.inputText
            }}
            size='xs'
            label='Widget theme'
            value={form.values.theme.name}
            onChange={(value) => {
              form.setFieldValue('theme', THEMES[value.toUpperCase()]);
            }}
          >
            <Group mt='xs'>
              <Radio
                label='Light'
                value='light'
                classNames={{
                  radio: classnames({ [styles.radio]: form.values.theme.name === 'light' })
                }}
              />
              <Radio
                label='Dark'
                value='dark'
                classNames={{
                  radio: classnames({ [styles.radio]: form.values.theme.name === 'dark' })
                }}
              />
              <Radio
                label='Custom'
                value='custom'
                classNames={{
                  radio: classnames({ [styles.radio]: form.values.theme.name === 'custom' })
                }}
              />
            </Group>
          </Radio.Group>
          {form.values.theme.name === 'custom' && (
            <>
              <Grid className={styles.customThemeWrapper} gutter='6'>
                {COLOR_INPUTS.map((input) => (
                  <Grid.Col key={input.id} span={6} className={styles.column}>
                    <ColorInput
                      radius='sm'
                      classNames={{ input: styles.colorInput }}
                      leftSectionProps={{
                        style: { borderRight: '1px solid #e0e0e1' }
                      }}
                      label={input.label}
                      withEyeDropper={false}
                      value={form.values.theme[input.value]}
                      onChange={(value) => form.setFieldValue(`theme.${input.value}`, value)}
                    />
                  </Grid.Col>
                ))}
              </Grid>
            </>
          )}
        </div>
      </div>
    </ScrollArea>
  );
};

AppearanceTab.propTypes = {
  form: shape({}).isRequired
};

export default AppearanceTab;
