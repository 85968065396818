import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLazyGetDrivesQuery } from 'redux/services/google';
import Picker from './Picker';
import AddSourceButtonWithModal from '../../OtherIntegrations/AddSourceButtonWithModal';
import { AddSourceButtonUI } from '../../Components/AddSourceButton';

const Drives = () => {
  const [openModal, setOpenModal] = useState(false);
  const [drivesData, setDrivesData] = useState([]);
  const [getDrives, { isLoading: fetchingDrives }] = useLazyGetDrivesQuery();
  const { user } = useSelector((state) => state.user);
  const { provider } = useParams();
  const isProviderConnected = !!user?.connections?.find((conn) => provider === conn.provider);

  const handleButtonClick = async () => {
    const response = await getDrives();
    if (response?.isSuccess) {
      setDrivesData(response.data);
      setOpenModal(true);
    }
  };
  const handleCloseModal = () => setOpenModal(false);

  return (
    <div>
      {isProviderConnected ? (
        <AddSourceButtonUI
          handleAddSource={handleButtonClick}
          isLoading={fetchingDrives}
          isDrivesCta={true}
        />
      ) : (
        <AddSourceButtonWithModal provider={provider} isDrivesCta={true} />
      )}

      {openModal && (
        <Picker
          close={handleCloseModal}
          open={openModal}
          data={drivesData}
          isFetching={fetchingDrives}
        />
      )}
    </div>
  );
};

export default Drives;
