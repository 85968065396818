import { useEffect, useState } from 'react';
import { Text, Grid, Skeleton, Loader } from '@mantine/core';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebouncedValue, useMediaQuery } from '@mantine/hooks';
import { useFetchQuestionsQuery, useResolveEscalationMutation } from 'redux/services/questions';
import Filter from '../Filter';
import AnswerModal from '../AnswerModal';
import EmptyState from 'pages/Integrations/Connector/Components/EmptyState';
import Card from '../QuestionCard';
import { getDateForSelectedOption } from 'pages/Questions/utils';
import { DATE_DROPDOWN_VALUES, NAVBAR_ROUTES, QUESTIONS_PAGE_TABS } from 'utils/constants';
import styles from '../../styles.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const Tab = ({ setQuestionCount, tab, defaultSelectedQuestion }) => {
  const [filter, setFitler] = useState({
    page: 1,
    q: '',
    dateFitlerValue: DATE_DROPDOWN_VALUES.ALL,
    dateRange: {
      ...getDateForSelectedOption(DATE_DROPDOWN_VALUES.ALL)
    }
  });
  const screen767 = useMediaQuery('(max-width: 767px)');
  const screen1800 = useMediaQuery('(min-width: 1800px)');
  const [debouncedSearchTerm] = useDebouncedValue(filter.q, 500);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [resolveEscalation] = useResolveEscalationMutation();
  const [questions, setQuestions] = useState([]);

  const {
    data: { questions: questionsData, hasNext, totalAnswers } = { questions: [] },
    isLoading,
    isFetching
  } = useFetchQuestionsQuery(
    {
      page: filter.page,
      type: tab === QUESTIONS_PAGE_TABS.NEEDS_ATTENTION ? 'escalated' : 'others',
      origin: 'escalations',
      search: debouncedSearchTerm || undefined,
      dateRangeStart: filter?.dateRange?.start || undefined,
      dateRangeEnd: filter?.dateRange?.end || undefined,
      timezone: filter?.dateRange?.end ? dayjs.tz.guess() : undefined
    },
    {
      skip:
        filter?.dateFitlerValue === DATE_DROPDOWN_VALUES.CUSTOM &&
        (filter?.dateRange?.start === null || filter?.dateRange?.end == null)
    }
  );

  useEffect(() => {
    if (!isFetching) {
      if (filter.page > 1) {
        setQuestions((prev) => [...prev, ...questionsData]);
      } else {
        setQuestions(questionsData);
      }
    }
    if (tab === QUESTIONS_PAGE_TABS.NEEDS_ATTENTION) {
      setQuestionCount((prev) => ({ ...prev, escalatedCount: totalAnswers }));
    } else {
      setQuestionCount((prev) => ({ ...prev, nonEscalatedCount: totalAnswers }));
    }
  }, [questionsData]);

  useEffect(() => {
    // If defaultSelectedQuestion is present, then open the modal
    if (defaultSelectedQuestion) {
      setSelectedQuestion({
        id: defaultSelectedQuestion,
        showPrev: false,
        showNext: false
      });
      window.history.pushState(null, null, NAVBAR_ROUTES.QUESTIONS);
    }
  }, [defaultSelectedQuestion]);

  const handleResolve = async (payload, closeModal = true) => {
    const { answer, promptId, resolveQuestion } = payload;
    const response = await resolveEscalation({ body: { answer, resolveQuestion }, promptId });
    if (response?.data?.ok) {
      toast.success(
        closeModal ? `Resolved and moved to 'Everything else' tab` : 'Escalation Resolved'
      );
    }
    closeModal && setSelectedQuestion(null);
  };

  return (
    <>
      {selectedQuestion?.id ? (
        <AnswerModal
          opened={true}
          selectedQuestion={selectedQuestion}
          onClose={() => setSelectedQuestion(null)}
          tab={tab}
          handleResolve={handleResolve}
          handleNavigation={(i) => {
            setSelectedQuestion({
              id: questions[i]._id,
              prevIndex: i - 1,
              nextIndex: i + 1,
              showPrev: i > 0,
              showNext: i < questions.length - 1
            });
          }}
        />
      ) : null}
      <Filter filter={filter} setFilter={setFitler} />
      {tab === QUESTIONS_PAGE_TABS.NEEDS_ATTENTION ? (
        <Text size='xs' mt={20}>
          <b>Note:</b> when Albus answers a question incorrectly and a user clicks &apos;Ask admins
          for help,&apos; the question is displayed here. This can be from a public or private
          channel, group DM, or Albus&apos;s DM. When an admin answers or resolves the question, it
          is moved to the &apos;Everything else&apos; tab.
        </Text>
      ) : (
        <Text size='xs' mt={20}>
          <b>Note:</b> questions asked in public channels and the web dashboard are displayed here.
          Questions asked in private channels, group DMs and Albus&apos;s DM are only displayed here
          if a user clicks &apos;Ask admins for help&apos; and an admin answers or resolves the
          question.
        </Text>
      )}

      <div>
        {questions?.length ? (
          <div>
            <InfiniteScroll
              dataLength={questions.length}
              hasMore={hasNext}
              height={hasNext ? (screen1800 ? '80vh' : '100vh') : 'auto'}
              next={() => setFitler({ ...filter, page: filter.page + 1 })}
              style={{ padding: '10px' }}
            >
              <Grid mt={10} gutter='lg'>
                {questions.map((d, i) => (
                  <Grid.Col
                    key={d._id}
                    span={screen767 ? 12 : 4}
                    display='flex'
                    style={{
                      justifyContent: 'space-evenly'
                    }}
                  >
                    <Card
                      data={d}
                      onClick={(id) => {
                        setSelectedQuestion({
                          id,
                          prevIndex: i - 1,
                          nextIndex: i + 1,
                          showPrev: i > 0,
                          showNext: i < questions.length - 1
                        });
                      }}
                    />
                  </Grid.Col>
                ))}
                {isLoading || isFetching ? (
                  <Grid.Col
                    span={screen767 ? 12 : 4}
                    display='flex'
                    style={{ justifyContent: 'space-evenly' }}
                  >
                    <Skeleton height='100%' width='100%' />
                  </Grid.Col>
                ) : null}
              </Grid>
              {(isLoading || isFetching) && (questions.length % 3 == 0 || screen767) ? (
                <Skeleton height={screen767 ? '50%' : '40%'} width={screen767 ? '100%' : '33%'} />
              ) : null}
            </InfiniteScroll>
          </div>
        ) : isLoading ? (
          <div className={styles.loadingState}>
            <Loader />
          </div>
        ) : (
          <EmptyState
            hideButton={true}
            text={
              tab === QUESTIONS_PAGE_TABS.NEEDS_ATTENTION
                ? 'When a teammate asks admins for help, their question will be displayed here as an issue. You have no issues needing attention at the moment.'
                : 'Things look quiet here. When your colleagues ask Albus questions, they will appear here.'
            }
            textStyles={
              tab === QUESTIONS_PAGE_TABS.NEEDS_ATTENTION
                ? styles.needAttentionEmptyText
                : styles.othersEmptyText
            }
          />
        )}
      </div>
    </>
  );
};

Tab.propTypes = {
  tab: PropTypes.string,
  defaultSelectedQuestion: PropTypes.string,
  setQuestionCount: PropTypes.func.isRequired
};

export default Tab;
