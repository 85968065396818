import { useState } from 'react';
import { Button, Flex, Group, Modal, Combobox, Text } from '@mantine/core';
import { array, bool, func, oneOf, string } from 'prop-types';
import { ReactComponent as PrivateChannelIcon } from 'assets/icons/private-channel-lock.svg';
import { useFetchChannelFiltersQuery } from 'redux/services/licenses.js';
import CustomOptionMultiSelect from 'components/CustomOptionMultiSelect';
import styles from './AddChannelsModal.module.scss';

const SelectItem = (props) => {
  const { label, type, isAdded, ...others } = props;

  return (
    <Combobox.Option disabled={isAdded} {...others} className={styles.optionItem}>
      <Flex justify='space-between' align='center'>
        <Group justify='left' spacing={4}>
          {type === 'private' ? <PrivateChannelIcon /> : <div>#</div>}
          <div>{label}</div>
        </Group>
        {isAdded && <div className={styles.chip}>Added</div>}
      </Flex>
    </Combobox.Option>
  );
};

SelectItem.displayName = 'SelectItem';
SelectItem.propTypes = {
  label: string.isRequired,
  type: oneOf(['public', 'private']).isRequired,
  isAdded: bool.isRequired
};

const AddChannelsModal = ({ opened, onClose, loading, autoAnswerEnabledChannels, onConfirm }) => {
  const { data: channelList = [] } = useFetchChannelFiltersQuery('channels');
  const channels = [...channelList];

  const [channelsToAdd, setChannelsToAdd] = useState([]); // This will be the state of the modal
  channels
    .sort((a, b) => b.name.localeCompare(a.name))
    .sort((a, b) => {
      // Put all selected channels at the bottom of the array
      const aIndex = autoAnswerEnabledChannels.indexOf(a.id);
      const bIndex = autoAnswerEnabledChannels.indexOf(b.id);

      if (aIndex === -1) {
        return -1;
      }
      if (bIndex === -1) {
        return 1;
      }
      return bIndex - aIndex;
    });

  const data = channels.map((channel) => ({
    value: channel.id,
    label: channel.name,
    isAdded: autoAnswerEnabledChannels.includes(channel.id),
    type: channel.type
  }));

  const handleCancel = () => {
    onClose();
    setChannelsToAdd([]);
  };

  return (
    <Modal
      centered
      opened={opened}
      onClose={handleCancel}
      title={<div className={styles.modalTitle}>Enable auto-answer in channels</div>}
      size='lg'
    >
      <CustomOptionMultiSelect
        optionValue={channelsToAdd}
        onChange={setChannelsToAdd}
        optionsData={data}
        label={<Text size='sm'>Select channels</Text>}
        placeholder='Search'
        CustomOption={SelectItem}
      />
      <div className={styles.note}>
        <span>Note:</span> to enable Auto-answer in a private channel, first add Albus to the
        channel in Slack.
        <br />
        <a
          href='https://support.springworks.in/portal/en/kb/articles/how-to-add-albus-to-a-slack-channel'
          target='_blank'
          rel='noreferrer'
        >
          Learn more.
        </a>
      </div>
      <Group justify='right'>
        <Button
          radius='sm'
          variant='outline'
          onClick={handleCancel}
          className={styles.secondaryCta}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          radius='sm'
          className='primaryBtn'
          loading={loading}
          disabled={!channelsToAdd.length}
          onClick={() => {
            const _channels = channels.filter((channel) =>
              channelsToAdd.some((addedChannel) => addedChannel.value === channel.id)
            );
            onConfirm(_channels);
            setChannelsToAdd([]);
          }}
        >
          Confirm
        </Button>
      </Group>
    </Modal>
  );
};

AddChannelsModal.propTypes = {
  opened: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  autoAnswerEnabledChannels: array.isRequired,
  loading: bool
};

export default AddChannelsModal;
