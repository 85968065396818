import { toast } from 'react-toastify';
import { current } from '@reduxjs/toolkit';
import localStorage from 'utils/localStorage';
import { END_POINT, LOGOUT, USER, USER_CONNECTION_TOKEN } from 'utils/apiEndpoints';
import { handleApiError } from 'utils/helpers';
import api from './api';
import resetCache from '../actions/resetStore';
import { saveUserInfo } from 'redux/features/userSlice';
import { updateConnectStatus } from 'redux/features/connectionSlice';

const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query({
      query: () => ({
        url: USER,
        method: 'GET'
      }),
      providesTags: ['User'],
      async onQueryStarted(Data, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(saveUserInfo(response.user));
            dispatch(updateConnectStatus(response.user));
          } else {
            toast.error('Failed to fetch user info.');
          }
        } catch ({ error }) {
          handleApiError(error);
        }
      }
    }),
    getConnectionToken: build.query({
      query: (provider) => ({
        url: USER_CONNECTION_TOKEN + '/' + provider,
        method: 'GET'
      }),
      providesTags: ['Token'],
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        } else {
          toast.error(response.message);
          window.open(response.redirectTo, '_self');
        }
      },
      transformErrorResponse: (response) => {
        if (![401].includes(response?.status)) {
          toast.error(response?.data?.message ?? 'Failed to fetch google connection token');
          window.open(response?.data?.redirectTo, '_self');
        }
      }
    }),
    logout: build.mutation({
      query: () => ({
        url: LOGOUT,
        method: 'POST'
      }),
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            localStorage.clearStorage();
            dispatch(resetCache);
            window.location.href = `${import.meta.env.VITE_BASE_URL}/login`;
          } else {
            toast.error('Failed to logout, please try again');
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      }
    }),
    updateUser: build.mutation({
      query: (payload) => ({
        url: USER,
        method: 'PATCH',
        body: payload
      }),
      async onQueryStarted(data, { dispatch }) {
        if (!('tutorialStatus' in data) && !('featuresExploredFlags' in data)) {
          // for all other updates than tutorial status and feature explored flags, do not update local state
          return;
        }
        let updatedData;
        dispatch(
          api.util.updateQueryData('getUser', undefined, (cachedData) => {
            updatedData = { ...current(cachedData) };
            updatedData['user'] = {
              ...updatedData.user,
              ...data
            };
          })
        );
        dispatch(saveUserInfo(updatedData.user));
      }
    }),
    getUserCompanies: build.query({
      query: () => ({ url: END_POINT.USER.ORGANISATIONS, method: 'GET' }),
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (error) => handleApiError(error),
      providesTags: ['OrganisationsList']
    })
  })
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useLazyGetConnectionTokenQuery,
  useLogoutMutation,
  useUpdateUserMutation,
  useGetUserCompaniesQuery
} = userApi;

export default userApi;
