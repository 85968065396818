import { Text } from '@mantine/core';
import styles from './styles.module.scss';

const TextTheme = Text.extend({
  classNames: {
    root: styles.text
  }
});

export default TextTheme;
