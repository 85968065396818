import { Modal, Title, Flex, Button, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { bool, func } from 'prop-types';
import styles from './already-connected-modal.module.scss';

const AlreadyConnectedModal = ({ opened, setOpened }) => {
  const screen767 = useMediaQuery('(max-width: 767px)');

  return (
    <Modal
      centered
      opened={opened}
      size={630}
      radius={4}
      onClose={() => setOpened(false)}
      title={<Title className={styles.modal_title}>We’re already talking!</Title>}
    >
      <Text className={styles.modal_description}>
        You’re already connected with my team on Slack.
      </Text>
      <Flex mt={30} justify='flex-end'>
        <Button
          variant='outline'
          radius={4}
          color='dark'
          onClick={() => {
            setOpened(false);
          }}
          style={{ height: screen767 ? 35 : 42 }}
        >
          Close
        </Button>
        <Button
          className='primaryBtn'
          ml={20}
          onClick={() => {
            const slackConnectUrl = 'slack://app?tab=connect';
            window.location.href = slackConnectUrl;
            setOpened(false);
          }}
        >
          Take me there!
        </Button>
      </Flex>
    </Modal>
  );
};

AlreadyConnectedModal.propTypes = {
  opened: bool.isRequired,
  setOpened: func.isRequired
};

export default AlreadyConnectedModal;
