import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDebouncedValue, usePagination } from '@mantine/hooks';
import { useFetchSourcesQuery } from 'redux/services/integrations';
import DataView from './DataView';
import { NAVBAR_ROUTES, PROVIDERS, ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS } from 'utils/constants';

const Web = (props) => {
  const { viewType, setViewType, user, teamName, teamId } = props;
  const navigate = useNavigate();

  const [filter, setFilter] = useState('all');
  const [searchValue, setSearchValue] = useState(null);
  const [page, setPage] = useState(1);
  const [debouncedValue] = useDebouncedValue(searchValue, 500);

  const payload = {
    provider: PROVIDERS.WEB,
    params: {
      teamId,
      page: debouncedValue ? undefined : page,
      limit: debouncedValue ? undefined : ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS,
      q: debouncedValue ? debouncedValue : undefined,
      sort: 'lastTrainingStartedAt'
    }
  };

  if (filter !== 'all') {
    payload.params.type = filter;
  }

  const {
    data: { data: sources = [], totalCount = 0 } = {},
    isLoading,
    isFetching
  } = useFetchSourcesQuery({ ...payload }, { refetchOnMountOrArgChange: true });

  const pagination = usePagination({
    page: page,
    onChange: setPage
  });

  useEffect(() => {
    if (!sources.length && page === 1 && !isFetching && !isLoading && !debouncedValue) {
      navigate(
        `${NAVBAR_ROUTES.INTEGRATIONS_PROVIDERS.WEB}/${teamId}/addSource?teamName=${teamName}&teamId=${teamId}&fromButtonClick=false`
      );
    }
    if (!sources.length && page > 1 && !isFetching && !isLoading) {
      setPage((prev) => prev - 1);
    }
  }, [sources]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  function handleSearch(value) {
    setSearchValue(value);
    setPage(1);
  }

  return (
    <DataView
      viewType={viewType}
      setViewType={setViewType}
      user={user}
      teamName={teamName}
      teamId={teamId}
      loading={isLoading || isFetching}
      sources={sources}
      totalCount={totalCount}
      pagination={pagination}
      debouncedValue={debouncedValue}
      setSearchValue={handleSearch}
      filter={filter}
      searchValue={searchValue}
      setFilter={handleFilterChange}
      metaPayload={payload}
    />
  );
};

Web.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  user: PropTypes.object,
  teamName: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired
};

export default Web;
