import { GOOGLE } from 'utils/apiEndpoints';
import api from './api';
import { handleApiError } from 'utils/helpers';

const googleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDrives: build.query({
      query: () => ({ url: GOOGLE + '/drives' }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }

        return [];
      },
      transformErrorResponse: (error) => handleApiError(error)
    })
  })
});

export const { useLazyGetDrivesQuery } = googleApi;

export default googleApi;
