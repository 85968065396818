import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactPlayer from 'react-player';
import PlayIcon from 'assets/icons/play.svg';
import PauseIcon from 'assets/icons/pause.svg';
import styles from './styles.module.scss';

const VideoPlayer = (props) => {
  const { playing, video, clickHandler, width } = props;
  const { url, title } = video;
  const [loading, setLoading] = useState(true);

  return (
    <div className={styles.wrapper}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.videoContainer} onClick={() => clickHandler(video)}>
        <ReactPlayer
          height='100%'
          width={width}
          loop
          playing={playing}
          url={url}
          onReady={() => setLoading(false)}
        />
        {/* loading state  */}
        {loading && <div className={styles.loader}>loading...</div>}
        {/* paused state  */}
        {!loading && !playing && (
          <div className={classNames(styles.play)}>
            <img src={PlayIcon} alt='play' />
          </div>
        )}
        {/* playing state  */}
        {!loading && playing && (
          <div className={classNames({ [styles.pause]: playing })}>
            <img src={PauseIcon} alt='pause' />
          </div>
        )}
      </div>
    </div>
  );
};

VideoPlayer.defaultProps = {
  video: {
    title: ''
  },
  width: '100%'
};

VideoPlayer.propTypes = {
  playing: PropTypes.bool.isRequired,
  video: PropTypes.shape({
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string
  }).isRequired,
  clickHandler: PropTypes.func.isRequired,
  width: PropTypes.string
};

export default VideoPlayer;
