import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Modal } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import classNames from 'classnames';
import VideoPlayer from 'components/VideoPlayer';
import { VIDEO_DATA } from './data';
import styles from './styles.module.scss';

const LearnDescriptionModal = (props) => {
  const { onClose } = props;
  const [playingVideo, setPlayingVideo] = useState(null);
  const screen560 = useMediaQuery('(max-width: 560px)');

  function clickHandler(video) {
    if (playingVideo?.id === video.id) {
      setPlayingVideo(null);
      return;
    }

    setPlayingVideo(video);
  }

  return (
    <Modal
      opened
      onClose={onClose}
      title='Improve answer accuracy with descriptions'
      size={852}
      className='mobile-modal'
      classNames={{
        title: styles.title
      }}
    >
      <div className={styles.modalBody}>
        <div className={styles.wrapper}>
          <div className={styles.videoContainer}>
            {VIDEO_DATA.map((video) => (
              <VideoPlayer
                key={video.id}
                playing={playingVideo?.id === video.id}
                clickHandler={clickHandler}
                video={video}
                width={screen560 ? null : '378px'}
              />
            ))}
          </div>
          <p>
            Learn more about why you should describe your connections and sources in our&nbsp;
            <a
              href=' https://support.springworks.in/portal/en/kb/articles/how-do-descriptions-help-albus-answer-questions-correctly'
              target='_blank'
              rel='noreferrer'
            >
              knowledge base.
            </a>
          </p>
        </div>

        <Flex w={'100%'} justify={'end'}>
          <Button className={classNames('primaryBtnOutline', styles.closeButton)} onClick={onClose}>
            Close
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};

LearnDescriptionModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default LearnDescriptionModal;
