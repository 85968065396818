import PropTypes from 'prop-types';
import NoSearchResultIcon from 'assets/icons/no_search_result.svg';
import styles from './styles.module.scss';

const NoSearchResult = ({ hideSearchCount }) => {
  return (
    <div className={styles.wrapper}>
      {!hideSearchCount && (
        <div className={styles.searchlabel}>
          Search result <span>(0)</span>
        </div>
      )}
      <div className={styles.body}>
        <img src={NoSearchResultIcon} alt='No search result' />
        <div>No connections found</div>
      </div>
    </div>
  );
};

NoSearchResult.propTypes = {
  hideSearchCount: PropTypes.bool
};

NoSearchResult.defaultProps = {
  hideSearchCount: false
};

export default NoSearchResult;
