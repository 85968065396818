import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '@mantine/core';
import Layout from 'components/Layout';
import { useLazyGetEmbedHtmlQuery } from 'redux/services/activity';
import styles from './styles.module.scss';

const Analytics = () => {
  const { user, loading: isUserLoading } = useSelector((state) => state.user);
  const [embedHtml, setEmbedHtml] = useState('');
  const mixpanelReportURL = user?.analyticsUrl;
  const [fetchEmbedUrl, { isLoading: fetchingEmbedLink }] = useLazyGetEmbedHtmlQuery();

  useEffect(() => {
    const fetchEmbedData = async () => {
      if (mixpanelReportURL) {
        const embedData = await fetchEmbedUrl(mixpanelReportURL);
        setEmbedHtml(embedData?.data);
      }
    };

    fetchEmbedData();
  }, [mixpanelReportURL, fetchEmbedUrl]);

  if (isUserLoading || fetchingEmbedLink) {
    return (
      <Layout pageTitle='Activity logs'>
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      </Layout>
    );
  }

  return (
    <Layout pageTitle='Activity logs'>
      <div className={styles.iframeContainer} dangerouslySetInnerHTML={{ __html: embedHtml }} />
    </Layout>
  );
};

export default Analytics;
