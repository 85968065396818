import { Button } from '@mantine/core';
import PropTypes from 'prop-types';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus-o.svg';
import styles from '../styles.module.scss';

const AddSourceButton = (props) => {
  const { isDisabled, clickCallback, buttonText } = props;

  return (
    <Button
      className={styles.addSourceBtn}
      disabled={isDisabled}
      onClick={() => {
        clickCallback?.(true);
      }}
    >
      <PlusIcon />
      {buttonText}
    </Button>
  );
};

AddSourceButton.propTypes = {
  isDisabled: PropTypes.bool,
  clickCallback: PropTypes.func.isRequired,
  buttonText: PropTypes.string
};

AddSourceButton.defaultProps = {
  isDisabled: false,
  buttonText: 'Add source'
};

export default AddSourceButton;
