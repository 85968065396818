import resetStore from 'redux/actions/resetStore';
import localStorage from 'utils/localStorage';
import { toast } from 'react-toastify';

let isSessionExpired = false;

const authenticateResponse =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if ([401].includes(action?.meta?.baseQueryMeta?.response?.status)) {
      isSessionExpired && toast.info('Session expired!', { toastId: 'session-expired' });
      dispatch(resetStore);

      localStorage.clearStorage();
      if (!isSessionExpired) {
        isSessionExpired = true;
      }
    }
    return next(action);
  };

export default authenticateResponse;
