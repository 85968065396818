import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import PropTypes from 'prop-types';

const WarningModal = (props) => {
  const { warningData, handleCancel, handleDelete, disabled } = props;

  return (
    <DeleteWarningModal
      open={warningData.open}
      onClose={handleCancel}
      handleCancel={handleCancel}
      handleDelete={() => handleDelete(warningData.action, warningData.data)}
      title={warningData.title}
      header={warningData.header}
      body={warningData.body}
      removeText={warningData.removeText}
      disabled={disabled}
    />
  );
};

WarningModal.defaultProps = {
  disabled: false
};

WarningModal.propTypes = {
  warningData: PropTypes.shape({
    open: PropTypes.bool,
    header: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    removeText: PropTypes.string,
    action: PropTypes.string,
    data: PropTypes.object.isRequired
  }).isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default WarningModal;
