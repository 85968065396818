import { ActionIcon, Button, FileButton, TextInput } from '@mantine/core';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import ProfilePicture from 'components/ProfilePicture';
import UploadDp from 'assets/icons/upload-dp.svg';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from './styles.module.scss';

const View = ({
  isLoading,
  dp,
  showAllSections,
  nameValue,
  teamName,
  email,
  lastPasswordChangeDate,
  handleDpSave,
  setNameValue,
  showEdit,
  setShowEdit,
  handleSaveEdit,
  showChangePassword,
  handleChangePasswordCtaClick,
  handleDeleteAccountCtaClick,
  handleLeaveCompanyCtaClick,
  handleCancel
}) => {
  return (
    <div className={styles.wrapper}>
      <h1>Personal details</h1>
      <div className={styles.company}>
        <div className={styles.avatar}>
          <ProfilePicture photoUrl={dp} name={nameValue} />
          <FileButton
            className={styles.fileButton}
            onChange={handleDpSave}
            accept='image/png,image/jpeg'
          >
            {(props) => (
              <Button {...props}>
                <img src={UploadDp} alt='upload dp' />
              </Button>
            )}
          </FileButton>
        </div>

        {showEdit ? (
          <>
            <TextInput
              classNames={{ input: styles.input, error: styles.error }}
              value={nameValue}
              onChange={(e) => setNameValue(e.target.value)}
              error={
                !nameValue?.length || nameValue.length > 30
                  ? 'Name must be between 1 and 30 characters'
                  : null
              }
            />
            <Button
              className='primaryBtn'
              size='sm'
              onClick={handleSaveEdit}
              disabled={!nameValue?.length || nameValue?.length > 30}
              loading={isLoading}
            >
              Save
            </Button>
            <ActionIcon variant='transparent' onClick={handleCancel}>
              <SVGIcon name={ICONS_LIST.CROSS_RED} height={37} width={36} />
            </ActionIcon>
          </>
        ) : (
          <>
            <p className={styles.teamName}>{nameValue}</p>
            <ActionIcon variant='transparent' onClick={() => setShowEdit(true)}>
              <SVGIcon name={ICONS_LIST.EDIT} height={16} width={16} />
            </ActionIcon>
          </>
        )}
      </div>
      <div className={styles.divider} />
      <div className={styles.email}>
        <h3>Email Address</h3>
        <p>{email}</p>
      </div>
      {showChangePassword && (
        <>
          <div className={styles.divider} />
          <div className={styles.sectionWrapper}>
            <div className={styles.section}>
              <h3>Password</h3>
              <p>Last updated: {dayjs(lastPasswordChangeDate).format('HH:MM A, DD MMMM YYYY')}</p>
            </div>
            <Button
              className={classNames('primaryBtnOutline', styles.button)}
              onClick={handleChangePasswordCtaClick}
            >
              Change password
            </Button>
          </div>
        </>
      )}
      {showAllSections && (
        <>
          <div className={styles.divider} />
          <div className={styles.sectionWrapperWithRedButton}>
            <div className={styles.section}>
              <h3>Leave company</h3>
              <p>Leave ‘{teamName}’ on Albus. </p>
            </div>
            <Button
              className={classNames('primaryBtnOutline', styles.button)}
              onClick={handleLeaveCompanyCtaClick}
            >
              Leave company
            </Button>
          </div>
          <div className={styles.divider} />
          <div className={styles.sectionWrapperWithRedButton}>
            <div className={styles.section}>
              <h3>Delete account</h3>
              <p>Delete your account and account data.</p>
            </div>
            <Button
              className={classNames('primaryBtnOutline', styles.button)}
              onClick={handleDeleteAccountCtaClick}
            >
              Delete account
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

View.defaultProps = {
  isLoading: false,
  showAllSections: false,
  showChangePassword: false,
  lastPasswordChangeDate: ''
};

View.propTypes = {
  isLoading: bool.isRequired,
  dp: string.isRequired,
  email: string.isRequired,
  showAllSections: bool,
  teamName: string.isRequired,
  lastPasswordChangeDate: string,
  handleChangePasswordCtaClick: func.isRequired,
  nameValue: string.isRequired,
  setNameValue: func.isRequired,
  handleDpSave: func.isRequired,
  showChangePassword: bool,
  showEdit: bool.isRequired,
  setShowEdit: func.isRequired,
  handleSaveEdit: func.isRequired,
  handleDeleteAccountCtaClick: func.isRequired,
  handleLeaveCompanyCtaClick: func.isRequired,
  handleCancel: func.isRequired
};

export default View;
