import { useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import useDrivePicker from 'react-google-drive-picker/dist';
import { AddSourceButtonUI } from '../../Components/AddSourceButton';
import UnproccessedFilesModal from './UnproccessedFilesModal';
import { useAddSourcesMutation } from 'redux/services/integrations';
import { useLazyGetConnectionTokenQuery } from 'redux/services/user';
import { useGoogleAuthMutation } from 'redux/services/auth';
import { PROVIDERS, GOOGLE } from 'utils/constants';
import { maybePluralize } from 'utils/utilities';

const AddGoogleSources = ({ teamIdFromProps, clickCallback }) => {
  const [showUnprocessedFilesModal, setShowUnproccessedFilesModal] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { teamId } = useParams();

  const [openPicker] = useDrivePicker();
  const [addSources] = useAddSourcesMutation();
  const [getConnectionToken, connectionDataRequest] = useLazyGetConnectionTokenQuery();
  const [googleAuth] = useGoogleAuthMutation();
  const [totalCount, setTotalCount] = useState(0);
  const [unproccessedDocs, setUnprocessedDocs] = useState([]);

  useEffect(() => {
    if (connectionDataRequest.data) {
      const { clientId, developerKey, token } = connectionDataRequest.data;

      handleOpenPicker({ clientId, developerKey, token });
    }
  }, [connectionDataRequest.data]);

  const handleAddSource = () => {
    const isGoogleAuthenticated = user?.connections?.find(
      (_conn) => _conn.provider === PROVIDERS.GOOGLE
    );
    if (connectionDataRequest.data) {
      const { clientId, developerKey, token } = connectionDataRequest.data;

      handleOpenPicker({ clientId, developerKey, token });
    } else if (isGoogleAuthenticated) {
      getConnectionToken(PROVIDERS.GOOGLE);
    } else {
      googleAuth(user._id);
    }
  };

  const handleOpenPicker = ({ clientId, developerKey, token }) => {
    openPicker({
      clientId,
      developerKey,
      viewMimeTypes: import.meta.env.VITE_ALLOWED_MIMETYPES,
      token: token, // pass oauth token in case you already have one
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: false,
      multiselect: true,
      setIncludeFolders: true,
      callbackFunction: async (data) => {
        if (data.action === 'picked') {
          const docArray = [];
          const localUproccessedDocs = [];

          data.docs.forEach((doc) => {
            if (
              [GOOGLE.MIME_TYPES.SHEET, GOOGLE.MIME_TYPES.DOC, GOOGLE.MIME_TYPES.SLIDE].includes(
                doc.mimeType
              ) &&
              doc.sizeBytes > 10000000
            ) {
              //upper limit for google sheets is 10MB
              localUproccessedDocs.push({
                name: doc.name,
                type: doc.mimeType
              });
              return;
            }

            if (doc.sizeBytes > 50000000) {
              localUproccessedDocs.push({
                name: doc.name,
                type: doc.mimeType
              });
              return;
            }

            docArray.push({
              provider: PROVIDERS.GOOGLE,
              documentId: doc.id,
              documentName: doc.name,
              url: doc.url,
              type: GOOGLE.MIME_TYPES_TO_DOC_TYPES[doc.mimeType],
              lastUpdatedAt: doc.lastEditedUtc,
              mimeType: doc.mimeType
            });
          });

          setTotalCount(data.docs.length);
          setUnprocessedDocs(localUproccessedDocs);

          if (docArray.length) {
            const payload = {
              body: { documents: docArray },
              provider: PROVIDERS.GOOGLE,
              params: { teamId: teamId || teamIdFromProps }
            };

            const response = await addSources(payload);
            if (response?.data?.ok) {
              clickCallback?.();
              if (localUproccessedDocs.length) {
                setShowUnproccessedFilesModal(true);
              }

              const sourceCopy =
                data.docs[0].type === 'folder'
                  ? 'source'
                  : maybePluralize(docArray.length, 'source');
              toast.success(`Started training on ${sourceCopy} successfully`);
            }

            return;
          }

          if (localUproccessedDocs.length) {
            setShowUnproccessedFilesModal(true);
            return;
          }
        }
      }
    });
  };

  return (
    <>
      {showUnprocessedFilesModal && (
        <UnproccessedFilesModal
          files={unproccessedDocs}
          onClose={() => {
            setShowUnproccessedFilesModal(false);
            setUnprocessedDocs([]);
          }}
          totalCount={totalCount}
        />
      )}
      <AddSourceButtonUI handleAddSource={handleAddSource} />
    </>
  );
};

AddGoogleSources.defaultProps = {
  teamIdFromProps: '',
  clickCallback: () => null
};

AddGoogleSources.propTypes = {
  teamIdFromProps: string,
  clickCallback: func
};

export default AddGoogleSources;
