import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Flex, Select, Space, TextInput, Group, Text } from '@mantine/core';
import localStorage from 'utils/localStorage';
import { useFetchChannelFiltersQuery } from 'redux/services/licenses';
import { updateSearchQuery } from 'redux/features/licenseSlice';
import CustomOptionSingleSelect from 'components/CustomOptionSingleSelect';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { CHANNEL_TYPE, LICENSE_STATUS_LIST } from 'utils/constants';
import styles from './styles.module.scss';

const ItemComponent = (props) => {
  const getChannelIcon = (channelType) =>
    channelType === CHANNEL_TYPE.PRIVATE ? (
      <>
        <LockIcon />
        &nbsp;
      </>
    ) : (
      '#'
    );

  const { label, type, ...others } = props;
  return (
    <div {...others}>
      <Group className='cursor-pointer'>
        <Text fz={12}>
          {getChannelIcon(type)} {label}
        </Text>
      </Group>
    </div>
  );
};

const ManageLicensesFilter = (props) => {
  const {
    licenseStatus,
    setLicenseStatus,
    onSearch,
    setChanelFilters,
    channelFilters,
    setManualStatusFilter
  } = props;

  const { user } = useSelector((state) => state.user);
  // Get Login Platform
  const platform = localStorage.getPlatform();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  // Get Channel or Team Filters Based on Platform
  const { data: filtersData = [] } = useFetchChannelFiltersQuery(
    { origin: 'billing' },
    {
      skip: user?.platform !== 'slack'
    }
  );

  const handleChange = (event) => {
    const inputValue = event.currentTarget.value;

    if (licenseStatus !== 'invited') {
      onSearch(inputValue);
    } else {
      dispatch(updateSearchQuery(inputValue));
    }
  };

  const channelList = [];
  if (platform === 'slack') {
    channelList.push({ id: 'all', name: 'All' });
  }
  channelList.push(...filtersData);

  const searchBarPlaceHolder =
    licenseStatus === 'invited' ? 'Search by email' : 'Search by name, email';

  return (
    <Flex className={styles.filterContainer} align='center' justify='space-between'>
      <Flex className={styles.dropDownFilters}>
        {user?.platform === 'slack' && (
          <CustomOptionSingleSelect
            label={platform === 'slack' ? 'Channels' : 'Teams'}
            placeholder='Select One'
            inputLabelStyles={styles.selectLabel}
            inputBaseStyles={styles.selectInput}
            inputDropdownStyles={styles.dropdown}
            inputOptionStyles={styles.selectOption}
            rightSection={<ArrowIcon />}
            searchValue={searchTerm}
            onSearchChange={(searchTerm) => setSearchTerm(searchTerm)}
            radius={4}
            searchType={true}
            optionsData={channelList.map((c) => {
              return {
                id: c.id,
                label: c.name,
                value: c.id,
                type: c.type
              };
            })}
            optionValue={channelFilters[platform === 'slack' ? 'channel' : 'teams']}
            onChange={(value) => {
              setChanelFilters({ [platform === 'slack' ? 'channel' : 'teams']: value });
            }}
            CustomOption={ItemComponent}
          />
        )}
        <Space w='md' />
        <Select
          label='License status'
          placeholder='Select One'
          classNames={{
            input: styles.selectInput,
            option: styles.selectOption,
            dropdown: styles.dropdown,
            label: styles.selectLabel
          }}
          defaultValue='assigned'
          radius={4}
          value={licenseStatus}
          allowDeselect={false}
          searchable={false}
          data={LICENSE_STATUS_LIST}
          onChange={(value) => {
            setManualStatusFilter(true);
            setLicenseStatus(value);
          }}
        />
      </Flex>
      <div className={styles.searchContainer}>
        <TextInput
          className={styles.searchInput}
          placeholder={searchBarPlaceHolder}
          leftSection={<SearchIcon />}
          onChange={(event) => {
            handleChange(event);
          }}
        />
      </div>
    </Flex>
  );
};

ManageLicensesFilter.propTypes = {
  licenseStatus: PropTypes.string.isRequired,
  setLicenseStatus: PropTypes.func.isRequired,
  setChanelFilters: PropTypes.func.isRequired,
  setManualStatusFilter: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  channelFilters: PropTypes.shape({
    channel: PropTypes.string,
    teams: PropTypes.string
  }).isRequired
};

ItemComponent.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string
};

export default ManageLicensesFilter;
