import { Text } from '@mantine/core';

export const ACTIONS = {
  VIEW_SOURCES: 'VIEW_SOURCES',
  EDIT_TEAM: 'EDIT_TEAM',
  DELETE_TEAM: 'DELETE_TEAM',
  REMOVE_TEAMMATE: 'REMOVE_TEAMMATE',
  LEAVE_TEAM: 'LEAVE_TEAM',
  UNMARK_ADMIN: 'UNMARK_ADMIN',
  MARK_ADMIN: 'MARK_ADMIN'
};

export const warningDataConstructor = (action, data) => {
  switch (action) {
    case ACTIONS.DELETE_TEAM:
      return {
        open: true,
        header: 'Delete team',
        title: (
          <>
            Are you sure you want to delete your team <b>{data.name}</b>?
          </>
        ),
        body: (
          <>
            <Text c='dimmed' mr={4} display={'inline'}>
              Note:
            </Text>
            &nbsp;when you delete a team, all team sources are deleted as well.
          </>
        ),
        removeText: 'Delete',
        action,
        data
      };
    case ACTIONS.REMOVE_TEAMMATE:
      return {
        open: true,
        header: 'Remove teammate',
        title: (
          <>
            Are you sure you want to remove your teammate <b>{data?.name}</b> from your team&nbsp;
            <b>{data.teamName}</b> ?
          </>
        ),
        body: (
          <>
            <Text c='dimmed' mr={4} display={'inline'}>
              Note:{' '}
            </Text>
            when you remove a teammate from a team, Albus no longer answers their questions with
            information from the team wiki.
          </>
        ),
        removeText: 'Remove',
        action,
        data
      };
    case ACTIONS.LEAVE_TEAM:
      return {
        open: true,
        header: 'Leave team',
        title: (
          <>
            Are you sure you want to leave the team <b>{data.name}</b>?
          </>
        ),
        body: (
          <>
            <Text c='dimmed' mr={4} display={'inline'}>
              Note:{' '}
            </Text>{' '}
            when you leave a team, Albus no longer answers your questions with information from the
            team wiki
          </>
        ),
        removeText: 'Leave',
        action,
        data
      };
    case ACTIONS.UNMARK_ADMIN:
      return {
        open: true,
        header: 'Unmark admin',
        title: (
          <>
            Are you sure you want to remove <b>{data.name}</b>&apos;s admin privileges?
          </>
        ),
        body: (
          <>
            <Text c='dimmed' mr={4} display={'inline'}>
              Note:{' '}
            </Text>{' '}
            when you lose admin privileges you can no longer open the dashboard to manage Licenses,
            sources and your subscription.
          </>
        ),
        removeText: 'Unmark',
        action,
        data
      };
    default:
      break;
  }
};
