import ApiGeneration from './Components/ApiGeneration';
import Information from './Components/Information';
import SupportedApps from './Components/SupportedApps';

const Automations = () => {
  return (
    <>
      <Information />
      <ApiGeneration />
      <SupportedApps />
    </>
  );
};

export default Automations;
