import { ActionIcon, TextInput } from '@mantine/core';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { func, string } from 'prop-types';
import styles from './styles.module.scss';

const ConnectionsHeader = ({ value, onChange, CTAClickHandler }) => {
  return (
    <div className={styles.wrapper}>
      <TextInput
        placeholder='Search for connections'
        leftSection={<SearchIcon />}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={styles.search}
        rightSection={
          value ? (
            <ActionIcon
              variant='transparent'
              onClick={() => {
                onChange('');
              }}
            >
              <SVGIcon name={ICONS_LIST.CROSS} height={18} width={18} />
            </ActionIcon>
          ) : null
        }
      />
      <p onClick={CTAClickHandler}>Manage wiki</p>
    </div>
  );
};

ConnectionsHeader.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  CTAClickHandler: func.isRequired
};

export default ConnectionsHeader;
