import { Flex, Text } from '@mantine/core';
import PropTypes from 'prop-types';
import EmptyState from 'assets/empty-state.svg';

const SearchEmptyState = (props) => {
  const { searchValue, fromSearch, tab } = props;
  return (
    <Flex direction='column' gap='40px' align='center' justify='center' mt='15%' mb='md'>
      <img src={EmptyState} />
      <Text display='inline-flex'>
        {fromSearch ? (
          <>
            No sources matching&nbsp;<Text fw='bold'>{searchValue}</Text>&nbsp;found
          </>
        ) : (
          `No sources are ${tab === 'error' ? tab + `ed out` : tab}.`
        )}
      </Text>
    </Flex>
  );
};

SearchEmptyState.defaultProps = {
  searchValue: '',
  tab: ''
};

SearchEmptyState.propTypes = {
  searchValue: PropTypes.string,
  fromSearch: PropTypes.bool.isRequired,
  tab: PropTypes.string
};

export default SearchEmptyState;
