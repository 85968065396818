import { Card } from '@mantine/core';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const FeatureCard = ({ icon, title, desc, clickHandler }) => (
  <Card onClick={clickHandler} className={styles.wrapper}>
    <img src={icon} alt='feature_icon' />
    <div className={styles.content}>
      <p className={styles.title}>{title}</p>
      <p className={styles.desc}>{desc}</p>
    </div>
  </Card>
);

FeatureCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired
};

export default FeatureCard;
