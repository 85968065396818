import PropTypes from 'prop-types';
import { Avatar, Flex, Group, Popover, Title, Tooltip } from '@mantine/core';
import classNames from 'classnames';
import DownArrow from 'assets/icons/down-arrow.svg';
import FallbackImage from 'assets/company-fallback.svg';
import { truncateName } from 'pages/Integrations/utils';
import styles from './styles.module.scss';

const CompanySwitch = (props) => {
  const { companies, user, handleCompanySwitch, customStyles, popoverPosition } = props;

  const companiesRender = companies?.map((item, index) => {
    return (
      <Flex
        key={item.id}
        onClick={() => (index === 0 ? undefined : handleCompanySwitch(item.id))}
        gap={10}
        align='center'
        className={classNames(styles.companyItem, { [styles.active]: index === 0 })}
      >
        <Avatar
          mb={5}
          color='#182037'
          size={32}
          radius='md'
          src={item?.logo || FallbackImage}
          classNames={{ image: styles.avatarImage }}
        />
        <Tooltip disabled={item.name.length <= 15} w='fit-content' label={item.name}>
          <p>{truncateName(item.name, 15)}</p>
        </Tooltip>
      </Flex>
    );
  });

  return (
    <Popover
      width={190}
      offset={{ mainAxis: 15, crossAxis: 18 }}
      position={popoverPosition}
      shadow='md'
      disabled={!companies?.length}
    >
      <Popover.Target>
        <Group justify='apart' gap={10} className={classNames(styles.target, customStyles)}>
          <Avatar
            color='#182037'
            size={32}
            radius='md'
            src={user.team?.photo || FallbackImage}
            classNames={{ image: styles.avatarImage }}
          />
          <Title className={styles.title}>{user.team.name}</Title>
          <img src={DownArrow} height={16} width={16} />
        </Group>
      </Popover.Target>
      <Popover.Dropdown>{companiesRender}</Popover.Dropdown>
    </Popover>
  );
};

CompanySwitch.defaultProps = {
  customStyles: '',
  popoverPosition: 'bottom-end',
  user: {
    team: {
      name: 'Your Team'
    }
  }
};

CompanySwitch.propTypes = {
  popoverPosition: PropTypes.string,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      logo: PropTypes.string
    })
  ).isRequired,
  user: PropTypes.shape({
    team: PropTypes.shape({
      photo: PropTypes.string,
      name: PropTypes.string
    })
  }),
  customStyles: PropTypes.string,
  handleCompanySwitch: PropTypes.func.isRequired
};

export default CompanySwitch;
