import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Anchor } from '@mantine/core';
import { useDebouncedValue, usePagination } from '@mantine/hooks';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useFetchSourcesQuery } from 'redux/services/integrations';
import { useUpdateUserMutation } from 'redux/services/user';
import Announcement from 'components/Announcement';
import DocumentsDataView from './DocumentsDataView';
import Disclosure from './Disclosure';
import { PROVIDERS, ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS, NAVBAR_ROUTES } from 'utils/constants';
import styles from './Disclosure/styles.module.scss';

const GoogleSources = (props) => {
  const { user, viewType, setViewType } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [updateUser] = useUpdateUserMutation();
  const searchParams = new URLSearchParams(location.search);
  const [openDisclosure, setOpenDisclosure] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const { teamId } = useParams();
  const [page, onChange] = useState(1);
  const success = searchParams.get('success');
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue] = useDebouncedValue(searchValue, 500);

  useEffect(() => {
    if (success) {
      toast.success(`Google Drive™ integrated successfully.`);
      window.history.pushState(null, null, window.location.pathname);
    }
  }, []);

  useEffect(() => {
    const bannerObj = user?.featuresExploredFlags?.filter(
      (flag) => flag.featureName === 'google_drive_auto_sync'
    )?.[0];

    if (bannerObj?.state === 'inactive') {
      setShowBanner(false);
    }
  }, [user]);

  const payload = {
    provider: PROVIDERS.GOOGLE,
    params: {
      teamId,
      page: debouncedValue ? undefined : page,
      limit: debouncedValue ? undefined : ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS,
      q: debouncedValue ? debouncedValue : undefined,
      sort: 'lastTrainingStartedAt'
    }
  };

  const {
    data: { data: documents = [], totalCount = 0 } = {},
    isLoading,
    isFetching
  } = useFetchSourcesQuery({ ...payload }, { refetchOnMountOrArgChange: true });

  const pagination = usePagination({
    page,
    onChange
  });

  function bannerCloseCtaHandler() {
    updateUser({
      featuresExploredFlags: [{ featureName: 'google_drive_auto_sync', state: 'inactive' }]
    });
    setShowBanner(false);
  }

  return (
    <>
      {showBanner && (
        <Announcement
          title='Automatically train on files added to Google Drive'
          showCloseCta
          handleClose={bannerCloseCtaHandler}
          textCopy={
            <div className={styles.disclosureNote}>
              To automatically train on new files added to Google Drive, create an automation using
              Zapier.&nbsp;
              <Anchor
                underline='always'
                c='primaryGreen.3'
                onClick={() => navigate(NAVBAR_ROUTES.SETTINGS_TABS.AUTOMATIONS)}
              >
                Create an automation using Zapier
              </Anchor>
            </div>
          }
        />
      )}

      <DocumentsDataView
        viewType={viewType}
        setViewType={setViewType}
        user={user}
        documents={documents}
        pageCount={Math.ceil(totalCount / ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS)}
        pagination={pagination}
        searchValue={searchValue}
        debouncedValue={debouncedValue}
        setSearchValue={setSearchValue}
        isLoading={isLoading || isFetching}
        setOpenDisclosure={setOpenDisclosure}
        totalCount={totalCount}
        metaPayload={payload}
      />
      {openDisclosure && <Disclosure opened={openDisclosure} setOpened={setOpenDisclosure} />}
    </>
  );
};

GoogleSources.propTypes = {
  viewType: PropTypes.string.isRequired,
  setViewType: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};
export default GoogleSources;
