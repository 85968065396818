import { toast } from 'react-toastify';

import api from './api';
import { END_POINT } from 'utils/apiEndpoints';

const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchUsers: build.query({
      providesTags: ['Admins'],
      query: (params) => ({
        url: END_POINT.DASHBOARD_USERS,
        method: 'GET',
        params
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        } else {
          toast.error('Failed to fetch admins');
        }
      },
      transformErrorResponse: (response) => {
        toast.error(response.data.message);
      }
    })
  })
});

export const { useFetchUsersQuery } = usersApi;

export default usersApi;
