import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal, Title, Text, Button, Tooltip, Anchor } from '@mantine/core';
import { useFetchUsersQuery } from 'redux/services/users';
import { ReactComponent as TrailExpiryImage } from 'assets/trail-expiry.svg';
import localStorage from 'utils/localStorage';
import CompanySwitch from 'components/CompanySwitch';
import styles from './styles.module.scss';

const TrialExpired = ({ companies, handleCompanySwitch }) => {
  const { user, loading: userLoading } = useSelector((state) => state.user);
  const { planInfo } = useSelector((state) => state.plan);
  const { data: { admins = [] } = {} } = useFetchUsersQuery(undefined, {
    skip: userLoading || user.isAdmin
  });

  const handleClick = () => {
    const token = localStorage.getToken();
    const referral = window.Rewardful?.referral ?? '';
    window.open(
      `${import.meta.env.VITE_API_URL}/billing/checkout-session?referral=${referral}&key=${token}`,
      '_self'
    );
  };

  return (
    <Modal
      centered
      opened={true}
      withCloseButton={false}
      size='xl'
      onClose={() => {}}
      classNames={{ root: styles.root }}
    >
      {companies.length ? (
        <CompanySwitch
          customStyles={styles.switchStyles}
          companies={companies}
          handleCompanySwitch={handleCompanySwitch}
          user={user}
          popoverPosition='bottom-start'
        />
      ) : null}
      <div className={styles.container}>
        <TrailExpiryImage />
        <Title className={styles.title} order={4}>
          {planInfo.trialDaysLeft && !planInfo.questionsLeft
            ? 'Your company exhausted the 50 free questions in your free trial.'
            : 'Your free trial is over.'}
        </Title>
        <Text className={styles.content}>
          Subscribe within 1 month to keep the sources, training and <br />
          settings intact. After that, you will lose all your data.
        </Text>

        {user.isAdmin ? (
          <Button className={styles.primaryBtn} onClick={handleClick}>
            Subscribe now
          </Button>
        ) : (
          <div className={styles.dialogBox}>
            <Text className={styles.dialogBoxText}>
              Ask your company admins to buy a subscription if you want to continue using Albus -
              <br />
              {admins.map((admin, index) => (
                <>
                  <span key={admin._id}>
                    <Tooltip label={admin.email}>
                      <Anchor className={styles.adminNameText}>{admin.name}</Anchor>
                    </Tooltip>
                  </span>
                  {index < admins.length - 1 ? ', ' : '.'}
                </>
              ))}
            </Text>
          </div>
        )}
      </div>
    </Modal>
  );
};

TrialExpired.propTypes = {
  companies: PropTypes.array.isRequired,
  handleCompanySwitch: PropTypes.func.isRequired
};

export default TrialExpired;
