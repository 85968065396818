import PropTypes from 'prop-types';
import { Badge, Flex, Image } from '@mantine/core';
import { ReactComponent as SupportActionsIcon } from 'assets/icons/support-actions-icon.svg';
import Arrow from 'assets/icons/new-arrow.svg';
import { ACTIONS_SUPPORTED_CONNECTORS } from 'pages/ControlPanel/constants';
import styles from './styles.module.scss';

const ConnectorCard = ({ conn, setSelectedConnection }) => {
  const isActionsPage = window.location.pathname.includes('/actions');

  return (
    <div key={conn.title} onClick={() => setSelectedConnection(conn)} className={styles.item}>
      <div className={styles.itemName}>
        <img src={conn.icon} width={48} alt='connector icon' loading='lazy' />
        {conn.isConnected && <div className={styles.connectedTag}>Connected</div>}
      </div>
      <div className={styles.content}>
        <span className={styles.title}>{conn.title} </span>
      </div>
      {conn.subTitle && <div className={styles.subtitle}>{conn.subTitle}</div>}
      <Flex w='100%'>
        {ACTIONS_SUPPORTED_CONNECTORS.includes(conn.name) && !isActionsPage && (
          <Badge className={styles.supportActionsBadge} color='#EFEFEF'>
            <Flex gap={5} align='center' justify='center'>
              <SupportActionsIcon />
              Supports Actions
            </Flex>
          </Badge>
        )}
        <Image ml='auto' src={Arrow} h={14} w={21} alt='arrow icon' loading='lazy' />
      </Flex>
    </div>
  );
};

ConnectorCard.propTypes = {
  conn: PropTypes.object.isRequired,
  setSelectedConnection: PropTypes.func.isRequired
};

export default ConnectorCard;
