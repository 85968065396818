import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const InfiniteScroll = (props) => {
  const { children, hasNext, onNext, scrollTarget, dataLength, threshold } = props;
  const [hasCalledNext, setHasCalledNext] = useState(false);

  useEffect(() => {
    const targetElement = document.getElementById(scrollTarget);

    if (targetElement) {
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = targetElement;
        const scrollPercent = (scrollTop / (scrollHeight - clientHeight)) * 100;
        if (scrollPercent >= threshold && hasNext && !hasCalledNext) {
          setHasCalledNext(true);
          onNext?.();
        }
      };

      targetElement.addEventListener('scroll', handleScroll);

      return () => {
        targetElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollTarget, hasNext, onNext, hasCalledNext]);

  useEffect(() => {
    // Reset hasCalledNext when dataLength changes and hasNext is true
    if (hasNext) {
      setHasCalledNext(false);
    }
  }, [hasNext, dataLength]);

  return children;
};

InfiniteScroll.defaultProps = {
  threshold: 40
};

InfiniteScroll.propTypes = {
  hasNext: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  scrollTarget: PropTypes.string.isRequired,
  dataLength: PropTypes.number.isRequired
};

export default InfiniteScroll;
