import { Tooltip } from '@mantine/core';
import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MailIcon from 'assets/icons/mail.svg';
import LeftAccordionIcons from 'assets/icons/left-accordion.svg';
import styles from './styles.module.scss';

const EmailVerification = ({ email, handleResendEmail, handleBack }) => {
  const [disableResend, setDisableResend] = useState(false);
  const [timer, setTimer] = useState(60);

  const startTimer = () => {
    setDisableResend(true);
    let seconds = 60; //variable for seconds bcz timer variable is const (Can't change)
    const interval = setInterval(() => {
      seconds--;
      setTimer(seconds); // updating on each second
      if (seconds === 0) {
        setDisableResend(false);
        clearInterval(interval);
      }
    }, 1000);
  };

  /*Initiates 60 secs timer when the resend email is clicked, disabling the button 
    and enabling it again after 60 secs.*/
  const handleResendClick = () => {
    if (!disableResend) {
      handleResendEmail();
      setTimer(60); //resets timer on click
      startTimer();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.goBack} onClick={handleBack}>
        <img src={LeftAccordionIcons} />
        <span>Go back</span>
      </div>
      <div className={styles.content}>
        <img src={MailIcon} alt='Mail Icon' />
        <h2>Verify your email to continue</h2>
        <p className={styles.text}>You&apos;re almost there! We sent you a verification email to</p>
        <strong>{email}</strong>
        <p className={styles.subText}>
          Click on the link in your verification email to sign up. If you don&apos;t see the email,
          check your spam folder.
        </p>
        <p className={styles.footNote}>
          Didn&apos;t receive an email?
          <Tooltip
            w='max-content'
            multiline={false}
            radius='sm'
            arrowOffset={10}
            disabled={!disableResend}
            label={`You can send another email in ${timer} seconds.`}
          >
            <span
              className={classNames(
                { [styles.disableResend]: disableResend },
                { [styles.resend]: !disableResend }
              )}
              onClick={handleResendClick}
            >
              &nbsp;Resend email
            </span>
          </Tooltip>
        </p>
      </div>
    </div>
  );
};

EmailVerification.propTypes = {
  email: PropTypes.string.isRequired,
  handleResendEmail: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired
};

export default EmailVerification;
