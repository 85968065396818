import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import View from './View';
import { useJoinTeamMutation } from 'redux/services/auth';

const OtherCompanies = ({ type, companiesList, companyDomain }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const provider = searchParams.get('provider');
  let header;
  if (type === 'invited') {
    header = 'Accept an invitation';
  } else if (type === 'sameDomain') {
    header = `Anyone with @${companyDomain} can join`;
  }

  const [joinTeam] = useJoinTeamMutation();
  async function handleJoin(id, inviteMongoId) {
    const { data } = await joinTeam({ id, provider, inviteMongoId });
    if (data?.ok) {
      window.open(`${import.meta.env.VITE_BASE_URL}/?token=${data.data.token}`, '_self');
    } else {
      toast.error(data.message);
    }
  }

  return (
    <View
      header={header}
      companiesList={companiesList}
      companiesCount={companiesList.length}
      handleJoin={handleJoin}
    />
  );
};

OtherCompanies.propTypes = {
  type: PropTypes.string.isRequired,
  companyDomain: PropTypes.string.isRequired,
  companiesList: PropTypes.array.isRequired
};

export default OtherCompanies;
