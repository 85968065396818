import PropTypes from 'prop-types';
import { useState } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Anchor, Text, Button, Tooltip } from '@mantine/core';
import BuyAddOnQuestionsModal from 'components/Modals/BuyAddOnQuestionsModal';
import styles from './styles.module.scss';

const View = (props) => {
  const { isAdmin, admins, billingDate } = props;
  dayjs.extend(advancedFormat);
  const [showAddOnQuestionsModal, setShowAddOnQuestionsModal] = useState(false);

  function paidPlanDialogBox() {
    return (
      <>
        <Text className={styles.dialogBoxText}>
          You cannot ask any questions because your company exhausted its question quota. Your
          monthly limit resets on the&nbsp;
          {dayjs(billingDate).format('Do')}.
        </Text>

        {isAdmin ? (
          <Text className={styles.dialogBoxText}>
            To ask questions now, you can
            <Button onClick={() => setShowAddOnQuestionsModal(true)} className={styles.addOnButton}>
              Buy an add-on.
            </Button>
          </Text>
        ) : (
          <Text className={styles.dialogBoxText}>
            To ask questions now, request an admin to buy an add-on:&nbsp;
            {admins.map((admin, index) => (
              <>
                <span key={admin._id}>
                  <Tooltip label={admin.email}>
                    <Anchor className={styles.adminNameText}>{admin.name}</Anchor>
                  </Tooltip>
                </span>
                {index < admins.length - 1 ? ', ' : '.'}
              </>
            ))}
          </Text>
        )}
      </>
    );
  }

  return (
    <div className={styles.dialogBoxContainer}>
      {paidPlanDialogBox()}
      {showAddOnQuestionsModal && (
        <BuyAddOnQuestionsModal
          showAddOnQuestionsModal={showAddOnQuestionsModal}
          setShowAddOnQuestionsModal={setShowAddOnQuestionsModal}
        />
      )}
    </div>
  );
};

View.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  admins: PropTypes.array.isRequired,
  billingDate: PropTypes.string.isRequired
};

export default View;
