import { Button } from '@mantine/core';
import { func } from 'prop-types';
import styles from './styles.module.scss';

const NoConnectionFound = ({ CTAHandler }) => {
  return (
    <div className={styles.wrapper}>
      <h2>My connections</h2>
      <div className={styles.body}>
        <h3>No connections available</h3>
        <p>Connect Albus to your favorite apps to absorb information for your wiki</p>
        <Button className='primaryBtn' onClick={CTAHandler}>
          Explore apps
        </Button>
      </div>
    </div>
  );
};

NoConnectionFound.propTypes = {
  CTAHandler: func.isRequired
};

export default NoConnectionFound;
