import React from 'react';
import classNames from 'classnames';
import { Text, Image, Divider, Button } from '@mantine/core';
import PropTypes from 'prop-types';
import icon from 'assets/team-plan.svg';
import styles from './styles.module.scss';

const View = (props) => {
  const { handleTryDifferentEmail, handleCreateCompany, showCreateCompany } = props;

  return (
    <div className={styles.container}>
      <div className={classNames(styles.differentEmail, { [styles.addGap]: !showCreateCompany })}>
        <Text className={styles.text}>Don&rsquo;t see your company?</Text>
        <Button className={styles.button} onClick={handleTryDifferentEmail}>
          Try different email
        </Button>
      </div>
      {showCreateCompany && (
        <>
          <Divider className={styles.divider} label='or' labelPosition='center' size='sm' />
          <div className={styles.createCompany}>
            <Image className={styles.img} src={icon} />
            <Text className={styles.text1}>Want to start fresh?</Text>
            <Button className={styles.button} onClick={handleCreateCompany}>
              Create company
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

View.propTypes = {
  handleTryDifferentEmail: PropTypes.func.isRequired,
  handleCreateCompany: PropTypes.func.isRequired,
  showCreateCompany: PropTypes.bool.isRequired
};

export default View;
