import PropTypes from 'prop-types';
import RemoveSource from '../RemoveSource';
import View from './View';

const MultiSelectActions = (props) => {
  const {
    handleDeleteSources,
    showRemoveSourceModal,
    setShowRemoveSourceModal,
    isProcessing,
    selectAll,
    isDisconnectSourcesLoading,
    selectedSources
  } = props;

  return (
    <>
      <View
        count={selectedSources.length}
        setShowRemoveSourceModal={setShowRemoveSourceModal}
        isProcessing={isProcessing}
        selectAll={selectAll}
      />
      <RemoveSource
        opened={showRemoveSourceModal}
        onClose={() => setShowRemoveSourceModal(false)}
        handleDelete={handleDeleteSources}
        count={selectedSources.length}
        selectAll={selectAll}
        selectedSources={selectedSources}
        isDisconnectSourcesLoading={isDisconnectSourcesLoading}
      />
    </>
  );
};

MultiSelectActions.defaultProps = {
  handleDeleteSources: () => null,
  showRemoveSourceModal: false,
  setShowRemoveSourceModal: () => null,
  isProcessing: false,
  selectAll: false,
  isDisconnectSourcesLoading: false,
  selectedSources: []
};

MultiSelectActions.propTypes = {
  handleDeleteSources: PropTypes.func,
  showRemoveSourceModal: PropTypes.bool,
  isDisconnectSourcesLoading: PropTypes.bool,
  setShowRemoveSourceModal: PropTypes.func,
  isProcessing: PropTypes.bool,
  selectAll: PropTypes.bool,
  selectedSources: PropTypes.array
};

export default MultiSelectActions;
