import { createSlice } from '@reduxjs/toolkit';
import { USER_TYPE } from 'utils/constants';

const initialState = {
  loading: true,
  user: {},
  validationChats: {}
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUserInfo: (state, action) => {
      let userType = USER_TYPE.USER;
      if (action.payload.isTeamAdmin) {
        userType = USER_TYPE.TEAM_ADMIN;
      }
      if (action.payload.isAdmin) {
        userType = USER_TYPE.COMPANY_ADMIN;
      }
      state.user = { ...action.payload, userType };
      state.loading = false;
    },
    addValidationChats: (state, action) => {
      const { promptId, conversations } = action.payload;
      state.validationChats[promptId] = { conversations };
    },
    updateValidationChatsConversation: (state, action) => {
      const { promptId, conversations } = action.payload;
      if (state.validationChats[promptId]) {
        state.validationChats[promptId].conversations = [
          ...(state.validationChats[promptId].conversations || []),
          ...conversations
        ];
      } else {
        state.validationChats[promptId] = { conversations };
      }
    },
    updateValidationChatsContinuePromptId: (state, action) => {
      const { promptId, continuePromptId } = action.payload;
      const updatedValidationChats = { ...state.validationChats };
      updatedValidationChats[promptId] = {
        ...updatedValidationChats[promptId],
        continuePromptId
      };
      state.validationChats = updatedValidationChats;
    },
    deleteValidationChat: (state, action) => {
      const promptIdToDelete = action.payload;
      delete state.validationChats[promptIdToDelete];
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  saveUserInfo,
  addValidationChats,
  updateValidationChatsConversation,
  updateValidationChatsContinuePromptId,
  deleteValidationChat
} = userSlice.actions;

export default userSlice.reducer;
