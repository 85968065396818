import { Modal, Title, Text, Flex, Button } from '@mantine/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

const DeleteWarningModal = (props) => {
  const {
    open,
    onClose,
    header,
    title,
    body,
    handleCancel,
    handleDelete,
    removeText,
    buttonColor,
    disabled,
    disableRemove,
    customClass,
    children,
    loading
  } = props;

  let content = null;
  if (children) {
    content = children;
  } else if (body) {
    content = <Text className={styles.content}>{body}</Text>;
  }
  return (
    <Modal
      centered
      opened={open}
      size={550}
      closeOnClickOutside={false}
      onClose={disabled ? undefined : onClose}
      title={<span className={styles.title}>{header}</span>}
      onClick={(e) => e.stopPropagation()}
    >
      <Title className={styles.question} order={6}>
        {title}
      </Title>
      {content}
      <Flex align='center' justify='flex-end' className={styles.btnContainer}>
        <Button
          variant='outline'
          color={buttonColor}
          onClick={handleCancel}
          className={classNames(styles.button, customClass?.cancelBtn)}
          disabled={disabled}
        >
          Cancel
        </Button>
        <Button
          color={buttonColor}
          onClick={handleDelete}
          className={classNames(styles.button, customClass?.confirmBtn)}
          disabled={disabled || disableRemove}
          loading={loading}
        >
          {removeText}
        </Button>
      </Flex>
    </Modal>
  );
};

DeleteWarningModal.defaultProps = {
  loading: false,
  disabled: false,
  disableRemove: false,
  header: 'Delete Warning',
  title: 'Are you sure you want to delete this item?',
  buttonColor: 'red',
  removeText: 'Remove',
  customClass: {}
};

DeleteWarningModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.node,
  title: PropTypes.node,
  body: PropTypes.any,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  removeText: PropTypes.string,
  buttonColor: PropTypes.string,
  disabled: PropTypes.bool,
  disableRemove: PropTypes.bool,
  customClass: PropTypes.shape({
    cancelBtn: PropTypes.string,
    confirmBtn: PropTypes.string
  }),
  children: PropTypes.node,
  loading: PropTypes.bool
};

export default DeleteWarningModal;
