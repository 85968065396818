import PropTypes from 'prop-types';
import { ReactComponent as Laptop } from 'assets/widgets/only-desktop.svg';
import styles from './OnlyDesktop.module.scss';

const OnlyDesktop = ({ onlyDesktopText }) => {
  return (
    <div className={styles.container}>
      <div>
        <Laptop />
      </div>
      <div className={styles.header}>Configure your {onlyDesktopText} on your desktop</div>
      <div className={styles.note}>
        To configure your {onlyDesktopText}, open your dashboard on a desktop or tablet
      </div>
    </div>
  );
};

OnlyDesktop.defaultProps = {
  onlyDesktopText: 'widget'
};

OnlyDesktop.propTypes = {
  onlyDesktopText: PropTypes.string
};

export default OnlyDesktop;
