import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Drawer, Image } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import AddIcon from 'assets/ask-albus/add-icon.svg';
import CollapseIcon from 'assets/ask-albus/collapse-icon.svg';
import ExpandIcon from 'assets/ask-albus/expand-icon.svg';
import ChatTitle from './ChatTitle';
import styles from './styles.module.scss';

const ChatView = (props) => {
  const { hideChats, setHideChats, chats, selectedChat, handleChatSelect } = props;
  const screen480 = useMediaQuery('(max-width: 480px)');

  const getChatDateSectionText = (key) => {
    switch (key) {
      case 'yesterday':
        return 'Yesterday';
      case 'last7days':
        return 'Last 7 days';
      case 'last30days':
        return 'Last 30 days';
      default:
        return 'Today';
    }
  };

  return (
    <div
      className={classNames(styles.chatsContainer, { [styles.collapseChats]: hideChats })}
      id='chat-list'
    >
      {!hideChats && (
        <>
          <div className={styles.createNewChatContainer}>
            {!screen480 && <div className={styles.heading}>Chats</div>}
            <Button
              className={styles.primaryBtn}
              leftSection={<Image src={AddIcon} />}
              onClick={() => handleChatSelect(null)}
            >
              New chat
            </Button>
          </div>
          <div className={styles.chatHistoryContainer}>
            {Object.keys(chats).map(function (key) {
              if (chats[key].length) {
                return (
                  <div key={key}>
                    <div className={styles.dateHeader}>{getChatDateSectionText(key)}</div>
                    {chats[key].map((chat) => (
                      <ChatTitle
                        key={chat.promptId}
                        chat={chat}
                        selectedChat={selectedChat}
                        handleChatSelect={(chat) => handleChatSelect(chat)}
                        dateRange={key}
                      />
                    ))}
                  </div>
                );
              }
            })}
          </div>
        </>
      )}
      {!screen480 && (
        <div
          className={classNames(styles.hideChatSection, { [styles.collapseHide]: hideChats })}
          onClick={() => setHideChats((prev) => !prev)}
        >
          <Image src={hideChats ? ExpandIcon : CollapseIcon} w={32} />
        </div>
      )}
    </div>
  );
};

ChatView.propTypes = {
  hideChats: PropTypes.bool.isRequired,
  setHideChats: PropTypes.func.isRequired,
  chats: PropTypes.shape({
    today: PropTypes.arrayOf(
      PropTypes.shape({
        promptId: PropTypes.string,
        title: PropTypes.string
      })
    ).isRequired,
    yesterday: PropTypes.arrayOf(
      PropTypes.shape({
        promptId: PropTypes.string,
        title: PropTypes.string
      })
    ).isRequired,
    last7days: PropTypes.arrayOf(
      PropTypes.shape({
        promptId: PropTypes.string,
        title: PropTypes.string
      })
    ).isRequired,
    last30days: PropTypes.arrayOf(
      PropTypes.shape({
        promptId: PropTypes.string,
        title: PropTypes.string
      })
    ).isRequired
  }),
  handleChatSelect: PropTypes.func.isRequired,
  selectedChat: PropTypes.shape({
    promptId: PropTypes.string,
    title: PropTypes.string
  })
};

ChatView.defaultProps = {
  chats: {
    today: [],
    yesterday: [],
    last7days: [],
    last30days: []
  },
  selectedPromptId: ''
};

const View = (props) => {
  const { showChatHistoryModal, setShowChatHistoryModal } = props;
  const screen480 = useMediaQuery('(max-width: 480px)');

  return screen480 ? (
    <Drawer
      title='Chats'
      opened={showChatHistoryModal}
      position='bottom'
      classNames={{
        content: styles.drawerBody,
        header: styles.drawerHeader,
        title: styles.drawerTitle
      }}
      onClose={() => setShowChatHistoryModal(false)}
    >
      {<ChatView {...props} />}
    </Drawer>
  ) : (
    <ChatView {...props} />
  );
};

View.propTypes = {
  showChatHistoryModal: PropTypes.bool,
  setShowChatHistoryModal: PropTypes.func
};

View.defaultProps = {
  showChatHistoryModal: false,
  setShowChatHistoryModal: () => null
};

export default View;
