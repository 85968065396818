import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Anchor, Flex, Image, Text, Title } from '@mantine/core';
import { toast } from 'react-toastify';
import { usePostSlackConnectMutation } from 'redux/services/slack-connect.js';
import UrlDescription from 'assets/connectors/desc_img1.svg';
import RelevantPageDescription from 'assets/connectors/desc_img2.svg';
import WebCrawlingDescription from 'assets/connectors/desc_img3.svg';
import DescriptionCtaIcon from 'assets/connectors/desc_cta.svg';
import KBIcon from 'assets/icons/kb-faq.svg';
import emailIcon from 'assets/icons/email-v1.svg';
import TABS from '../../../constants';
import styles from './styles.module.scss';

const CtaBlock = ({ image, title, text }) => {
  return (
    <Flex className={styles.ctaBlockContainer}>
      <Image src={image} />
      <div>
        <Title className={styles.ctaTitle}>{title}</Title>
        <Text className={styles.ctaText}>{text}</Text>
      </div>
    </Flex>
  );
};

CtaBlock.propTypes = {
  image: PropTypes.node,
  title: PropTypes.node,
  text: PropTypes.node
};

const Description = ({ handler }) => {
  const { user } = useSelector((state) => state.user);
  const [postSlackConnect] = usePostSlackConnectMutation();

  const handleSlackConnect = async () => {
    const response = await postSlackConnect({
      emails: [user?.email]
    });
    if (response?.data?.channelId) {
      toast.warning("You're already connected to Kartik on Slack.");
    } else if (response?.data.ok) {
      toast.success('You have been connected to Kartik on Slack.');
    } else {
      toast.error('Something went wrong!');
    }
  };

  const webpageDescription = [
    {
      image: UrlDescription,
      title: 'Add a URL you want Albus to absorb information from',
      description:
        'Albus can absorb information from public web pages. You can train him on one specific web page or ask him to crawl across a website, identify all web pages on the same domain and train on all these pages.'
    },
    {
      image: RelevantPageDescription,
      title: 'Include relevant pages in your wiki',
      description:
        'When Albus crawls a website, he trains on all public web pages on the same domain by default. You can choose to exclude certain pages from your wiki later by managing this source further.'
    },
    {
      image: WebCrawlingDescription,
      title: 'Website crawling is in beta',
      description:
        'To ensure fast processing and answer accuracy, Albus currently crawls websites to a certain depth. If you want him to crawl deeper, write to us!'
    }
  ];

  const sitemapDescription = [
    {
      image: UrlDescription,
      title: 'Add a sitemap of all the web pages you want Albus to absorb information from',
      description:
        'When you add a sitemap, Albus absorbs information from all public web pages on that sitemap.'
    },
    {
      image: RelevantPageDescription,
      title: 'Include relevant pages in your wiki',
      description:
        'When Albus absorbs information from a sitemap, he trains on all public web pages on that sitemap by default. You can choose to exclude certain pages from your wiki later by managing this source further.'
    }
  ];

  const descriptionArray = handler === TABS.WEBSITE ? webpageDescription : sitemapDescription;

  return (
    <Flex gap={42} direction='column'>
      {descriptionArray.map((desc, index) => (
        <Flex gap={30} className={styles.descriptionContainer} key={index}>
          <Image src={desc.image} className={index % 2 === 1 && styles.right} />
          <div>
            <Title className={styles.descTitle}>{desc.title}</Title>
            <Text className={styles.descText}>{desc.description}</Text>
          </div>
        </Flex>
      ))}

      <Flex align='center' justify='center' gap={40} className={styles.ctaContainer}>
        <Image src={DescriptionCtaIcon} className={styles.ctaBlockImage} />
        <Flex direction='column' gap={20}>
          <Title className={styles.ctaBlockTitle}>Get more help:</Title>
          <Flex gap={20} wrap='wrap'>
            <CtaBlock
              image={KBIcon}
              title='Have questions?'
              text={
                <>
                  Visit our&nbsp;
                  <Anchor href='https://support.springworks.in/portal/en/kb/albus' target='_blank'>
                    knowledge base
                  </Anchor>
                  .
                </>
              }
            />
            <CtaBlock
              image={emailIcon}
              title='Drop an email at'
              text={
                <>
                  <Anchor href='mailto:albus@springworks.in' target='_blank'>
                    albus@springworks.in
                  </Anchor>
                </>
              }
            />
          </Flex>
          <CtaBlock
            image={'https://albus-backend-assets.s3.amazonaws.com/Kartik.png'}
            title={
              <>
                Get a direct line with <strong>CEO Kartik Mandaville</strong>.
              </>
            }
            text={
              <>
                DM on <Anchor onClick={handleSlackConnect}>Slack</Anchor> or&nbsp;
                <Anchor href='https://calendly.com/albus-sw/1v1-with-albus-team' target='_blank'>
                  schedule a call
                </Anchor>
                .
              </>
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

Description.propTypes = {
  handler: PropTypes.string.isRequired,
  setHandler: PropTypes.func.isRequired
};

export default Description;
