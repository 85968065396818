// import { toast } from 'react-toastify';
import { END_POINT } from 'utils/apiEndpoints';
import api from './api';

const adminsApi = api.injectEndpoints({
  endpoints: (build) => ({
    assignPrivilege: build.mutation({
      query: (body) => ({
        url: END_POINT.DASHBOARD_ADMINS,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Admins']
    }),
    unAssignPrivilege: build.mutation({
      query: (userId) => ({
        url: END_POINT.DASHBOARD_ADMINS + '/' + userId,
        method: 'DELETE'
      }),
      invalidatesTags: ['Admins']
    })
  })
});

export const { useAssignPrivilegeMutation, useUnAssignPrivilegeMutation } = adminsApi;

export default adminsApi;
