// import { toast } from 'react-toastify';
import { END_POINT } from 'utils/apiEndpoints';
import api from './api';

const automationApi = api.injectEndpoints({
  endpoints: (build) => ({
    generateApiKey: build.mutation({
      query: () => ({
        url: END_POINT.AUTOMATIONS.GENERATE_API_KEY,
        method: 'POST'
      })
    })
  })
});

export const { useGenerateApiKeyMutation } = automationApi;

export default automationApi;
