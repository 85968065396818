import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, Text, Flex, Button, LoadingOverlay } from '@mantine/core';
import { maybePluralize } from 'utils/utilities';
import styles from './styles.module.scss';

const UnableToAssignLicensesModal = (props) => {
  const {
    opened,
    setOpened,
    handleConfirmation,
    unusedLicenses = 0,
    selectedUsersCount = 0
  } = props;

  return (
    <>
      <LoadingOverlay />
      <Modal
        centered
        opened={opened}
        size={550}
        radius={4}
        className='mobile-modal'
        onClose={() => setOpened(false)}
        title={
          <Text className={styles.title}>
            Unable to assign {maybePluralize(selectedUsersCount, 'License')} to {selectedUsersCount}
            &nbsp;
            {maybePluralize(selectedUsersCount, 'teammate')}
          </Text>
        }
      >
        <Text className={styles.content}>
          To assign {maybePluralize(selectedUsersCount, 'License')} to {selectedUsersCount}&nbsp;
          {maybePluralize(selectedUsersCount, 'teammate').trim()}, buy&nbsp;
          {selectedUsersCount - unusedLicenses}&nbsp;
          {maybePluralize(selectedUsersCount - unusedLicenses, 'License').trim()}.
          {unusedLicenses
            ? ` Or assign your ${unusedLicenses} unassigned ${maybePluralize(
                unusedLicenses,
                'License'
              )}  to any ${unusedLicenses} ${maybePluralize(unusedLicenses, 'teammate')} randomly.`
            : ''}
        </Text>

        <Flex className={styles.buttonFlex}>
          {unusedLicenses ? (
            <Button
              variant='outline'
              className={classNames(styles.button, styles.buttonGreenOutline)}
              onClick={() => {
                handleConfirmation('Approved', unusedLicenses);
                setOpened(false);
              }}
            >
              Assign to {unusedLicenses} {maybePluralize(unusedLicenses, 'user')}
            </Button>
          ) : null}

          <Button
            className={classNames(styles.button, styles.buttonGreen, {
              [styles.rightmostButton]: !unusedLicenses
            })}
            onClick={() => {
              handleConfirmation('Approved', 0, true);
              setOpened(false);
            }}
          >
            Buy Licenses & assign to all
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

UnableToAssignLicensesModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  setOpened: PropTypes.func.isRequired,
  unusedLicenses: PropTypes.number.isRequired,
  selectedUsersCount: PropTypes.number.isRequired,
  handleConfirmation: PropTypes.func.isRequired
};

export default UnableToAssignLicensesModal;
