import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import api from './services/api';
import authenticateResponse from './middleware/authenticateResponse';
import userReducer from './features/userSlice';
import settingReducer from './features/settingsSlice';
import planReducer from './features/planSlice';
import licenseReducer from './features/licenseSlice';
import popupReducer from './features/popupSlice';
import connectionReducer from './features/connectionSlice';
import widgetsReducer from './features/widgetsSlice';

const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [api.reducerPath]: api.reducer,
    user: userReducer,
    settings: settingReducer,
    plan: planReducer,
    license: licenseReducer,
    popup: popupReducer,
    connections: connectionReducer,
    widgets: widgetsReducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([authenticateResponse, api.middleware])
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export default store;
