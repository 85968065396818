import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  widgets: []
};

export const widgetsSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    saveWidgets: (state, action) => ({ widgets: action.payload, loading: false }),
    updateWidget: (state, action) => {
      const { id, data } = action.payload;
      const widgetIndex = state.widgets.findIndex((widget) => widget.id === id);
      state.widgets[widgetIndex] = data;
    },
    addWidget: (state, action) => {
      state.widgets.push(action.payload);
    },
    deleteWidget: (state, action) => {
      const { id } = action.payload;
      const widgetIndex = state.widgets.findIndex((widget) => widget.id === id);
      state.widgets.splice(widgetIndex, 1);
    }
  }
});

// Action creators are generated for each case reducer function
export const { saveWidgets, addWidget, deleteWidget, updateWidget } = widgetsSlice.actions;

export default widgetsSlice.reducer;
