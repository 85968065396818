import PropTypes from 'prop-types';
import { Button } from '@mantine/core';
import AddSourceButton from '../AddSourceButton';
import ReAuthenticateButton from '../ReAuthenticateButton';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { ReactComponent as GridIcon } from 'assets/icons/grid.svg';
import { VIEW_TYPE } from 'utils/constants';
import styles from '../../connector.module.scss';

const ViewChanger = (props) => {
  const {
    viewType,
    setViewType,
    user,
    hideAddSourceButton,
    hideReAuthButton,
    provider,
    showUpdateModal,
    customWrapperStyles,
    pagination
  } = props;

  return (
    <div className={customWrapperStyles || styles.toolBar}>
      <div className={styles.buttonsGroup}>
        <Button.Group className={styles.actionBtnGroup}>
          <Button
            variant='default'
            className={viewType === VIEW_TYPE.LIST && styles.activeViewTypeBtn}
            onClick={() => {
              pagination?.setPage(1);
              setViewType(VIEW_TYPE.LIST);
              localStorage.setItem('viewType', VIEW_TYPE.LIST);
            }}
          >
            <ListIcon />
          </Button>
          <Button
            variant='default'
            className={viewType === VIEW_TYPE.GRID && styles.activeViewTypeBtn}
            onClick={() => {
              pagination?.setPage(1);
              setViewType(VIEW_TYPE.GRID);
              localStorage.setItem('viewType', VIEW_TYPE.GRID);
            }}
          >
            <GridIcon />
          </Button>
        </Button.Group>

        {!hideAddSourceButton && (
          <AddSourceButton isDisabled={!user.isAdmin} showUpdateModal={showUpdateModal} />
        )}
        {!hideReAuthButton && <ReAuthenticateButton user={user} provider={provider} />}
      </div>
    </div>
  );
};

ViewChanger.defaultProps = {
  viewType: 'grid',
  hideAddSourceButton: false,
  hideReAuthButton: true,
  showUpdateModal: false,
  customWrapperStyles: '',
  provider: ''
};

ViewChanger.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  user: PropTypes.object.isRequired,
  hideAddSourceButton: PropTypes.bool,
  hideReAuthButton: PropTypes.bool,
  provider: PropTypes.string,
  showUpdateModal: PropTypes.bool,
  customWrapperStyles: PropTypes.string,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  })
};

export default ViewChanger;
