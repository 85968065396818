import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Modal, PasswordInput, Text } from '@mantine/core';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon.svg';
import { ReactComponent as HidePassword } from 'assets/icons/hide-pass.svg';
import { ReactComponent as ShowPassword } from 'assets/icons/show-pass.svg';
import styles from './styles.module.scss';

const ApiKeyModal = (props) => {
  const { generatedKey, handleCloseModal, handleCopyClick, opened, showAlert } = props;
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Modal centered opened={opened} onClose={handleCloseModal} size='md' title='Generate API Key'>
      <Text size='sm' mb={10}>
        Store the API key securely. It won&apos;t be displayed again to keep your data safe.
      </Text>
      <Flex justify='flex-start' align='flex-end' gap={10} mb={15}>
        <PasswordInput
          variant='default'
          label='API key'
          disabled
          value={generatedKey}
          visible={isVisible}
          classNames={{ input: styles.input, innerInput: styles.innerInput }}
        />
        <Button
          variant='transparent'
          onClick={() => setIsVisible(!isVisible)}
          className={styles.icon}
        >
          {isVisible ? <ShowPassword /> : <HidePassword />}
        </Button>
      </Flex>
      {showAlert && (
        <div className={styles.errorMessage}>
          <ErrorIcon />
          <span>Copy the API key and store it securely.</span>
        </div>
      )}
      <Flex gap={10} justify='flex-end'>
        <Button
          variant='outline'
          color='primaryGreen.3'
          className={styles.copyBtn}
          onClick={handleCopyClick}
          disabled={!generatedKey}
        >
          <CopyIcon />
          &nbsp;Copy
        </Button>
        <Button
          variant='filled'
          color='primaryGreen.3'
          className={styles.closeBtn}
          onClick={handleCloseModal}
        >
          Close
        </Button>
      </Flex>
    </Modal>
  );
};

ApiKeyModal.propTypes = {
  generatedKey: PropTypes.string,
  handleCloseModal: PropTypes.func,
  handleCopyClick: PropTypes.func,
  opened: PropTypes.bool,
  showAlert: PropTypes.bool
};

export default ApiKeyModal;
