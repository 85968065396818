import PropTypes, { array, func } from 'prop-types';
import { useMediaQuery } from '@mantine/hooks';
import { Modal, Title, MultiSelect, Flex, Button } from '@mantine/core';
import styles from './styles.module.scss';

const AddAdminsModal = ({
  isLoading,
  opened,
  setOpened,
  users,
  adminsToBeAdded,
  setAdminsToBeAdded,
  handleConfirm
}) => {
  const screen767 = useMediaQuery('(max-width: 767px)');

  return (
    <>
      <Modal
        centered
        opened={opened}
        size={630}
        radius={4}
        className='mobile-modal'
        onClose={() => setOpened(false)}
        title={<Title className={styles.pageTitle}>Add company admin</Title>}
      >
        <div>
          <MultiSelect
            data={users}
            className='multi-select-style-v1'
            label='Select teammates'
            onChange={(selectedOptions) => {
              const selectedUsers = selectedOptions.map((option) =>
                users.find((user) => user.value === option)
              );
              setAdminsToBeAdded(selectedUsers);
            }}
            searchable
          />
        </div>

        <Flex mt={80} justify='flex-end'>
          <Button
            variant='outline'
            radius={4}
            color='dark'
            onClick={() => {
              setOpened(false);
              setAdminsToBeAdded([]);
            }}
            style={{ height: screen767 ? 35 : 42 }}
          >
            Cancel
          </Button>
          <Button
            className='primaryBtn'
            onClick={() => {
              setOpened(false);
              handleConfirm();
            }}
            ml={20}
            disabled={!adminsToBeAdded.length}
            loading={isLoading}
          >
            Confirm
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

AddAdminsModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  opened: PropTypes.bool.isRequired,
  setOpened: PropTypes.func.isRequired,
  users: array,
  adminsToBeAdded: array,
  setAdminsToBeAdded: func,
  handleConfirm: func
};

export default AddAdminsModal;
