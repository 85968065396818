import { END_POINT } from 'utils/apiEndpoints';
import api from './api';
import { toast } from 'react-toastify';

const connectorApi = api.injectEndpoints({
  endpoints: (build) => ({
    enableConnection: build.mutation({
      query: (provider) => ({
        url: `${END_POINT.CONNECTOR.ENABLE}/${provider}`,
        method: 'PATCH'
      }),
      transformErrorResponse: () =>
        toast.error('Could not enable the connection. Please try again later.'),
      invalidatesTags: ['Providers', 'User']
    })
  })
});

export const { useEnableConnectionMutation } = connectorApi;

export default connectorApi;
