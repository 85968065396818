import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { RouterProvider } from 'react-router-dom';
import router from 'router';
import { Flex } from '@mantine/core';
import { ENV } from 'utils/constants';
import { useLazyGetUserQuery } from 'redux/services/user';
import localStorage from 'utils/localStorage';

const App = () => {
  const hasToken = localStorage.hasToken();
  const [isSessionChecked, setSessionChecked] = useState(false);
  const [getUser] = useLazyGetUserQuery();

  useEffect(() => {
    if (import.meta.env.VITE_NODE_ENV === ENV.PRODUCTION) {
      Sentry.init({
        dsn: 'https://372963932a52c931178d7c913ebaf259@o271082.ingest.sentry.io/4506234103332864',
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['https://api-albus.springworks.in']
          }),
          new Sentry.Replay()
        ],
        environment: 'production',
        // Performance Monitoring
        tracesSampleRate: 0.3, // Capture 30% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }

    let e = document.getElementById('zsiq_agtpic');
    if (e !== null && e !== undefined) {
      e.parentElement.style.height = '50px';
      e.parentElement.style.width = '50px';
      e.parentElement.style.left = '6px';
    }

    if (hasToken) {
      !isSessionChecked && checkSessionValidity();
    } else {
      setSessionChecked(true);
    }
  }, []);

  async function checkSessionValidity() {
    await getUser();
    setSessionChecked(true);
  }

  if (isSessionChecked)
    return (
      <Flex>
        <RouterProvider router={router} />
      </Flex>
    );
};
export default App;
