import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useInviteUsersMutation } from 'redux/services/invite';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import View from './View';

const InviteViaLinkModal = ({ open, onClose }) => {
  const { user, loading: isUserLoading } = useSelector((state) => state.user);
  const [selectedOption, setSelectedOption] = useState('');
  const [domains, setDomains] = useState([]);
  const [generatedLink, setGeneratedLink] = useState('');
  const genericDomains = ['gmail.com', 'yahoo.com', 'hotmail.com'];
  const [isGeneric, setIsGeneric] = useState(true);
  const [isDisabled, setIsDisabled] = useState(null);
  const [buttonText, setButtonText] = useState('');
  const [inviteUsers] = useInviteUsersMutation();

  useEffect(() => {
    if (user && user.email) {
      const userDomain = user?.email?.split('@')[1];
      genericDomains.includes(userDomain)
        ? setSelectedOption('Allow any email address')
        : handleCustomDomain(userDomain);

      setGeneratedLink('Click on generate link to get invite link');
      setButtonText('Generate link');
    }
  }, [open, user]);

  function handleCustomDomain(userDomain) {
    setSelectedOption('only allow');
    setIsGeneric(false);
    setDomains([userDomain]);
  }

  function handleOnChange(value) {
    const pattern = /^(?:@)?[A-Za-z0-9-]{1,63}(?:\.[A-Za-z]{2,})+$/;
    const validDomains = value.filter((domain) => pattern.test(domain));

    if (validDomains.length === value.length) {
      setIsDisabled(false);
      setDomains(validDomains);
      setButtonText('Generate link');
      setGeneratedLink('Click on generate link to get invite link');
    } else {
      toast.error('Invalid allowed domain');
      setIsDisabled(true);
      setDomains(validDomains);
    }
  }

  async function handleGenerateLink() {
    const inviteCode = nanoid(24);

    let allowedDomains = [];
    if (selectedOption === 'only allow') {
      allowedDomains = domains.map((domain) => {
        const domainToSend = domain[0] === '@' ? domain.split('@')[1] : domain;
        return domainToSend;
      });
    }

    const inviteBody = {
      type: 'group',
      code: inviteCode,
      allowedDomains: allowedDomains
    };

    setGeneratedLink('Please wait, the link is being generated...');

    try {
      await inviteUsers(inviteBody);
      setGeneratedLink(`${import.meta.env.VITE_API_URL}/invite/join/${inviteCode}`);
      setButtonText('Copy link');
    } catch (error) {
      toast.error('Failed to geneate invite link. Please try again.');
      setGeneratedLink('Click on generate link to get invite link');
      setButtonText('Generate link');
    }
  }

  async function handleCopyUrl() {
    try {
      await navigator.clipboard.writeText(generatedLink);
      toast.success('Link copied successfully');
    } catch (err) {
      toast.error("Couldn't copy link. Try again");
    }
  }

  function handleRadioChange(value) {
    setButtonText('Generate link');
    setGeneratedLink('Click on generate link to get invite link');
    setSelectedOption(value);
  }

  return (
    <View
      open={open}
      onClose={onClose}
      selectedOption={selectedOption}
      domains={domains}
      setDomains={setDomains}
      generatedLink={generatedLink}
      handleRadioChange={handleRadioChange}
      handleOnChange={handleOnChange}
      handleGenerateLinkButtonClick={handleGenerateLink}
      handleCopyUrl={handleCopyUrl}
      isGeneric={isGeneric}
      isDisabled={isDisabled}
      userDomain={isUserLoading ? '' : user?.email?.split('@')[1]}
      buttonText={buttonText}
      setButtonText={setButtonText}
      setGeneratedLink={setGeneratedLink}
    />
  );
};

InviteViaLinkModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default InviteViaLinkModal;
