import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { toast } from 'react-toastify';
import { useForm } from '@mantine/form';
import {
  useGetSettingsQuery,
  useUpdateSettingMutation,
  useCreatePersonaMutation,
  useUpdatePersonaMutation,
  useDeletePersonaMutation
} from 'redux/services/settings';
import View from './View';
import ConfirmationModal from './ConfirmationModal';

const Personas = () => {
  const { data: settings } = useGetSettingsQuery();
  const [updateSettings, { isLoading: settingsUpdateInProgress }] = useUpdateSettingMutation();
  const [createPersona, { isLoading: creationInProgress }] = useCreatePersonaMutation();
  const [updatePersona, { isLoading: updateInProgress }] = useUpdatePersonaMutation();
  const [deletePersona, { isLoading: deletionInProgress }] = useDeletePersonaMutation();

  const [personaToEditOrDelete, setPersonaToEditOrDelete] = useState();
  const [focusedPersona, setFocusedPersona] = useState();
  const [modalData, setModalData] = useState({
    isOpen: false,
    value: ''
  });
  const [showCustomForm, setShowCustomForm] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [personasList, setPersonasList] = useState([]);

  const screen991 = useMediaQuery('(max-width: 991px)');

  const customPersonaForm = useForm({
    initialValues: {
      name: '',
      prompt: ''
    },
    clearInputErrorOnChange: true,
    validateInputOnBlur: true,
    validate: {
      name: (value) => {
        if (!value.trim()) {
          return 'This is a required field';
        }
        if (value.trim().length < 3) {
          return 'Name must be at least 3 characters long';
        }
        return null;
      },
      prompt: (value) => {
        if (!value.trim()) {
          return 'This is a required field';
        }
        if (value.trim().length < 10) {
          return 'Definition must be at least 10 characters long';
        }
        return null;
      }
    }
  });

  const handleTileClick = (persona) => {
    setFocusedPersona(persona);
  };

  const handleSelectBtnClick = (value) => {
    setModalData({
      isOpen: true,
      value
    });
  };

  const handleCustomFormClose = () => {
    setShowCustomForm(false);
    setPersonaToEditOrDelete(null);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteConfirmation(false);
    setPersonaToEditOrDelete(null);
  };

  const handleEditPersonaClick = async (persona) => {
    setPersonaToEditOrDelete(persona);
    customPersonaForm.setValues({ name: persona.name, prompt: persona.prompt });
    setShowCustomForm(true);
  };

  const handleDeletePersonaClick = async (persona) => {
    setPersonaToEditOrDelete(persona);
    setShowDeleteConfirmation(true);
  };

  const handleChangePersona = async () => {
    const response = await updateSettings({
      persona: modalData.value
    });
    if (response?.data?.ok) {
      setModalData({ isOpen: false, value: '' });
      toast.success('Persona selected successfully');
      if (screen991) {
        // for mobile, reset focused persona
        handleTileClick({});
      }
    }
  };

  const handleCreateOrEditPersona = async (values) => {
    if (personaToEditOrDelete) {
      await updatePersona({
        id: personaToEditOrDelete._id,
        ...values
      });
    } else {
      await createPersona(values);
    }
    handleCustomFormClose();
  };

  const handleDeletePersona = async (id) => {
    await deletePersona(id);
    handleDeleteModalClose();
  };

  useEffect(() => {
    if (settings?.personas?.length) {
      const presetPersonas = settings?.personas?.filter((p) => p.scope === 'global');

      const customPersonas = settings?.personas
        .filter((p) => p.scope === 'organisation')
        .map((p) => ({
          ...p,
          icon: 'DefaultPersonaIcon',
          details:
            'This persona was set from BE. Reach out to Albus support to modify the persona.',
          question: 'What is business? Explain in 50 words.',
          answer: 'personas set from BE cannot be previewed.'
        }));

      setPersonasList([...presetPersonas, ...customPersonas]);
    }
  }, [settings]);

  useEffect(() => {
    setFocusedPersona(personasList.find((p) => p._id === settings?.selectedPersona));
  }, [personasList]);

  return (
    <>
      {modalData.isOpen ? (
        <ConfirmationModal
          isOpen={modalData.isOpen}
          onClose={() => setModalData({ isOpen: false, value: '' })}
          handleCancel={() => setModalData({ isOpen: false, value: '' })}
          handleChange={handleChangePersona}
          prevPersona={personasList.find((p) => p._id === settings?.selectedPersona).name}
          nextPersona={personasList.find((p) => p._id === modalData.value).name}
          isLoading={settingsUpdateInProgress}
        />
      ) : null}
      {personasList.length && focusedPersona ? (
        <View
          personasList={personasList}
          selectedPersona={settings?.selectedPersona}
          focusedPersona={focusedPersona}
          handleSelectBtnClick={handleSelectBtnClick}
          handleTileClick={handleTileClick}
          handleEditPersonaClick={handleEditPersonaClick}
          handleDeletePersonaClick={handleDeletePersonaClick}
          showCustomForm={showCustomForm}
          setShowCustomForm={setShowCustomForm}
          personaToEditOrDelete={personaToEditOrDelete}
          handleCustomFormClose={handleCustomFormClose}
          customPersonaForm={customPersonaForm}
          handleCreateOrEditPersona={handleCreateOrEditPersona}
          loading={creationInProgress || updateInProgress || deletionInProgress}
          handleDeleteModalClose={handleDeleteModalClose}
          showDeleteConfirmation={showDeleteConfirmation}
          handleDeletePersona={handleDeletePersona}
        />
      ) : null}
    </>
  );
};

export default Personas;
