import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, Image, Divider, Button, Tooltip, Avatar } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import arrow from 'assets/right-arrow.svg';
import FallbackProfilePic from 'assets/profile.svg';
import { truncateName } from 'pages/Integrations/utils';
import { maybePluralize } from 'utils/utilities';
import { capitalizeInitials } from 'utils/formatters';
import styles from './styles.module.scss';

const View = (props) => {
  const { joinedCompanies, count, handleOpen } = props;

  const screen560 = useMediaQuery('(max-width: 560px)');
  const [displayCount, setDisplayCount] = useState(3);
  const THREE = 3;

  const handleViewMore = () => {
    setDisplayCount(joinedCompanies.length);
  };

  const handleViewLess = () => {
    setDisplayCount(THREE);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerLable}>
        <Text className={styles.text}>You already joined</Text>
      </div>

      <div>
        <div className={styles.wrapper}>
          {joinedCompanies?.slice(0, displayCount).map((company, index, array) => (
            <div key={company.id}>
              <div className={styles.companyWrapper}>
                <div className={styles.companyContainer}>
                  {company?.organisation?.logo ? (
                    <Image className={styles.companyProfile} src={company.organisation.logo} />
                  ) : (
                    <Avatar color='blue' mr='10' size={42} alt='profile pic' radius='md'>
                      {capitalizeInitials(company.organisation.name)}
                    </Avatar>
                  )}
                  <div className={styles.companyInfo}>
                    <Tooltip
                      disabled={company.organisation.name.length <= 25}
                      label={company.organisation.name}
                    >
                      <Text className={styles.companyName}>
                        {truncateName(company.organisation.name, 25)}
                      </Text>
                    </Tooltip>
                    <div className={styles.profiles}>
                      {company?.users?.map((user, index) => (
                        <Tooltip key={index} w='fit-content' label={user.name}>
                          <Image
                            key={index}
                            className={styles.profile}
                            src={user.photo || FallbackProfilePic}
                          />
                        </Tooltip>
                      ))}
                      <Text className={styles.profileCount}>
                        {company.usersCount} {maybePluralize(company.usersCount, 'member')}
                      </Text>
                    </div>
                  </div>
                  {!screen560 && (
                    <span
                      onClick={() => handleOpen(company.organisation.id)}
                      className={styles.openButton}
                    >
                      Open
                    </span>
                  )}
                  {!screen560 && <Image src={arrow} />}
                </div>
                {screen560 && (
                  <Button
                    onClick={() => handleOpen(company.organisation.id)}
                    classNames={{
                      root: styles.greenButton,
                      label: styles.buttonText
                    }}
                  >
                    Open
                  </Button>
                )}

                {/*hide divider line for last company when total companies count is <=3*/}
                {(count > THREE || index !== array.length - 1) && (
                  <Divider className={styles.divider} mt={18} size='sm' />
                )}
              </div>
            </div>
          ))}
        </div>
        {count > THREE &&
          (displayCount < joinedCompanies.length ? (
            <span onClick={handleViewMore} className={styles.viewButton}>
              View {count - THREE} more {count - THREE > 1 ? 'companies' : 'company'}
            </span>
          ) : (
            <span onClick={handleViewLess} className={styles.viewButton}>
              View less
            </span>
          ))}
      </div>
    </div>
  );
};

View.propTypes = {
  joinedCompanies: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  handleOpen: PropTypes.func.isRequired
};

export default View;
