import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, TextInput, Group } from '@mantine/core';
import { useSelector } from 'react-redux';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { NAVBAR_ROUTES } from 'utils/constants.js';
import styles from './styles.module.scss';

const TableHeader = (props) => {
  const { value, setValue, ctaHandler, isTeamTable, teamId } = props;

  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  return (
    <Flex className={styles.header} align='center'>
      <TextInput
        classNames={{
          input: styles.search,
          root: styles.inputRoot
        }}
        placeholder={isTeamTable ? 'Search for teams' : 'Search for teammates'}
        leftSection={<SearchIcon />}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {isTeamTable && user?.isAdmin && (
        <Button
          id='create-team'
          className={classNames('primaryBtn', styles.cta)}
          onClick={ctaHandler}
        >
          <SVGIcon name={ICONS_LIST.ADD_FILLED} height={14} width={14} /> Create Team
        </Button>
      )}
      {!isTeamTable && (
        <Group justify='right'>
          {user?.isAdmin && (
            <Button
              className={classNames('primaryBtnOutline', styles.cta)}
              onClick={() => navigate(`${NAVBAR_ROUTES.INTEGRATIONS}/${teamId}`)}
            >
              Manage team wiki
            </Button>
          )}
          <Button className={classNames('primaryBtn', styles.cta)} onClick={ctaHandler}>
            Add teammates
          </Button>
        </Group>
      )}
    </Flex>
  );
};

TableHeader.defaultProps = {
  isTeamTable: false,
  teamId: ''
};

TableHeader.propTypes = {
  teamId: PropTypes.string,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  ctaHandler: PropTypes.func.isRequired,
  isTeamTable: PropTypes.bool
};

export default TableHeader;
