import { Flex, Modal, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FeatureCard from './FeatureCard';
import { FEATURES_CARD_LIST } from './FeatureCard/data';

const TutorialModal = ({ closeHandler, isAdmin, isTeamAdmin, isSlackPlatform }) => {
  const navigate = useNavigate();

  function filterFeaturesByTitle(titles, include = true) {
    return FEATURES_CARD_LIST.filter((feature) => include === titles.includes(feature.title));
  }

  function filterFeatures() {
    if (isSlackPlatform) {
      if (isAdmin) {
        return FEATURES_CARD_LIST;
      }

      if (isTeamAdmin) {
        return filterFeaturesByTitle(['Ask Albus', 'Wiki']);
      }

      return filterFeaturesByTitle(['Ask Albus']);
    }

    if (isAdmin) {
      return filterFeaturesByTitle(['Teams', 'Escalations'], false);
    }
    return filterFeaturesByTitle(['Ask Albus']);
  }

  return (
    <Modal
      size={764}
      centered
      opened
      onClose={closeHandler}
      className='mobile-modal'
      closeOnClickOutside={false}
    >
      <Flex direction='column' justify='center' align='center' gap={4} mb={50}>
        <Text size='lg' fw={600} h={27} ta='center'>
          Take a tour of Albus
        </Text>
        <Text size='sm' fw={400} h={24} c='#5E5873' ta='center'>
          Explore various capabilities, features and settings of Albus in minutes
        </Text>
      </Flex>
      <Flex justify='center' align='center' wrap='wrap' gap={20} mb={30}>
        {filterFeatures().map((feature) => {
          const clickHandler = () => navigate(feature.route);
          return <FeatureCard key={feature.title} {...feature} clickHandler={clickHandler} />;
        })}
      </Flex>
    </Modal>
  );
};

TutorialModal.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isTeamAdmin: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  isSlackPlatform: PropTypes.bool.isRequired
};

export default TutorialModal;
