import PropTypes from 'prop-types';
import View from './View';
import { useLoginCompanyMutation } from 'redux/services/auth';

const JoinedCompanies = ({ companiesList }) => {
  const [loginCompany] = useLoginCompanyMutation();

  async function handleOpen(id) {
    const { data } = await loginCompany({ id });
    if (data?.ok) {
      window.open(`${import.meta.env.VITE_BASE_URL}/?token=${data.data.token}`, '_self');
    }
  }

  return (
    <View joinedCompanies={companiesList} count={companiesList.length} handleOpen={handleOpen} />
  );
};

JoinedCompanies.propTypes = {
  companiesList: PropTypes.array.isRequired
};

export default JoinedCompanies;
