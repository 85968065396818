import { Avatar } from '@mantine/core';
import PropTypes from 'prop-types';
import { capitalizeInitials } from 'utils/formatters';

const ProfilePicture = (props) => {
  const { photoUrl, name } = props;

  if (photoUrl) {
    return <Avatar color='blue' alt='profile pic' src={photoUrl} size={40} radius='xl' />;
  }

  if (name) {
    return (
      <Avatar color='blue' size={42} alt='profile pic' radius='xl'>
        {capitalizeInitials(name)}
      </Avatar>
    );
  }
  return <Avatar size={42} alt='profile pic' radius='xl' />;
};

ProfilePicture.defaultProps = {
  photoUrl: '',
  name: ''
};

ProfilePicture.propTypes = {
  photoUrl: PropTypes.string,
  name: PropTypes.string
};

export default ProfilePicture;
