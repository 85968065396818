import { Flex, UnstyledButton, Image } from '@mantine/core';
import { useProSidebar } from 'react-pro-sidebar';
import AlbusLogo from 'assets/albus-mobile-logo.svg';
import { ReactComponent as HamburgerMenuIcon } from 'assets/icons/hamburger-menu.svg';
import styles from './styles.module.scss';

const MobileHeader = () => {
  const { toggleSidebar } = useProSidebar();

  return (
    <Flex
      mih={50}
      justify={'space-between'}
      align={'center'}
      wrap='wrap'
      className={styles.mobileHeader}
    >
      <Image src={AlbusLogo} alt='albus logo' w={91} />
      <Flex gap='2rem'>
        <UnstyledButton onClick={toggleSidebar}>
          <HamburgerMenuIcon />
        </UnstyledButton>
      </Flex>
    </Flex>
  );
};

export default MobileHeader;
