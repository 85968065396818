import { toast } from 'react-toastify';
import Pluralize from 'pluralize';
import {
  isUsersLoading,
  saveLicenseUsersInfo,
  saveTotalRequests
} from 'redux/features/licenseSlice';
import api from './api';
import { LICENSE, LICENSE_REQUESTS, LICENSE_USERS, FETCH_SLACK_CHANNELS } from 'utils/apiEndpoints';
import { handleApiError } from 'utils/helpers';

export const licensesApi = api.injectEndpoints({
  endpoints: (build) => ({
    // Fetch Filters
    fetchChannelFilters: build.query({
      query: (params) => ({
        url: FETCH_SLACK_CHANNELS,
        method: 'GET',
        params
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        } else {
          toast.error('Failed to fetch channels or teams');
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),

    // Fetch Users With Status and Other Filters
    fetchLicenseUsers: build.query({
      query: (params) => ({
        url: LICENSE_USERS,
        method: 'GET',
        params: { ...params, pageCount: 10 }
      }),
      providesTags: ['Licenses', 'Plan'],
      keepUnusedDataFor: 0,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(isUsersLoading(true));
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(saveLicenseUsersInfo(response));
            if (args && args.status === 'requested') {
              dispatch(saveTotalRequests(response.total));
            }
          } else {
            toast.error('Failed to fetch license users.');
          }
          dispatch(isUsersLoading(false));
        } catch ({ error }) {
          handleApiError(error);
        }
      }
    }),

    // Fetch Only Requested users
    fetchLicenseRequests: build.query({
      query: () => ({
        url: LICENSE_USERS + '?status=requested&page=1&pageCount=1000&channel=all',
        method: 'GET'
      }),
      providesTags: ['LicenseRequests'],
      keepUnusedDataFor: 0,
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        } else {
          toast.error('Failed to fetch user requested users');
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),

    // Assign or Unassign Licenses
    updateLicenses: build.mutation({
      query: (licenseUpdates) => ({
        url: LICENSE,
        method: 'PATCH',
        body: licenseUpdates
      }),
      invalidatesTags: ['Licenses', 'Plan'],
      transformResponse: (response, meta, args) => {
        if (response.ok) {
          const reqCount = args.ids.length;
          const message = `${args.status} ${args.ids.length} ${Pluralize(
            'License',
            reqCount
          )} successfully`;
          toast.success(message);
        }
        toast.error(response.message);
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),

    // Approve or Deny Requests
    updateLicenseRequests: build.mutation({
      query: (requests) => ({
        url: LICENSE_REQUESTS,
        method: 'POST',
        body: { ...requests }
      }),
      invalidatesTags: ['Licenses', 'Plan'],
      transformResponse: (response, meta, args) => {
        if (response.ok) {
          const reqCount = args.requests.length;
          const action = args.status === 'Approve' ? 'Approved' : 'Denied';
          const message = `${action}  ${Pluralize('License', reqCount)} to ${reqCount} ${Pluralize(
            'teammate',
            reqCount
          )} successfully`;
          toast.success(message);
        }
        toast.error(response.message);
      },
      transformErrorResponse: (error) => handleApiError(error)
    })
  })
});

export const {
  useFetchLicenseUsersQuery,
  useUpdateLicensesMutation,
  useUpdateLicenseRequestsMutation,
  useFetchLicenseRequestsQuery,
  useFetchChannelFiltersQuery
} = licensesApi;
