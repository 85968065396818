import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import View from './View';

const States = (props) => {
  const { buyLicense, unassignedLicenses } = props;
  const { planInfo } = useSelector((state) => state.plan);

  return (
    <View
      buyLicense={buyLicense}
      unassignedLicenses={unassignedLicenses}
      licensesAssigned={planInfo.plan?.licensesAssigned}
      billingDate={planInfo.billingDate}
      addOnInteractionsLeft={planInfo.addOnInteractionsLeft}
      questionsLeft={planInfo.questionsLeft}
      billingCycle={planInfo.billingCycle}
      isSetToCancel={planInfo.isSetToCancel}
      allowedInteractions={planInfo.allowedInteractions}
    />
  );
};

States.propTypes = {
  buyLicense: PropTypes.func.isRequired,
  unassignedLicenses: PropTypes.number.isRequired
};

export default States;
