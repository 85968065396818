import React, { useState } from 'react';
import { array, func } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Menu, Pagination, Table, TextInput, UnstyledButton } from '@mantine/core';
import { usePagination } from '@mantine/hooks';
import pluralize from 'pluralize';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as AddIcon } from 'assets/icons/plus-o.svg';
import { ReactComponent as ActionIcon } from 'assets/icons/3-v-dots.svg';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal/index.jsx';
import { NAVBAR_ROUTES } from 'utils/constants.js';
import styles from './WikiTable.module.scss';

const ROWS_PER_PAGE = 10;

const WikiTable = ({ data, onAddWiki, onRemoveWiki }) => {
  const [search, setSearch] = useState('');
  const [page, onChange] = useState(1);
  const [selectedWiki, setSelectedWiki] = useState(null);
  const navigate = useNavigate();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const pagination = usePagination({
    total: Math.ceil(data.length / ROWS_PER_PAGE),
    page,
    onChange,
    siblings: 2
  });
  let wikisToDisplay = [...data];
  if (search) {
    wikisToDisplay = wikisToDisplay.filter((wiki) =>
      wiki.name.toLowerCase().includes(search.toLowerCase())
    );
  }
  const hasPagination = wikisToDisplay.length > ROWS_PER_PAGE;
  const totalPages = Math.ceil(wikisToDisplay.length / ROWS_PER_PAGE);
  const pageWikis = wikisToDisplay.slice(
    (pagination.active - 1) * ROWS_PER_PAGE,
    pagination.active * ROWS_PER_PAGE
  );

  const handleOnSelectedForRemoval = (wiki) => {
    setSelectedWiki(wiki);
    setShowWarningModal(true);
  };

  const clearSelectionAndCloseModal = () => {
    setSelectedWiki(null);
    setShowWarningModal(false);
  };

  return (
    <>
      <div className={styles.tableContainer}>
        <div className={styles.searchSection}>
          <TextInput
            className={styles.searchBar}
            leftSection={<SearchIcon />}
            placeholder='Search'
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
          <Button
            className={styles.createButton}
            radius='sm'
            leftSection={<AddIcon />}
            onClick={() => onAddWiki(true)}
          >
            Add wiki
          </Button>
        </div>
        <div className={styles.tableWrapper}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Wiki name</Table.Th>
                <Table.Th />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {pageWikis.map((wiki) => (
                <Table.Tr key={wiki.id}>
                  <Table.Td>{wiki.name}</Table.Td>
                  <Table.Td>
                    <Menu position='left-end' withArrow>
                      <Menu.Target>
                        <UnstyledButton className={styles.menuIcon}>
                          <ActionIcon />
                        </UnstyledButton>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item onClick={() => handleOnSelectedForRemoval(wiki)}>
                          Remove from widget
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => navigate(`${NAVBAR_ROUTES.INTEGRATIONS}/${wiki.id}`)}
                        >
                          Edit wiki
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </div>
        <Flex justify={hasPagination ? 'space-between' : 'flex-end'} align='center' mt={20} pb={16}>
          {hasPagination && (
            <Pagination
              size='xs'
              color='dark'
              siblings={2}
              total={totalPages}
              value={pagination.active}
              onChange={pagination.setPage}
            />
          )}
          <div className={styles.pageCounter}>
            <strong>
              {(pageWikis.length > 0 ? (pagination.active - 1) * ROWS_PER_PAGE + 1 : 0) +
                '-' +
                Math.min(ROWS_PER_PAGE * pagination.active, wikisToDisplay.length)}
            </strong>
            &nbsp;of&nbsp;
            <strong>{wikisToDisplay.length}</strong>{' '}
            {pluralize('result', wikisToDisplay.length, false)}
          </div>
        </Flex>
      </div>
      <DeleteWarningModal
        onClose={clearSelectionAndCloseModal}
        handleCancel={clearSelectionAndCloseModal}
        open={showWarningModal}
        handleDelete={() => {
          onRemoveWiki(selectedWiki);
          clearSelectionAndCloseModal();
        }}
        header='Remove this wiki from widget'
        title={
          <div className={styles.confirmationMessage}>
            Are you sure you want to remove the wiki <strong>{selectedWiki?.name}</strong> from this
            widget?
          </div>
        }
      >
        <div className={styles.noteMessage}>
          <span>Note:</span>Albus will no longer use sources inside this wiki to answer questions
          asked through this widget.
        </div>
      </DeleteWarningModal>
    </>
  );
};

WikiTable.propTypes = {
  data: array.isRequired,
  onAddWiki: func.isRequired,
  onRemoveWiki: func.isRequired
};

export default WikiTable;
