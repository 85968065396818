import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Badge, Group } from '@mantine/core';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { ReactComponent as NewFeatureIcon } from 'assets/icons/new-feature.svg';
import styles from './styles.module.scss';

const Panel = ({ header, cardsData }) => {
  const cards = cardsData.map(({ title, description, handler, isNew, id }, index) => (
    <div onClick={handler} key={`card-${index}`} className={styles.cardWrapper} id={id}>
      <div className={styles.titleWrapper}>
        <Group gap={8}>
          {isNew && <NewFeatureIcon />}
          <h3 className={classnames({ [styles.newFeature]: isNew })}>{title}</h3>
          {isNew && (
            <Badge
              variant='gradient'
              gradient={{ from: '#462980 0%', to: '#6129CF 114.13%', deg: 90 }}
              size='md'
            >
              New
            </Badge>
          )}
        </Group>
        <SVGIcon name={ICONS_LIST.RIGHT_ACCORDION} height={18} width={18} />
      </div>
      <p>{description}</p>
    </div>
  ));
  return (
    <div className={styles.panelWrapper}>
      <header>{header}</header>
      <section>{cards}</section>
    </div>
  );
};

Panel.propTypes = {
  header: PropTypes.string.isRequired,
  cardsData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
      isNew: PropTypes.bool
    })
  ).isRequired
};

export default Panel;
