import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConnectSourceButtonUI } from '../../Components/ConnectSourceButton';
import { useLazyGetConnectionTokenQuery } from 'redux/services/user';
import { useSelector } from 'react-redux';
import { NAVBAR_ROUTES, PROVIDERS } from 'utils/constants';
import Disclosure from '../Disclosure';

const GoogleConnectButton = () => {
  const { user } = useSelector((state) => state.user);
  const [openDisclosure, setOpenDisclosure] = useState(false);
  const { teamId } = useParams();
  const isOnboarding = window.location.pathname.includes('/on-boarding');
  const [getConnectionToken] = useLazyGetConnectionTokenQuery();

  const handleConnect = async () => {
    const isGoogleAuthenticated = user?.connections?.find(
      (_conn) => _conn.provider === PROVIDERS.GOOGLE
    );
    if (isGoogleAuthenticated) {
      await getConnectionToken(PROVIDERS.GOOGLE);
    } else {
      setOpenDisclosure(true);
    }
  };

  return (
    <>
      <ConnectSourceButtonUI handleConnect={handleConnect} />
      <Disclosure
        opened={openDisclosure}
        setOpened={setOpenDisclosure}
        handleContinue={() =>
          window.open(
            `${import.meta.env.VITE_API_URL}/auth/google/redirect?userId=${user._id}${
              teamId ? `&teamId=${teamId}` : ''
            }${
              isOnboarding
                ? `&redirectURL=${import.meta.env.VITE_BASE_URL}${NAVBAR_ROUTES.ON_BOARDING}`
                : ''
            }`,
            '_self'
          )
        }
      />
    </>
  );
};

export default GoogleConnectButton;
