export const VIDEO_DATA = [
  {
    id: 1,
    title: 'Editing descriptions of connections',
    url: 'https://video.gumlet.io/614ac1fa71ee9511b70253de/65aa0d89f04d2abfbb659c63/main.m3u8'
  },
  {
    id: 2,
    title: 'Editing descriptions of sources',
    url: 'https://video.gumlet.io/614ac1fa71ee9511b70253de/65aa0d89f04d2abfbb659c61/main.m3u8'
  }
];
