import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Center, Container, Image, Text, LoadingOverlay } from '@mantine/core';
import { useUpdateSettingMutation } from 'redux/services/settings';
import Layout from 'components/Layout';
import TrainingImage from 'assets/start-training.svg';
import { NAVBAR_ROUTES } from 'utils/constants';
import styles from './styles.module.scss';

const FirstUserLogin = () => {
  const navigate = useNavigate();
  const [updateSettings, { isLoading }] = useUpdateSettingMutation();
  const { user } = useSelector((state) => state.user);

  const handleCreateNowCLick = async () => {
    const { data } = await updateSettings({
      training: true
    });
    if (data?.ok) {
      navigate(NAVBAR_ROUTES.SETTINGS_TABS.CONNECTIONS);
    }
  };

  return (
    <Layout pageTitle={'Integrations'}>
      <LoadingOverlay visible={isLoading} />
      <div className={styles.dashboardPage}>
        <Container mb={80}>
          <Center>
            <Image className={styles.albusTrainImage} src={TrainingImage} alt='Training Image' />
          </Center>
          <Text className={styles.pageTitle}>Create your wiki (beta)</Text>
          <Text className={styles.pageContent}>
            Create your wiki on internal sources like your company policies, Slack channels, Jira®,
            Confluence® and more. When an employee asks me a question, I will try to write an
            appropriate answer based on your wiki.
          </Text>
          <Center>
            <Button
              className={styles.primaryBtn}
              disabled={!user.isAdmin}
              onClick={handleCreateNowCLick}
            >
              <Link style={{ color: 'white', textDecoration: 'none' }}>Create now</Link>
            </Button>
          </Center>
        </Container>
      </div>
    </Layout>
  );
};

export default FirstUserLogin;
