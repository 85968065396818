import { Button, Divider, Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import TeamPlanImage from 'assets/team-plan.svg';
import styles from './styles.module.scss';

const WelcomeModal = ({ closeHandler, getStartedHandler, userName }) => {
  return (
    <Modal
      opened
      onClose={closeHandler}
      centered
      size={667}
      radius={8}
      className={'mobile-modal'}
      classNames={{ header: styles.header, body: styles.body }}
      closeOnClickOutside={false}
    >
      <div className={styles.wrapper}>
        <img className={styles.img} src={TeamPlanImage} />
        <div className={styles.contentWrapper}>
          <p className={styles.text}>
            Welcome <p className='capitalize-first-letter'>{userName}!</p>
          </p>
          <p className={styles.subText}>
            Let&apos;s take a quick tour of Albus and explore what all you can achieve for your
            company together.
          </p>
        </div>
      </div>
      <Divider mt={24} mb={24} />
      <div className={styles.footer}>
        <Button className='primaryBtn' onClick={getStartedHandler}>
          Get Started
        </Button>
      </div>
    </Modal>
  );
};

WelcomeModal.propTypes = {
  userName: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  getStartedHandler: PropTypes.func.isRequired
};

export default WelcomeModal;
