import {
  saveUserInfo,
  addValidationChats,
  updateValidationChatsConversation,
  updateValidationChatsContinuePromptId
} from 'redux/features/userSlice';
import { setFreeTrialExpired, setQuestionsToZero } from 'redux/features/planSlice';

/**
 * Checks and updates the user setup status based on the response received.
 * Returns the updated response.
 *
 * @param {Object} response - The response received from the server.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @param {Object} user - The user object.
 * @param {Function} setIsStreaming - The state setter function for setIsStreaming.
 * @returns {string|Object} - The updated response.
 */
export function checkAndUpdateUserSetupStatus(response, dispatch, user) {
  if (response?.state === 'User Ineligible') {
    dispatch(setFreeTrialExpired());
  }
  if (response?.state === 'No Questions Left') {
    dispatch(setQuestionsToZero());
  }
  if (response?.state === 'Not Fully Setup') {
    dispatch(saveUserInfo({ ...user, isNotFullySetup: true }));
  } else if (user?.isNotFullySetup) {
    dispatch(saveUserInfo({ ...user, isNotFullySetup: false }));
  }
}

/**
 * Generates a random prompt ID - using for validation sort of chats eg: 'hi', 'hello', 'help'
 * @returns {string} The generated random prompt ID eg: 'vald12345678901234567890'
 */
export function generateRandomPromptId() {
  const prefix = 'vald';
  const maxLength = 24; // Maximum length of regular prompt IDs
  const randomNumberLength = maxLength - prefix.length;

  let randomId = prefix;
  for (let i = 0; i < randomNumberLength; i++) {
    randomId += Math.floor(Math.random() * 10); // Append a random digit (0-9)
  }

  return randomId;
}

/**
 * Handles non-end value response in the chat.
 * If no start/end value exists, it handles validation sort of chats.
 * @param {Object} lastTwoConversations - The last two conversations in the chat.
 * @param {Object} selectedChat - The selected chat.
 * @param {Object} validationChats - The validation chats.
 * @param {Function} dispatch - The dispatch function.
 * @param {Function} updateChatHistoryWithPromptId - The function to update chat history with prompt ID.
 */
export function handleValidationChatUpdates(
  lastTwoConversations,
  selectedChat,
  validationChats,
  dispatch,
  updateChatHistoryWithPromptId
) {
  //if no start/end value exists - chat is validation sort of chats - eg: 'hi', 'hello', 'help'
  if ((selectedChat && !validationChats[selectedChat.promptId]) || !selectedChat) {
    const promptId = generateRandomPromptId();
    updateChatHistoryWithPromptId(promptId);
    dispatch(addValidationChats({ promptId: promptId, conversations: lastTwoConversations }));
    if (selectedChat) {
      dispatch(
        updateValidationChatsContinuePromptId({
          promptId: promptId,
          continuePromptId: selectedChat.promptId
        })
      );
    }
  } else {
    dispatch(
      updateValidationChatsConversation({
        promptId: selectedChat.promptId,
        conversations: lastTwoConversations
      })
    );
  }
}

/**
 * Handles the response received after completing a conversation.
 *
 * @param {string} promptId - user prompt document mongo id.
 * @param {Object} selectedChat - The selected chat object.
 * @param {Object} validationChats - The validation chats object.
 * @param {Function} dispatch - The dispatch function.
 * @param {Function} updateChatHistoryWithPromptId - The function to update chat history with prompt ID.
 */
export function handleUpdatePromptId(
  promptId,
  selectedChat,
  validationChats,
  dispatch,
  updateChatHistoryWithPromptId
) {
  if (!selectedChat) {
    updateChatHistoryWithPromptId(promptId);
  } else if (selectedChat && validationChats[selectedChat.promptId]) {
    dispatch(
      updateValidationChatsContinuePromptId({
        promptId: selectedChat.promptId,
        continuePromptId: promptId
      })
    );
  }
}
