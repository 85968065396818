import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Popover as MantinePopover, Image } from '@mantine/core';
import CrossPopover from 'assets/icons/cross-popover.svg';
import styles from './styles.module.scss';

const Popover = (props) => {
  const { children, open, handleChange, handleClose, heading, body, width, hideCloseBtn } = props;

  return (
    <MantinePopover
      width={width}
      arrowSize={14}
      withArrow
      shadow='md'
      opened={open}
      onChange={handleChange}
      position='top-end'
      arrowPosition='center'
      zIndex={2000}
      classNames={{
        dropdown: styles.dropdown,
        arrow: styles.arrow
      }}
    >
      <MantinePopover.Target>{children}</MantinePopover.Target>
      <MantinePopover.Dropdown>
        <div className={styles.headerWrapper}>
          <div className={styles.heading}>{heading}</div>
          <Image
            src={CrossPopover}
            alt='cross_popover'
            className={classNames(styles.closeIcon, { [styles.hideCloseBtn]: hideCloseBtn })}
            h='20'
            w='20'
            onClick={handleClose}
          />
        </div>
        <div className={styles.description}>{body}</div>
      </MantinePopover.Dropdown>
    </MantinePopover>
  );
};

Popover.propTypes = {
  children: PropTypes.element.isRequired,
  heading: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  open: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  width: PropTypes.number,
  hideCloseBtn: PropTypes.bool
};

Popover.defaultProps = {
  width: 304,
  hideCloseBtn: false
};

export default Popover;
