import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Flex, Modal, Title, Text, Button } from '@mantine/core';
import styles from './styles.module.scss';

const UserDetailsModal = ({ opened, setOpened, userDetails }) => {
  return (
    <>
      <Modal
        centered
        opened={opened}
        size={550}
        radius={4}
        className='mobile-modal'
        onClose={() => setOpened(false)}
        title={<Title className={classNames(styles.title)}>Details</Title>}
      >
        <Flex className={styles.userDetailsContainer} wrap='wrap'>
          <div className={styles.userContent}>
            <Text className={styles.fieldLabel} component='label'>
              Name
            </Text>
            <Text className={styles.fieldValue} component='p'>
              {userDetails.name}
            </Text>
          </div>
          <div className={styles.userContent}>
            <Text className={styles.fieldLabel} component='label'>
              License Status
            </Text>
            <Button
              variant='outline'
              className={classNames(styles.statusBtn, styles[userDetails.licenseStatus])}
              size='compact-xs'
              radius={30}
            >
              {userDetails.licenseStatus}
            </Button>
          </div>
          <div className={styles.userContent}>
            <Text className={styles.fieldLabel} component='label'>
              E-mail
            </Text>
            <Text className={styles.fieldValue} component='p'>
              {userDetails.email}
            </Text>
          </div>
          {userDetails.requestedOn && (
            <div className={styles.userContent}>
              <Text className={styles.fieldLabel} component='label'>
                Requested Date
              </Text>
              <Text className={styles.fieldValue} component='p'>
                {userDetails.requestedOn}
              </Text>
            </div>
          )}
          {userDetails.channels && (
            <div className={styles.userContent}>
              <Text className={styles.fieldLabel} component='label'>
                Channels
              </Text>
              <Text className={styles.fieldValue} component='p'>
                {userDetails.channels}
              </Text>
            </div>
          )}
        </Flex>

        <Flex justify={'flex-end'} mt={40}>
          <Button
            onClick={() => {
              setOpened(false);
            }}
            className={classNames(styles.button, styles.buttonGreen)}
          >
            Close
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

UserDetailsModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  setOpened: PropTypes.func.isRequired,
  userDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    channels: PropTypes.string,
    requestedOn: PropTypes.string,
    licenseStatus: PropTypes.string
  })
};

export default UserDetailsModal;
