import { PROVIDERS } from 'utils/constants';

export const STEPS = {
  ZERO: 'ZERO',
  ONE_TWO: 'ONE_TWO',
  CONNECT_AN_APP: 'CONNECT_AN_APP',
  CONNECTORS: 'CONNECTORS',
  ADD_A_SOURCE: 'ADD_A_SOURCE',
  ASK_A_QUESTION: 'ASK_A_QUESTION',
  CHAT: 'CHAT',
  THREE: 'THREE'
};

export const YOUR_ROLE = [
  'Engineering',
  'Product',
  'Design',
  'HR',
  'Sales',
  'Marketing',
  'Customer Support',
  'Other'
];

export const YOUR_TEAM_SIZE = ['1-10', '11-50', '51-200', '201-500', '500+'];

export const HIDE_INTEGRATE_BUTTON_FOR = [
  PROVIDERS.GOOGLE,
  PROVIDERS.JIRA,
  PROVIDERS.CONFLUENCE,
  PROVIDERS.CUSTOM_SOURCES,
  PROVIDERS.SLACK
];
