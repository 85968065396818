import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import omit from 'lodash.omit';
import { useNavigate } from 'react-router-dom';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { usePagination } from '@mantine/hooks';
import { Flex, Menu, Pagination, Table, TextInput, UnstyledButton } from '@mantine/core';
import Button from 'components/Button';
import ApiKeyModal from '../ApiKeyModal';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import settingsApi, {
  useCreateCustomApiMutation,
  useDeleteCustomApiMutation,
  useLazyGetCustomApiQuery
} from 'redux/services/settings';
import { ReactComponent as ActionIcon } from 'assets/icons/3-v-dots.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ROWS_PER_PAGE_FOR_OTHER_TABLES, NAVBAR_ROUTES } from 'utils/constants';
import styles from './styles.module.scss';

const ApisTable = (props) => {
  const { customApis, onManageCustomApi, onNewCustomApi } = props;

  const [page, onChange] = useState(1);
  const [search, setSearch] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCustomApi, setSelectedCustomApi] = useState(null);
  const [generatedKey, setGeneratedKey] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [opened, setOpened] = useState(false);
  const [customApiId, setCustomApiId] = useState(null);
  const [deleteCustomApi, { isLoading: isDeleting }] = useDeleteCustomApiMutation();
  const [getCustomApiDetails] = useLazyGetCustomApiQuery();
  const [duplicateCustomApi] = useCreateCustomApiMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pagination = usePagination({
    total: Math.ceil(customApis.length / ROWS_PER_PAGE_FOR_OTHER_TABLES),
    page,
    onChange,
    siblings: 2
  });
  let customApisToDisplay = [...customApis];
  let copyButtonClicked = useRef(false);
  if (search) {
    customApisToDisplay = customApisToDisplay.filter((customApi) =>
      customApi.name.toLowerCase().includes(search.toLowerCase())
    );
  }
  const hasPagination = customApisToDisplay.length > ROWS_PER_PAGE_FOR_OTHER_TABLES;
  const totalPages = Math.ceil(customApisToDisplay.length / ROWS_PER_PAGE_FOR_OTHER_TABLES);
  const pageCustomApis = customApisToDisplay.slice(
    (pagination.active - 1) * ROWS_PER_PAGE_FOR_OTHER_TABLES,
    pagination.active * ROWS_PER_PAGE_FOR_OTHER_TABLES
  );

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!copyButtonClicked.current && opened) {
        event.returnValue =
          "Are you sure you want to leave? Your API Key may not be accessible if it hasn't been copied yet.";
      }
    };
    if (!copyButtonClicked.current && opened) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [copyButtonClicked, opened]);

  const handleDeleteCustomApi = async () => {
    try {
      const response = await deleteCustomApi(selectedCustomApi?.id);
      if (response?.data?.ok) {
        setShowDeleteModal(false);
        setSelectedCustomApi(null);
      }
    } catch (error) {
      toast.error('Failed to delete custom API. Try again!');
    }
  };

  const handleDuplicateCustomApi = async (customApi) => {
    try {
      const { data } = await getCustomApiDetails(customApi.id);
      const duplicate = omit(data.data, [
        '_id',
        'id',
        'createdBy',
        'updatedBy',
        'interactions',
        'organisation',
        'createdAt',
        'accessTokens'
      ]);
      const result = await duplicateCustomApi({
        ...duplicate,
        name: `Copy of ${customApi.name}`
      });
      if (result?.data?.ok) {
        setGeneratedKey(result?.data?.accessToken);
        setOpened(true);
        dispatch(settingsApi.util.invalidateTags(['CustomApis']));
        toast.success('Custom API duplicated successfully.');
        setCustomApiId(result?.data?.id);
      }
    } catch (error) {
      toast.error('Failed to duplicate custom API. Try again!');
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(generatedKey);
    toast.success('API key copied successfully');
    copyButtonClicked.current = true;
    setShowAlert(false);
  };

  const handleCloseModal = async () => {
    if (copyButtonClicked.current) {
      setOpened(false);
      copyButtonClicked.current = false;
      onManageCustomApi(customApiId);
      navigate(NAVBAR_ROUTES.SETTINGS_TABS.CUSTOM_APIS + '#' + customApiId);
    } else {
      setShowAlert(true);
      setOpened(true);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.searchSection}>
        <TextInput
          className={styles.searchBar}
          leftSection={<SearchIcon />}
          placeholder='Search for Custom APIs'
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
        <Button withIcon onClick={onNewCustomApi}>
          Create Custom API
        </Button>
      </div>
      <div className={styles.tableWrapper}>
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Custom API name</Table.Th>
              <Table.Th>Created on</Table.Th>
              <Table.Th>Created by</Table.Th>
              <Table.Th>Questions asked</Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {pageCustomApis.map((api) => (
              <Table.Tr
                key={api.id}
                onClick={() => {
                  onManageCustomApi(api.id);
                  navigate(NAVBAR_ROUTES.SETTINGS_TABS.CUSTOM_APIS + '#' + api.id);
                }}
              >
                <Table.Td>{api.name}</Table.Td>
                <Table.Td className={styles.strongText}>
                  {dayjs(api.createdAt).format('DD MMM YYYY')}
                </Table.Td>
                <Table.Td>{api.createdBy}</Table.Td>
                <Table.Td>{api.questionsAsked ?? 0}</Table.Td>
                <Table.Td
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <Menu>
                    <Menu.Target>
                      <UnstyledButton className={styles.menuIcon}>
                        <ActionIcon />
                      </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        onClick={() => {
                          onManageCustomApi(api.id);
                          navigate(NAVBAR_ROUTES.SETTINGS_TABS.CUSTOM_APIS + '#' + api.id);
                        }}
                      >
                        Manage
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          setSelectedCustomApi(api);
                          setShowDeleteModal(true);
                        }}
                      >
                        Delete
                      </Menu.Item>
                      <Menu.Item onClick={() => handleDuplicateCustomApi(api)}>Duplicate</Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </div>
      <Flex justify={hasPagination ? 'space-between' : 'flex-end'} align='center' mt={20} pb={16}>
        {hasPagination && (
          <Pagination
            size='md'
            color='primaryGreen.3'
            siblings={2}
            total={totalPages}
            value={pagination.active}
            onChange={pagination.setPage}
          />
        )}
        <div className={styles.pageCounter}>
          <strong>
            {(pageCustomApis.length > 0
              ? (pagination.active - 1) * ROWS_PER_PAGE_FOR_OTHER_TABLES + 1
              : 0) +
              '-' +
              Math.min(
                ROWS_PER_PAGE_FOR_OTHER_TABLES * pagination.active,
                customApisToDisplay.length
              )}
          </strong>
          &nbsp;of&nbsp;
          <strong>{customApisToDisplay.length}</strong>&nbsp;
          {pluralize('Custom Api', customApisToDisplay.length, false)}
        </div>
      </Flex>
      <DeleteWarningModal
        open={showDeleteModal}
        handleCancel={() => setShowDeleteModal(false)}
        onClose={() => setShowDeleteModal(false)}
        header='Delete Custom API'
        title='Are you sure you want to delete this API?'
        body='Note: Deleting this API will revoke access to all associated API keys and permanently delete the Custom API.'
        handleDelete={handleDeleteCustomApi}
        removeText='Delete'
        loading={isDeleting}
      />
      <ApiKeyModal
        generatedKey={generatedKey}
        handleCloseModal={handleCloseModal}
        handleCopyClick={handleCopyClick}
        opened={opened}
        showAlert={showAlert}
      />
    </div>
  );
};

ApisTable.propTypes = {
  customApis: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      entryPoint: PropTypes.string,
      createdAt: PropTypes.string,
      createdBy: PropTypes.string,
      interactions: PropTypes.number
    }).isRequired
  ).isRequired,
  onManageCustomApi: PropTypes.func.isRequired,
  onNewCustomApi: PropTypes.func.isRequired
};

export default ApisTable;
