import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import GoogleConnectButton from '../../Google/GoogleConnectButton';
import SlackConnectButton from '../../Slack/SlackConnectButton';
import ConnectNotion from '../../Notion/Connect';
import ConnectAtlassian from '../../Atlassian/Connect';
import AddAPIDeckSources from '../../OtherIntegrations/AddSourcesButton';
import apiDeckConnectApi from 'redux/services/api-deck';
import { ReactComponent as LinkIcon } from 'assets/link-white.svg';
import { PROVIDERS } from 'utils/constants';

export const ConnectSourceButtonUI = ({ handleConnect, isDisabled, btnText }) => {
  return (
    <Button
      disabled={isDisabled}
      variance='primary'
      rightSection={<LinkIcon />}
      onClick={(e) => {
        e.stopPropagation();
        handleConnect();
      }}
    >
      {btnText || 'Connect'}
    </Button>
  );
};

ConnectSourceButtonUI.propTypes = {
  handleConnect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  btnText: PropTypes.string
};

const ConnectSourceButton = ({ provider }) => {
  const dispatch = useDispatch();

  const handleConnect = async () => {
    const {
      data: { url: vaultUrl }
    } = await dispatch(apiDeckConnectApi.endpoints.getAPIDeckVaultURL.initiate());
    window.open(vaultUrl, '_blank');
  };

  switch (provider) {
    case PROVIDERS.GOOGLE:
      return <GoogleConnectButton />;
    case PROVIDERS.SLACK:
      return <SlackConnectButton />;
    case PROVIDERS.NOTION:
      return <ConnectNotion />;
    case PROVIDERS.JIRA:
    case PROVIDERS.CONFLUENCE:
      return <ConnectAtlassian provider={provider} />;
    case PROVIDERS.HUBSPOT:
    case PROVIDERS.ZENDESKSELL:
    case PROVIDERS.PIPEDRIVE:
    case PROVIDERS.COPPER:
    case PROVIDERS.INTERCOM:
      return <AddAPIDeckSources provider={provider} />;
    case PROVIDERS.APIDECK_INTEGRATIONS:
      return <ConnectSourceButtonUI handleConnect={handleConnect} btnText='Manage' />;
    default:
      return <ConnectSourceButtonUI handleConnect={() => {}} />;
  }
};

ConnectSourceButton.propTypes = {
  provider: PropTypes.string
};
export default ConnectSourceButton;
