import PropTypes from 'prop-types';
import { Modal, Text, Flex, Radio, TagsInput, Button } from '@mantine/core';
import styles from './styles.module.scss';

const View = (props) => {
  const {
    open,
    onClose,
    selectedOption,
    domains,
    handleOnChange,
    generatedLink,
    handleRadioChange,
    handleGenerateLinkButtonClick,
    handleCopyUrl,
    isGeneric,
    isDisabled,
    userDomain,
    buttonText
  } = props;

  return (
    <Modal
      centered
      opened={open}
      size={650}
      closeOnClickOutside={false}
      padding='1.5rem'
      onClose={() => onClose(false)}
      title='Invite your teammates to join your company on Albus via URL'
      classNames={{ title: styles.title }}
      className='mobile-modal'
    >
      <Flex direction='column' justify='center' align='flex-start' gap='1.6rem'>
        <Text className={styles.modalText}>
          Share this URL with your teammates to invite them to your company on Albus.
        </Text>
        <Text className={styles.modalText}>
          Teammates joining via this URL will have non-admin privileges by default. You can modify
          their admin privileges in the dashboard.
        </Text>

        <Radio.Group
          value={selectedOption}
          onChange={handleRadioChange}
          className={styles.radioContainer}
          orientation='vertical'
        >
          <Radio
            color='green'
            label='Allow any email address'
            value='Allow any email address'
            className={styles.radio}
            mb='lg'
          />

          <Flex justify='center' align='center' gap='1rem' className={styles.onlyAllowContainer}>
            <Radio color='green' label='Only allow' value='only allow' className={styles.radio} />
            <TagsInput
              data={isGeneric ? [] : [{ label: userDomain, value: userDomain }]}
              defaultValue={isGeneric ? [] : [userDomain]}
              disabled={selectedOption === 'Allow any email address'}
              placeholder='@example.org, @domain.com'
              onChange={(value) => handleOnChange(value)}
            />
          </Flex>
        </Radio.Group>

        <div className={styles.urlContainer}>
          <Text className={styles.urlTextBox}>
            {selectedOption === 'only allow' && domains.length === 0 ? (
              <>Enter a domain above to generate a shareable URL</>
            ) : (
              <>{generatedLink}</>
            )}
          </Text>

          <Button
            onClick={buttonText === 'Generate link' ? handleGenerateLinkButtonClick : handleCopyUrl}
            disabled={(selectedOption === 'only allow' && domains.length === 0) || isDisabled}
            className={styles.urlButton}
          >
            &nbsp;{buttonText}
          </Button>
        </div>
      </Flex>
    </Modal>
  );
};

View.defaultProps = {
  isDisabled: false
};

View.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedOption: PropTypes.string.isRequired,
  domains: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  generatedLink: PropTypes.string.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleGenerateLinkButtonClick: PropTypes.func.isRequired,
  handleCopyUrl: PropTypes.func.isRequired,
  isGeneric: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  userDomain: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired
};

export default View;
