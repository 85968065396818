import classNames from 'classnames';
import {
  Box,
  Group,
  Button,
  Flex,
  Modal,
  Combobox,
  Text,
  Title,
  CloseButton,
  Input,
  InputLabel
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import PropTypes from 'prop-types';
import CustomOptionMultiSelect from 'components/CustomOptionMultiSelect';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { CHANNEL_TYPE } from '../../../../../utils/constants';
import styles from './styles.module.scss';

const ItemComponent = (props) => {
  const { label, type, isAdded, ...others } = props;

  const getChannelIcon = (channelType) =>
    channelType === CHANNEL_TYPE.PRIVATE ? (
      <>
        <LockIcon />
        &nbsp;
      </>
    ) : (
      '#'
    );

  return (
    <Combobox.Option disabled={isAdded} {...others} className={styles.optionItem}>
      <Group justify='space-between' gap='md' className={styles.item}>
        <Flex>
          <Text className={styles.itemText}>{getChannelIcon(type)}</Text>
          <Text className={styles.itemText}>{label}</Text>
        </Flex>
        {isAdded && <Text className={styles.channelAddedText}>Added</Text>}
      </Group>
    </Combobox.Option>
  );
};

const CustomPill = (props) => {
  const { label, type, onRemove, ...others } = props;
  const getChannelIcon = (channelType) =>
    channelType === CHANNEL_TYPE.PRIVATE ? (
      <>
        <LockIcon />
        &nbsp;
      </>
    ) : (
      '#'
    );

  return (
    <div {...others} className='multi-select-label'>
      <Box className={styles.multiSelectLabelWrapper}>
        <Box className={styles.channelBox}>
          <Text className={styles.itemText}>{getChannelIcon(type)}</Text>
          <Text className={styles.itemText}>{label}</Text>
        </Box>
        <CloseButton
          onMouseDown={onRemove}
          variant='transparent'
          color='gray'
          size={22}
          iconSize={14}
          tabIndex={-1}
        />
      </Box>
    </div>
  );
};

const ChannelPicker = ({
  isLoading,
  opened,
  setOpened,
  channels,
  channelsToBeAdded,
  setChannelsToBeAdded,
  handleConfirm,
  isChannelsFetching
}) => {
  const screen767 = useMediaQuery('(max-width: 767px)');

  return (
    <Modal
      centered
      opened={opened}
      size={630}
      radius={4}
      className='mobile-modal'
      onClose={() => {
        setOpened(false);
        setChannelsToBeAdded([]);
      }}
      title={<Title className={styles.pageTitle}>Add source</Title>}
    >
      <div>
        <div className={styles.banner}>
          <Text className={styles.bannerText}>
            🔒 Albus does not share Slack information outside Slack. This means he will share Slack
            information when you ask questions inside Slack but not on the web dashboard, chat
            widget and through custom APIs.
          </Text>
        </div>
        {isChannelsFetching && !channels.length ? (
          <>
            <InputLabel>Select channels</InputLabel>
            <Input placeholder='loading channels....' disabled />
          </>
        ) : (
          <CustomOptionMultiSelect
            optionsData={channels}
            label='Select channels'
            optionValue={channelsToBeAdded}
            onChange={setChannelsToBeAdded}
            CustomOption={ItemComponent}
            CustomPill={CustomPill}
          />
        )}

        <Text component='p' className={styles.noteContent}>
          <strong>Note:</strong> to select a private channel as a source, first add Albus to that
          channel in Slack.
        </Text>
      </div>

      <Flex mt={80} justify='flex-end'>
        <Button
          variant='outline'
          radius={4}
          color='dark'
          onClick={() => {
            setOpened(false);
            setChannelsToBeAdded([]);
          }}
          style={{ height: screen767 ? 35 : 42 }}
        >
          Cancel
        </Button>
        <Button
          className={classNames('primaryBtn', { [styles.disabledBtn]: !channelsToBeAdded.length })}
          onClick={handleConfirm}
          ml={20}
          disabled={!channelsToBeAdded.length}
          loading={isLoading}
        >
          Confirm
        </Button>
      </Flex>
    </Modal>
  );
};

ChannelPicker.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  opened: PropTypes.bool.isRequired,
  setOpened: PropTypes.func.isRequired,
  channels: PropTypes.array,
  channelsToBeAdded: PropTypes.array,
  setChannelsToBeAdded: PropTypes.func,
  handleConfirm: PropTypes.func,
  isChannelsFetching: PropTypes.bool.isRequired
};

ItemComponent.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isAdded: PropTypes.bool
};

CustomPill.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default ChannelPicker;
