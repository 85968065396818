import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text } from '@mantine/core';
import { toast } from 'react-toastify';
import AuthWrapper from '../Components/AuthWrapper';
import Sign from '../Components/Sign';
import AuthForm from '../Components/AuthForm';
import {
  useDashboardLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation
} from 'redux/services/auth';
import SlackIcon from 'assets/icons/slack.svg';
import GoogleG from 'assets/icons/googleG.svg';
import MicrosoftIcons from 'assets/icons/microsoft.svg';
import EmailIcons from 'assets/icons/email.svg';
import { stringDecoder } from 'utils/helpers';
import { NAVBAR_ROUTES } from 'utils/constants';
import { END_POINT } from 'utils/apiEndpoints';
import { INITIAL_VALUES, STEPS } from '../constants';

const Signin = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stepFromQuery = queryParams.get('step');
  const redirectEmail = queryParams.get('email');
  const isResetPassword = window.location.pathname.includes('/reset-password');
  const isLoginViaEmail = stepFromQuery === STEPS.ONE;
  const [step, setStep] = useState(
    isResetPassword
      ? STEPS.RESET_PASSWORD
      : isLoginViaEmail || redirectEmail
      ? STEPS.ONE
      : STEPS.ZERO
  );
  const decodedRedirectEmail = stringDecoder(redirectEmail);
  const initialValuesWithEmail = decodedRedirectEmail && {
    ...INITIAL_VALUES,
    email: decodedRedirectEmail
  };
  const redirect = queryParams.get('redirect') ?? '';

  const [login, { isLoading }] = useDashboardLoginMutation();
  const [forgotPassword, { isLoading: isForgotPasswordLoading }] = useForgotPasswordMutation();
  const [resetPassword, { isLoading: isResetPasswordLoading }] = useResetPasswordMutation();
  const navigate = useNavigate();

  const handlePasswordReset = async (values) => {
    try {
      await resetPassword({
        password: values.newPassword,
        confirmPassword: values.confirmPassword
      });
      toast.success('Password is reset successfully.');
      setTimeout(() => {
        window.open(`${NAVBAR_ROUTES.AUTH.SIGNIN}`, '_self');
      }, 2000);
    } catch (error) {
      toast.error('Oops,reset password failed. Try again!');
    }
  };

  const providers = [
    {
      icon: SlackIcon,
      label: 'Sign in with Slack',
      handler: () => {
        try {
          window.open(
            `${import.meta.env.VITE_API_URL}${
              END_POINT.AUTH.DASHBOARD_LOGIN
            }?provider=slack&redirect=${redirect}`,
            '_self'
          );
        } catch (error) {
          toast.error('Oops, something went wrong. Try again!');
        }
      }
    },
    {
      icon: GoogleG,
      label: 'Sign in with Google',
      handler: () => {
        try {
          window.open(
            `${import.meta.env.VITE_API_URL}${
              END_POINT.AUTH.DASHBOARD_LOGIN
            }?provider=google&redirect=${redirect}`,
            '_self'
          );
        } catch (error) {
          toast.error('Oops, something went wrong. Try again!');
        }
      }
    },
    {
      icon: MicrosoftIcons,
      label: 'Sign in with Microsoft',
      handler: () => {
        try {
          window.open(
            `${import.meta.env.VITE_API_URL}${
              END_POINT.AUTH.DASHBOARD_LOGIN
            }?provider=microsoft&redirect=${redirect}`,
            '_self'
          );
        } catch (error) {
          toast.error('Oops, something went wrong. Try again!');
        }
      }
    },
    {
      icon: EmailIcons,
      label: 'Sign in with email',
      handler: () => setStep(STEPS.ONE)
    }
  ];

  const footer = (
    <>
      Don&apos;t have an account?&nbsp;
      <Text
        display='inline'
        size='sm'
        fw={500}
        c='primaryGreen.3'
        td='underline'
        className='cursor-pointer font-poppins'
        onClick={() => navigate(NAVBAR_ROUTES.AUTH.SIGNUP)}
      >
        Sign up for free
      </Text>
    </>
  );

  return (
    <AuthWrapper>
      {step === STEPS.ZERO ? (
        <Sign headerText='Sign in to your account' providers={providers} footerText={footer} />
      ) : null}

      {step === STEPS.ONE ? (
        <AuthForm
          isLoading={isLoading}
          initialValues={redirectEmail ? initialValuesWithEmail : INITIAL_VALUES}
          header='Sign in with your email'
          email
          password
          forgotPassword
          ctaLabel='Sign in'
          handleForgotPassword={() => setStep(STEPS.FORGOT_PASSWORD)}
          handleSubmitCTA={async (values) => {
            try {
              const { data } = await login({ email: values.email, password: values.password });
              if (data?.ok) {
                return window.open(data.data.redirectTo, '_self');
              }
            } catch (error) {
              toast.error('Oops, something went wrong. Try again!');
            }
          }}
          footer={footer}
        />
      ) : null}

      {step === STEPS.FORGOT_PASSWORD ? (
        <AuthForm
          initialValues={INITIAL_VALUES}
          header='Forgot your password?'
          email
          hideEmailLabel
          ctaLabel='Continue'
          handleSubmitCTA={({ email }) => forgotPassword({ email })}
          footer={footer}
          isDisabled={isForgotPasswordLoading}
        />
      ) : null}

      {step === STEPS.RESET_PASSWORD ? (
        <AuthForm
          isLoading={isResetPasswordLoading}
          initialValues={INITIAL_VALUES}
          header='Reset your password'
          newPassword
          confirmPassword
          ctaLabel='Reset password'
          handleSubmitCTA={handlePasswordReset}
        />
      ) : null}
    </AuthWrapper>
  );
};

export default Signin;
