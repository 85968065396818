import PropTypes from 'prop-types';
import { Flex, Image, Title, Text, Button } from '@mantine/core';
import styles from './styles.module.scss';

const AppBanner = (props) => {
  const { logo, appName, description, supportedApps, handleConnect, showConnectCta } = props;
  return (
    <Flex justify='space-between' align='center' w='100%'>
      <Flex gap='24px' className={styles.appInfo}>
        <Image src={logo} width={60} height={60} />
        <div>
          <Title>{appName}</Title>
          <Text className={styles.description}>{description}</Text>
          <Text className={styles.supportedApps}>
            Supported apps:&nbsp;
            {supportedApps.map((app, index) => (
              <span key={index}>
                {app}
                {index < supportedApps.length - 1 ? ', ' : ''}
              </span>
            ))}
          </Text>
        </div>
      </Flex>
      {showConnectCta && (
        <Button className={styles.connectBtn} onClick={handleConnect}>
          Connect
        </Button>
      )}
    </Flex>
  );
};

AppBanner.defaultProps = {
  showConnectCta: false
};

AppBanner.propTypes = {
  logo: PropTypes.node.isRequired,
  appName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  supportedApps: PropTypes.array.isRequired,
  handleConnect: PropTypes.func.isRequired,
  showConnectCta: PropTypes.bool
};

export default AppBanner;
