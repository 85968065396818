import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import userApi from 'redux/services/user';
import {
  useLazyGetTrutoIntegratedAccountTokenQuery,
  useLazyGetTrutoLinkTokenQuery
} from 'redux/services/truto';
import authenticate, { rapidForm } from '@truto/truto-link-sdk';
import { Flex, Indicator, Menu, Modal, Text, Tooltip } from '@mantine/core';
import View from './View';
import RightSection from './RightSection/index';
import ConnectSourceButton from 'pages/Integrations/Connector/Components/ConnectSourceButton';
import ConfirmationModal from '../ConfirmationModal';
import Button from 'components/Button';
import {
  ACTIONS_SUPPORTED_CONNECTORS,
  CONNECTOR_CATEGORIES_LIST,
  CONNECTOR_LIST,
  NON_TRUTO_CONNECTORS
} from 'pages/ControlPanel/constants';
import { APIDECK_INTEGRATIONS, PROVIDERS } from 'utils/constants';
import { ReactComponent as LinkIcon } from 'assets/link-white.svg';
import { ReactComponent as LinkGreenIcon } from 'assets/link.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/3-h-dots.svg';
import { ReactComponent as IIcon } from 'assets/connectors/i-icon.svg';
import styles from './styles.module.scss';

const InstallMenuBtn = ({ connectorName }) => {
  return (
    <div className={styles.installMenuBtn}>
      <p className={styles.installCtaText}>Install</p>
      <Tooltip
        maw={400}
        label={`If you uninstalled Albus from your ${connectorName} dashboard, you need to install Albus to ensure your wiki is up to date.`}
      >
        <div className={styles.tooltipIcon}>
          <IIcon />
        </div>
      </Tooltip>
    </div>
  );
};

InstallMenuBtn.propTypes = {
  connectorName: PropTypes.object.isRequired
};

const ConnectorDetailsModal = (props) => {
  const {
    conn,
    selectedConnector,
    onClose,
    handleConnect,
    handleDisconnect,
    handleEnable,
    handleDisable,
    handleRequest,
    handleAddSource
  } = props;

  const { user } = useSelector((state) => state.user);
  const [opened, setOpened] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const location = useLocation();
  const isOnBoarding = location.pathname.includes('on-boarding');
  const connectorSupportsActions = ACTIONS_SUPPORTED_CONNECTORS.includes(conn.name);
  const provider = conn.name;
  const dispatch = useDispatch();
  const [getTrutoLinkTokenTrigger] = useLazyGetTrutoLinkTokenQuery();
  const [getTrutoIntegratedAccountToken] = useLazyGetTrutoIntegratedAccountTokenQuery();
  const [isConfigureCtaDisabled, setIsConfigureCtaDisabled] = useState(false);

  async function handleConfigure() {
    setIsConfigureCtaDisabled(true);

    try {
      const { data } = await getTrutoIntegratedAccountToken(provider);
      const response = await rapidForm(data?.token);

      if (response.result === 'success') {
        const rapidFormResponse = JSON.parse(response.post_connect_form || {});

        if (!rapidFormResponse.collections && !rapidFormResponse.drive_items) {
          toast.error('Please select at least one source to add');
          setIsConfigureCtaDisabled(false);
          return;
        }
      }
      setIsConfigureCtaDisabled(false);
      toast.success(`${conn.title} successfully configured for actions.`);
    } catch (error) {
      setIsConfigureCtaDisabled(false);
      toast.error('Oops, something went wrong. Try again!');
    }
  }

  async function handleReAuthenticate() {
    try {
      const options = {
        integration: provider,
        noBack: true,
        authFormat: 'oauth2',
        skipRapidForm: true,
        iframe: true
      };

      const params = {
        trutoIntegratedAccountId: user?.connections?.find(
          (connection) => provider === connection.provider
        )?.trutoIntegratedAccountId,
        provider,
        isConnect: false
      };

      const connector = CONNECTOR_LIST.find((connection) => provider === connection.name);

      const { data } = await getTrutoLinkTokenTrigger(params);

      const response = await authenticate(data.linkToken, options);
      if (response?.result === 'success') {
        dispatch(userApi.util.invalidateTags(['User']));
        return toast.success(`${connector.title} re-authenticated successfully`);
      }
    } catch (error) {
      if (
        error.message === 'Not Found: Error while fetching resource - LIST ticketing/workspaces'
      ) {
        return toast.error('Unable to find the workspace by this name. Please try again.');
      }
      return toast.error(
        'Authentication error: Unable to proceed. Please check your credentials or try again.'
      );
    }
  }

  function generateButtonText() {
    if (!conn.isActive) {
      return 'Request';
    }
    if (conn.autoConnect) {
      return conn.isConnected ? 'Add source' : 'Enable';
    } else {
      return conn.isConnected ? 'Add source' : 'Connect';
    }
  }

  const buttonText = generateButtonText();
  const hideAddSourceCta =
    (!user?.isAdmin || conn.category === CONNECTOR_CATEGORIES_LIST.SCHEDULING) &&
    buttonText === 'Add source';

  const clickHandler = () => {
    setLoadingBtn(true);
    switch (buttonText) {
      case 'Enable':
        handleEnable(conn);
        break;
      case 'Connect':
        handleConnect(conn);
        break;
      case 'Disable':
        handleDisable(conn);
        break;
      case 'Disconnect':
        handleDisconnect(conn);
        break;
      case 'Request':
        handleRequest(conn);
        break;
      case 'Add source':
        handleAddSource(conn);
        break;
      default:
        break;
    }
    setLoadingBtn(false);
    setOpened(false);
  };

  function renderRightSideIcon() {
    if (buttonText === 'Connect') {
      return conn.installLink ? <LinkGreenIcon /> : <LinkIcon />;
    }
  }

  function handleEnableCtaClick() {
    if (
      conn.installLink &&
      buttonText !== 'Disable' &&
      buttonText !== 'Disconnect' &&
      buttonText !== 'Add source'
    ) {
      if (conn.name === PROVIDERS.SLACK) {
        clickHandler();
      } else {
        setOpened(true);
      }
    } else {
      clickHandler();
    }
  }

  function handleDisconnectClick() {
    if (conn.autoConnect) {
      handleDisable(conn);
    } else {
      handleDisconnect(conn);
    }
  }

  function handleInstallClick() {
    window.open(conn.installLink, '_blank');
  }

  function handleSlackInstallClick() {
    const redirectUrl = isOnBoarding ? '/on-boarding' : '/settings/connections';
    window.open(
      `${import.meta.env.VITE_API_URL}/install?organisationId=${user.organisationId}&userId=${
        user.userId
      }&redirect=${redirectUrl}&connector=slack`,
      '_self'
    );
  }

  const disableCta =
    !!JSON.parse(localStorage.getItem('connectionInitiated'))?.[conn.name] &&
    buttonText === 'Connect';

  return (
    <Modal
      opened={true}
      size='90%'
      onClose={onClose}
      centered
      h='600'
      className={styles.modal}
      classNames={{
        header: styles.modalTitle,
        body: styles.body
      }}
    >
      <div className={styles.header}>
        <Flex direction='row' align='center' gap='sm'>
          <img src={conn.icon} className={styles.connectorLogo} />
          <Text className={styles.connectorTitle}>{conn.title}</Text>
          {conn.tag && (
            <Tooltip
              w={281}
              position='top-start'
              arrowOffset={20}
              label='You will be our pilot customer for this beta connection. Get a direct line with CEO Kartik Mandaville while in beta.'
            >
              <div className={styles.tag}>{conn.tag}</div>
            </Tooltip>
          )}
        </Flex>

        <div className={styles.ctaContainer}>
          {conn.installLink && !conn.isConnected && conn.name !== PROVIDERS.SLACK && (
            <Button mr={16} rightSection={<LinkIcon />} onClick={handleInstallClick}>
              Install
            </Button>
          )}

          {conn.installLink && conn.name === PROVIDERS.SLACK && !user.isAppConnected && (
            <Button mr={16} rightSection={<LinkIcon />} onClick={handleSlackInstallClick}>
              Install
            </Button>
          )}

          {conn.isConnected && buttonText === 'Add source' && (
            <Menu shadow='sm' position='bottom-end'>
              <Menu.Target>
                <Button className={styles.menuBtn}>
                  <MenuIcon />
                </Button>
              </Menu.Target>

              <Menu.Dropdown className={styles.menuDropdown}>
                {APIDECK_INTEGRATIONS.includes(provider) && (
                  <Menu.Item onClick={handleReAuthenticate} className={styles.reauthenticateCta}>
                    Re-authenticate
                  </Menu.Item>
                )}
                {conn.installLink && (
                  <Menu.Item onClick={handleInstallClick} className={styles.installCta}>
                    <InstallMenuBtn connectorName={conn.title} />
                  </Menu.Item>
                )}

                <Menu.Item onClick={handleDisconnectClick} className={styles.disconnectCta}>
                  {conn.autoConnect ? 'Disable' : 'Disconnect'}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}

          {connectorSupportsActions &&
            conn.isConnected &&
            (conn.name === PROVIDERS.JIRA || conn.name === PROVIDERS.BITBUCKET) && (
              <Button
                outlined
                onClick={handleConfigure}
                disabled={isConfigureCtaDisabled}
                className={styles.configureCta}
              >
                Configure
              </Button>
            )}

          {NON_TRUTO_CONNECTORS.includes(conn.name) && !conn.isConnected ? (
            <ConnectSourceButton provider={conn.name} />
          ) : (
            <>
              {disableCta ? (
                <Tooltip
                  label={
                    <div className='font-poppins'>
                      Connecting to {conn.title}, this may take a few minutes.
                    </div>
                  }
                  width={200}
                  opened
                  withinPortal={false}
                  position='bottom-end'
                  arrowOffset={20}
                  classNames={{ tooltip: styles.disableCtaTooltip }}
                >
                  <Button
                    onClick={clickHandler}
                    rightSection={renderRightSideIcon()}
                    disabled
                    outlined={conn.installLink}
                    variance='primary'
                    className={styles.disabledBtn}
                  >
                    {buttonText}
                  </Button>
                </Tooltip>
              ) : (
                <>
                  {((user.isAppConnected &&
                    user.platform === PROVIDERS.SLACK &&
                    conn.name === PROVIDERS.SLACK) ||
                    conn.name !== PROVIDERS.SLACK) &&
                    !hideAddSourceCta && (
                      <Indicator
                        disabled={buttonText !== 'Add source'}
                        size={14}
                        position='top-start'
                        color='orange'
                        processing
                      >
                        <Button
                          onClick={handleEnableCtaClick}
                          loading={loadingBtn}
                          rightSection={renderRightSideIcon()}
                          disabled={selectedConnector === conn.name}
                          outlined={
                            buttonText !== 'Add source' &&
                            conn.name !== PROVIDERS.SLACK &&
                            conn.installLink
                          }
                          variance='primary'
                        >
                          {buttonText}
                        </Button>
                      </Indicator>
                    )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      <div className={styles.content}>
        <View
          conn={conn}
          handleConnect={clickHandler}
          connectorSupportsActions={connectorSupportsActions}
        />
        <RightSection conn={conn} />
      </div>
      {opened && (
        <ConfirmationModal
          conn={conn}
          selectedConnector={selectedConnector}
          installCta={handleInstallClick}
          connectCta={clickHandler}
          onClose={() => setOpened(false)}
        />
      )}
    </Modal>
  );
};

ConnectorDetailsModal.propTypes = {
  conn: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleEnable: PropTypes.func.isRequired,
  handleConnect: PropTypes.func.isRequired,
  handleDisconnect: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  handleDisable: PropTypes.func.isRequired,
  selectedConnector: PropTypes.string.isRequired,
  handleAddSource: PropTypes.func.isRequired
};

export default ConnectorDetailsModal;
