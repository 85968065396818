import { Tooltip } from '@mantine/core';

const TooltipTheme = Tooltip.extend({
  defaultProps: {
    arrowSize: 8,
    color: 'grayscale.2',
    multiline: true,
    withArrow: true
  }
});

export default TooltipTheme;
