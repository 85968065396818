import { useState } from 'react';
import { Button, Flex, Text } from '@mantine/core';
import Layout from 'components/Layout';
import Description from './Components/Description/View';
import InputTab from './Components/InputTab/View';
import { useNavigate } from 'react-router-dom';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import TABS from '../constants';
import { NAVBAR_ROUTES } from 'utils/constants';
import styles from './styles.module.scss';

const AddSource = () => {
  const [handler, setHandler] = useState(TABS.WEBSITE);
  const url = new URL(window.location.href);
  const teamName = url.searchParams.get('teamName');
  const teamId = url.searchParams.get('teamId');
  const navigate = useNavigate();

  const fromButtonClick = url.searchParams.get('fromButtonClick');
  function handleBackButtonClick() {
    fromButtonClick === 'true'
      ? navigate(`${NAVBAR_ROUTES.INTEGRATIONS_PROVIDERS.WEB}/${teamId}`)
      : navigate(`${NAVBAR_ROUTES.INTEGRATIONS}/${teamId}`);
  }

  return (
    <Layout
      pageTitle={
        <Flex align='center'>
          <Button
            className={styles.navigateBackButton}
            onClick={handleBackButtonClick}
            variant='default'
          >
            <SVGIcon name={ICONS_LIST.LEFT_ARROW} height={18} width={18} />
          </Button>
          <Text className={styles.pageTitle}>
            Wiki &gt; {teamName} &gt;&nbsp;
            {fromButtonClick === 'true' ? (
              <>
                Web &gt; <span>Add source</span>
              </>
            ) : (
              <span>Web</span>
            )}
          </Text>
        </Flex>
      }
    >
      <Flex
        className={styles.addSourceContainer}
        direction='column'
        justify='center'
        align='center'
        w='100%'
        gap={42}
      >
        <InputTab handler={handler} setHandler={setHandler} />
        <Description handler={handler} />
      </Flex>
    </Layout>
  );
};

export default AddSource;
