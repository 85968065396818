import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Flex } from '@mantine/core';
import Layout from 'components/Layout';
import Panel from './Components/Panel';
import useShowTutorial from 'custom_hooks/useShowTutorial';
import { startMoreFeaturesTutorial } from './Tutorial';
import { eventTracking } from 'components/EventAnalyticsProvider/utils';
import { NAVBAR_ROUTES } from 'utils/constants';

const ControlPanel = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const currentDate = dayjs();
  const numberOfDaysToShowNewTag = parseInt(import.meta.env.VITE_SHOW_NEW_TAGS_FOR, 10);

  useShowTutorial(startMoreFeaturesTutorial);

  useEffect(() => {
    if (user._id) {
      eventTracking('User opened dashboard', user, 'Settings');
    }
  }, [user]);

  const companySettingCards = [
    {
      title: 'General',
      description: 'Manage your company and colleagues',
      handler: () => navigate(NAVBAR_ROUTES.SETTINGS_TABS.GENERAL),
      isNew: false
    },
    {
      title: 'Search capabilities',
      description: 'Manage online and wiki search capabilities',
      handler: () => navigate(NAVBAR_ROUTES.SETTINGS_TABS.SEARCH_CAPABILITIES),
      isNew: false,
      id: 'search-capabilities'
    },
    {
      title: 'Company admins',
      description: 'Manage who has access to company wiki, billing, personas and more settings',
      handler: () => navigate(NAVBAR_ROUTES.SETTINGS_TABS.COMPANY_ADMIN),
      isNew: false
    },
    {
      title: 'Personas',
      description: "Manage Albus's personality",
      handler: () => navigate(NAVBAR_ROUTES.SETTINGS_TABS.PERSONAS),
      isNew: false,
      id: 'persona'
    }
  ];

  if (user.platform === 'slack') {
    companySettingCards.push({
      title: 'Auto-answer',
      description: 'Manage auto-answer capabilities of Albus on Slack',
      handler: () => navigate(NAVBAR_ROUTES.SETTINGS_TABS.AUTO_ANSWER),
      // Going live date: 29th Nov, 2023. Show new tag for VITE_SHOW_NEW_TAGS_FOR days (currently set at 60 days)
      isNew: currentDate.isBefore(
        dayjs('2023-11-29', 'YYYY-MM-DD').add(numberOfDaysToShowNewTag, 'day')
      ),
      id: 'auto-answer'
    });
  }

  companySettingCards.push({
    title: 'Custom APIs',
    description: 'Integrate Albus with internal tools and workflows',
    handler: () => navigate(NAVBAR_ROUTES.SETTINGS_TABS.CUSTOM_APIS),
    isNew: true,
    id: 'custom-apis'
  });

  const accountSettingCards = [
    {
      title: 'My account',
      description: 'Manage your account and password',
      handler: () => navigate(NAVBAR_ROUTES.SETTINGS_TABS.MY_ACCOUNT),
      isNew: false
    }
  ];

  if (user?.isAdmin || user.isTeamAdmin) {
    accountSettingCards.push(
      {
        title: 'My connections',
        description: 'Manage your connected apps',
        handler: () => navigate(NAVBAR_ROUTES.SETTINGS_TABS.CONNECTIONS),
        isNew: false
      },
      {
        title: 'Automations',
        description: 'Manage your automations and API key',
        handler: () => navigate(NAVBAR_ROUTES.SETTINGS_TABS.AUTOMATIONS),
        isNew: true
      }
    );
  }

  return (
    <Layout pageTitle={'Settings'}>
      <Flex direction='column' gap={24} mb={24}>
        {user?.isAdmin && <Panel header='Company settings' cardsData={companySettingCards} />}
        <Panel header='Account settings' cardsData={accountSettingCards} />
      </Flex>
    </Layout>
  );
};

export default ControlPanel;
