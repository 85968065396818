import { ConnectSourceButtonUI } from '../../Components/ConnectSourceButton';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ConnectNotion = () => {
  const { user } = useSelector((state) => state.user);
  const { teamId } = useParams();

  const handleConnect = async () => {
    window.open(
      `${import.meta.env.VITE_API_URL}/auth/notion/redirect?userId=${user._id}${
        teamId ? `&teamId=${teamId}` : ''
      }`,
      '_self'
    );
  };

  return <ConnectSourceButtonUI handleConnect={handleConnect} />;
};

export default ConnectNotion;
