import Notification from './Notification';
import LoadingOverlay from './LoadingOverlay';
import NumberInput from './NumberInput';
import Title from './Title';
import Text from './Text';
import Tooltip from './Tooltip';
import Stepper from './Stepper';
import SimpleGrid from './SimpleGrid';
import ColorInput from './ColorInput';
import Select from './Select';
import themeColors from './themeColors';

const albusTheme = {
  colorScheme: 'light',
  colors: themeColors,
  scale: 1.6,
  primaryColor: 'brand',
  defaultRadius: 'md',
  loader: 'oval',
  components: {
    Notification,
    Title,
    LoadingOverlay,
    Text,
    Tooltip,
    NumberInput,
    Stepper,
    SimpleGrid,
    ColorInput,
    Select
  }
};

export default albusTheme;
