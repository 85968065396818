import mixpanel from 'mixpanel-browser';
import { node } from 'prop-types';
import { useEffect } from 'react';

const EventAnalyticsProvider = ({ children }) => {
  useEffect(() => {
    // Initializing Mixpanel
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
      debug: import.meta.env.VITE_NODE_ENV === 'local'
    });

    //Initialize CustomerIO
    var _cio = _cio || [];
    window._cio = _cio;
    (function () {
      var a, b, c;
      a = function (f) {
        return function () {
          _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
        };
      };
      b = ['load', 'identify', 'sidentify', 'track', 'page'];
      for (c = 0; c < b.length; c++) {
        _cio[b[c]] = a(b[c]);
      }
      var t = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];
      t.async = true;
      t.id = 'cio-tracker';
      t.setAttribute('data-site-id', import.meta.env.VITE_CIO_SITE_ID);
      t.setAttribute('data-auto-track-page', 'false');
      t.src = 'https://assets.customer.io/assets/track.js';
      s.parentNode.insertBefore(t, s);
    })();

    return () => {
      // Clean up Mixpanel when the component unmounts
      mixpanel.reset();
    };
  }, []);

  return <>{children}</>;
};

EventAnalyticsProvider.propTypes = {
  children: node.isRequired
};

export default EventAnalyticsProvider;
