import { func, object } from 'prop-types';
import { Button, Modal, PasswordInput } from '@mantine/core';
import classNames from 'classnames';
import Password from 'components/FormComponents/Password';
import styles from './styles.module.scss';

const ChangePassword = ({ form, handleSubmitCTA, onClose }) => {
  return (
    <Modal
      opened
      title='Change password'
      classNames={{ title: styles.title }}
      onClose={onClose}
      className='mobile-modal'
      centered
    >
      <form
        onSubmit={form.onSubmit((values) => handleSubmitCTA(values))}
        className={styles.wrapper}
      >
        <PasswordInput
          classNames={{
            error: styles.error
          }}
          radius={4}
          size='lg'
          label='Current password'
          placeholder='Add current password'
          {...form.getInputProps('password')}
        />
        <Password
          classNames={{
            error: styles.error
          }}
          radius={4}
          size='lg'
          label='New password'
          placeholder='Add new password'
          {...form.getInputProps('newPassword')}
        />
        <PasswordInput
          classNames={{
            error: styles.error
          }}
          radius={4}
          size='lg'
          label='Confirm password'
          placeholder='Confirm new password'
          {...form.getInputProps('confirmPassword')}
        />
        <div className={styles.actions}>
          <Button onClick={onClose} className={classNames('primaryBtnOutline', styles.cancel)}>
            Cancel
          </Button>
          <Button className='primaryBtn' type='submit'>
            Change password
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ChangePassword.propTypes = {
  handleSubmitCTA: func.isRequired,
  onClose: func.isRequired,
  form: object.isRequired
};

export default ChangePassword;
