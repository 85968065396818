import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import localStorage from 'utils/localStorage';
import { jwtDecode } from 'jwt-decode';

const getFetchBaseQuery = () => {
  const params = new URLSearchParams(window.location.search);
  const encToken = params.get('token');
  let authToken;

  if (encToken) {
    const { token } = localStorage.decryptToken(encToken);
    authToken = token;
    if (!authToken) {
      const decoded = jwtDecode(encToken);
      const { token } = localStorage.decryptToken(decoded.setupToken);
      authToken = token;
    }
  } else {
    authToken = localStorage.getToken();
  }
  const baseUrl = import.meta.env.VITE_API_URL;

  return {
    baseUrl,
    prepareHeaders: (header) => {
      header.set('authorization', authToken);
      return header;
    }
  };
};

// initialize an empty api service that we'll inject endpoints into later as needed
const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery(getFetchBaseQuery()),
  tagTypes: [
    'Licenses',
    'Plan',
    'Admins',
    'Providers',
    'SourcesByProvider',
    'User',
    'Questions',
    'Escalation_Summary',
    'Questions_Details',
    'OrganisationsList',
    'Settings',
    'Token',
    'Webpages',
    'Completion'
  ],
  keepUnusedDataFor: 300, // setting the cache time to 5 minutes for all the endpoints under this base url
  endpoints: () => ({})
});

export default api;
