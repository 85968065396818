import { useNavigate, useParams } from 'react-router-dom';
import { Button, Flex, Text } from '@mantine/core';
import Layout from 'components/Layout';
import Zapier from '../Automations/AutomationsRouter/Zapier';
import { settingsTitleProvider } from 'pages/ControlPanel/utils';
import { NAVBAR_ROUTES } from 'utils/constants';
import { AUTOMATION_APPS, SETTINGS_TABS } from 'pages/ControlPanel/constants';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from '../SettingsRouter/styles.module.scss';

const SettingsSubRouter = () => {
  const { tab, subTab } = useParams();
  const navigate = useNavigate();

  const SubTabViewObj = {
    [SETTINGS_TABS.AUTOMATIONS]: {
      [AUTOMATION_APPS.ZAPIER]: <Zapier />
    }
  };

  return (
    <Layout
      pageTitle={
        <Flex align='center'>
          <Button
            className={styles.navigateBackButton}
            onClick={() => navigate(NAVBAR_ROUTES.SETTINGS_TABS[tab.toLocaleUpperCase()])}
            variant='default'
          >
            <SVGIcon name={ICONS_LIST.LEFT_ARROW} height={18} width={18} />
          </Button>

          <Text className={styles.providerTitle}>
            Settings &gt; {settingsTitleProvider(tab)} &gt;&nbsp;
            <strong className='capitalize-first-letter'>{subTab}</strong>
          </Text>
        </Flex>
      }
    >
      {SubTabViewObj[tab][subTab]}
    </Layout>
  );
};

export default SettingsSubRouter;
