import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextInput } from '@mantine/core';
import { useSelector } from 'react-redux';
import RequestModal from 'components/Modals/RequestModal';
import SlackConnectModal from 'components/Modals/SlackConnectModal';
import AlreadyConnectedModal from 'components/Modals/AlreadyConnectedModal';
import { usePostSlackConnectMutation } from 'redux/services/slack-connect';
import { data } from './data';
import { NAVBAR_ROUTES } from 'utils/constants';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import styles from './styles.module.scss';

const SupportedApps = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showRequestModal, setShowRequestModal] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [postSlackConnect, { isSuccess: slackConnectSuccess }] = usePostSlackConnectMutation();
  const [slackConnectModal, setSlackConnectModal] = useState(false);
  const [alreadyConnectedModal, setAlreadyConnectedModal] = useState(false);
  const apps = searchTerm
    ? [...data.filter((app) => app.title.toLowerCase().includes(searchTerm.toLowerCase()))]
    : [...data];
  const navigate = useNavigate();

  function cardClickHandler(app) {
    if (app.isActive) {
      navigate(NAVBAR_ROUTES.SETTINGS_SUB_TABS.AUTOMATIONS.ZAPIER);
      return;
    }
    setShowRequestModal(true);
  }

  function handleBookCall() {
    setShowRequestModal(false);
    window.open('https://calendly.com/albus-sw/1v1-with-albus-team', '_blank');
  }

  async function handleChatOnSlack() {
    const response = await postSlackConnect({
      emails: [user?.email]
    });
    setShowRequestModal(null);
    if (response?.data?.channelId) {
      setAlreadyConnectedModal(true);
    } else {
      if (response?.data.ok) {
        setSlackConnectModal(true);
      }
    }
  }

  return (
    <>
      {slackConnectSuccess && (
        <SlackConnectModal opened={slackConnectModal} setOpened={setSlackConnectModal} />
      )}
      {alreadyConnectedModal && (
        <AlreadyConnectedModal
          opened={alreadyConnectedModal}
          setOpened={setAlreadyConnectedModal}
        />
      )}
      {showRequestModal && (
        <RequestModal
          isIntegration
          setShowRequestModal={setShowRequestModal}
          handleBookCall={handleBookCall}
          handleChatOnSlack={handleChatOnSlack}
        />
      )}
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>Supported apps</div>
          <TextInput
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            leftSection={<SearchIcon />}
            classNames={{ input: styles.search }}
            placeholder='Search for apps'
          />
        </div>
        <div className={styles.appContainer}>
          {apps.map((app) => (
            <div className={styles.appCard} key={app.id} onClick={() => cardClickHandler(app)}>
              <div className={styles.cardHeader}>
                <img src={app.icon} />
                <div className={styles.title}>{app.title}</div>
                {!app.isActive && <div className={styles.tag}>Coming Soon</div>}
              </div>
              <div className={styles.description}>{app.description}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SupportedApps;
