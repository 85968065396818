import QuestionsEmptyStateIcon from 'assets/questions-empty-state.svg';
import styles from './styles.module.scss';

const QuestionEmptyState = () => {
  return (
    <div className={styles.wrapper}>
      <img src={QuestionsEmptyStateIcon} />
      <p className={styles.text}>
        Albus is absorbing information from your sources. Hold on while we set up your account.
      </p>
    </div>
  );
};

export default QuestionEmptyState;
