import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';
import { useSelector } from 'react-redux';
import { useForm } from '@mantine/form';
import { useUpdateCustomApiMutation } from 'redux/services/settings.js';
import EmptyState from '../EmptyState';
import View from './View.jsx';

export const DEFAULT_FORM_VALUES = {
  name: 'Untitled',
  search: {
    ai: false,
    training: true
  },
  entryPoint: 'Miscellaneous',
  wikis: [],
  persona: '',
  useAgent: false,
  searchFlow: 'deep'
};

const CustomApiDetails = (props) => {
  const { customApi, selectedWikis, isFetchingTeams, optionsData, onManageCustomApi } = props;
  const { planInfo } = useSelector((state) => state.plan);

  const [updateCustomApi, { isLoading: updateInProgress }] = useUpdateCustomApiMutation();
  const [wikisToAdd, setWikisToAdd] = useState(selectedWikis);
  const [resetOptionsData, setResetOptionsData] = useState(false);
  let customApiDetails;
  if (customApi?.id) {
    const fieldsToRemove = ['createdBy', 'interactions', 'organisation'];
    customApiDetails = omit(customApi, fieldsToRemove);
  }

  useEffect(() => {
    form.setValues(customApiDetails || DEFAULT_FORM_VALUES);
    form.resetDirty(customApiDetails || DEFAULT_FORM_VALUES);
  }, [customApi]);

  const form = useForm({
    initialValues: customApi?.id ? customApiDetails : DEFAULT_FORM_VALUES,
    validate: {
      name: (value) => (value ? null : 'Name is required'),
      search: (value) =>
        !value.ai && !value.training ? 'At least one search option must be selected' : null,
      wikis: (value, values) => {
        if (values.search.training && (!value || value.length === 0)) {
          return ' You need to select wikis to finish setting up.';
        }
        if (value?.length) {
          const allWikisHaveZeroSources = value.every((wiki) => wiki.sourcesCount === 0);
          if (allWikisHaveZeroSources) {
            return 'At least one wiki must have sources';
          }
        }
        return null;
      }
    }
  });

  const handleCheckboxChange = useCallback(
    (event, fieldName) => {
      const updatedSearch = { ...form.values.search };
      updatedSearch[fieldName] = event.target.checked;
      form.setFieldValue('search', updatedSearch);
    },
    [form]
  );

  const handleRadioChange = useCallback(
    (event) => {
      if (event === 'quick') {
        form.setFieldValue('useAgent', false);
      }

      form.setFieldValue('searchFlow', event);
    },
    [form]
  );

  const handleCustomApiUpdate = useCallback(async () => {
    const payload = {
      name: form.values.name,
      search: form.values.search,
      entryPoint: form.values.entryPoint,
      teams: form.values.wikis.map((wiki) => wiki.value),
      persona: form.values.persona,
      useAgent:
        form.values.search.training && form.values.searchFlow === 'deep'
          ? form.values.useAgent
          : false,
      searchFlow: form.values.searchFlow
    };
    const formData = { id: customApi.id, data: payload };
    const response = await updateCustomApi(formData);
    if (response?.data?.ok) {
      form.resetDirty();
    }
  }, [form, customApi, updateCustomApi]);

  const handleCloseFooter = useCallback(() => {
    form.reset();
    setWikisToAdd(selectedWikis);
    setResetOptionsData(true);
  }, [form, selectedWikis]);

  return (
    <>
      <EmptyState />
      <View
        form={form}
        handleCheckboxChange={handleCheckboxChange}
        planInfo={planInfo}
        optionsData={optionsData}
        setWikisToAdd={setWikisToAdd}
        wikisToAdd={wikisToAdd}
        isFetchingTeams={isFetchingTeams}
        customApi={customApi}
        handleCustomApiUpdate={handleCustomApiUpdate}
        isUpdating={updateInProgress}
        handleCloseFooter={handleCloseFooter}
        resetOptionsData={resetOptionsData}
        setResetOptionsData={setResetOptionsData}
        onManageCustomApi={onManageCustomApi}
        handleRadioChange={handleRadioChange}
      />
    </>
  );
};

CustomApiDetails.propTypes = {
  customApi: PropTypes.shape({
    id: PropTypes.string,
    organisationId: PropTypes.string,
    name: PropTypes.string,
    search: PropTypes.shape({
      ai: PropTypes.bool,
      training: PropTypes.bool
    }),
    entryPoint: PropTypes.string,
    wikis: PropTypes.arrayOf(PropTypes.object),
    persona: PropTypes.string
  }),
  selectedWikis: PropTypes.array.isRequired,
  isFetchingTeams: PropTypes.bool.isRequired,
  optionsData: PropTypes.array.isRequired,
  onManageCustomApi: PropTypes.func.isRequired
};

export default CustomApiDetails;
