import { useState } from 'react';
import { Flex, Menu, Text, UnstyledButton } from '@mantine/core';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import { array, func, object } from 'prop-types';
import ProfilePicture from 'components/ProfilePicture';
import { ReactComponent as ActionIcon } from 'assets/icons/3-v-dots.svg';
import styles from '../../CompanyAdmins/styles.module.scss';

const AdminsList = ({ admins, unAssignPrivilege, adminToBeRemoved, setAdminToBeRemoved }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <DeleteWarningModal
        open={open}
        onClose={() => {
          setOpen(false);
          setAdminToBeRemoved({});
        }}
        header='Remove admin'
        title='Are you sure you want to remove admin privileges?'
        handleCancel={() => {
          setOpen(false);
          setAdminToBeRemoved({});
        }}
        handleDelete={() => {
          unAssignPrivilege(adminToBeRemoved._id);
          setOpen(false);
        }}
        body={
          "When you remove a user's admin privileges, they can no longer access the dashboard, manage Licenses and control your account."
        }
      />
      {admins.map((user) => (
        <Flex key={user._id} className={styles.adminUserItem}>
          <Flex align='center'>
            <ProfilePicture photoUrl={user?.photo} name={user?.name} />
            <Text className={styles.userName} ml={12}>
              {user.name || user.email}
            </Text>
          </Flex>

          {!user.owner && (
            <Menu position='left'>
              <Menu.Target>
                <UnstyledButton style={{ width: 30, textAlign: 'center' }}>
                  <ActionIcon />
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  onClick={() => {
                    setOpen(true);
                    setAdminToBeRemoved(user);
                  }}
                >
                  Remove admin
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </Flex>
      ))}
    </>
  );
};

AdminsList.propTypes = {
  admins: array,
  unAssignPrivilege: func.isRequired,
  setAdminToBeRemoved: func.isRequired,
  adminToBeRemoved: object
};
export default AdminsList;
