import { useState } from 'react';
import { array, bool, func, number, string } from 'prop-types';
import pluralize from 'pluralize';
import classnames from 'classnames';
import { Button, Flex, Group, Modal, Combobox } from '@mantine/core';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { useGetTeamsQuery, useLazyGetTeamsQuery } from 'redux/services/settings.js';
import CustomOptionMultiSelect from 'components/CustomOptionMultiSelect';
import styles from './styles.module.scss';

const SelectItem = (props) => {
  const { label, isAdded, sourcesCount, ...others } = props;
  return (
    <Combobox.Option disabled={isAdded} {...others} className={styles.optionItem}>
      <Flex
        justify='space-between'
        align='center'
        className={classnames(styles.item, { [styles.disabledItem]: isAdded })}
      >
        <Group justify='left' spacing={4}>
          <div>
            {label} <span>{pluralize('source', sourcesCount, true)}</span>
          </div>
        </Group>
        {isAdded && <div className={styles.chip}>Added</div>}
      </Flex>
    </Combobox.Option>
  );
};

SelectItem.displayName = 'SelectItem';
SelectItem.propTypes = {
  label: string.isRequired,
  sourcesCount: number.isRequired,
  isAdded: bool.isRequired
};

const SelectWikiModal = ({ opened, onClose, loading, onConfirm, selectedWikis }) => {
  const [wikisToAdd, setWikisToAdd] = useState([]);
  const { data: { teams = [] } = { teams: [] } } = useGetTeamsQuery();
  const [getTeams, { isFetching }] = useLazyGetTeamsQuery();
  const availableWikis = [...teams];

  availableWikis
    .sort((a, b) => b.name.localeCompare(a.name))
    .sort((a, b) => {
      // Put all selected channels at the bottom of the array
      const aIndex = selectedWikis.indexOf(a.teamId);
      const bIndex = selectedWikis.indexOf(b.teamId);

      if (aIndex === -1) {
        return -1;
      }
      if (bIndex === -1) {
        return 1;
      }
      return bIndex - aIndex;
    });

  const data = availableWikis.map((wiki) => ({
    value: wiki.teamId,
    label: wiki.name,
    isAdded: selectedWikis.includes(wiki.teamId),
    sourcesCount: wiki.sourcesCount
  }));

  return (
    <Modal
      opened={opened}
      onClose={() => {
        setWikisToAdd([]);
        onClose();
      }}
      centered
      title={<div className={styles.modalTitle}>Select wiki</div>}
      size='lg'
      classNames={{ header: styles.modalHeader }}
    >
      <div className={styles.container}>
        <div className={styles.note}>
          Select wikis this chat widget should refer to for answering incoming questions.
        </div>
        <CustomOptionMultiSelect
          optionsData={data}
          optionValue={wikisToAdd}
          onChange={setWikisToAdd}
          label='Select wiki'
          placeholder='Search'
          CustomOption={SelectItem}
        />
        <div className={styles.refreshNote}>
          Can&apos;t find the team you created?&nbsp;
          <span
            onClick={() => getTeams()}
            className={classnames({ [styles.disabled]: isFetching })}
          >
            Refresh <RefreshIcon />
          </span>
        </div>
        <Group justify='right'>
          <Button
            radius='sm'
            variant='outline'
            onClick={() => {
              setWikisToAdd([]);
              onClose();
            }}
            className={styles.secondaryCta}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            radius='sm'
            className={styles.primaryCta}
            loading={loading}
            disabled={wikisToAdd.length === 0}
            onClick={() => {
              const _wikis = data
                .filter((wiki) => {
                  return wikisToAdd.some((addedWiki) => addedWiki.value === wiki.value);
                })
                .map((wiki) => ({
                  id: wiki.value,
                  name: wiki.label,
                  numberOfSources: wiki.sourcesCount
                }));

              onConfirm(_wikis);
              setWikisToAdd([]);
            }}
          >
            Confirm
          </Button>
        </Group>
      </div>
    </Modal>
  );
};

SelectWikiModal.propTypes = {
  opened: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  selectedWikis: array.isRequired,
  loading: bool
};

export default SelectWikiModal;
