import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetTeamsQuery } from 'redux/services/settings';
import { useSelector } from 'react-redux';
import { Loader } from '@mantine/core';
import EmptyState from './EmptyState';
import PageWrapper from './PageWrapper';
import Table from './Table';
import CreateTeam from './Forms/CreateTeam';
import { startTeamsTutorials } from './Tutorial';
import useShowTutorial from 'custom_hooks/useShowTutorial';
import styles from './Teams.module.scss';

const Teams = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const {
    data: { teams = [] } = {
      teams: []
    },
    isFetching
  } = useGetTeamsQuery();
  const [createTeamModal, setCreateTeamModal] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);

  useShowTutorial(startTeamsTutorials);

  useEffect(() => {
    if (Object.keys(user).length && !user?.isAdmin && !user?.isTeamAdmin) {
      navigate('/');
    }
  }, [user]);

  if (isFetching) {
    return (
      <PageWrapper pageTitle='Teams' alignCenter>
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      </PageWrapper>
    );
  }

  if ((teams.length === 1 && teams[0].companyWiki) || teams.length === 0) {
    return (
      <PageWrapper pageTitle='Teams' alignCenter>
        <EmptyState handleClick={() => setCreateTeamModal(true)} />
        {createTeamModal && (
          <CreateTeam
            open={createTeamModal}
            handleClose={() => setCreateTeamModal(false)}
            setSelectedTeamId={setSelectedTeamId}
          />
        )}
      </PageWrapper>
    );
  }

  return (
    <PageWrapper
      pageTitle='Teams'
      selectedTeamId={selectedTeamId}
      setSelectedTeamId={setSelectedTeamId}
    >
      <Table
        teams={teams}
        handleTeamCreateClick={() => setCreateTeamModal(true)}
        selectedTeamId={selectedTeamId}
        setSelectedTeamId={setSelectedTeamId}
      />
      {createTeamModal && (
        <CreateTeam
          open={createTeamModal}
          handleClose={() => setCreateTeamModal(false)}
          setSelectedTeamId={setSelectedTeamId}
        />
      )}
    </PageWrapper>
  );
};

export default Teams;
