import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingOverlay, Select } from '@mantine/core';
import classNames from 'classnames';
import PromptCard from '../../PromptCard';
import NoWikiIdea from '../../NoWikiIdea';
import InfiniteScroll from 'components/InfiniteScroll';
import { useGetIntegrationsSourcesQuery, useGetPromptIdeasQuery } from 'redux/services/ask-albus';
import styles from '../../styles.module.scss';

const CompanyWiki = (props) => {
  const { setShowPromptIdeas, tab, isFreePlan, setQuestionCount } = props;
  const { data: sources = [] } = useGetIntegrationsSourcesQuery();
  const [sourcesList, setSourcesList] = useState([{ label: 'All', value: 'all' }]);

  const [page, setPage] = useState(1);
  const [selectedSource, setSelectedSource] = useState('all');
  const [promptIdeasList, setPromptIdeasList] = useState([]);
  const {
    data: { questions: wikiPromptIdeas = [], hasNext } = {},
    isLoading: isFetchingWikiPromptIdeas
  } = useGetPromptIdeasQuery(
    selectedSource === 'all' ? { page } : { documentId: selectedSource, page }
  );

  useEffect(() => {
    if (sources.length) {
      setSourcesList((prev) => [
        ...prev,
        ...sources.map((source) => ({
          label: source.name,
          value: source.id
        }))
      ]);
    }
  }, [sources]);

  useEffect(() => {
    if (wikiPromptIdeas.length) {
      if (page === 1) {
        setPromptIdeasList(
          wikiPromptIdeas.map((idea) => ({
            id: idea._id,
            prompt: idea.question,
            provider: idea.documentProvider,
            documentName: idea.documentName
          }))
        );
        setQuestionCount((prev) => ({ ...prev, wiki: wikiPromptIdeas.length }));
      } else {
        setPromptIdeasList((prev) => [
          ...prev,
          ...wikiPromptIdeas.map((idea) => ({
            id: idea._id,
            prompt: idea.question,
            provider: idea.documentProvider,
            documentName: idea.documentName
          }))
        ]);
        setQuestionCount((prev) => ({ ...prev, wiki: prev.wiki + wikiPromptIdeas.length }));
      }
    }
  }, [wikiPromptIdeas]);

  return (
    <>
      {/* Source Selection */}
      <Select
        radius='sm'
        label={'sources'}
        value={selectedSource}
        data={sourcesList}
        onChange={(value) => {
          setPage(1);
          setSelectedSource(value);
        }}
        classNames={{
          input: styles.selectInput,
          label: styles.selectLabel,
          root: classNames('select-style-v1', {
            [styles.freePlanselectRoot]: isFreePlan,
            [styles.selectRoot]: !isFreePlan
          })
        }}
        allowDeselect={false}
        maxDropdownHeight={250}
        searchable
      />
      {!promptIdeasList?.length ? (
        <NoWikiIdea />
      ) : (
        <InfiniteScroll
          dataLength={promptIdeasList.length}
          scrollTarget='scroll-target'
          hasNext={hasNext}
          onNext={() => setPage((prev) => prev + 1)}
        >
          <div className={styles.promptIdeasCardsWrapper}>
            {/* Prompt Ideas Cards */}
            {isFetchingWikiPromptIdeas && <LoadingOverlay visible />}

            {promptIdeasList.map((idea) => (
              <PromptCard
                key={`${idea.id}-${idea.prompt}`}
                prompt={idea.prompt}
                documentName={idea.documentName}
                provider={idea.provider}
                type={tab}
                category={idea.category}
                setShowPromptIdeas={setShowPromptIdeas}
                questionId={idea.id}
              />
            ))}
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};

CompanyWiki.propTypes = {
  tab: PropTypes.string.isRequired,
  setShowPromptIdeas: PropTypes.func.isRequired,
  isFreePlan: PropTypes.bool.isRequired,
  setQuestionCount: PropTypes.func.isRequired
};

export default CompanyWiki;
