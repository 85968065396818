import { useState } from 'react';
import { func, object, bool } from 'prop-types';
import { Image, Menu, UnstyledButton } from '@mantine/core';
import ActionIcon from 'assets/icons/3-v-dots.svg';

const ActionMenu = ({ data, actionMenuData, opened, setOpened, ...others }) => {
  const [showMenu, setShowMenu] = useState(false);

  function handleMenuChange(value) {
    setShowMenu(value);
  }

  return (
    <Menu
      position='right'
      opened={opened || showMenu}
      onChange={setOpened ?? handleMenuChange}
      {...others}
    >
      <Menu.Target>
        <UnstyledButton pl={10} pr={10}>
          <Image src={ActionIcon} alt='action icon' w={4} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{actionMenuData(data).map((item) => item)}</Menu.Dropdown>
    </Menu>
  );
};

ActionMenu.propTypes = {
  data: object.isRequired,
  actionMenuData: func.isRequired,
  opened: bool,
  setOpened: func
};

ActionMenu.defaultProps = {
  setOpened: null,
  opened: false
};

export default ActionMenu;
