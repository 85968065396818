import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Flex, Text } from '@mantine/core';
import Layout from 'components/Layout';
import Header from './Header';
import DataView from './DataView';
import { useFetchSubDocumentsQuery } from 'redux/services/integrations';
import { SOURCES_STATUS } from '../constants';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from './styles.module.scss';

const ManageSources = () => {
  const url = new URL(window.location.href);
  const teamName = url.searchParams.get('teamName');
  const docId = url.searchParams.get('docId');

  const { teamId } = useParams();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [selectedSources, setSelectedSources] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedTab, setSelectedTab] = useState(SOURCES_STATUS.ACTIVE);

  const [count, setCount] = useState({
    active: null,
    processing: null,
    error: null,
    excluded: null
  });

  const {
    data: { data: { totalDocumentsCount, processingDocumentsCount } = {} } = {},
    isFetching
  } = useFetchSubDocumentsQuery({
    docId,
    params: { teamId }
  });

  const [page, setPage] = useState(1);

  function handleBackButton() {
    navigate(`/integrations/web/${teamId}`);
  }

  function handleTabChange(tab) {
    if (tab !== selectedTab) {
      setIsAllSelected(false);
      setSelectedSources([]);
      setSelectedTab(tab);
      setPage(1);
    }
  }

  return (
    <Layout
      pageTitle={
        <Flex align='center' gap={12}>
          <Button onClick={handleBackButton} className={styles.backButton} variant='default'>
            <SVGIcon name={ICONS_LIST.LEFT_ARROW} height={18} width={18} />
          </Button>
          <Text className={styles.pageTitle}>
            Wiki &gt; {teamName} &gt; Web &gt; <span>Manage source</span>
          </Text>
        </Flex>
      }
    >
      <div className={styles.wrapper}>
        <Header
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          count={count}
          setCount={setCount}
          setSelectedSources={setSelectedSources}
          setIsAllSelected={setIsAllSelected}
          disableRetrain={!isFetching && totalDocumentsCount === processingDocumentsCount}
        />
        <DataView
          selectedTab={selectedTab}
          searchValue={searchValue}
          teamId={teamId}
          count={count}
          setCount={setCount}
          selectedSources={selectedSources}
          setSelectedSources={setSelectedSources}
          isAllSelected={isAllSelected}
          setIsAllSelected={setIsAllSelected}
          handleTabChange={handleTabChange}
          page={page}
          setPage={setPage}
          totalDocumentsCount={totalDocumentsCount}
        />
      </div>
    </Layout>
  );
};

export default ManageSources;
