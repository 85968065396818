import { toast } from 'react-toastify';
import { WIDGET, WIDGETS } from 'utils/apiEndpoints';
import { handleApiError } from 'utils/helpers';
import api from './api';
import { saveWidgets, updateWidget, deleteWidget, addWidget } from '../features/widgetsSlice.js';

const widgetsApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchWidgets: build.query({
      query: () => ({
        url: WIDGETS,
        method: 'GET'
      }),
      providesTags: ['Widgets'],
      async onQueryStarted(Data, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(saveWidgets(response.data));
          } else {
            toast.error(response.message);
          }
        } catch ({ error }) {
          handleApiError(error);
        }
      }
    }),
    createWidget: build.mutation({
      query: (data) => ({
        url: WIDGET,
        method: 'POST',
        body: data
      }),
      async onQueryStarted(Data, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(addWidget(response.data));
            toast.success('Chat widget created successfully');
            return response.data;
          } else {
            toast.error(response.message);
          }
        } catch ({ error }) {
          handleApiError(error);
        }
      }
    }),
    getWidget: build.query({
      query: (id) => ({
        url: `${WIDGET}/${id}`,
        method: 'GET'
      }),
      providesTags: ['Widget'],
      async onQueryStarted(Data, { queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            return response.data;
          } else {
            toast.error(response.message);
          }
        } catch ({ error }) {
          handleApiError(error);
        }
      }
    }),
    updateWidget: build.mutation({
      query: ({ id, data }) => ({
        url: `${WIDGET}/${id}`,
        method: 'PATCH',
        body: data
      }),
      async onQueryStarted(Data, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(updateWidget({ id: Data.id, data: response.data }));
            toast.success('Changes saved successfully');
            return response.data;
          } else {
            toast.error(response.message);
          }
        } catch ({ error }) {
          handleApiError(error);
        }
      }
    }),
    deleteWidget: build.mutation({
      query: (id, data) => ({
        url: `${WIDGET}/${id}`,
        method: 'DELETE',
        body: data
      }),
      async onQueryStarted(Data, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(deleteWidget({ id: Data.id }));
          } else {
            toast.error(response.message);
          }
        } catch ({ error }) {
          handleApiError(error);
        }
      }
    }),
    fetchWidgetWikis: build.query({
      query: (id) => ({
        url: `${WIDGET}/${id}/teams`,
        method: 'GET'
      }),
      async onQueryStarted(Data, { queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            return response.data;
          } else {
            toast.error(response.message);
          }
        } catch ({ error }) {
          handleApiError(error);
        }
      }
    })
  })
});

export const {
  useFetchWidgetsQuery,
  useCreateWidgetMutation,
  useLazyGetWidgetQuery,
  useUpdateWidgetMutation,
  useDeleteWidgetMutation,
  useLazyFetchWidgetWikisQuery
} = widgetsApi;

export default widgetsApi;
