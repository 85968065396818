import { useState } from 'react';
import { useDebouncedValue, usePagination } from '@mantine/hooks';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useFetchSourcesQuery } from 'redux/services/integrations';
import SourcesDataView from './SourcesDataView/index.jsx';
import { ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS } from 'utils/constants.js';

const OtherIntegration = (props) => {
  const { user, viewType, setViewType, provider } = props;
  const { teamId } = useParams();
  const [page, onChange] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue] = useDebouncedValue(searchValue, 500);
  const payload = {
    provider,
    params: {
      teamId,
      page: debouncedValue ? undefined : page,
      limit: debouncedValue ? undefined : ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS,
      q: debouncedValue ? debouncedValue : undefined,
      sort: 'lastTrainingStartedAt'
    }
  };
  const { data: { data: customSources = [], totalCount = 0 } = {}, isFetching: isCustomFetching } =
    useFetchSourcesQuery(
      { ...payload },
      {
        refetchOnMountOrArgChange: true
      }
    );

  const pagination = usePagination({
    page,
    onChange
  });

  return (
    <SourcesDataView
      viewType={viewType}
      setViewType={setViewType}
      sources={customSources}
      user={user}
      isLoading={isCustomFetching}
      providerName={provider}
      pagination={pagination}
      pageCount={Math.ceil(totalCount / ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS)}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      debouncedValue={debouncedValue}
      totalCount={totalCount}
      metaPayload={payload}
    />
  );
};

OtherIntegration.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  user: PropTypes.shape({
    connections: PropTypes.arrayOf(PropTypes.object),
    email: PropTypes.string,
    featuresExploredFlags: PropTypes.arrayOf(PropTypes.object),
    isAdmin: PropTypes.bool,
    isLicensed: PropTypes.bool,
    isTeamAdmin: PropTypes.bool,
    name: PropTypes.string,
    organisationId: PropTypes.string,
    photo: PropTypes.string,
    team: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    userId: PropTypes.string,
    _id: PropTypes.string
  }),
  provider: PropTypes.string
};

OtherIntegration.defaultProps = {
  viewType: 'grid',
  setViewType: () => {},
  user: {
    connections: [],
    email: '',
    featuresExploredFlags: [],
    isAdmin: false,
    isLicensed: false,
    isTeamAdmin: false,
    name: '',
    organisationId: '',
    photo: '',
    team: {
      id: '',
      name: ''
    },
    userId: '',
    _id: ''
  },
  provider: ''
};
export default OtherIntegration;
