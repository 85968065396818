import {
  Accordion,
  Button,
  Flex,
  AppShell,
  List,
  Loader,
  Modal as MantineModal,
  ScrollArea,
  Text,
  Tooltip,
  UnstyledButton,
  Image
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import PropTypes from 'prop-types';
import AnswerItem from './AnswerItem';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { useEffect, useState, useRef } from 'react';
import { useLazyFetchQuestionsDetailsQuery } from 'redux/services/questions';
import VectorDown from 'assets/vector-down.svg';
import { maybePluralize } from 'utils/utilities';
import styles from './styles.module.scss';

const AnswerModal = ({
  opened,
  onClose,
  tab,
  handleResolve,
  selectedQuestion,
  handleNavigation
}) => {
  const [page, setPage] = useState(1);

  const [trigger, { data: { questions: questionData = [], hasNext } = {}, isLoading, isFetching }] =
    useLazyFetchQuestionsDetailsQuery(
      { id: selectedQuestion?.id, page },
      {
        skip: true
      }
    );
  const listContainerRef = useRef(null);
  const [questions, setQuestions] = useState([]);
  const [value, setValue] = useState([]);
  const [isMarkedAllAsResolvedDisabled, setIsMarkedAllAsResolvedDisabled] = useState(false);
  const screen767 = useMediaQuery('(max-width: 767px)');
  const loading = isLoading || isFetching;
  const escalatedPrompts = questions?.filter((d) => d?.escalation?.status === 'active') || [];
  const resolvedPrompts = questions?.filter((d) => d?.escalation?.status === 'resolved') || [];
  const [showFloatingButton, setShowFloatingButton] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const modalRef = useRef();

  useEffect(() => {
    setQuestions([]);
    trigger({ id: selectedQuestion?.id, page });
  }, [selectedQuestion]);

  useEffect(() => {
    if (questions?.length) {
      setValue([questions?.[0]?._id.toString()]);
      setShowFloatingButton(
        questions?.filter((d) => d.escalation?.status === 'active')?.length > 3
      );
    }
  }, [questions]);

  useEffect(() => {
    if (!loading) {
      setQuestions([...questions, ...questionData]);
    }
  }, [loading]);

  const scrollToItem = () => {
    const container = modalRef.current;
    const scrollToIndex = Math.floor(scrollHeight / 600);
    const listItem = container.childNodes[scrollToIndex + 3];
    listItem.scrollIntoView({ behavior: 'smooth' });
    setShowFloatingButton(scrollToIndex + 3 < escalatedPrompts.length);
  };

  const paginationOnScroll = (event) => {
    const contentElement = event.target;
    if (contentElement.scrollHeight - contentElement.scrollTop === contentElement.clientHeight) {
      if (hasNext) {
        setPage((page) => page + 1);
        trigger({ id: selectedQuestion?.id, page: page + 1 });
      }
    }
  };

  return (
    <AppShell>
      <MantineModal
        className='mobile-modal'
        opened={opened}
        onClose={() => {
          setValue([questions?.[0]?._id.toString()]);
          setQuestions([]);
          setPage(1);
          onClose();
        }}
        centered
        title={
          <Text fw={600} mb={9} size='lg'>
            Answers
          </Text>
        }
        size={screen767 ? '100%' : '70%'}
        scrollAreaComponent={ScrollArea.Autosize}
        classNames={{
          close: styles.modalCloseButton,
          content: styles.modalBody
        }}
        onScrollCapture={(event) => {
          paginationOnScroll(event);
          setScrollHeight(event.target.scrollHeight);
        }}
      >
        {loading && !questions.length ? (
          <Flex align='center' justify='center'>
            <Loader />
          </Flex>
        ) : (
          <>
            {questions?.length ? (
              <Accordion
                value={value}
                onChange={setValue}
                multiple={true}
                classNames={{
                  control: styles.control,
                  chevron: styles.chevron,
                  content: styles.content,
                  item: styles.item,
                  root: styles.root
                }}
              >
                <List
                  withPadding={!screen767}
                  styles={{
                    item: {
                      listStyleType: 'none'
                    }
                  }}
                  ref={listContainerRef}
                >
                  {questions.map((d, i) => (
                    <AnswerItem
                      loading={loading}
                      key={d._id}
                      data={d}
                      isFirstIndex={i === 0}
                      isOnlyQuestion={questions.length === 1}
                      isOnlyEscalatedQuestion={escalatedPrompts?.length === 1}
                      value={value}
                      tab={tab}
                      handleResolve={handleResolve}
                      handleAnswerButtonClick={(value) => setIsMarkedAllAsResolvedDisabled(value)}
                    />
                  ))}
                </List>
              </Accordion>
            ) : null}

            {showFloatingButton && (
              <div className={styles.scrollFloater} onClick={scrollToItem}>
                <Tooltip
                  w={130}
                  label='More responses where teammates need help'
                  position='top-end'
                  arrowPosition='center'
                  classNames={{
                    tooltip: styles.floaterTooltip,
                    arrow: styles.floaterArrow
                  }}
                >
                  <Image src={VectorDown} alt='vector' />
                </Tooltip>
              </div>
            )}

            <AppShell.Footer className={styles.footer}>
              {escalatedPrompts.length ? (
                <Flex justify='space-between' align='center'>
                  <strong>
                    <center>
                      Your teammates need help with {escalatedPrompts.length}&nbsp;
                      {maybePluralize(escalatedPrompts.length, 'response')} here.
                    </center>
                  </strong>
                  {escalatedPrompts.length > 1 ? (
                    <Button
                      size={screen767 ? 'xs' : 'md'}
                      className='primaryBtn'
                      onClick={() =>
                        handleResolve(
                          {
                            promptId: escalatedPrompts[0]?._id,
                            resolveThread: true
                          },
                          true
                        )
                      }
                      style={{ fontSize: screen767 ? 12 : 16 }}
                      disabled={isMarkedAllAsResolvedDisabled}
                    >
                      Mark all as resolved
                    </Button>
                  ) : null}
                </Flex>
              ) : (
                <Text style={{ display: 'flex', justifyContent: 'end' }}>
                  {escalatedPrompts.length === 0 && resolvedPrompts.length ? (
                    <Flex align='center' gap={8}>
                      <Text size='md' className='capitalize-first-letter'>
                        <b>{questions?.[0].escalation.resolvedBy?.map((e) => e.name).join(', ')}</b>
                        &nbsp;resolved this.
                      </Text>
                      <Tooltip
                        label={`${questions?.[0].escalation.escalatedBy
                          .map((e) => e.name)
                          .join(
                            ', '
                          )} asked admins for help. To verify if they were able to get help, view the entire conversation or reach out to ${questions?.[0].escalation.resolvedBy
                          ?.map((e) => e.name)
                          .join(', ')}.`}
                        w={253}
                        classNames={{ tooltip: 'capitalize-first-letter' }}
                      >
                        <Flex align='center'>
                          <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
                        </Flex>
                      </Tooltip>
                    </Flex>
                  ) : (
                    <b>No one asked for help here.</b>
                  )}
                </Text>
              )}

              {selectedQuestion?.showPrev || selectedQuestion?.showNext ? (
                <Flex mt={20} justify='space-between'>
                  {selectedQuestion?.showPrev ? (
                    <UnstyledButton
                      onClick={() => {
                        selectedQuestion?.prevIndex > -1 &&
                          handleNavigation(selectedQuestion.prevIndex);
                      }}
                    >
                      <Text className={styles.accordion}>&lt; Previous</Text>
                    </UnstyledButton>
                  ) : (
                    <div /> // to maintain the spacing
                  )}
                  {selectedQuestion?.showNext && (
                    <UnstyledButton
                      style={{ position: 'relative', right: 0 }}
                      onClick={() =>
                        selectedQuestion?.nextIndex && handleNavigation(selectedQuestion.nextIndex)
                      }
                    >
                      <Text className={styles.accordion}>Next &gt;</Text>
                    </UnstyledButton>
                  )}
                </Flex>
              ) : null}
            </AppShell.Footer>
          </>
        )}
      </MantineModal>
    </AppShell>
  );
};

AnswerModal.defaultProps = {
  opened: false
};

AnswerModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  handleResolve: PropTypes.func.isRequired,
  selectedQuestion: PropTypes.shape({
    id: PropTypes.string.isRequired,
    nextIndex: PropTypes.number.isRequired,
    prevIndex: PropTypes.number.isRequired,
    showPrev: PropTypes.bool.isRequired,
    showNext: PropTypes.bool.isRequired
  }),
  handleNavigation: PropTypes.func.isRequired
};
export default AnswerModal;
