import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Pagination, Text, LoadingOverlay } from '@mantine/core';
import { toast } from 'react-toastify';
import BillingTable from './Table';
import ConfirmationModal from '../ConfirmationModal';
import MultiSelectActions from '../MultiSelectActions';
import UnableToAssignLicensesModal from '../UnableToAssignLicensesModal';
import UserDetailsModal from 'components/UserDetailsModal/UserDetailsModal';
import { useUpdateLicensesMutation } from 'redux/services/licenses';
import { useRevokeInvitationsMutation } from 'redux/services/invite';
import { ReactComponent as TrainingImage } from 'assets/start-training.svg';
import { ReactComponent as EmptyStateImage } from 'assets/licenses-empty.svg';
import styles from './styles.module.scss';

const ManageLicensesTable = (props) => {
  const { searchTerm, licenseStatus, pagination } = props;

  const { users = [], total: usersTotal } = useSelector((state) => state.license);

  // Plan Information
  const { planInfo } = useSelector((state) => state.plan);
  const { searchQuery } = useSelector((state) => state.license);

  //Filtering users based on query
  let usersToDisplay = users;

  if (searchQuery) {
    usersToDisplay = users.filter((user) =>
      user?.email?.toLowerCase().includes(searchQuery?.toLowerCase())
    );
  }

  const [isDenyConfirmModalOpen, setIsDenyConfirmModalOpen] = useState(false);
  const [isAssignConfirmModalOpen, setIsAssignConfirmModalOpen] = useState(false);
  const [isUnassignConfirmModalOpen, setIsUnassignConfirmModalOpen] = useState(false);
  const [isRevokeInvitationConfirmModalOpen, setIsRevokeInvitationConfirmModalOpen] =
    useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isMultiSelectActionsOpen, setIsMultiSelectActionsOpen] = useState(false);
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Reset selection on change status filter
  useEffect(() => {
    setSelectedUsers([]);
    setSelectAll(false);
    setIsMultiSelectActionsOpen(false);
  }, [licenseStatus]);

  // To display Bottom sheet for Bulk Actions
  useEffect(() => {
    if (selectedUsers.length > 0) {
      setIsMultiSelectActionsOpen(true);
    } else {
      setIsMultiSelectActionsOpen(false);
      setSelectAll(false);
    }
  }, [selectedUsers]);

  // Update user status Mutation
  const [updateLicenses, { isLoading: isDataUpdating }] = useUpdateLicensesMutation();
  const [revokeInvitations, { isLoading: isRevokeInvitationLoading }] =
    useRevokeInvitationsMutation();

  // Count of unassigned licenses
  const unassignedLicenses = planInfo?.plan
    ? Math.max(planInfo.plan.licensesBought - planInfo.plan.licensesAssigned, 0)
    : 0;

  // To Select All Users
  const handleSelectAllUsers = (e) => {
    if (e.target.checked) {
      setSelectedUsers(users.map((d) => d.userId));
      setSelectAll(true);
    } else {
      setSelectedUsers([]);
      setSelectAll(false);
    }
  };

  const handleMultipleSelectionConfirmation = (type, size = 0, autoBuySubscription = false) => {
    const body = {
      ids:
        size > 0
          ? selectedUsers.sort(() => Math.random() - Math.random()).slice(0, size)
          : selectedUsers,
      status: type,
      autoBuySubscription
    };
    updateLicenses(body);
  };

  const handleConfirmation = (type) => {
    const body = {
      ids: [userDetails.userId],
      status: type,
      autoBuySubscription: false
    };

    updateLicenses(body);
  };

  const handleRevokeInvitationConfirmation = () => {
    const body = {
      ids: [userDetails._id]
    };
    try {
      revokeInvitations(body);
    } catch (error) {
      toast.error('Failed to send request');
    }
  };

  return (
    <>
      <LoadingOverlay visible={isDataUpdating} />
      <div className={styles.tableContainer}>
        <BillingTable
          selectAll={selectAll}
          setIsDenyConfirmModalOpen={setIsDenyConfirmModalOpen}
          setIsApproveModalOpen={setIsApproveModalOpen}
          setIsAssignConfirmModalOpen={setIsAssignConfirmModalOpen}
          unassignedLicenses={unassignedLicenses}
          setIsRevokeInvitationConfirmModalOpen={setIsRevokeInvitationConfirmModalOpen}
          setIsUnassignConfirmModalOpen={setIsUnassignConfirmModalOpen}
          setIsUserDetailsModalOpen={setIsUserDetailsModalOpen}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          setUserDetails={setUserDetails}
          licenseStatus={licenseStatus}
          handleSelectAllUsers={handleSelectAllUsers}
          setIsModalOpen={setIsModalOpen}
          searchQuery={searchQuery}
        />

        {planInfo?.plan?.licensesBought === 0 && (
          <div className={styles.emptyStateContainer}>
            <TrainingImage style={{ width: '250px' }} />
            <Text className={styles.emptyStateText}>
              Buy Licenses and assign them to users to provide access to Albus
            </Text>
          </div>
        )}

        {usersToDisplay.length === 0 && (
          <div className={styles.emptyStateContainer}>
            <EmptyStateImage style={{ width: '250px' }} />
            <Text mt={30} className={styles.emptyStateText}>
              {searchTerm ? `No results matching "${searchTerm}" found` : 'No entries to show'}
            </Text>
          </div>
        )}

        <div className={styles.tableFooter}>
          {planInfo?.plan?.licensesBought > 0 && (
            <Pagination
              total={Math.ceil(usersTotal / 10)}
              size='sm'
              value={pagination.active}
              onChange={pagination.setPage}
              color='dark'
            />
          )}
        </div>
      </div>

      {/* Assign Modal */}
      <ConfirmationModal
        confirmBtnLabel='Assign'
        title='Assign License'
        message='Are you sure you want to assign License?'
        opened={isAssignConfirmModalOpen}
        setOpened={setIsAssignConfirmModalOpen}
        handleConfirmation={handleConfirmation}
        actionType='Assign'
      />

      {/* Revoke Invite Modal */}
      <ConfirmationModal
        isLoading={isRevokeInvitationLoading}
        confirmBtnLabel='Revoke'
        title='Revoke Invitation'
        message='Are you sure you want to revoke invitation?'
        opened={isRevokeInvitationConfirmModalOpen}
        setOpened={setIsRevokeInvitationConfirmModalOpen}
        handleConfirmation={handleRevokeInvitationConfirmation}
        actionType='Revoke'
      />

      {/* Unassign Modal */}
      <ConfirmationModal
        confirmBtnLabel='Unassign'
        title='Unassign License'
        message='Are you sure you want to unassign License?'
        opened={isUnassignConfirmModalOpen}
        setOpened={setIsUnassignConfirmModalOpen}
        handleConfirmation={handleConfirmation}
        actionType='Unassign'
      />

      {/* Deny Modal */}
      <ConfirmationModal
        confirmBtnLabel='Deny'
        title='Deny License request'
        message='Are you sure you want to deny License request?'
        opened={isDenyConfirmModalOpen}
        setOpened={setIsDenyConfirmModalOpen}
        handleConfirmation={handleConfirmation}
        actionType='Denied'
      />

      {/* Approve Modal */}
      <ConfirmationModal
        confirmBtnLabel='Approve'
        title='Approve License request'
        message='Are you sure you want to approve License request?'
        opened={isApproveModalOpen}
        setOpened={setIsApproveModalOpen}
        handleConfirmation={handleConfirmation}
        actionType='Approved'
      />

      {/* Buy More Licenses Modal */}
      {isModalOpen && (
        <UnableToAssignLicensesModal
          opened={isModalOpen}
          selectedUsersCount={selectedUsers.length}
          unusedLicenses={unassignedLicenses}
          handleConfirmation={handleMultipleSelectionConfirmation}
          setOpened={setIsModalOpen}
        />
      )}

      {isMultiSelectActionsOpen && (
        <MultiSelectActions
          status={licenseStatus}
          resetData={setSelectedUsers}
          selectedUsers={selectedUsers}
          setIsModalOpen={setIsModalOpen}
          unassignedLicenses={unassignedLicenses}
        />
      )}

      {isUserDetailsModalOpen && (
        <UserDetailsModal
          opened={isUserDetailsModalOpen}
          setOpened={setIsUserDetailsModalOpen}
          userDetails={userDetails}
        />
      )}
    </>
  );
};

ManageLicensesTable.defaultProps = {
  searchTerm: ''
};

ManageLicensesTable.propTypes = {
  searchTerm: PropTypes.string,
  licenseStatus: PropTypes.string.isRequired,
  pagination: PropTypes.object.isRequired
};

export default ManageLicensesTable;
