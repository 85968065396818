import PropTypes from 'prop-types';
import { Button, Text, Flex } from '@mantine/core';
import { ReactComponent as InfoIcon } from 'assets/icons/info-gray.svg';
import { maybePluralize } from 'utils/utilities';
import styles from './styles.module.scss';

const View = (props) => {
  const { setShowRemoveSourceModal, count, isProcessing, selectAll } = props;

  return (
    <div className={styles.container}>
      <Text mr={20} className={styles.content}>
        {selectAll
          ? 'All sources selected'
          : `${count} ${maybePluralize(count, 'source')} selected`}
      </Text>

      <Flex align='center'>
        {isProcessing && selectAll && (
          <div className={styles.info}>
            <InfoIcon className={styles.infoIcon} />
            &nbsp;Some sources are still in process, you cannot take any actions on them
          </div>
        )}
        <Button
          ml={20}
          mr={20}
          className={styles.buttonRed}
          onClick={() => setShowRemoveSourceModal(true)}
        >
          Remove
        </Button>
      </Flex>
    </div>
  );
};

View.defaultProps = {
  setShowRemoveSourceModal: () => null,
  count: 0,
  isProcessing: false,
  selectAll: false
};

View.propTypes = {
  setShowRemoveSourceModal: PropTypes.func,
  count: PropTypes.number,
  isProcessing: PropTypes.bool,
  selectAll: PropTypes.bool
};

export default View;
