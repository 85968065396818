export const CAN_ANSWER = [
  'when is our next townhall?',
  'what is the policy to take leaves longer than 3 days?',
  'what is the GTM plan for our new platform integrations?',
  'what is the status of Jira ticket to revamp backend code?'
];

export const CANNOT_ANSWER = [
  'what kind of questions can you answer?',
  'do you have access to Google Drive?',
  'can you look for references in Notion?',
  'can you list all the tickets that went live in the last month?'
];
