import { END_POINT } from 'utils/apiEndpoints';
import api from './api';
import { handleApiError } from 'utils/helpers';

export const sitemapApi = api.injectEndpoints({
  endpoints: (build) => ({
    //fetch all providers data
    fetchSitemapURls: build.query({
      providesTags: ['Sitemap'],
      query: (params) => ({
        url: END_POINT.SITEMAP,
        method: 'GET',
        params
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),
    isValidSitemap: build.query({
      query: (params) => ({
        url: END_POINT.VALID_SITEMAP,
        method: 'GET',
        params
      }),
      transformResponse: (response) => {
        return response;
      },
      transformErrorResponse: (error) => {
        return error;
      }
    })
  })
});

export const {
  useFetchSitemapURlsQuery,
  useLazyFetchSitemapURlsQuery,
  useIsValidSitemapQuery,
  useLazyIsValidSitemapQuery
} = sitemapApi;

export default sitemapApi;
