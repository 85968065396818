import { toast } from 'react-toastify';
import { APIDECK_VAULT } from 'utils/apiEndpoints';
import api from './api';

const apiDeckConnectApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAPIDeckVaultURL: build.query({
      query: () => ({
        url: APIDECK_VAULT,
        method: 'GET'
      }),
      transformResponse: (response) => {
        if (response) {
          return response;
        } else {
          toast.error('Failed to fetch api deck connect url.');
        }
      }
    })
  })
});

export const { useGetAPIDeckVaultURLQuery } = apiDeckConnectApi;

export default apiDeckConnectApi;
