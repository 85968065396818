import { useMediaQuery } from '@mantine/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function useShowTutorial(callack) {
  const searchParams = new URLSearchParams(location.search);
  const showTutorial = searchParams.get('showTutorial');
  const isMobileScreen = useMediaQuery('(max-width: 480px)');
  const navigate = useNavigate();

  function showTutorialModal() {
    navigate(`${window.location.pathname}?showTutorialModal=true`);
  }

  useEffect(() => {
    if (isMobileScreen === undefined || isMobileScreen || !showTutorial) {
      //for mobile screen, show no tutorial
      return;
    }
    callack(showTutorialModal);
  }, [showTutorial, isMobileScreen]);
}

export default useShowTutorial;
