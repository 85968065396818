import { useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { maybePluralize } from 'utils/utilities';
import Form from './Form';
import { useUpdateTeamMutation, useGetUsersQuery, useGetTeamQuery } from 'redux/services/settings';

const AddTeammates = (props) => {
  const { open, handleClose, teamId } = props;
  const { data: users = [], isFetching: fetchingUsers } = useGetUsersQuery(teamId);
  const [updateTeam, { isLoading: updatingTeam }] = useUpdateTeamMutation();
  const [formValues, setFormValues] = useState({
    members: []
  });
  const {
    data: teamDetails = {
      members: []
    },
    isFetching: fetchingTeam
  } = useGetTeamQuery(teamId);

  const membersIds = fetchingTeam ? [] : teamDetails.members.map((member) => member.userId);

  const usersOptions =
    fetchingUsers || fetchingTeam
      ? ['Fetching users...']
      : users
          ?.map((user) => ({ value: user.userId, label: user.name }))
          .filter((user) => !membersIds.includes(user.value));

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      teamId,
      body: { membersAdded: formValues.members }
    };

    const response = await updateTeam(formData).unwrap();

    if (!response.error) {
      handleClose();
      const teammateRemoved = usersOptions.find(
        (user) => user.value === formValues.members[0]
      ).label;
      const teammatesAdded = formValues.members.length;
      const otherTeammatesAddedText =
        teammatesAdded > 1
          ? `and ${teammatesAdded - 1} ${maybePluralize(teammatesAdded - 1, 'other')} `
          : '';
      toast.success(`${teammateRemoved} ${otherTeammatesAddedText}added to team successfully`);
    }
  };

  const handleChange = (field, value) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Form
      open={open}
      formValues={formValues}
      users={fetchingUsers ? [] : usersOptions}
      handleChange={handleChange}
      handleConfirm={onSubmit}
      handleClose={handleClose}
      loading={fetchingUsers || updatingTeam}
    />
  );
};

AddTeammates.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired
};

export default AddTeammates;
