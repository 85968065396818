import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Button, Flex } from '@mantine/core';
import { useSelector } from 'react-redux';
import { ReactComponent as CreateFromScratch } from 'assets/widgets/create-wiki-scratch.svg';
import { ReactComponent as SelectExistingWiki } from 'assets/widgets/select-existing-wiki.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon.svg';
import { useGetTeamsQuery, useLazyGetTeamsQuery } from 'redux/services/settings.js';
import WikiTable from './WikiTable';
import SelectWikiModal from './SelectWikiModal';
import styles from './WikiTab.module.scss';
import classnames from 'classnames';

const WikiTab = ({ wikis, updateWidget, isUpdating, onRemoveWiki }) => {
  const { user } = useSelector((state) => state.user);
  const [showSelectWikiModal, setShowSelectWikiModal] = useState(false);
  const { data: { teams = [] } = { teams: [] }, isLoading } = useGetTeamsQuery();
  const [getTeams, { isFetching }] = useLazyGetTeamsQuery();
  const isSlackPlatform = user?.platform === 'slack';

  const noSourcesInSelectedWikis =
    wikis.length > 0 && wikis.every((wiki) => wiki.numberOfSources === 0);

  return (
    <div className={styles.container}>
      {noSourcesInSelectedWikis && (
        <div className={styles.errorMessage}>
          <ErrorIcon />
          <span>
            Your wikis don&apos;t have any sources. To install the widget, add at least one source
            to your wikis.
          </span>
        </div>
      )}
      <div className={styles.title}>Wikis for your chat widget</div>
      <div className={styles.subTitle}>
        These wikis will be used to write answers to questions asked on this chat widget.
      </div>
      {wikis.length === 0 ? (
        <div className={styles.cards}>
          {isSlackPlatform && (
            <Flex justify={'center'} align={'center'} direction={'column'}>
              <div className={styles.card}>
                <CreateFromScratch />
                <div className={styles.header}>Create wiki from scratch</div>
                <div className={styles.description}>
                  If you don&apos;t have a wiki with sources appropriate for this widget, first
                  create a team and then add the required sources to its wiki.
                </div>
                <Button className='primaryBtn' onClick={() => window.open('/teams', '_blank')}>
                  Create wiki
                </Button>
              </div>
              <div className={styles.refreshNote}>
                Can&apos;t find the team you created?&nbsp;
                <span
                  className={classnames({ [styles.disabled]: isFetching })}
                  onClick={() => getTeams()}
                >
                  Refresh <RefreshIcon />
                </span>
              </div>
            </Flex>
          )}
          {!isLoading && teams.length > 0 && (
            <div className={styles.card}>
              <SelectExistingWiki />
              <div className={styles.header}>Select existing wiki</div>
              <div className={styles.description}>
                If you have wiki with the sources required for this widget, select it here.
              </div>
              <Button className='primaryBtn' onClick={() => setShowSelectWikiModal(true)}>
                Select wiki
              </Button>
            </div>
          )}
        </div>
      ) : (
        <WikiTable data={wikis} onAddWiki={setShowSelectWikiModal} onRemoveWiki={onRemoveWiki} />
      )}
      <SelectWikiModal
        onClose={() => setShowSelectWikiModal(false)}
        selectedWikis={wikis.map((wiki) => wiki.id)}
        onConfirm={(newWikis) => {
          setShowSelectWikiModal(false);
          updateWidget(newWikis);
        }}
        opened={showSelectWikiModal}
        loading={isUpdating}
      />
    </div>
  );
};

WikiTab.propTypes = {
  wikis: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired
    })
  ).isRequired,
  updateWidget: func.isRequired,
  isUpdating: bool.isRequired,
  onRemoveWiki: func.isRequired
};

export default WikiTab;
