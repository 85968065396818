import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  LoadingOverlay,
  Table,
  Text,
  Title,
  Tooltip
} from '@mantine/core';
import { CodeHighlight } from '@mantine/code-highlight';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import ApiKeyModal from '../../ApiKeyModal';
import { embedCode, pollingCurlCommand, feedbackCurlCommand } from './data';
import settingsApi, {
  useCreateCustomApiMutation,
  useUpdateCustomApiKeysMutation
} from 'redux/services/settings';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { ReactComponent as InfoIcon } from 'assets/icons/info-gray.svg';
import { NAVBAR_ROUTES } from 'utils/constants';
import styles from './styles.module.scss';

const CustomApiKeySection = (props) => {
  const { customApiExists, customApiForm, onManageCustomApi } = props;

  const [createCustomApi, { isLoading: creationInProgress }] = useCreateCustomApiMutation();
  const [updateCustomApiKeys, { isLoading: updatingKeys }] = useUpdateCustomApiKeysMutation();
  const [opened, setOpened] = useState(false);
  const [isApiKeyGenerated, setIsApiKeyGenerated] = useState(false);
  const [generatedKey, setGeneratedKey] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [customApiDetails, setCustomApiDetails] = useState(customApiForm.values);
  const [customApiId, setCustomApiId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [flagForUpdateKeys, setFlagForUpdateKeys] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState(null);
  const [closingModal, setClosingModal] = useState(false);
  const [creatingKey, setCreatingKey] = useState(false);
  let copyButtonClicked = useRef(false);
  const navigate = useNavigate();
  const showDeleteKeyCta = customApiExists ? customApiDetails?.accessTokens.length > 1 : false;
  const dispatch = useDispatch();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!copyButtonClicked.current && opened) {
        event.returnValue =
          "Are you sure you want to leave? Your API Key may not be accessible if it hasn't been copied yet.";
      }
    };
    if (!copyButtonClicked.current && opened) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [opened]);

  useEffect(() => {
    if (closingModal) {
      updateCustomApiDetails();
      setFlagForUpdateKeys(false);
      setClosingModal(false);
    }
  }, [closingModal]);

  async function updateCustomApiDetails() {
    customApiForm.reset();
    const data = await onManageCustomApi(customApiId);
    setCustomApiDetails(data);
    setLoading(false);
    if (!flagForUpdateKeys) {
      dispatch(settingsApi.util.invalidateTags(['CustomApis']));
    }
  }

  const handleCloseModal = async () => {
    if (copyButtonClicked.current) {
      setOpened(false);
      setLoading(true);
      setClosingModal(true);
      setIsApiKeyGenerated(true);
      navigate(NAVBAR_ROUTES.SETTINGS_TABS.CUSTOM_APIS + '#' + customApiId);
      copyButtonClicked.current = false;
    } else {
      setShowAlert(true);
      setOpened(true);
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(generatedKey);
    toast.success('API key copied successfully');
    copyButtonClicked.current = true;
    setShowAlert(false);
  };

  async function handleCreateCustomApi() {
    const payload = {
      name: customApiForm.values.name,
      search: customApiForm.values.search,
      entryPoint: customApiForm.values.entryPoint,
      teams: customApiForm.values.wikis.map((wiki) => wiki.value),
      persona: customApiForm.values.persona,
      useAgent: customApiForm.values.useAgent,
      searchFlow: customApiForm.values.searchFlow
    };
    const result = await createCustomApi(payload);
    if (result?.data?.ok) {
      setGeneratedKey(result.data?.accessToken);
      setCustomApiId(result.data?.id);
      setOpened(true);
    }
  }

  async function handleCreateCustomApiKey() {
    const formData = {
      accessId: customApiDetails.id,
      operation: 'create'
    };
    setCreatingKey(true);
    const result = await updateCustomApiKeys(formData);
    if (result?.data?.ok) {
      setGeneratedKey(result.data?.data?.accessToken);
      setCustomApiId(formData.accessId);
      setCreatingKey(false);
      setOpened(true);
      setFlagForUpdateKeys(true);
    }
  }

  async function handleDeleteCustomApiKey() {
    const formData = {
      accessId: customApiDetails.id,
      operation: 'delete',
      id: keyToDelete
    };

    const result = await updateCustomApiKeys(formData);
    if (result?.data?.ok) {
      setShowDeleteWarningModal(false);
      setCustomApiId(formData.accessId);
      setFlagForUpdateKeys(true);
      setClosingModal(true);
    }
  }

  if (loading) {
    return <LoadingOverlay visible={loading} />;
  }

  return (
    <>
      <Flex direction='column' justify='start' align='start' className={styles.sectionWrapper}>
        <Title className={styles.title}>API key</Title>
        <Text className={styles.description}>
          To trigger Albus in your internal tool or workflow, generate an API key.
        </Text>
        {customApiExists || (isApiKeyGenerated && !loading) ? (
          <>
            <Flex align='center' gap={10} mt={10}>
              <Text size='sm' fw='600'>
                API keys
              </Text>
              <Tooltip
                label={
                  <Text size='sm'>
                    These are your active API keys. To maintain optimal data security, delete any
                    keys that are no longer in use.
                  </Text>
                }
                w='400'
                position='right'
              >
                <span>
                  <InfoIcon />
                </span>
              </Tooltip>
            </Flex>
            <div className={styles.apiKeysTable}>
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>API key</Table.Th>
                    <Table.Th>Created by</Table.Th>
                    <Table.Th>Created at</Table.Th>
                    {showDeleteKeyCta && <Table.Th></Table.Th>}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {customApiDetails.accessTokens.map((token) => (
                    <Table.Tr key={token.id}>
                      <Table.Td>{token.maskedKey}</Table.Td>
                      <Table.Td>{token.createdBy}</Table.Td>
                      <Table.Td>{dayjs(token.createdAt).format('DD MMM YYYY')}</Table.Td>
                      {showDeleteKeyCta && (
                        <Table.Td>
                          <ActionIcon
                            variant='transparent'
                            className={styles.delete}
                            onClick={() => {
                              setShowDeleteWarningModal(true);
                              setKeyToDelete(token.id);
                            }}
                          >
                            <SVGIcon name={ICONS_LIST.TRASH_ICON} height={16} width={16} />
                          </ActionIcon>
                        </Table.Td>
                      )}
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </div>
            <Button
              mt={20}
              mb={20}
              className={styles.generateBtn}
              onClick={() => handleCreateCustomApiKey()}
              loading={creatingKey}
            >
              Generate API key
            </Button>
            <Text size='md' w='80%' mt={20}>
              <b>Using your API key</b>
              <br />
            </Text>
            <Text size='sm' w='80%' mt={10}>
              Below are your cURLs. Send the API Key and cURLs to your developer and they’ll set up
              your Custom API. You can use this in Google Sheets, Zapier, Make, and similar tools.
            </Text>
            <Text size='md' mt={20} fw='700'>
              1. Interact with Albus
            </Text>
            <Box ml={10}>
              <Text size='sm' w='70%' mt={20}>
                <b>a. Invoke your Custom API:</b> Enter your desired prompt and invoke your Custom
                API using the following cURL. Upon invoking, you&apos;ll receive a polling endpoint
                to retrieve your Albus completions.
              </Text>
              <CodeHighlight
                code={embedCode()}
                copyLabel='Copy cURL'
                language='markup'
                classNames={{
                  root: styles.codeContainer,
                  copy: styles.codeCopyBtn
                }}
              />
              <Text size='sm' w='70%' mt={20}>
                <b>b. Retrieve your answers:</b> Use the provided polling endpoint with the
                following cURL command to receive responses based on your prompt.
              </Text>
              <CodeHighlight
                code={pollingCurlCommand()}
                copyLabel='Copy cURL'
                language='markup'
                classNames={{
                  root: styles.codeContainer,
                  copy: styles.codeCopyBtn
                }}
              />
            </Box>
            <Text size='md' mt={20} fw='700'>
              2. Send Your Feedback
            </Text>
            <Text size='sm' w='80%' mt={10}>
              After receiving the answer, you can provide feedback to Albus to improve the quality
              of responses. Follow these steps to integrate upvote and downvote functionality into
              your interface:
            </Text>
            <Box ml={10}>
              <Text size='sm' w='70%' mt={20}>
                <b>a. Add and Capture Feedback:</b> Design and include two feedback elements
                labeled, for example, &quot;Like&quot; and &quot;Dislike&quot; in your user
                interface. Track which element the user clicks to determine their feedback choice.
              </Text>
              <Text size='sm' w='70%' mt={20}>
                <b>b. Submit Upvote or Downvote Feedback to Albus:</b> Use the following cURL
                command to send your feedback for the answer. In the body of the cURL, the vote
                value can be <b>1</b> for an upvote or <b>-1</b> for a downvote. Use the&nbsp;
                <b>votingEndpoint</b> from the initial cURL command in this step.
              </Text>
              <CodeHighlight
                code={feedbackCurlCommand()}
                copyLabel='Copy cURL'
                language='markup'
                classNames={{
                  root: styles.codeContainer,
                  copy: styles.codeCopyBtn
                }}
              />
              <Text size='sm' w='70%' mt={20}>
                <b>c. Handle Feedback State:</b> When a user clicks an element and receives a
                successful response <b>&#123;ok: true&#125;</b>, highlight the selected element.
                Reset the other element to its initial state to prevent multiple selections and
                maintain a clean interface.
              </Text>
            </Box>
          </>
        ) : (
          <Button
            className='primaryBtn'
            onClick={customApiForm.onSubmit(() => {
              handleCreateCustomApi();
            })}
            loading={creationInProgress}
          >
            Generate API Key
          </Button>
        )}
      </Flex>
      <DeleteWarningModal
        open={showDeleteWarningModal}
        handleCancel={() => setShowDeleteWarningModal(false)}
        onClose={() => setShowDeleteWarningModal(false)}
        header='Delete API key'
        title='Are you sure you want to delete this API key?'
        body='Note: If you delete an API key, Albus will respond with a 401 error to requests made using this key.'
        handleDelete={() => handleDeleteCustomApiKey()}
        removeText='Delete'
        loading={updatingKeys}
      />
      <ApiKeyModal
        opened={opened}
        handleCloseModal={handleCloseModal}
        generatedKey={generatedKey}
        handleCopyClick={handleCopyClick}
        showAlert={showAlert}
      />
    </>
  );
};

CustomApiKeySection.defaultProps = {
  customApiExists: false,
  customApiForm: ''
};

CustomApiKeySection.propTypes = {
  customApiExists: PropTypes.bool.isRequired,
  customApiForm: PropTypes.shape({
    values: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    reset: PropTypes.func
  }),
  onManageCustomApi: PropTypes.func.isRequired
};

export default CustomApiKeySection;
