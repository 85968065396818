import { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/Layout';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import OnlineData from './Tabs/OnlineData';
import CompanyWiki from './Tabs/CompanyWiki';
import Header from './Header';
import { useGetSettingsQuery } from 'redux/services/settings';
import { PROMPT_IDEA_TYPES } from 'utils/constants';
import { promptIdeas } from 'data/onlinePromptIdeas.json';
import styles from './styles.module.scss';

const PromptIdeas = ({ showPromptIdeas, setShowPromptIdeas }) => {
  const [tab, setTab] = useState(showPromptIdeas);
  const { planInfo } = useSelector((state) => state.plan);
  const { data: settings } = useGetSettingsQuery();
  const { OPEN_AI, COMPANY_WIKI } = PROMPT_IDEA_TYPES;
  const [questionCount, setQuestionCount] = useState({
    wiki: 0,
    ai: promptIdeas.length
  });

  function backButtonHandler() {
    setShowPromptIdeas(false);
    window.history.replaceState(null, '', `/ask-albus`);
  }

  return (
    <Layout pageTitle={<Header backBtnClickHandeler={backButtonHandler} />}>
      <div className={styles.promptIdeasWrapper}>
        {/* Prompt Ideas Type Selection Tab */}
        <div className={styles.ideaSelector}>
          {planInfo.hasSubscription && settings?.training && (
            <div
              className={classNames(styles.ideaTab, {
                [styles.selectedTab]: tab === COMPANY_WIKI
              })}
              onClick={() => setTab(COMPANY_WIKI)}
            >
              Suggested questions on wiki data ({questionCount.wiki})
            </div>
          )}
          {planInfo.hasSubscription && settings?.ai && (
            <div
              className={classNames(styles.ideaTab, {
                [styles.selectedTab]: tab === OPEN_AI
              })}
              onClick={() => setTab(OPEN_AI)}
            >
              Suggested questions on online data ({questionCount.ai})
            </div>
          )}
        </div>
        {tab === COMPANY_WIKI ? (
          <CompanyWiki
            tab={tab}
            setShowPromptIdeas={setShowPromptIdeas}
            isFreePlan={!planInfo.hasSubscription}
            setQuestionCount={setQuestionCount}
          />
        ) : (
          <OnlineData
            tab={tab}
            setShowPromptIdeas={setShowPromptIdeas}
            isFreePlan={!planInfo.hasSubscription}
          />
        )}
      </div>
    </Layout>
  );
};

PromptIdeas.propTypes = {
  showPromptIdeas: PropTypes.string.isRequired,
  setShowPromptIdeas: PropTypes.func.isRequired
};

export default PromptIdeas;
