import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Flex, TextInput, Loader, Tooltip, Checkbox } from '@mantine/core';
import integrationsApi, { useDisconnectSourcesMutation } from 'redux/services/integrations';
import SourceGrid from '../../Components/SourceGrid';
import Table from '../../../../../components/Table';
import ViewChanger from '../../Components/ViewChanger';
import EmptyState from '../../Components/EmptyState/index.jsx';
import MultiSelectActions from '../../Components/MultiSelectActions';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { trainedAtTableHeader } from '../../Notion/DataView';
import { formatDate } from 'utils/formatters';
import { retrainFailureTooltip, truncateName } from 'pages/Integrations/utils';
import { returnOtherIntegrationsIcon } from 'utils/utilities';
import { SOURCES_STATUS } from '../../Web/constants';
import {
  ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS,
  TRUTO_CONNECTIONS_WITH_PROJECT_NAME,
  VIEW_TYPE,
  APIDECK_INTEGRATIONS
} from 'utils/constants';
import { INTEGRATIONS_TABLE_HEADERS } from '../../../../../utils/constants';
import styles from './styles.module.scss';

const SourcesDataView = (props) => {
  const {
    viewType,
    setViewType,
    sources,
    user,
    isLoading,
    providerName,
    pagination,
    pageCount,
    searchValue,
    setSearchValue,
    debouncedValue,
    totalCount,
    metaPayload
  } = props;

  const dispatch = useDispatch();
  const [source, setSource] = useState({
    selectAll: false,
    showRemoveModal: false,
    selected: []
  });
  const [totalItems, setTotalItems] = useState([]);
  const selectedSources = totalItems.filter((item) => source.selected.includes(item?.id));
  const [disconnectSources, { isLoading: isDisconnectSourcesLoading }] =
    useDisconnectSourcesMutation();
  const allInProcessing = sources.every((source) => source.status === SOURCES_STATUS.PROCESSING);
  const isProcessing = sources.some((source) => source.status === SOURCES_STATUS.PROCESSING);
  const tooltipLabels = [
    'Unchecking individual sources when you ‘select all’ is not supported currently. Coming soon.',
    'You cannot select the sources which are under the process. Please wait until they turn active.'
  ];

  const { teamId } = useParams();

  const formattedRows = sources.map((list) => ({
    id: list.id,
    title: truncateName(list.name ? list.name : list.url, 40),
    name: TRUTO_CONNECTIONS_WITH_PROJECT_NAME.includes(providerName)
      ? list.name
      : `Absorbing knowledge from ${list?.uploadedBy || 'User'}'s account`,
    url: list.url,
    date: formatDate(list.lastTrainingStartedAt),
    uploadBy: <div className={styles.uploaderName}>{list?.uploadedBy || 'User'}</div>,
    status: list.status,
    admin: list.admin,
    trainingFrequency: list.trainingFrequency,
    lastTrainingStartedAt: list.lastTrainingStartedAt || list.createdAt,
    lastTrainingAttemptedAt: list.lastTrainingAttemptedAt || list.createdAt,
    error: list.error,
    description: list.description,
    retrainError: retrainFailureTooltip(
      list.status,
      list.lastTrainType,
      list.lastTrainingAttemptedAt || list.lastTrainingStartedAt
    ),
    handleOpenSource: () => list.url && window.open(list.url, '_blank'),
    tooltip: list.name.length > 40 && list.name
  }));

  const tableHeaders = [...INTEGRATIONS_TABLE_HEADERS];
  tableHeaders[1] = trainedAtTableHeader;

  async function handleDeleteSources() {
    const payload = {
      provider: providerName,
      teamId,
      ...(source.selected.length ? { documentMongoIds: source.selected } : {})
    };

    const response = await disconnectSources(payload);
    if (response?.data?.ok) {
      toast.success(response.data.message);
      setTotalItems((prevItems) => prevItems.filter((item) => !source.selected.includes(item.id)));
      dispatch(integrationsApi.util.invalidateTags(['SourcesByProvider']));
      setSource((prevState) => ({
        ...prevState,
        showRemoveModal: false,
        selected: [],
        selectAll: false
      }));
    }
  }

  function handleSelectAll() {
    setSource((prevState) => ({
      ...prevState,
      selectAll: !prevState.selectAll,
      selected: []
    }));
  }

  function handleCheckboxCta(source) {
    setSource((prevState) => {
      const uncheckSelectedSource = prevState.selected.includes(source.id);

      const updatedSelectedSources = uncheckSelectedSource
        ? prevState.selected.filter((sourceId) => sourceId !== source.id)
        : [...prevState.selected, source.id];

      return {
        ...prevState,
        selected: updatedSelectedSources
      };
    });
  }

  return (
    <>
      <Flex mt={27} justify='space-between' align='flex-end' className={styles.searchContainer}>
        <TextInput
          className={styles.searchInput}
          placeholder='Search for sources'
          leftSection={<SearchIcon />}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <Flex>
          <Tooltip
            disabled={!allInProcessing}
            w={325}
            label='To select sources and take action, wait for them to be processed.'
          >
            <Checkbox
              label='Select all'
              onChange={handleSelectAll}
              color='primaryGreen.3'
              radius={4}
              disabled={allInProcessing}
              checked={source.selectAll}
              classNames={{
                root: classNames(styles.checkbox, {
                  [styles.hideSelectAll]: !sources.length
                }),
                input: 'cursor-pointer',
                label: classNames(styles.selectAll, { [styles.disabled]: allInProcessing })
              }}
            />
          </Tooltip>
          <ViewChanger
            viewType={viewType}
            setViewType={setViewType}
            user={user}
            hideAddSourceButton={!formattedRows.length}
            hideReAuthButton={!APIDECK_INTEGRATIONS.includes(providerName)}
            provider={providerName}
            pagination={pagination}
          />
        </Flex>
      </Flex>
      {formattedRows.length ? (
        viewType === VIEW_TYPE.GRID ? (
          <SourceGrid
            items={formattedRows}
            totalItems={totalItems}
            setTotalItems={setTotalItems}
            user={user}
            hideCreatedAt={true}
            sourceIcon={returnOtherIntegrationsIcon(providerName)}
            pagination={pagination}
            pageCount={pageCount}
            isLoading={isLoading}
            metaPayload={metaPayload}
            selectedSources={source.selected}
            handleCheckboxCta={handleCheckboxCta}
            showCheckbox
            isAllSourcesSelected={source.selectAll}
            checkboxTooltipLabels={tooltipLabels}
          />
        ) : (
          <Table
            headers={tableHeaders}
            rows={formattedRows}
            hideCreatedAt={true}
            pagination={pagination}
            pageCountFromProp={pageCount}
            pageLimitFromProp={ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS}
            isLoading={isLoading}
            sourceIcon={returnOtherIntegrationsIcon(providerName)}
            totalCount={totalCount}
            metaPayload={metaPayload}
            disableRowClick
            selectedSources={source.selected}
            handleCheckboxCta={handleCheckboxCta}
            showCheckbox
            isAllSourcesSelected={source.selectAll}
            checkboxTooltipLabels={tooltipLabels}
          />
        )
      ) : isLoading ? (
        <Flex align='center' justify='center' h='55vh'>
          <Loader />
        </Flex>
      ) : debouncedValue ? (
        <EmptyState hideButton text={`No sources matching "${searchValue}" found.`} />
      ) : (
        <EmptyState />
      )}
      {(source.selectAll || source.selected.length > 0) && (
        <MultiSelectActions
          selectedSources={selectedSources}
          isDisconnectSourcesLoading={isDisconnectSourcesLoading}
          selectAll={source.selectAll}
          isProcessing={isProcessing}
          handleDeleteSources={handleDeleteSources}
          showRemoveSourceModal={source.showRemoveModal}
          setShowRemoveSourceModal={(value) =>
            setSource((prevState) => ({ ...prevState, showRemoveModal: value }))
          }
        />
      )}
    </>
  );
};

SourcesDataView.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  sources: PropTypes.array,
  user: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  providerName: PropTypes.string,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  }).isRequired,
  pageCount: PropTypes.PropTypes.number.isRequired,
  searchValue: PropTypes.PropTypes.string.isRequired,
  setSearchValue: PropTypes.PropTypes.func.isRequired,
  debouncedValue: PropTypes.PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }).isRequired
};

export default SourcesDataView;
