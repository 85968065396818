import { stepConstructor, tutorialIntiator } from 'components/TutorialWrapper';
import OneIcon from 'assets/tutorial-icons/wiki/one.svg';
import ThreeIcon from 'assets/tutorial-icons/wiki/three.svg';
import TwoOneIcon from 'assets/tutorial-icons/wiki/two-one.svg';
import TwoTwoIcon from 'assets/tutorial-icons/wiki/two-two.svg';
import FiveIcon from 'assets/tutorial-icons/wiki/five.svg';
import styles from './styles.module.scss';

export function startWikiTutorial(showTutorialModal) {
  const steps = [
    {
      popover: {
        description: stepConstructor(
          'What is a wiki?',
          `Albus absorbs information about your company and remembers it in a wiki. It&apos;s his wikipedia of your data.<br/>He uses this wiki to answer questions you ask.`,
          OneIcon
        )
      }
    },
    {
      element: '#add-connection',
      popover: {
        description: stepConstructor(
          'Connect Albus to absorb information from your apps',
          `
            You can absorb data from Google Drive, Slack, Jira and 20+ other workplace apps.<br/>
            <div class=${styles.two} >
          <div class=${styles.wrapper} >
          <img src='${TwoOneIcon}' class=${styles.imgOne} alt='two one icon'/>
          <img src='${TwoTwoIcon}' class=${styles.imgTwo} alt='two two icon'/>
          </div>
          <div class=${styles.text}>Albus uses <a class=${styles.link} href='https://truto.one' target='_blank'>Truto</a> to connect with your workplace apps. Your data is encrypted in transit and at rest, and is never shared with Azure OpenAI for training their models. We are SOC-2 Type II and ISO 27001 certified, and GDPR compliant. <a href='https://support.springworks.in/portal/en/kb/albus/albus-for-slack/data-privacy-and-security' target='_blank'>Learn more.</a></div>
          <div/>
          `
        )
      }
    },
    {
      popover: {
        description: stepConstructor(
          'How does Albus remember information?',
          `Let’s say you want Albus to remember your company policies documented on Google Docs.<br/>To get started, connect Albus to your Google Drive account. Then, add all the relevant Google Docs as sources on the Wiki page. Learn how to do that next.`,
          ThreeIcon
        )
      }
    },
    {
      element: '#connectors-list',
      popover: {
        description: stepConstructor(
          'Manage all your wiki data here',
          'View and manage data sources across all connections. To view sources from a connection and manage them, open the respective card.'
        )
      }
    },
    {
      popover: {
        description: stepConstructor(
          'How does Albus stay up to date?',
          `Information stored in sources change frequently. You can set Albus to revisit your information and look for updates by retraining him on sources.<br/>You can retrain Albus on sources manually or create an automatic schedule with the auto retrain.<br/><br/>💡 Enable daily auto-retrain for sources that see frequent changes.<br/>`,
          FiveIcon
        )
      }
    },
    {
      element: '#team-select',
      popover: {
        description: stepConstructor(
          'Maintain privacy of information among teams',
          `<div class=${styles.text}>By default, Albus creates a company wiki for all employees of your company. This is where you add sources that are relevant for everyone.<br/>But what if you have different policies for your engineering and sales teams? What if you want to restrict some information to those in leadership roles?<br/>This is when you should set up teams to maintain privacy of information. <a href='/teams?showTut=true' >Explore now<a>.</div>`
        )
      }
    }
  ];

  tutorialIntiator(steps, showTutorialModal);
}
