import PropTypes from 'prop-types';
import { Button, Flex, TextInput, Tooltip } from '@mantine/core';
import Table from '../../../../../components/Table';
import ViewChanger from '../../Components/ViewChanger';
import SourceGrid from '../../Components/SourceGrid';
import LoadingState from '../../Components/LoadingState';
import EmptyState from '../../Components/EmptyState';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import NotionIcon from 'assets/icons/notion.svg';
import { formatDate } from 'utils/formatters';
import { VIEW_TYPE } from 'utils/constants';
import { retrainFailureTooltip, truncateName } from 'pages/Integrations/utils';
import styles from '../styles.module.scss';

export const trainedAtTableHeader = (
  <Flex>
    Last trained
    <Tooltip
      w={300}
      label="When you add a source, Albus 'trains' on it. This is how he answers your questions contextually. If you update your source ensure to retrain Albus. This ensures Albus has the latest intel."
    >
      <div className={styles.infoIcon}>
        <SVGIcon name={ICONS_LIST.TOOL_TIP} className={styles.img} />
      </div>
    </Tooltip>
  </Flex>
);

const DataView = (props) => {
  const {
    viewType,
    setViewType,
    sources,
    user,
    handleRefresh,
    handleManageSource,
    loading,
    value,
    setChange,
    totalCount
  } = props;

  const formattedRows = sources.map((list) => ({
    id: list.id,
    name: list.name,
    title: truncateName(list.name, 40),
    url: list.url,
    date: formatDate(list.lastTrainingStartedAt),
    status: list.status,
    admin: list.admin,
    lastTrainingStartedAt: list.lastTrainingStartedAt || list.createdAt,
    lastTrainingAttemptedAt: list.lastTrainingAttemptedAt || list.createdAt,
    trainingFrequency: list.trainingFrequency,
    domainName: list.domainName,
    error: list.error,
    description: list.description,
    retrainError: retrainFailureTooltip(
      list.status,
      list.lastTrainType,
      list.lastTrainingAttemptedAt || list.lastTrainingStartedAt
    ),
    handleOpenSource: () => list.url && window.open(list.url, '_blank'),
    tooltip: list.name.length > 40 && list.name
  }));

  return (
    <>
      <Flex mt={27}>
        <TextInput
          className={styles.input}
          placeholder='Search sources by name or workspace.'
          leftSection={<SearchIcon />}
          value={value}
          onChange={(e) => {
            setChange(e.target.value);
          }}
        />
        <ViewChanger
          viewType={viewType}
          setViewType={setViewType}
          user={user}
          hideAddSourceButton={true}
        />

        <Button
          variant='outline'
          className='primaryBtnOutline'
          ml={16}
          radius='sm'
          onClick={handleRefresh}
        >
          Refresh
        </Button>
        <Button className='primaryBtn' ml={16} radius='sm' onClick={handleManageSource}>
          <span style={{ marginRight: 6 }}>Manage source</span>
          <SVGIcon name={ICONS_LIST.REDIRECT} height={10} width={10} />
        </Button>
      </Flex>

      {loading ? (
        <LoadingState />
      ) : sources?.length === 0 ? (
        <EmptyState hideButton={true} />
      ) : viewType === VIEW_TYPE.GRID ? (
        <SourceGrid
          items={formattedRows}
          user={user}
          sourceIcon={NotionIcon}
          hideCreatedAt={true}
          showAutoTrain={true}
          hideUploadedBy={true}
          hideDisconnect={true}
          showManageResources={true}
        />
      ) : (
        <Table
          headers={['Name', 'Workspace', trainedAtTableHeader, 'Status', '']}
          rows={formattedRows}
          showAutoTrain={true}
          hideDisconnect={true}
          showManageResources={true}
          sourceIcon={NotionIcon}
          totalCount={totalCount}
          disableRowClick
        />
      )}
    </>
  );
};

DataView.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func.isRequired,
  sources: PropTypes.array,
  user: PropTypes.object.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleManageSource: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  value: PropTypes.string,
  setChange: PropTypes.func,
  totalCount: PropTypes.number.isRequired
};
export default DataView;
