import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Flex, Text } from '@mantine/core';
import { ReactComponent as NotificationIcon } from 'assets/icons/custom-api-settings.svg';
import styles from './styles.module.scss';

const CustomizedApiInfo = (props) => {
  const { customApi } = props;
  const resolvedQueries = customApi.interactions - customApi.downvotes;

  return (
    <Flex gap={10} mt={10}>
      <NotificationIcon />
      <Flex direction='column'>
        <Text fz={13}>
          <span className={styles.heading}>Created on: </span>
          {dayjs(customApi.createdAt).format('MMM D, YYYY h:mm A')}
        </Text>
        <Text fz={13}>
          <span className={styles.heading}>Created by: </span>
          {customApi.createdBy}
        </Text>
        <Text fz={13}>
          <span className={styles.heading}>Questions asked: </span>
          {customApi.interactions}&nbsp;(Resolved: {resolvedQueries} | Open: {customApi.downvotes})
        </Text>
      </Flex>
    </Flex>
  );
};

CustomizedApiInfo.propTypes = {
  customApi: PropTypes.object.isRequired
};

export default CustomizedApiInfo;
