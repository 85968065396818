import React from 'react';
import classNames from 'classnames';
import { bool, node, object, oneOfType, string } from 'prop-types';
import { Anchor, Flex, Image, Popover, Text } from '@mantine/core';
import ProfilePicture from 'components/ProfilePicture';
import AlbusLogo from 'assets/login-ai-avatar.svg';
import AlbusLogoMobile from 'assets/albus-logo-mobile.svg';
import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from '../../../../styles.module.scss';

const AnswerPreview = ({ answer, screen767, data, hideSources, adminAnswer }) => {
  const image = data?.escalation?.resolvedBy?.[0].photo;

  const sourceItems = () => {
    const sourceItemMaker = (source, i) => {
      const url = source.url || source.publicUrl;
      return url ? (
        <Flex key={i}>
          <Anchor ml={4} href={url} target='_blank' underline='always'>
            {source.name} <ExportIcon />
          </Anchor>
          &nbsp;
          {!screen767 && i !== data.sourcesUsed.length - 1 && ','}
        </Flex>
      ) : (
        <Text ml={4} className={styles.sourceName}>
          {source.name}
        </Text>
      );
    };

    if (data.sourcesUsed.length > 2) {
      const firstSource = sourceItemMaker(data.sourcesUsed[0], 0);
      const secondSource = sourceItemMaker(data.sourcesUsed[1], 1);
      const slicedSources = data.sourcesUsed.slice(2);
      const moreSources = (
        <Popover>
          <Popover.Target>
            <Flex className={styles.moreSources} align='center' gap={4}>
              &nbsp; + {data.sourcesUsed.length - 2} more{' '}
              <SVGIcon name={ICONS_LIST.DOWN_ARROW} height={11} width={8} />
            </Flex>
          </Popover.Target>
          <Popover.Dropdown>
            {slicedSources.map((source) => sourceItemMaker(source, data.sourcesUsed.length - 1))}
          </Popover.Dropdown>
        </Popover>
      );
      return [
        <Text key={'source'} className={classNames('nowrap', styles.sourceTitle)}>
          Sources:
        </Text>,
        firstSource,
        secondSource,
        moreSources
      ];
    }

    return [
      <Text key={'source'} className={classNames('nowrap', styles.sourceTitle)}>
        Source:
      </Text>,
      ...data.sourcesUsed.map((source, i) => sourceItemMaker(source, i))
    ];
  };

  return (
    <>
      <Flex justify='space-between' align='center' gap={12} w='100%'>
        <Flex align='center' gap={12}>
          {adminAnswer && image ? (
            <ProfilePicture photoUrl={image} name={data?.escalation?.resolvedBy?.[0].name} />
          ) : (
            <Image h={36} w={36} src={screen767 ? AlbusLogoMobile : AlbusLogo} />
          )}
          <Text className='nowrap'>
            <b>{adminAnswer ? data?.escalation?.resolvedBy?.[0].name : 'Albus'}</b>
          </Text>
        </Flex>
        {data.sourcesUsed.length && !hideSources ? (
          <Flex
            className={styles.userName}
            direction={screen767 ? 'column' : 'row'}
            align={screen767 ? 'flex-end' : 'center'}
            wrap='wrap'
            justify='flex-end'
          >
            {sourceItems()}
          </Flex>
        ) : null}
      </Flex>
      <Text
        className={styles.answer}
        mt={10}
        ml={screen767 ? 20 : 46}
        pb={screen767 ? 0 : 16}
        style={{ fontSize: screen767 ? 'sm' : 'md' }}
      >
        {answer}
      </Text>
    </>
  );
};

AnswerPreview.defaultProps = {
  data: {},
  hideSources: false,
  adminAnswer: false
};

AnswerPreview.propTypes = {
  answer: oneOfType([string, node]).isRequired,
  screen767: bool,
  data: object,
  hideSources: bool,
  adminAnswer: bool
};

export default AnswerPreview;
