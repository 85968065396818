import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mantine/hooks';
import { toast } from 'react-toastify';
import {
  Table,
  Checkbox,
  UnstyledButton,
  Button,
  Menu,
  Anchor,
  Tooltip,
  Flex
} from '@mantine/core';
import ProfilePicture from 'components/ProfilePicture';
import { useUpdateInvitationMutation } from 'redux/services/invite';
import { ReactComponent as ActionIcon } from 'assets/icons/3-v-dots.svg';
import { dateFormat } from 'utils/date-fns';
import styles from './styles.module.scss';

const View = (props) => {
  const {
    setIsDenyConfirmModalOpen,
    setIsApproveModalOpen,
    setIsAssignConfirmModalOpen,
    setIsModalOpen,
    unassignedLicenses,
    setIsRevokeInvitationConfirmModalOpen,
    setIsUnassignConfirmModalOpen,
    selectedUsers,
    setSelectedUsers,
    setUserDetails,
    setIsUserDetailsModalOpen,
    searchQuery
  } = props;

  const { users = [] } = useSelector((state) => state.license);

  let usersToDisplay = users;
  const { planInfo } = useSelector((state) => state.plan);

  if (searchQuery) {
    usersToDisplay = users.filter((user) =>
      user?.email?.toLowerCase().includes(searchQuery?.toLowerCase())
    );
  }
  const screen767 = useMediaQuery('(max-width: 767px)');
  const [updateInvitation] = useUpdateInvitationMutation();

  // To Select or Deselect User
  const handleOnSelectUser = (e) => {
    if (e.target.checked) {
      setSelectedUsers((userList) => [...userList, e.target.value]);
    } else {
      setSelectedUsers(selectedUsers.filter((el) => el !== e.target.value));
    }
  };

  const handleUpdateInvitation = (userId, checked) => {
    const payload = {
      autoAssignLicense: checked
    };
    try {
      updateInvitation({ id: userId, data: payload });
    } catch (error) {
      toast.error('Failed to send request');
    }
  };

  return usersToDisplay.map((user) => (
    <Table.Tr key={user.userId || user._id}>
      <Table.Td>
        <Checkbox
          onChange={handleOnSelectUser}
          checked={
            selectedUsers &&
            (selectedUsers.includes(user._id) || selectedUsers.includes(user.userId))
          }
          value={user.status === 'invited' ? user._id : user.userId}
          color='green'
          radius={4}
        />
      </Table.Td>

      {user.status !== 'invited' && (
        <Table.Td>
          <Flex align='center' gap={10}>
            <ProfilePicture photoUrl={user?.photo} name={user?.name} />
            {screen767 ? (
              <Anchor
                underline={false}
                onClick={() => {
                  setIsUserDetailsModalOpen(true);
                  setUserDetails(user);
                }}
              >
                {user.name}
              </Anchor>
            ) : (
              user.name
            )}
          </Flex>
        </Table.Td>
      )}
      <Table.Td>{user.email}</Table.Td>
      {user.requestedAt && user.status === 'requested' && (
        <Table.Td>{dateFormat(user.requestedAt)}</Table.Td>
      )}
      {user.status === 'invited' && <Table.Td>{dateFormat(user.invitedAt)}</Table.Td>}
      {user.status === 'invited' ? (
        <Table.Td>
          <Tooltip
            arrowPosition='side'
            arrowOffset={14}
            w={274}
            disabled={planInfo?.plan?.licensesBought > 0}
            label='Please get more licenses if you want to assign a license to this teammate.'
            className={styles.tooltip}
          >
            <Checkbox
              disabled={false}
              onChange={(e) => handleUpdateInvitation(user._id, e.target.checked)}
              checked={user.autoAssignLicense}
              value={user._id}
              color='green'
              radius={4}
            />
          </Tooltip>
        </Table.Td>
      ) : (
        <Table.Td>
          <Button
            variant='outline'
            className={classNames(styles.statusBtn, styles[user.status])}
            size='compact-xs'
            radius={30}
          >
            {user.status}
          </Button>
        </Table.Td>
      )}
      <Table.Td>
        <Menu position='left'>
          <Menu.Target>
            <UnstyledButton className={styles.button}>
              <ActionIcon />
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            {user.status === 'assigned' && (
              <Menu.Item
                onClick={() => {
                  setIsUnassignConfirmModalOpen(true);
                  setUserDetails(user);
                }}
              >
                Unassign License
              </Menu.Item>
            )}

            {user.status === 'invited' && (
              <Menu.Item
                onClick={() => {
                  setIsRevokeInvitationConfirmModalOpen(true);
                  setUserDetails(user);
                }}
              >
                Revoke Invitation
              </Menu.Item>
            )}

            {user.status === 'unassigned' && (
              <Menu.Item
                onClick={() => {
                  if (unassignedLicenses - selectedUsers.length <= 0) {
                    setSelectedUsers([user.userId]);
                    setIsModalOpen(true);
                  } else {
                    setIsAssignConfirmModalOpen(true);
                    setUserDetails(user);
                  }
                }}
              >
                Assign License
              </Menu.Item>
            )}

            {user.status === 'requested' && (
              <>
                <Menu.Item
                  onClick={() => {
                    if (unassignedLicenses - selectedUsers.length <= 0) {
                      setIsModalOpen(true);
                    } else {
                      setIsApproveModalOpen(true);
                      setUserDetails(user);
                    }
                  }}
                >
                  Approve License
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    setIsDenyConfirmModalOpen(true);
                    setUserDetails(user);
                  }}
                >
                  Deny License
                </Menu.Item>
              </>
            )}
          </Menu.Dropdown>
        </Menu>
      </Table.Td>
    </Table.Tr>
  ));
};

View.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  setUserDetails: PropTypes.func.isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  setIsUserDetailsModalOpen: PropTypes.func.isRequired,
  setIsUnassignConfirmModalOpen: PropTypes.func.isRequired,
  setIsRevokeInvitationConfirmModalOpen: PropTypes.func.isRequired,
  unassignedLicenses: PropTypes.number.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  setIsAssignConfirmModalOpen: PropTypes.func.isRequired,
  setIsApproveModalOpen: PropTypes.func.isRequired,
  setIsDenyConfirmModalOpen: PropTypes.func.isRequired
};

export default View;
