import { NumberInput } from '@mantine/core';
import styles from './styles.module.scss';

const NumberInputTheme = NumberInput.extend({
  defaultProps: {
    radius: 'sm',
    hideControls: true
  },
  classNames: {
    label: styles.inputLabel,
    input: styles.inputText,
    section: styles.section
  }
});

export default NumberInputTheme;
