import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMediaQuery } from '@mantine/hooks';
import { Menu, Tooltip } from '@mantine/core';
import Table from 'components/Table';
import TableHeader from '../TableHeader';
import { useGetTeamQuery } from 'redux/services/settings';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { ACTIONS } from '../../utils';
import { maybePluralize } from 'utils/utilities';
import styles from '../../Teams.module.scss';

const TeamsTable = (props) => {
  const { teams, handleActions, handleTeamCreateClick } = props;

  const screen767 = useMediaQuery('(max-width: 767px)');
  const [searchValue, setSearchValue] = useState('');
  const { user } = useSelector((state) => state.user);

  const formattedRows = teams.reduce((acc, team) => {
    if (!team.companyWiki && team.name.toLowerCase().includes(searchValue.toLowerCase())) {
      acc.push({
        id: team.teamId,
        title: team.name,
        sources: `${team.sourcesCount} ${maybePluralize(team.sourcesCount, 'Source')}`,
        members: screen767
          ? undefined
          : `${team.membersCount} ${maybePluralize(team.membersCount, 'Member')}`,
        isMemberOfTeam: team.isUserMember,
        isTeamTable: true
      });
    }
    return acc;
  }, []);

  const actionMenuData = (data) => {
    const {
      data: teamDetails = {
        members: []
      },
      isFetching
    } = useGetTeamQuery(data.id);

    const isTeamAdmin =
      !isFetching &&
      Boolean(teamDetails.members.find((member) => member.userId === user.userId)?.admin);
    const disableLeaveTeam =
      !isFetching &&
      isTeamAdmin &&
      teamDetails.members.filter((member) => member.admin).length <= 1;
    const handleLeaveTeam = () => handleActions(ACTIONS.LEAVE_TEAM, data);

    const actions = [
      <Menu.Item
        key={`${data.id}-view-sources`}
        onClick={() => handleActions(ACTIONS.VIEW_SOURCES, data)}
      >
        View sources
      </Menu.Item>,
      <Menu.Item
        key={`${data.id}-edit-team`}
        onClick={() => handleActions(ACTIONS.EDIT_TEAM, data)}
      >
        Edit team
      </Menu.Item>
    ];

    if (data.isMemberOfTeam) {
      actions.push(
        <Menu.Item
          className={classNames({ [styles.disable]: disableLeaveTeam })}
          key={`${data.id}-leave-team`}
          onClick={!disableLeaveTeam && handleLeaveTeam}
        >
          Leave team
          {disableLeaveTeam && (
            <Tooltip label='Team should have atleast one admin'>
              <span>
                <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
              </span>
            </Tooltip>
          )}
        </Menu.Item>
      );
    }

    if (user.isAdmin) {
      actions.push(
        <Menu.Item
          key={`${data.id}-delete-team`}
          onClick={() => handleActions(ACTIONS.DELETE_TEAM, data)}
        >
          Delete team
        </Menu.Item>
      );
    }

    return actions;
  };

  return (
    <>
      <TableHeader
        value={searchValue}
        setValue={setSearchValue}
        ctaHandler={handleTeamCreateClick}
        isTeamTable
      />
      <Table
        headers={screen767 ? ['Teams', 'Sources', ''] : ['Teams', 'Sources', 'Members', '']}
        rows={formattedRows}
        actionMenuData={actionMenuData}
        rowClickHandler={(row) => handleActions(ACTIONS.EDIT_TEAM, row)}
      />
    </>
  );
};

TeamsTable.defaultProps = {
  teams: [],
  value: '',
  setValue: () => null
};

TeamsTable.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      sourcesCount: PropTypes.number,
      membersCount: PropTypes.number,
      isUserMember: PropTypes.bool
    })
  ),
  handleActions: PropTypes.func.isRequired,
  handleTeamCreateClick: PropTypes.func.isRequired
};

export default TeamsTable;
