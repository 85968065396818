import { useState } from 'react';
/**
 * A custom React hook that provides input handling functionality.
 *
 * @param {string} initialValue - The initial value of the input.
 * @returns {{ value: string, onChange: function, reset: function }} An object containing the current input value, an event handler function for input changes, and a function to reset the input to its initial value.
 */

function useInput(initialValue) {
  const [value, setValue] = useState(initialValue);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const reset = () => {
    setValue(initialValue);
  };

  return {
    value,
    onChange: handleChange,
    reset
  };
}

export default useInput;
