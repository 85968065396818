import { toast } from 'react-toastify';
import { INVITE, INVITE_REVOKE } from 'utils/apiEndpoints';
import api from './api';

const inviteApi = api.injectEndpoints({
  endpoints: (build) => ({
    inviteUsers: build.mutation({
      query: (inviteData) => ({
        url: INVITE,
        method: 'POST',
        body: inviteData
      }),
      transformResponse: (response) => {
        if (response.ok) {
          toast.success(response.message);
        }
        return response;
      },
      transformErrorResponse: ({ data }) => {
        toast.error(data.message);
        return data;
      }
    }),

    revokeInvitations: build.mutation({
      query: (revokeData) => ({
        url: INVITE_REVOKE,
        method: 'POST',
        body: revokeData
      }),
      transformResponse: (response) => {
        if (response.ok) {
          toast.success(response.message);
        } else {
          toast.error('Failed to revoke users');
        }
      },
      invalidatesTags: ['Licenses', 'Plan']
    }),

    updateInvitation: build.mutation({
      query: ({ id, data }) => ({
        url: `invite/${id}`,
        method: 'PATCH',
        body: data
      }),
      transformResponse: (response) => {
        if (response.ok) {
          toast.success(response.message);
        } else {
          toast.error('Failed to update license');
        }
      },
      invalidatesTags: ['Licenses', 'Plan']
    })
  })
});

export const { useInviteUsersMutation, useRevokeInvitationsMutation, useUpdateInvitationMutation } =
  inviteApi;

export default inviteApi;
