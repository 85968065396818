import checkForElement, { stepConstructor, tutorialIntiator } from 'components/TutorialWrapper';

function moreFeaturesTutorialBuilder(showTutorialModal) {
  const steps = [
    {
      element: '#persona',
      popover: {
        description: stepConstructor(
          'What are personas?',
          `Personas define Albus's personality. You can define his tone, language and behavior by adjusting his persona.`
        )
      }
    },
    {
      element: '#auto-answer',
      popover: {
        description: stepConstructor(
          'What does auto-answer do?',
          `When you enable auto-answer on a channel, Albus listens to every message sent on the channel. If he finds a question or topic he has information on, he will reply to the message to help your colleagues.
            `
        )
      }
    },
    {
      element: '#search-capabilities',
      popover: {
        description: stepConstructor(
          'What search capabilities can you configure?',
          `Control how Albus generates answers - using your wiki data, GPT-4o public data trained up to April 2023 or both.`
        )
      }
    }
  ];

  tutorialIntiator(steps, showTutorialModal);
}

export function startMoreFeaturesTutorial(showTutorialModal) {
  checkForElement('persona', moreFeaturesTutorialBuilder, showTutorialModal);
}
