import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@mantine/core';
import { ReactComponent as FullPreviewIllustration } from 'assets/widgets/full-preview.svg';
import SmallPreview from './SmallPreview.jsx';
import styles from './styles.module.scss';

const FullPreview = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className={styles.fullPreview} style={{ display: props.show ? 'flex' : 'none' }}>
        <div className={styles.illustration}>
          <FullPreviewIllustration />
        </div>
        <div className={styles.previewCta} onClick={() => setOpen(true)}>
          View full preview
        </div>
      </div>
      <Modal
        zIndex={500}
        centered
        opened={open}
        onClose={() => setOpen(false)}
        fullScreen
        classNames={{ body: styles.modalBody }}
        overflow='inside'
      >
        <SmallPreview inModal={true} showLauncher={false} {...props} />
      </Modal>
    </>
  );
};

FullPreview.propTypes = {
  show: PropTypes.bool.isRequired
};

export default FullPreview;
