import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Table, Checkbox, Skeleton } from '@mantine/core';
import Rows from './Rows';
import styles from './styles.module.scss';

const BillingTable = (props) => {
  const {
    handleSelectAllUsers,
    selectAll,
    licenseStatus,
    setIsDenyConfirmModalOpen,
    setIsApproveModalOpen,
    setIsModalOpen,
    setIsAssignConfirmModalOpen,
    unassignedLicenses,
    setIsRevokeInvitationConfirmModalOpen,
    setIsUnassignConfirmModalOpen,
    setIsUserDetailsModalOpen,
    selectedUsers,
    setSelectedUsers,
    setUserDetails,
    searchQuery
  } = props;

  const { users = [], isLoading: isUsersLoading } = useSelector((state) => state.license);
  const { planInfo } = useSelector((state) => state.plan);

  const loadingHeaderBars = (count) => {
    return Array.from({ length: count }, (_, i) => (
      <Table.Th key={i}>
        &nbsp;
        <Skeleton height={20} radius={2} />
      </Table.Th>
    ));
  };

  const loadingDataBars = (count) => {
    return Array.from({ length: count }, (_, i) => (
      <Table.Td key={i}>
        &nbsp;
        <Skeleton height={20} radius={2} />
      </Table.Td>
    ));
  };

  return (
    <Table className={styles.licensesTable}>
      <Table.Thead>
        {isUsersLoading && <Table.Tr>{loadingHeaderBars(5)}</Table.Tr>}

        {!isUsersLoading && (
          <Table.Tr>
            <Table.Th>
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAllUsers}
                color='green'
                radius={4}
              />
            </Table.Th>
            {licenseStatus !== 'invited' && <Table.Th>Name</Table.Th>}
            <Table.Th>Email</Table.Th>
            {licenseStatus === 'invited' && <Table.Th>Invited on</Table.Th>}
            {licenseStatus === 'requested' && <Table.Th>Requested on</Table.Th>}
            {licenseStatus === 'invited' ? (
              <Table.Th>Assign License</Table.Th>
            ) : (
              <Table.Th>License Status</Table.Th>
            )}
          </Table.Tr>
        )}
      </Table.Thead>
      <Table.Tbody>
        {!isUsersLoading && planInfo?.plan?.licensesBought > 0 && (
          <Rows
            searchQuery={searchQuery}
            setIsModalOpen={setIsModalOpen}
            setIsDenyConfirmModalOpen={setIsDenyConfirmModalOpen}
            setIsApproveModalOpen={setIsApproveModalOpen}
            setIsAssignConfirmModalOpen={setIsAssignConfirmModalOpen}
            unassignedLicenses={unassignedLicenses}
            setIsRevokeInvitationConfirmModalOpen={setIsRevokeInvitationConfirmModalOpen}
            setIsUnassignConfirmModalOpen={setIsUnassignConfirmModalOpen}
            setIsUserDetailsModalOpen={setIsUserDetailsModalOpen}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            setUserDetails={setUserDetails}
          />
        )}

        {isUsersLoading &&
          users.map((e, i) => <Table.Tr key={'e' + i}>{loadingDataBars(5)}</Table.Tr>)}
      </Table.Tbody>
    </Table>
  );
};

BillingTable.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  handleSelectAllUsers: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired,
  licenseStatus: PropTypes.string.isRequired,
  setUserDetails: PropTypes.func.isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  setIsUserDetailsModalOpen: PropTypes.func.isRequired,
  setIsUnassignConfirmModalOpen: PropTypes.func.isRequired,
  setIsRevokeInvitationConfirmModalOpen: PropTypes.func.isRequired,
  unassignedLicenses: PropTypes.number.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  setIsAssignConfirmModalOpen: PropTypes.func.isRequired,
  setIsApproveModalOpen: PropTypes.func.isRequired,
  setIsDenyConfirmModalOpen: PropTypes.func.isRequired
};

export default BillingTable;
