import { Anchor, Button } from '@mantine/core';
import PropTypes from 'prop-types';
import RouteErrorIcon from 'assets/icons/route-error.svg';
import styles from './styles.module.scss';

const CustomMessageErrorPage = ({ message, handleCtaClick }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const subText = searchParams.get('subText');

  function displayText() {
    if (subText) {
      return <p>{subText}</p>;
    }

    return (
      <p>
        If you think this is a mistake, contact the wizards responsible at&nbsp;
        <Anchor href='mailto:support@springworks.in'>support@springworks.in</Anchor>
      </p>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img src={RouteErrorIcon} alt='router-icon' />
        <div className={styles.body}>
          <h3>{message}</h3>
          {displayText()}
        </div>
      </div>
      <Button className='primaryBtn' onClick={handleCtaClick}>
        Take me back home
      </Button>
    </div>
  );
};

CustomMessageErrorPage.propTypes = {
  message: PropTypes.string.isRequired,
  handleCtaClick: PropTypes.func.isRequired
};

export default CustomMessageErrorPage;
