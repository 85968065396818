import { Stepper } from '@mantine/core';
import styles from './styles.module.scss';

const StepperTheme = Stepper.extend({
  defaultProps: {
    breakpoint: 'sm',
    iconSize: 20
  },
  classNames: {
    root: styles.stepper,
    separator: styles.separator,
    steps: styles.steps,
    stepIcon: styles.stepIcon,
    stepCompletedIcon: styles.stepCompletedIcon
  }
});

export default StepperTheme;
