import dayjs from 'dayjs';
import { DATE_DROPDOWN_VALUES } from 'utils/constants';

/**

    Returns start and end dates based on selected date option and date range
    @param {string} selectedOption - The selected option from date dropdown
    @param {{ start: string | undefined, end: string | undefined }} dateRange - Date range object with start and end dates
    @returns {{ start: string, end: string }} - Object containing start and end dates in 'YYYY-MM-DD' format
    */
export const getDateForSelectedOption = (selectedOption, dateRange) => {
  let start, end;
  if (selectedOption === DATE_DROPDOWN_VALUES.ALL) {
    start = null;
    end = null;
  }
  if (selectedOption === DATE_DROPDOWN_VALUES.TODAY) {
    start = dayjs().format('YYYY-MM-DD');
    end = dayjs().format('YYYY-MM-DD');
  }
  if (selectedOption === DATE_DROPDOWN_VALUES.YESTERDAY) {
    start = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
    end = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
  }
  if (selectedOption === DATE_DROPDOWN_VALUES.CUSTOM) {
    start = dateRange?.start && dayjs(dateRange.start).format('YYYY-MM-DD');
    end = dateRange?.end && dayjs(dateRange.end).format('YYYY-MM-DD');
  }

  return { start, end };
};
