import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useGetTeamQuery, useUpdateTeamMutation } from 'redux/services/settings';
import Layout from 'components/Layout';
import Heading from '../Heading';
import classNames from 'classnames';
import styles from '../Teams.module.scss';

const PageWrapper = (props) => {
  const { children, alignCenter, setSelectedTeamId, selectedTeamId, pageTitle } = props;
  const {
    data: teamDetails = {
      members: []
    },
    isFetching
  } = useGetTeamQuery(selectedTeamId, { skip: !selectedTeamId });
  const [updateTeam, { isLoading: updatingTeam }] = useUpdateTeamMutation();
  const navigate = useNavigate();

  const handleBackBtnClick = () => {
    setSelectedTeamId(null);
    navigate('/teams');
  };

  const handleTeamNameChange = async (value) => {
    await updateTeam({ teamId: selectedTeamId, body: { name: value } });
  };

  return (
    <Layout
      pageTitle={
        selectedTeamId ? (
          <Heading
            teamName={isFetching ? '' : teamDetails.name}
            backBtnClickHandeler={handleBackBtnClick}
            handleTeamNameChange={handleTeamNameChange}
            disabled={updatingTeam}
          />
        ) : (
          pageTitle
        )
      }
    >
      <div className={classNames(styles.teamsPageWrapper, { [styles.centerAlign]: alignCenter })}>
        {children}
      </div>
    </Layout>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  alignCenter: PropTypes.bool,
  setSelectedTeamId: PropTypes.func,
  selectedTeamId: PropTypes.string,
  pageTitle: PropTypes.string
};

PageWrapper.defaultProps = {
  alignCenter: false,
  selectedTeamId: null
};

export default PageWrapper;
