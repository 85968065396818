import dayjs from 'dayjs';

/**
 * Capitalizes the initials of each word in a given string.
 *
 * @param {string} name - The string to extract initials from.
 * @returns {string} Capitalized initials of each word
 *
 * @example
 * capitalizeInitials("spider man");
 * // returns "SM"
 */
export function capitalizeInitials(name) {
  if (name) {
    return name
      .split(/[\s.]+/)
      .map((part) => part.charAt(0).toUpperCase())
      .join('');
  }
  return name;
}

/**
 * Capitalizes the first letter of each word in a given string.
 *
 * @param {string} name - The string to capitalize.
 * @returns {string} Capitalized first letter of each word
 *
 * @example
 * capitalizeFirstLetter("spider man");
 * // returns "Spider Man"
 */
export function capitalizeFirstLetter(name) {
  if (name) {
    return name
      .split(/[\s.]+/)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ');
  }
  return name;
}

/**
 * format date using dayjs
 * @param {string} date
 * @param {string} [format='DD MMMM, YYYY']
 * @returns {string} formatted date
 */
export function formatDate(date, format = 'DD MMMM, YYYY') {
  if (!date) {
    return;
  }
  return dayjs(date).format(format);
}

/**
 * Converts Slack links and normal URLs in a text into an array of formatted links.
 *
 * @param {string} text - The text to be converted.
 * @returns {Array} - An array of formatted links.
 */
export function convertSlackLinks(text) {
  const slackLinkRegex = /<([^|>]+)\|([^>]+)>/g; // Format - <url|name>
  const normalLinkRegex = /(?:(?:https?|ftp):\/\/[^\s]+)/g; // Format - https://url.com

  const containsSlackLinks = slackLinkRegex.test(text);

  let result = [];
  let lastIndex = 0;

  if (containsSlackLinks) {
    text.replace(slackLinkRegex, (match, slackLink, slackName, index) => {
      result.push(text.substring(lastIndex, index));
      result.push({ link: slackLink, name: slackName });
      lastIndex = index + match.length;
      return match;
    });
  } else {
    text.replace(normalLinkRegex, (match, index) => {
      const url = match;
      result.push(text.substring(lastIndex, index));
      result.push({ link: url, name: url });
      lastIndex = index + url.length;
      return match;
    });
  }

  result.push(text.substring(lastIndex));
  return result;
}

/**
 * Converts potential URLs in a text into formatted links.
 * @param {string} text - The input text.
 * @returns {Array} - An array containing the formatted links and remaining text.
 */
export function convertPotentialUrls(text) {
  const pattern =
    /\b(?:https?:\/\/)?(?:www\.[^\s]+|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/\S+)?)(?![\w.-])\b/g;
  const urls = text.match(pattern);

  if (!urls) {
    return text;
  }

  let result = '';
  let lastIndex = 0;

  urls.forEach((url) => {
    const formattedUrl = url.startsWith('http') ? `<${url}>` : `[${url}](http://${url})`;
    result += text.substring(lastIndex, text.indexOf(url, lastIndex));
    result += formattedUrl;
    lastIndex = text.indexOf(url, lastIndex) + url.length;
  });

  result += text.substring(lastIndex);
  return result;
}

/**
 * Sanitizes the user prompt by removing the search filters if present
 * @param {string} prompt - the user prompt may/ may not containing filters
 * @returns {string} - the sanitized user prompt with the search filters removed
 */
export const sanitizePrompt = (prompt) => {
  const regex = /^\[([^\]]+)\]\s*(.*)/;
  const match = regex.exec(prompt);

  if (match) {
    const sanitizedPrompt = match[2].trim();
    return sanitizedPrompt;
  }

  return prompt;
};
