import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import Header from './Components/Header/View';
import SourcesDataView from './Components/SourcesDataView/View';
import integrationsApi, { useDisconnectSourcesMutation } from 'redux/services/integrations';
import { ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS, PROVIDERS } from 'utils/constants';
import { SOURCES_STATUS } from '../constants';

const DataView = (props) => {
  const {
    viewType,
    setViewType,
    user,
    teamName,
    teamId,
    loading,
    sources,
    totalCount,
    pagination,
    debouncedValue,
    searchValue,
    setSearchValue,
    filter,
    setFilter,
    metaPayload
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [source, setSource] = useState({
    selectAll: false,
    showRemoveModal: false,
    selected: []
  });
  const [totalItems, setTotalItems] = useState([]);
  const allInProcessing = sources.every((source) => source.status === SOURCES_STATUS.PROCESSING);
  const [disconnectSources, { isLoading: isDisconnectSourcesLoading }] =
    useDisconnectSourcesMutation();

  function handleManageSourcesClick(item) {
    const sourceName = encodeURIComponent(item.originalName || item.name);
    if (item.childCounts.total > 0 || item.status === 'error') {
      navigate(
        `/integrations/web/${teamId}/manageSources?docId=${item.id}&teamName=${teamName}&sourceName=${sourceName}&hasChildSources=${item.isParent}`
      );
    }
  }

  async function handleDeleteSources() {
    const payload = {
      provider: PROVIDERS.WEB,
      teamId,
      ...(source.selected.length ? { documentMongoIds: source.selected } : {})
    };

    const response = await disconnectSources(payload);
    if (response?.data?.ok) {
      toast.success(response.data.message);
      setTotalItems((prevItems) => prevItems.filter((item) => !source.selected.includes(item.id)));
      dispatch(integrationsApi.util.invalidateTags(['SourcesByProvider']));
      setSource((prevState) => ({
        ...prevState,
        showRemoveModal: false,
        selected: [],
        selectAll: false
      }));
    }
  }

  function handleSelectAll() {
    setSource((prevState) => ({
      ...prevState,
      selectAll: !prevState.selectAll,
      selected: []
    }));
  }

  function handleCheckboxCta(source) {
    setSource((prevState) => {
      const uncheckSelectedSource = prevState.selected.includes(source.id);

      const updatedSelectedSources = uncheckSelectedSource
        ? prevState.selected.filter((sourceId) => sourceId !== source.id)
        : [...prevState.selected, source.id];

      return {
        ...prevState,
        selected: updatedSelectedSources
      };
    });
  }

  return (
    <>
      <Header
        viewType={viewType}
        setViewType={setViewType}
        user={user}
        teamId={teamId}
        teamName={teamName}
        filter={filter}
        setFilter={setFilter}
        pagination={pagination}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        allInProcessing={allInProcessing}
        handleSelectAll={handleSelectAll}
        sourcesCount={sources.length}
        isAllSourcesSelected={source.selectAll}
      />

      <SourcesDataView
        viewType={viewType}
        setViewType={setViewType}
        totalItems={totalItems}
        setTotalItems={setTotalItems}
        sources={sources}
        user={user}
        isLoading={loading}
        filter={filter}
        pagination={pagination}
        pageCount={Math.ceil(totalCount / ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS)}
        debouncedValue={debouncedValue}
        handleManageSourcesClick={handleManageSourcesClick}
        metaPayload={metaPayload}
        selectedSources={source.selected}
        isAllSourcesSelected={source.selectAll}
        handleCheckboxCta={handleCheckboxCta}
        handleDeleteSources={handleDeleteSources}
        showRemoveSourceModal={source.showRemoveModal}
        setShowRemoveSourceModal={(value) =>
          setSource((prevState) => ({ ...prevState, showRemoveModal: value }))
        }
        isDisconnectSourcesLoading={isDisconnectSourcesLoading}
      />
    </>
  );
};

DataView.propTypes = {
  viewType: PropTypes.string.isRequired,
  setViewType: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  teamName: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  sources: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  pagination: PropTypes.object.isRequired,
  debouncedValue: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: {
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    }
  }).isRequired
};

export default DataView;
