import { ConnectSourceButtonUI } from '../../Components/ConnectSourceButton';
import { toast } from 'react-toastify';

const SlackConnectButton = () => {
  const handleConnect = async () => {
    setTimeout(() => {
      toast.success('Slack integrated successfully.');
    }, [1000]);
  };

  return <ConnectSourceButtonUI handleConnect={handleConnect} />;
};

export default SlackConnectButton;
