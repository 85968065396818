import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Group, Tabs } from '@mantine/core';
import { useForm } from '@mantine/form';
import omit from 'lodash.omit';
import FooterConfirmationBar from 'components/FooterConfirmationBar/index.jsx';
import { useCreateWidgetMutation, useUpdateWidgetMutation } from 'redux/services/widgets.js';
import AppearanceTab from './AppearanceTab';
import SearchTab from './SearchTab';
import WikiTab from './WikiTab';
import InstallationTab from './InstallationTab';
import WidgetPreview from './WidgetPreview';
import AnswerTab from './AnswerTab';
import { DEFAULT_WIDGET_SETTINGS, TABS } from './data.jsx';
import { NAVBAR_ROUTES } from 'utils/constants';
import styles from './styles.module.scss';

const WidgetDetails = ({ widget, postAction, handleClose }) => {
  const [activeTab, setActiveTab] = useState(TABS.appearance.value);
  const form = useForm({
    initialValues: widget ?? DEFAULT_WIDGET_SETTINGS
  });
  const navigate = useNavigate();
  const [updateWidget, { isLoading: isUpdating }] = useUpdateWidgetMutation();
  const [createWidget, { isLoading: isCreating }] = useCreateWidgetMutation();

  const handleUpdateWidget = async () => {
    let payload = {
      size: {
        height: `${form.values.height}px`,
        width: `${form.values.width}px`
      },
      theme: form.values.theme,
      botMessage: form.values.botMessage,
      welcomeMessage: form.values.welcomeMessage,
      position: form.values.position,
      name: form.values.name,
      search: form.values.search,
      allowedOrigins: form.values.allowedOrigins,
      teams: form.values.wikis.map((wiki) => wiki.id),
      persona: form.values.persona
    };
    const fieldsToRemove = [
      'id',
      'createdBy',
      'interactions',
      'organisation',
      'updatedBy',
      'whiteLabel'
    ];
    if (payload.allowedOrigins.length === 0) {
      fieldsToRemove.push('allowedOrigins');
    }
    if (payload.teams.length === 0) {
      fieldsToRemove.push('teams');
    }
    if (!payload.search.training && !payload.search.ai) {
      fieldsToRemove.push('search');
    }
    payload = omit(payload, fieldsToRemove);
    let resp;
    if (!widget) {
      resp = await createWidget(payload);
    } else {
      resp = await updateWidget({ id: form.values.id ?? widget.id, data: payload });
    }
    if (resp?.data?.ok) {
      postAction(resp.data.data?.id);
      if (!widget) {
        navigate(NAVBAR_ROUTES.CHAT_WIDGETS + '/' + resp.data.data.id, { replace: true });
      }
      form.resetDirty();
    }
  };

  const erroredTab = (tab) => {
    if (tab.value !== TABS.search.value && tab.value !== TABS.wiki.value) {
      return null;
    }
    if (tab.value === TABS.search.value && !form.values.search.ai && !form.values.search.training) {
      return tab.icon;
    }
    if (
      tab.value === TABS.wiki.value &&
      form.values.wikis.length > 0 &&
      form.values.wikis.every((wiki) => wiki.numberOfSources === 0)
    ) {
      return tab.icon;
    }
    return null;
  };

  const filteredTabList = { ...TABS };
  const isWikiSelected = form.values.search.training;

  if (isWikiSelected) {
    filteredTabList.wiki = TABS.wiki;
  } else {
    delete filteredTabList.wiki;
  }

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.tabWrapper, {
          [styles.divider]: activeTab === TABS.appearance.value,
          [styles.partitionedTab]: activeTab === TABS.appearance.value
        })}
      >
        <Tabs
          defaultValue={TABS.appearance.value}
          value={activeTab}
          onChange={setActiveTab}
          className='tabs-style-1'
          color='primaryGreen.3'
        >
          <Tabs.List>
            {Object.values(filteredTabList).map((tab) => (
              <Tabs.Tab key={tab.id} value={tab.value} leftSection={erroredTab(tab)}>
                {tab.label}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          <Tabs.Panel value={TABS.appearance.value}>
            <AppearanceTab form={form} />
          </Tabs.Panel>
          <Tabs.Panel value={TABS.search.value}>
            <SearchTab form={form} />
          </Tabs.Panel>
          {isWikiSelected && (
            <Tabs.Panel value={TABS.wiki.value}>
              <WikiTab
                wikis={form.values.wikis ?? []}
                updateWidget={(newWikis) =>
                  newWikis.forEach((wiki) => form.insertListItem('wikis', wiki))
                }
                isUpdating={isUpdating}
                onRemoveWiki={(wiki) => {
                  const currentWikis = form.values.wikis;
                  const newWikis = currentWikis.filter((currentWiki) => currentWiki.id !== wiki.id);
                  form.setFieldValue('wikis', newWikis);
                }}
              />
            </Tabs.Panel>
          )}
          <Tabs.Panel value={TABS.answer.value}>
            <AnswerTab form={form} />
          </Tabs.Panel>
          <Tabs.Panel value={TABS.installation.value}>
            <InstallationTab
              allowedOrigins={form.values.allowedOrigins}
              onOriginsChange={(origin) => {
                if (Array.isArray(origin)) {
                  form.setFieldValue('allowedOrigins', origin);
                } else {
                  form.insertListItem('allowedOrigins', origin);
                }
              }}
              setupComplete={
                form.values.wikis.length > 0 &&
                (form.values.search.ai || form.values.search.training)
              }
              widgetId={form.values.id ?? widget?.id}
            />
          </Tabs.Panel>
        </Tabs>
      </div>
      {activeTab === TABS.appearance.value && (
        <div className={styles.previewSection}>
          <WidgetPreview {...form.values} inModal={false} />
        </div>
      )}
      <FooterConfirmationBar show={!widget || form.isDirty()}>
        <Group justify='left'>
          <Button
            loading={isCreating || isUpdating}
            className={styles.primaryCta}
            onClick={handleUpdateWidget}
          >
            Save
          </Button>
          <Button className={styles.secondaryCta} onClick={handleClose}>
            Cancel
          </Button>
        </Group>
      </FooterConfirmationBar>
    </div>
  );
};

WidgetDetails.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.string,
    search: PropTypes.shape({
      ai: PropTypes.bool,
      training: PropTypes.bool
    })
  }),
  postAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

WidgetDetails.defaultProps = {
  widget: DEFAULT_WIDGET_SETTINGS
};

export default WidgetDetails;
