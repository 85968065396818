import { bool, func, oneOfType, string, array, node } from 'prop-types';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Chip = ({
  label,
  value,
  selectedItem,
  handleClick,
  showCloseIcon,
  multiple,
  handleCrossClick
}) => {
  const isSelected = multiple ? selectedItem.includes(value) : selectedItem === value;

  const clickHandler = () => {
    if (multiple) {
      if (isSelected) {
        const newSelectedItem = selectedItem.filter((item) => item !== value);
        handleClick(newSelectedItem);
      } else {
        handleClick([...selectedItem, value]);
      }
    } else {
      if (isSelected) {
        handleClick('');
      } else {
        handleClick(value);
      }
    }
  };

  return (
    <div
      onClick={() => clickHandler()}
      className={classNames(styles.wrapper, { [styles.selected]: isSelected })}
    >
      <label>{label}</label>

      {showCloseIcon && (
        <button onClick={() => handleCrossClick()}>
          <SVGIcon
            name={isSelected ? ICONS_LIST.CROSS_WHITE : ICONS_LIST.CROSS_RED}
            height={14}
            width={14}
          />
        </button>
      )}
    </div>
  );
};

Chip.defaultProps = {
  selectedItem: '',
  showCloseIcon: false,
  multiple: false,
  handleCrossClick: () => {}
};

Chip.propTypes = {
  label: oneOfType([string, node]).isRequired,
  value: string.isRequired,
  handleClick: func.isRequired,
  selectedItem: oneOfType([string, array]),
  showCloseIcon: bool,
  multiple: bool,
  handleCrossClick: func
};

export default Chip;
