import { Switch, Text, Flex, LoadingOverlay, Divider, Tooltip } from '@mantine/core';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useGetSettingsQuery, useUpdateSettingMutation } from 'redux/services/settings';
import DeleteWarningModal from '../../../components/Modals/DeleteWarningModal';
import styles from './styles.module.scss';

const SearchCapabilities = () => {
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isDisableAiWarningModalOpen, setIsDisableAiWarningModalOpen] = useState(false);
  const { data: orgSettings, isFetching: areSettingsFetching } = useGetSettingsQuery();
  const [updateSettings, { isLoading: isUpdatingSettings }] = useUpdateSettingMutation();
  const { planInfo } = useSelector((state) => state.plan);
  const { user } = useSelector((state) => state.user);
  const isSlackPlatform = user?.platform === 'slack';

  const handleSettingsChange = async (type, status, isModal) => {
    const toastData = {
      type,
      status
    };
    const payload = {};
    switch (type) {
      case 'wiki': {
        if (!status && isModal) {
          setIsWarningModalOpen(true);
          return;
        }
        payload.training = status;
        break;
      }
      case 'ai': {
        if (!status && isModal) {
          setIsDisableAiWarningModalOpen(true);
          return;
        }
        payload.ai = status;
        toastData.enableState = status;
        toastData.type = 'AI';
        break;
      }
      default:
        break;
    }

    const response = await updateSettings(payload);
    setIsWarningModalOpen(false);
    setIsDisableAiWarningModalOpen(false);
    if (response.data) {
      toast.success(`${toastData.status ? 'Enabled' : 'Disabled'} ${toastData.type} successfully`);
    }
  };

  if (isDisableAiWarningModalOpen) {
    return (
      <DeleteWarningModal
        open={isDisableAiWarningModalOpen}
        header='Disable AI'
        title={`Are you sure you want disable AI?`}
        handleDelete={() => handleSettingsChange('ai', false)}
        body='Note: When you disable AI, Albus will no longer respond to your teammates with information
        outside your wiki.'
        handleCancel={() => setIsDisableAiWarningModalOpen(false)}
        onClose={() => setIsDisableAiWarningModalOpen(false)}
        removeText='Disable'
      />
    );
  }

  if (isWarningModalOpen) {
    return (
      <DeleteWarningModal
        open={isWarningModalOpen}
        header='Stop training'
        title={`Are you sure you want me to discontinue my training?`}
        handleDelete={() => handleSettingsChange('wiki', false)}
        body="When you stop my training, I remove all your sources and unlearn everything I absorbed from them. This is what an old wizard's got to do when they're aging, friend."
        handleCancel={() => setIsWarningModalOpen(false)}
        onClose={() => setIsWarningModalOpen(false)}
        removeText='Stop training'
      />
    );
  }

  return (
    <div className={styles.container}>
      <LoadingOverlay visible={isUpdatingSettings || areSettingsFetching} />
      <Text className={styles.heading}>
        When someone asks a question through the API, Albus should answer using:
      </Text>
      <Flex className={styles.wrapper}>
        <Text className={styles.title}>Wiki data</Text>
        <Switch
          size='md'
          checked={orgSettings?.training}
          disabled={!user.isAdmin}
          onChange={(event) => handleSettingsChange('wiki', event.currentTarget.checked, true)}
          classNames={{
            track: classNames(styles.switch, { [styles.checked]: orgSettings?.training }),
            thumb: styles.thumb
          }}
        />
      </Flex>
      <Text className={styles.details}>
        Answer using data sources like company policies,
        {isSlackPlatform ? ' Slack conversations' : ''} and team documentation across 30+ workplace
        apps including Google Drive{isSlackPlatform ? ' , Slack ' : ''}and Jira.
      </Text>
      <Divider className={styles.divider} />
      <Flex className={styles.wrapper}>
        <Tooltip
          w={274}
          disabled={planInfo.hasSubscription}
          label='To enable AI search capabilities, upgrade to a paid plan.'
          className={styles.tooltip}
        >
          <Text
            className={classNames(styles.title, {
              [styles.titleDisabled]: !planInfo.hasSubscription
            })}
          >
            Online data up to 2023
          </Text>
        </Tooltip>
        <Tooltip
          refProp='rootRef'
          w={274}
          disabled={planInfo.hasSubscription}
          label='To enable AI search capabilities, upgrade to a paid plan.'
          className={styles.tooltip}
        >
          <Switch
            size='md'
            checked={orgSettings?.ai}
            disabled={!user.isAdmin || !planInfo.hasSubscription}
            onChange={(event) => handleSettingsChange('ai', event.currentTarget.checked, true)}
            classNames={{
              track: classNames(styles.switch, {
                [styles.checked]: orgSettings?.ai,
                [styles.disableSwitch]: !planInfo.hasSubscription
              }),
              thumb: styles.thumb
            }}
          />
        </Tooltip>
      </Flex>
      <Tooltip
        w={274}
        disabled={planInfo.hasSubscription}
        label='To enable AI search capabilities, upgrade to a paid plan.'
        className={styles.tooltip}
      >
        <Text
          className={classNames(styles.details, {
            [styles.detailsDisabled]: !planInfo.hasSubscription
          })}
        >
          Answer questions using GPT-4o that is trained on online, public data up to December, 2023.
        </Text>
      </Tooltip>
    </div>
  );
};

export default SearchCapabilities;
