import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import View from './View';
import { useInviteUsersMutation } from 'redux/services/invite';
import { emailRegex } from '../../../utils/helpers';
import styles from './styles.module.scss';

const ViaEmail = ({ open, handleClose }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [showNewCTA, setShowNewCTA] = useState(false);
  const [sendWithoutLicensesLoading, setSendWithoutLicensesLoading] = useState(false);
  const [getMoreLicensesLoading, setGetMoreLicensesLoading] = useState(false);
  const [inviteUsers, { isLoading }] = useInviteUsersMutation();
  const emailSchema = Yup.string().matches(emailRegex);
  const { planInfo } = useSelector((state) => state.plan);
  const unassignedLicenses = planInfo?.plan
    ? Math.max(planInfo.plan.licensesBought - planInfo.plan.licensesAssigned, 0)
    : 0;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    if (!open) {
      setData([]);
      setTableData([]);
      setShowNewCTA(false);
      setError(null);
    }

    if (tableData.length === unassignedLicenses) {
      setShowNewCTA(false);
    }
  }, [open, unassignedLicenses]);

  const validateEmail = (query) => {
    const isValid = emailSchema.isValidSync(query);
    return isValid;
  };

  const handleTagsInputChange = (value) => {
    setData(value);
    // Check if email is valid
    const isInvalid = value.some((email) => !validateEmail(email));
    setError(isInvalid ? 'This email is invalid.' : null);
    // Check if email is already present in the table
    const query = value[value.length - 1];
    if (tableData.some((row) => row.email?.toLowerCase() === query?.toLowerCase())) {
      setError('This email is already added.');
    }
  };

  const handleCreateEmail = (query) => {
    if (query.length === 0) {
      return;
    }
    if (validateEmail(query)) {
      const newEmail = { value: query, label: query };
      setData((current) => [...current, newEmail]);
      return newEmail;
    }
    return null;
  };

  const handleAddButtonClick = () => {
    const newTableData = data.map((email) => ({
      email,
      selected: true
    }));

    setTableData((prevTableData) => [...prevTableData, ...newTableData]);
    setData([]);
    setIsTableVisible(true);
  };

  const handleCheckboxChange = (index) => {
    setTableData((current) =>
      current.map((row, i) => (i === index ? { ...row, selected: !row.selected } : row))
    );
  };

  const handleDeleteEmail = (index) => {
    setTableData((current) => current.filter((_, i) => i !== index));
  };

  const handleDiscardButton = () => {
    setData([]);
    setTableData([]);
    setIsTableVisible(false);
    setOpenDeleteModal(false);
    handleClose();
  };

  const handleCancel = () => {
    setOpenDeleteModal(true);
  };

  const handleSendClick = async () => {
    const totaldataInTable = tableData.filter((email) => email.selected).length;
    if (totaldataInTable <= unassignedLicenses || planInfo.isTrial) {
      const payload = {
        type: 'user',
        users: tableData.map((email) => ({
          email: email.email,
          autoAssignLicense: email.selected
        })),
        autoBuyLicense: false
      };

      try {
        const { data } = await inviteUsers(payload);
        if (data?.ok) {
          setTableData([]);
        }
      } catch (error) {
        toast.error('Failed to send request', error);
      }
    } else {
      setShowNewCTA(totaldataInTable > unassignedLicenses);
    }
  };

  const handleSendWithoutLicensesClick = async () => {
    const payload = {
      type: 'user',
      users: tableData.map((email) => ({
        email: email.email,
        autoAssignLicense: email.selected
      })),
      autoBuyLicense: false
    };

    try {
      setSendWithoutLicensesLoading(true);
      const { data } = await inviteUsers(payload);
      if (data?.ok) {
        setTableData([]);
        setSendWithoutLicensesLoading(false);
        setShowNewCTA(false);
      } else {
        setSendWithoutLicensesLoading(false);
      }
    } catch (error) {
      console.error('Failed to send request', error);
    }
  };

  const handleGetMoreLicensesClick = async () => {
    const payload = {
      type: 'user',
      users: tableData.map((email) => ({ email: email.email, autoAssignLicense: true })),
      autoBuyLicense: true
    };

    try {
      setGetMoreLicensesLoading(true);
      const { data } = await inviteUsers(payload);
      if (data?.ok) {
        setTableData([]);
        setGetMoreLicensesLoading(false);
        setShowNewCTA(false);
      } else {
        setGetMoreLicensesLoading(false);
      }
    } catch (error) {
      console.error('Failed to send request', error);
    }
  };

  return (
    <>
      <View
        isLoading={isLoading}
        getMoreLicensesLoading={getMoreLicensesLoading}
        sendWithoutLicensesLoading={sendWithoutLicensesLoading}
        open={open}
        handleClose={handleClose}
        data={data}
        error={error}
        tableData={tableData}
        handleTagsInputChange={handleTagsInputChange}
        handleCreateEmail={handleCreateEmail}
        handleAddButtonClick={handleAddButtonClick}
        handleCheckboxChange={handleCheckboxChange}
        handleDeleteEmail={handleDeleteEmail}
        isTableVisible={isTableVisible}
        handleCancelClick={handleCancel}
        handleSendClick={handleSendClick}
        shouldShowNewCTA={showNewCTA}
        handleGetMoreLicensesClick={handleGetMoreLicensesClick}
        handleSendWithoutLicensesClick={handleSendWithoutLicensesClick}
        enoughLicenses={unassignedLicenses}
      />

      <DeleteWarningModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        header='Discard emails'
        title='Are you sure you do not want to send invites to the teammates added here?'
        handleCancel={() => setOpenDeleteModal(false)}
        handleDelete={handleDiscardButton}
        removeText='Discard'
      >
        <p className={styles.fadedText}>
          Note:
          <span className={styles.noteText}>
            &nbsp;The teammate has not received an invite yet, and any progress you have made here
            will be lost.
          </span>
        </p>
      </DeleteWarningModal>
    </>
  );
};

ViaEmail.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ViaEmail;
